import styled from 'styled-components';

const InputContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 6px;
  gap: 4px;
`;

export default InputContainer;
