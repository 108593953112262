// task state
import getStates from './getStates.state.task';

// task states
import noStateState from '../states/noState.state.task';

const getState = (state) =>
  [...getStates()].find((stateToFind) => stateToFind.state === state) ||
  noStateState;

export default getState;
