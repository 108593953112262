// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// events lib
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

// service api
import createServiceApi from '../../api/create.api.service';

// service attributes
import categoryAttribute from '../../attributes/category.attribute.service';
import defaultCostAttribute from '../../attributes/default_cost.attribute.service';

// service components
import ConvertCustomServiceItemsModal from '../../components/ConvertCustomServiceItemsModal/ConvertCustomServiceItemsModal';

// service events
import showConvertServiceItemsModalEvent from '../../events/showConvertCustomItemsModal.event.service';
import serviceItemsConvertedEvent from '../../events/converted.event.service';

// service lib
import getCategories from '../../lib/getCategories.lib.service';

// service preparations
import convertCustomItemsPreparation from '../../preparation/convert.preparation.service';

class ConvertCustomServiceItemsContainer extends Component {
  static propTypes = {
    services: PropTypes.array,
    subscribe: PropTypes.func,
  };

  static DEFAULT_STATE = {
    category: categoryAttribute(''),
    default_cost: defaultCostAttribute(''),
    loading: false,
    serviceItems: [],
    visible: false,
  };

  state = {
    ...this.constructor.DEFAULT_STATE,
  };

  componentDidMount() {
    this.props.subscribe(
      showConvertServiceItemsModalEvent.subscribe(this.show)
    );
  }

  show = ({serviceItems}) => {
    this.setState({
      ...this.constructor.DEFAULT_STATE,
      serviceItems,
      visible: true,
    });
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  change = (value, key) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  convert = async () => {
    const {category, default_cost, loading, serviceItems} = this.state;

    if (loading) return;

    this.setState({loading: true});

    try {
      const preparedServiceItem = await convertCustomItemsPreparation({
        category,
        default_cost,
      });
      await Promise.all(
        serviceItems.map((serviceItem) =>
          createServiceApi({
            ...preparedServiceItem,
            name: serviceItem.custom_service,
          })
        )
      );
      serviceItemsConvertedEvent.publish();
      this.setState({loading: false, visible: false});
      alert.success('Items converted successfully');
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  categories = () =>
    getCategories(this.props.services).map(({name}) => ({
      value: name,
      label: name,
    }));

  render() {
    const {category, default_cost, loading, serviceItems, visible} = this.state;
    return (
      <ConvertCustomServiceItemsModal
        categories={this.categories()}
        category={category}
        default_cost={default_cost}
        loading={loading}
        onChange={this.change}
        onClose={this.hide}
        onSave={this.convert}
        serviceItems={serviceItems}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(
  connect((state) => ({services: state.spotlight.services}))(
    ConvertCustomServiceItemsContainer
  )
);
