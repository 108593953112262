// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// ticket components
import TicketAlerts from '../../components/TicketAlerts/TicketAlerts';

// types
import amountType from '@matthahn/sally-fw/lib/type/types/amount.type';
import dateType from '@matthahn/sally-fw/lib/type/types/date.type';
import shortDateTimeType from '@matthahn/sally-fw/lib/type/types/shortDateTime.type';

// vehicle lib
import hasRegistrationExpired from '../../../vehicle/lib/hasRegistrationExpired.lib.vehicle';
import milesSinceLastSeen from '../../../vehicle/lib/milesSinceLastSeen.lib.vehicle';
import shouldAlertMilesSinceLastSeen from '../../../vehicle/lib/shouldAlertMilesSinceLastSeen.lib.vehicle';
import shouldChangeOil from '../../../vehicle/lib/shouldChangeOil.lib.vehicle';

class TicketAlertsContainer extends Component {
  static propTypes = {
    georgeVehicle: PropTypes.object,
    syncTicket: PropTypes.func,
    ticket: PropTypes.object,
  };

  alerts = () => {
    const {ticket, georgeVehicle} = this.props;
    return [
      ticket?.approved && {
        key: 'approved',
        type: 'info',
        content: [
          'Ticket approved',
          !!ticket?.approved_at &&
            `on ${shortDateTimeType(ticket?.approved_at).format()}`,
          !!ticket?.approved_by && `by ${ticket?.approved_by}`,
        ]
          .filter((text) => !!text)
          .join(' '),
      },
      !ticket?.vehicle?.approved_for_work && {
        key: 'approvedForWork',
        type: 'error',
        content: 'This vehicle is not approved',
      },
      hasRegistrationExpired(georgeVehicle) && {
        key: 'registration',
        type: 'error',
        content: !georgeVehicle?.registration_expiry
          ? 'Missing registration'
          : `Registration expired on ${dateType(
              georgeVehicle?.registration_expiry
            ).format()}`,
      },
      !ticket?.approved &&
        shouldChangeOil({vehicle: georgeVehicle}) && {
          key: 'oilChange',
          type: 'error',
          content: 'Change oil',
        },
      !ticket?.approved &&
        shouldAlertMilesSinceLastSeen({vehicle: georgeVehicle}) && {
          key: 'milesSinceLastSeen',
          type: 'error',
          content: `Last seen ${amountType(
            milesSinceLastSeen({
              vehicle: georgeVehicle,
            })
          ).format()} miles ago`,
        },
    ].filter((info) => !!info);
  };

  render() {
    return <TicketAlerts alerts={this.alerts()} />;
  }
}

export default TicketAlertsContainer;
