import {SET} from './constants';

export const set = (data = {}) => ({
  type: SET,
  data,
});

export const setPage = (page) => (dispatch, getState) => {
  const previousPages = [...getState().page.previousPages, page];
  dispatch(set({page, previousPages}));
};
