import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  padding: 500px 24px 6px 24px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  position: relative;
  z-index: 10;
`;

export default Container;
