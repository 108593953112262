import f3tch from 'f3tch';

// Constants
import API_URL from '../../api/constants/url.const.api';

// Profiles
import profile from '@matthahn/sally-fw/lib/api/profiles/file.profile.api';

const downloadTicketsCsvApi = (query = {}) =>
  f3tch('/tickets/download_csv/')
    .profile(
      profile({
        url: API_URL(),
        authorizationType: 'JWT',
        ignoreAppVersionHeader: true,
      })
    )
    .query(query)
    .get();

export default downloadTicketsCsvApi;
