import styled from 'styled-components';

// colors
import {white} from '@matthahn/sally-ui/lib/libs/colors';

const Date = styled.div`
  font-weight: 700;
  font-size: 50px;
  position: fixed;
  top: 30px;
  right: 30px;
  color: ${white};
  z-index: 100;
`;

export default Date;
