import styled from 'styled-components';

// colors
import {grey} from '@matthahn/sally-ui/lib/libs/colors';

// media
import {media} from '@matthahn/sally-ui/lib/libs/media';

const Option = styled.div`
  width: 100%;
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  font-size: 36px;
  border-style: ${({border}) => (border ? 'solid none none none' : 'none')};
  border-width: 1px;
  border-color: ${grey};

  ${media.xs`
    padding: 16px;
    font-size: 26px;
  `}
`;

export default Option;
