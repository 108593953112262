import Dexie from 'dexie';

// constants
import DATABASE_NAME from './constants/name.constant.database';
import VERSION from './constants/version.constant.database';

// drivers
import driverName from '../driver/database/name.database.driver';

// services
import serviceName from '../service/database/name.database.service';

// vehicles
import vehicleName from '../vehicle/database/name.database.vehicle';

const db = new Dexie(DATABASE_NAME);
db.version(VERSION).stores({
  [driverName]: 'id',
  [serviceName]: 'id',
  [vehicleName]: 'id',
});

export default db;
