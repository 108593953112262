// date lib
import orderByDate from '@matthahn/sally-fw/lib/lib/orderByDate';

// types
import dateTimeType from '@matthahn/sally-fw/lib/type/types/dateTime.type';

const getLatestTicketNote = (ticket) => {
  const lastTicket =
    orderByDate([...(ticket?.ticketnotes || [])], 'created_at', 'desc')?.[0] ||
    null;
  return !!lastTicket
    ? `${lastTicket.text} (${dateTimeType(lastTicket.created_at).format()} by ${
        lastTicket.author
      })`
    : '-';
};

export default getLatestTicketNote;
