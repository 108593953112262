// components
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';

// item lib
import getItemName from '../../lib/getName.lib.item';
import isItemTaxed from '../../lib/isTaxed.lib.item';
import itemPrice from '../../lib/price.lib.item';

// layout components
import ListItem from '../../../layout/components/ListItem/ListItem';

// local components
import Price from './components/Price';
import PriceContainer from './components/PriceContainer';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// types
import amount from '@matthahn/sally-fw/lib/type/types/amount.type';

const ChargeItemsModal = ({
  charging,
  items,
  onClose,
  onSave,
  onSelect,
  selected,
  ticket,
  visible,
}) => (
  <Modal
    visible={visible}
    title={`Ticket #${ticket?.id || ''}`}
    subtitle="Select items to charge"
    onClose={onClose}
    buttonsRight={[
      {
        label: !!selected.length
          ? `Charge for ${selected.length} ${
              selected.length === 1 ? 'item' : 'items'
            }`
          : 'Select items to charge',
        loading: charging,
        disabled: !selected.length,
        onClick: onSave,
      },
    ]}
  >
    {(Content) => (
      <Content padding="none">
        {items.map((item) => (
          <ListItem
            selectable
            key={item.id}
            title={getItemName(item)}
            content={item?.service_description || null}
            onClick={onSelect(item)}
            selected={selected.includes(item.id)}
            rightContent={
              item.cost !== null ? (
                <PriceContainer>
                  {item.quantity}
                  <Price>
                    {isItemTaxed(item) && 'T '}$
                    {amount(itemPrice(item)).format()}
                  </Price>
                </PriceContainer>
              ) : (
                '-'
              )
            }
          />
        ))}
      </Content>
    )}
  </Modal>
);

ChargeItemsModal.propTypes = {
  charging: PropTypes.bool,
  items: PropTypes.array,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.array,
  ticket: PropTypes.object,
  visible: PropTypes.bool,
};

export default ChargeItemsModal;
