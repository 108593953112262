// api
import listTicketStatusesApi from '../../api/list.api.ticketStatus';

// csv services
import downloadCsvService from '../../../csv/services/download.service.csv';

// events
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// local constants
import columns from './constants/columns.constant';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

// router
import {withRouter} from 'react-router-dom';

// ticketStatus components
import TicketStatusesList from '../../components/TicketStatusesList/TicketStatusesList';

// ticketStatus events
import showCreateTicketStatusModalEvent from '../../events/showCreateModal.event.ticketStatus';
import showUpdateTicketStatusModalEvent from '../../events/showUpdateModal.event.ticketStatus';
import ticketStatusCreatedEvent from '../../events/created.event.ticketStatus';
import ticketStatusDeletedEvent from '../../events/deleted.event.ticketStatus';
import ticketStatusUpdatedEvent from '../../events/updated.event.ticketStatus';

// ticketStatus redux actions
import {setQuery as setTicketStatusesQuery} from '../../redux/actions';

// uuid
import {v4} from 'uuid';

class TicketStatusesContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object,
    query: PropTypes.object,
    subscribe: PropTypes.func,
  };

  state = {
    downloadingCsv: false,
    loading: true,
    ticketStatuses: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.getTicketStatuses();
    this.props.subscribe(
      ticketStatusCreatedEvent.subscribe(this.ticketStatusCreated),
      ticketStatusDeletedEvent.subscribe(this.ticketStatusDeleted),
      ticketStatusUpdatedEvent.subscribe(this.ticketStatusUpdated)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  apiId = null;

  getTicketStatuses = async ({
    page = this.props.query.page,
    perPage = 50,
    search = this.props.query.search,
  } = {}) => {
    const {dispatch} = this.props;

    const apiId = v4();
    this.apiId = apiId;

    dispatch(setTicketStatusesQuery({page, search}));
    this.setState({
      loading: true,
      ticketStatuses: [],
    });
    try {
      const query = this.generateApiQuery({page, perPage, search});

      const {results: ticketStatuses, count} = await listTicketStatusesApi(
        query
      );
      if (this.apiId !== apiId || !this.mounted) return;
      this.setState({
        loading: false,
        ticketStatuses,
      });
      dispatch(
        setTicketStatusesQuery({pages: Math.ceil(count / perPage) || 1})
      );
    } catch (error) {
      if (this.apiId !== apiId || !this.mounted) return;
      this.setState({loading: false});
    }
  };

  generateApiQuery = ({page, perPage, search}) => {
    const query = {
      limit: perPage,
      offset: (page - 1) * perPage,
      ordering: 'label',
    };

    if (!!search) query.search = search;

    return query;
  };

  downloadCsv = async () => {
    const {query} = this.props;
    const {downloadingCsv} = this.state;
    if (downloadingCsv) return;
    this.setState({downloadingCsv: true});
    await downloadCsvService({
      apiCall: listTicketStatusesApi,
      fileName: 'ticketStatuses.csv',
      query: this.generateApiQuery(query),
      parseData: (ticketStatuses) =>
        [...ticketStatuses].map((ticketStatus) => ({
          ...ticketStatus,
        })),
    });
    if (!this.mounted) return;
    this.setState({downloadingCsv: false});
  };

  setPage = (page) => {
    if (this.state.loading) return;
    this.getTicketStatuses({page});
  };

  search = (search) => this.getTicketStatuses({search, page: 1});

  ticketStatuses = () => this.state.ticketStatuses;

  actions = () => {
    const {downloadingCsv} = this.state;
    return [
      {
        key: 'downloadCSV',
        children: 'Download CSV',
        icon: 'download',
        loading: downloadingCsv,
        theme: 'grey',
        onClick: this.downloadCsv,
      },
      {
        key: 'create',
        children: 'New Status',
        icon: 'plus',
        theme: 'black',
        onClick: () => showCreateTicketStatusModalEvent.publish(),
      },
    ];
  };

  editTicketStatus = (ticketStatus) => () => {
    showUpdateTicketStatusModalEvent.publish({ticketStatus});
  };

  ticketStatusCreated = (ticketStatus) => {
    const {loading, ticketStatuses} = this.state;
    if (loading) return;
    const newTicketStatuses = [...ticketStatuses, ticketStatus];
    this.setState({ticketStatuses: newTicketStatuses});
  };

  ticketStatusUpdated = (ticketStatus) => {
    const {loading, ticketStatuses} = this.state;
    if (loading) return;
    const newTicketStatuses = [...ticketStatuses].map((searchingTicketStatus) =>
      searchingTicketStatus.id === ticketStatus.id
        ? ticketStatus
        : searchingTicketStatus
    );
    this.setState({ticketStatuses: newTicketStatuses});
  };

  ticketStatusDeleted = (ticketStatus) => {
    const {loading, ticketStatuses} = this.state;
    if (loading) return;
    const newTicketStatuses = [...ticketStatuses].filter(
      (searchingTicketStatus) => searchingTicketStatus.id !== ticketStatus.id
    );
    this.setState({ticketStatuses: newTicketStatuses});
  };

  render() {
    const {
      query: {page, pages, search},
    } = this.props;
    const {loading} = this.state;
    const ticketStatuses = this.ticketStatuses();
    return (
      <TicketStatusesList
        actions={this.actions()}
        columns={columns}
        loading={loading}
        onTicketStatus={this.editTicketStatus}
        onPage={this.setPage}
        onSearch={this.search}
        page={page}
        pages={pages}
        search={search}
        ticketStatuses={ticketStatuses}
      />
    );
  }
}

export default subscriptionHoc(
  withRouter(
    connect((state) => ({
      query: state.ticketStatus.query,
    }))(TicketStatusesContainer)
  )
);
