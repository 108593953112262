import styled from 'styled-components';

// colors
import {darkerGrey} from '@matthahn/sally-ui/lib/libs/colors';

const Date = styled.div`
  color: ${darkerGrey};
`;

export default Date;
