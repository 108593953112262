// components
import {Modal, Row, Column} from '@matthahn/sally-ui';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const EditLocationModal = ({
  address_city,
  address_state,
  address_street,
  address_zip,
  loading,
  name,
  onChange,
  onClose,
  onSave,
  visible,
}) => (
  <Modal
    visible={visible}
    onClose={onClose}
    title="Location Editor"
    buttonsRight={[{label: 'Save', onClick: onSave, loading}]}
    size="small"
  >
    {(Content) => (
      <Content>
        <Row margin>
          <Column>
            <AttributeInput value={name} disabled={loading} onChange={onChange}>
              {name.label.default}
            </AttributeInput>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <AttributeInput
              value={address_street}
              disabled={loading}
              onChange={onChange}
            >
              {address_street.label.default}
            </AttributeInput>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <AttributeInput
              value={address_zip}
              disabled={loading}
              onChange={onChange}
            >
              {address_zip.label.default}
            </AttributeInput>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <AttributeInput
              value={address_city}
              disabled={loading}
              onChange={onChange}
            >
              {address_city.label.default}
            </AttributeInput>
          </Column>
        </Row>
        <Row>
          <Column>
            <AttributeInput
              value={address_state}
              disabled={loading}
              onChange={onChange}
            >
              {address_state.label.default}
            </AttributeInput>
          </Column>
        </Row>
      </Content>
    )}
  </Modal>
);

EditLocationModal.propTypes = {
  address_city: PropTypes.object,
  address_state: PropTypes.object,
  address_street: PropTypes.object,
  address_zip: PropTypes.object,
  loading: PropTypes.bool,
  name: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  visible: PropTypes.bool,
};

export default EditLocationModal;
