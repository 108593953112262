// react
import React, {Component} from 'react';

// propTypes
import PropTypes from 'prop-types';

// alertify
import alertify from '../../../layout/lib/alertify';

// vehicle api
import createVehicleApi from '../../api/create.api.vehicle';
import decodeVehicleVinApi from '../../api/decodeVin.api.vehicle';

// attributes
import customOwnerAttr from '../../attributes/customOwner.attribute.vehicle';
import licensePlateAttr from '../../attributes/license_plate.attribute.vehicle';
import makeAttr from '../../attributes/make.attribute.vehicle';
import medallionAttr from '../../attributes/medallion.attribute.vehicle';
import modelAttr from '../../attributes/model.attribute.vehicle';
import ownerAttr from '../../attributes/owner.attribute.vehicle';
import vinAttr from '../../attributes/vin.attribute.vehicle';
import yearAttr from '../../attributes/year.attribute.vehicle';

// components
import NewVehicle from '../../components/NewVehicle/NewVehicle';

// error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// notifications
import notify from '@matthahn/sally-ui/lib/libs/notify';

// owner api
import createOwnerApi from '../../../owner/api/create.api.owner';

// owner lib
import isCustomOwnerSelected from '../../../owner/lib/isCustomOwnerSelected.lib.owner';

// owner prep
import prepareOwnersForSelect from '../../../owner/lib/prepareForSelect.lib.owner';

// preparations
import createVehiclePreparation from '../../preparations/create.preparation.vehicle';

// redux
import {connect} from 'react-redux';

// router
import {withRouter} from 'react-router-dom';

// vehicle components
import DecidedVinErrorMessage from '../../components/DecidedVinErrorMessage/DecidedVinErrorMessage';

// vehicle lib
import isVehicleVinError from '../../lib/isVinError.lib.vehicle';
import isVehicleVinTypeError from '../../lib/isVinTypeError.lib.vehicle';
import allVinDataParsed from '../../lib/allVinDataParsed.lib.vehicle';
import parseDecodedVehicleVin from '../../lib/parseDecodedVin.lib.vehicle';
import parseDecodedVehicleVinErrorMessage from '../../lib/parseDecodedVinErrorMessage.lib.vehicle';

// vehicle routes
import vehiclesRoute from '../../pages/VehiclesPage/route';

class NewVehicleContainer extends Component {
  static propTypes = {
    branch: PropTypes.object,
    owners: PropTypes.array,
    dispatch: PropTypes.func,
    history: PropTypes.object,
  };

  state = {
    loading: false,
    displayMore: false,
    customOwner: customOwnerAttr(''),
    license_plate: licensePlateAttr(''),
    make: makeAttr(''),
    medallion: medallionAttr(''),
    model: modelAttr(''),
    owner: ownerAttr(''),
    vin: vinAttr(''),
    year: yearAttr(''),
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  change = (value, key) => {
    if (this.state.loading) return;
    this.setState({
      [key]: value,
      displayMore: key === 'vin' ? false : this.state.displayMore,
    });
  };

  setStateAsync = (data) =>
    new Promise((resolve) => this.setState(data, resolve));

  parseVinLookupAndContinue = (decodedVinData) => () => {
    const make = makeAttr(decodedVinData.make || '');
    const model = modelAttr(decodedVinData.model || '');
    const year = yearAttr(decodedVinData.year || '');
    this.setState({
      loading: false,
      displayMore: true,
      make,
      model,
      year,
    });
  };

  save = async () => {
    const {branch, history} = this.props;
    const {
      loading,
      displayMore,
      customOwner,
      license_plate,
      make,
      medallion,
      model,
      owner,
      vin,
      year,
    } = this.state;
    if (loading) return;

    this.setState({loading: true});

    try {
      const {customOwner: preparedCustomOwner, ...preparedVehicle} =
        await createVehiclePreparation({
          full: displayMore,
          customOwner,
          license_plate,
          make,
          medallion,
          model,
          owner,
          vin,
          year,
        });
      if (isCustomOwnerSelected(owner)) {
        const newOwner = await createOwnerApi({name: preparedCustomOwner});
        preparedVehicle.owner = newOwner.id;
        this.setState({owner: ownerAttr(`${newOwner.id}`)});
      }
      const decodedVin = await (displayMore
        ? null
        : this.lookupVIN({vin: preparedVehicle.vin}));
      const decodedVinData = !!decodedVin
        ? parseDecodedVehicleVin(decodedVin)
        : null;
      if (
        !displayMore &&
        (isVehicleVinError(decodedVin) || !allVinDataParsed(decodedVinData))
      ) {
        const parsedVinErrorMessage =
          parseDecodedVehicleVinErrorMessage(decodedVin);
        return notify({
          title: 'VIN lookup issue',
          content: !parsedVinErrorMessage ? (
            'VIN lookup failed. Make sure all information is inserted and valid.'
          ) : (
            <DecidedVinErrorMessage messages={parsedVinErrorMessage} />
          ),
          primary: {
            label: 'Ok',
            onClick: isVehicleVinTypeError(decodedVin)
              ? () => this.setState({loading: false})
              : this.parseVinLookupAndContinue(decodedVinData),
          },
        });
      }
      const preparedVehicleWithVin = !!decodedVinData
        ? {...preparedVehicle, ...decodedVinData}
        : preparedVehicle;
      preparedVehicleWithVin.branch = branch.id;
      await createVehicleApi(preparedVehicleWithVin);
      if (!this.mounted) return;
      alertify({title: 'Success', message: 'Vehicle created'});
      history.push(vehiclesRoute());
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alertify({message});
      this.setState({loading: false});
    }
  };

  lookupVIN = async ({vin}) => {
    try {
      const decodedVin = await decodeVehicleVinApi(vin);
      return decodedVin;
    } catch (error) {
      return {'Error Code': '100'};
    }
  };

  render() {
    const {owners} = this.props;
    const {
      loading,
      displayMore,
      customOwner,
      license_plate,
      make,
      medallion,
      model,
      owner,
      vin,
      year,
    } = this.state;
    return (
      <NewVehicle
        loading={loading}
        displayMore={displayMore}
        customOwner={customOwner}
        license_plate={license_plate}
        make={make}
        medallion={medallion}
        model={model}
        owner={owner}
        vin={vin}
        year={year}
        owners={prepareOwnersForSelect({owners, enableCustom: true})}
        onChange={this.change}
        onSave={this.save}
      />
    );
  }
}

export default withRouter(
  connect((state) => ({
    branch: state.branch.branch,
    owners: state.owner.owners,
  }))(NewVehicleContainer)
);
