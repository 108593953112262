import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Attribute
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

class VehicleModelSelect extends Component {
  static propTypes = {
    model: PropTypes.object,
  };

  options = () => {
    const {model} = this.props;
    if (!model || !model?.additional?.options) return [];
    const options = [...model.additional.options];
    const additionalOptions = options.includes(model.value())
      ? []
      : [model.value()];
    return [...additionalOptions, ...options];
  };

  render() {
    const {model, ...props} = this.props;
    return !model ? null : (
      <AttributeInput
        value={model}
        {...props}
        options={this.options()}
        useOption
      >
        {model.label.default}
      </AttributeInput>
    );
  }
}

export default VehicleModelSelect;
