import React from 'react';
import PropTypes from 'prop-types';

// date
import entryDate from '../../../date/lib/entryDate.lib.date';
import entryHour from '../../../date/lib/entryHour.lib.date';

// driver
import getDriverMaskedName from '../../../driver/lib/getMaskedName.lib.driver';

// Local Components
import Border from './components/Border';
import Container from './components/Container';
import Date from './components/Date';
import Driver from './components/Driver';
import Info from './components/Info';
import Medallion from './components/Medallion';
import Plate from './components/Plate';

// Vehicle
import displayVehicleFieldFromObject from '../../../vehicle/lib/displayVehicleFieldFromObject.lib.vehicle';

const TvEntryCard = ({date, driver, highlight, vehicle}) => {
  const driverName = getDriverMaskedName({driver});
  return (
    <Container>
      <Border>
        <Info>
          <Driver>{driverName || '-'}</Driver>
          <Plate>
            {displayVehicleFieldFromObject({
              object: {vehicle},
              field: 'license_plate',
              empty: 'Unknown plate',
            })}
            {!!vehicle.medallion && (
              <Medallion>
                {displayVehicleFieldFromObject({
                  object: {vehicle},
                  field: 'medallion',
                })}
              </Medallion>
            )}
          </Plate>
        </Info>
        <Date highlight={highlight}>
          {entryHour(date)}
          <br />
          {entryDate(date)}
        </Date>
      </Border>
    </Container>
  );
};

TvEntryCard.propTypes = {
  date: PropTypes.string,
  highlight: PropTypes.bool,
  vehicle: PropTypes.any,
};

export default TvEntryCard;
