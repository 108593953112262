import React from 'react';

// layout containers
import Page from '../../../layout/containers/Page/Page';

// local constants
import PAGE from './page';

// ticket containers
import VehiclesContainer from '../../containers/VehiclesContainer/VehiclesContainer';

const VehiclesPage = () => (
  <Page page={PAGE}>
    <VehiclesContainer />
  </Page>
);

export default VehiclesPage;
