import {TEXT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import email from '@matthahn/sally-fw/lib/type/types/email.type';

export default attribute({
  type: TEXT,
  attribute: 'email',
  display: email,
  input: email,
  api: email,
  label: {
    default: 'Email',
    short: 'Email',
  },
});
