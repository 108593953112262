// components
import Select from '@matthahn/sally-ui/lib/components/Select/Select';

// layout components
import DateRangeFilterModal from '../DateRangeFilterModal/DateRangeFilterModal';
import SelectDropdown from '../SelectDropdown/SelectDropdown';

// local components
import Container from './components/Container';
import Label from './components/Label';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const ItemListHeaderFilter = ({filterSize, label, type, ...props}) =>
  type === 'select' ? (
    <Container filterSize={filterSize}>
      <SelectDropdown {...props}>{label}</SelectDropdown>
    </Container>
  ) : type === 'selectBkp' ? (
    <Container filterSize={filterSize}>
      <Label>{label}</Label>
      <Select size="normal" notFilterable flat hideOverflow {...props} />
    </Container>
  ) : type === 'dateRange' ? (
    <Container filterSize={filterSize} noPadding>
      <DateRangeFilterModal label={label} {...props} />
    </Container>
  ) : null;

ItemListHeaderFilter.propTypes = {
  filterSize: PropTypes.string,
  label: PropTypes.node,
  type: PropTypes.oneOf(['select', 'dateRange']),
};

export default ItemListHeaderFilter;
