import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

// alertify
import alertify from '../../../layout/lib/alertify';

// components
import {Modal, Row, Column, Switch, Info} from '@matthahn/sally-ui';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// types
import apiDateTime from '@matthahn/sally-fw/lib/type/types/apiDateTime.type';

// vehicle attributes
import calampDeviceIdAttr from '../../attributes/calamp_device_id.attribute.vehicle';
import calampInstalationMileageAttr from '../../attributes/calamp_instalation_mileage.attribute.vehicle';

// vehicle api
import updateCalampApi from '../../api/updateCalamp.api.vehicle';

// vehicle lib
import checkCalamp from '../../lib/checkCalamp.lib.vehicle.js';
import getCalampStatusColor from '../../lib/getCalampStatusColor.lib.vehicle.js';

// vehicle preparations
import prepareForUpdate from '../../preparations/calamp.preparation.vehicle';

class Calamp extends Component {
  static propTypes = {
    children: PropTypes.func,
    onSync: PropTypes.func,
    vehicle: PropTypes.object,
  };

  static defaultProps = {
    children: () => null,
  };

  state = {
    loading: false,
    modalVisible: false,
    noTelematics: false,
    calamp_device_id: calampDeviceIdAttr(''),
    calamp_instalation_mileage: calampInstalationMileageAttr(''),
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  modificationData = () => ({
    modified_at: apiDateTime(new Date()).format(),
    modified_user: this.props.username,
    calamp_modified_at: apiDateTime(new Date()).format(),
    calamp_user: this.props.username,
  });

  onChange = (val, key) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  showModal = () =>
    this.setState({
      modalVisible: true,
      noTelematics: !this.props.vehicle?.calamp_device_id,
      calamp_device_id: calampDeviceIdAttr(''),
      calamp_instalation_mileage: calampInstalationMileageAttr(''),
    });

  hideModal = () => this.setState({modalVisible: false});

  changeCalamp = async () => {
    const {
      vehicle: {id: vehicleID},
      onSync,
    } = this.props;
    const {
      loading,
      noTelematics,
      calamp_device_id,
      calamp_instalation_mileage,
    } = this.state;

    if (loading) return;
    this.setState({loading: true});

    try {
      const preparedVehicle = await prepareForUpdate({
        noTelematics,
        calamp_device_id,
        calamp_instalation_mileage,
      });
      const vehicle = await updateCalampApi(vehicleID, preparedVehicle);
      if (!this.mounted) return;
      onSync(vehicle);
      this.setState({loading: false, modalVisible: false});
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alertify({message});
      this.setState({loading: false});
    }
  };

  renderContent = () => {
    const {children, vehicle} = this.props;
    const calamp = checkCalamp({vehicle});
    return children({
      calamp,
      theme: getCalampStatusColor(calamp.status),
      onClick: this.showModal,
    });
  };

  render() {
    const {vehicle} = this.props;
    const {
      loading,
      modalVisible,
      noTelematics,
      calamp_device_id,
      calamp_instalation_mileage,
    } = this.state;

    const content = this.renderContent();
    const calamp = checkCalamp({vehicle});

    return (
      <Fragment>
        {content}
        <Modal
          title="Change Calamp ESN"
          visible={modalVisible}
          onClose={this.hideModal}
          buttonsRight={[{label: 'Save', onClick: this.changeCalamp, loading}]}
        >
          {(Content) => (
            <Fragment>
              {calamp?.status !== 'done' && (
                <Content padding="none">
                  <Info type={calamp.status} flat>
                    {calamp.message}
                  </Info>
                </Content>
              )}
              <Content>
                <Row margin>
                  <Column>
                    <Switch
                      size="large"
                      value={noTelematics}
                      onChange={(value) => this.onChange(value, 'noTelematics')}
                      disabled={loading}
                    >
                      No telematics device
                    </Switch>
                  </Column>
                </Row>
                <Row margin>
                  <Column>
                    <AttributeInput
                      size="large"
                      value={calamp_device_id}
                      onChange={this.onChange}
                      disabled={loading || noTelematics}
                    >
                      {calamp_device_id.label.default}
                    </AttributeInput>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <AttributeInput
                      size="large"
                      value={calamp_instalation_mileage}
                      onChange={this.onChange}
                      disabled={loading || noTelematics}
                    >
                      {calamp_instalation_mileage.label.default}
                    </AttributeInput>
                  </Column>
                </Row>
              </Content>
            </Fragment>
          )}
        </Modal>
      </Fragment>
    );
  }
}

export default Calamp;
