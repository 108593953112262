import {TOGGLE} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

export default attribute({
  type: TOGGLE,
  attribute: 'removed',
  label: {
    default: 'Is Removed',
    short: 'Is Removed',
  },
});
