// actions
import {set as setAct} from '../../redux/actions';

// alert
import alertify from '../../../layout/lib/alertify';

// api
import updateMechanicApi from '../../api/update.api.mechanic';

// attributes
import nameAttr from '../../attributes/name.attribute.mechanic';
import nicknameAttr from '../../attributes/nickname.attribute.mechanic';

// error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// events
import mechanicEditedEvent from '../../events/updated.event.mechanic';
import showUpdateMechanicModalEvent from '../../events/showUpdateModal.event.mechanic';

// lib
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';
import extractMechanicToObject from '../../lib/extractToObject.lib.mechanic';

// mechanic components
import EditMechanicModal from '../../components/EditMechanicModal/EditMechanicModal';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

// subscription
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

class EditMechanicContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    mechanics: PropTypes.array,
    subscribe: PropTypes.func,
  };

  state = {
    visible: false,
    loading: false,
    name: nameAttr(''),
    nickname: nicknameAttr(''),
    branch: null,
    mechanic: null,
  };

  componentDidMount() {
    this.props.subscribe(showUpdateMechanicModalEvent.subscribe(this.init));
  }

  init = ({mechanic}) => {
    this.setState({
      visible: true,
      mechanic,
      name: nameAttr(mechanic.first_name || ''),
      nickname: nicknameAttr(mechanic.nickname || ''),
      branch: fkOrId(mechanic.branch),
    });
  };

  change = (value, key) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  close = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  save = async () => {
    const {dispatch, mechanics} = this.props;
    const {loading, mechanic, name, nickname, branch} = this.state;

    if (loading) return;

    const mechanicName = name.api.format();
    const mechanicNickname = nickname.api.format();

    if (!mechanicName.trim().length) return alertify({message: 'Insert name'});
    if (!branch) return alertify({message: 'Insert branch'});

    this.setState({loading: true});

    try {
      const updatedMechanic = await updateMechanicApi(mechanic.id, {
        ...extractMechanicToObject({
          name: mechanicName,
          nickname: mechanicNickname,
        }),
        branch,
      });
      const updatedMechanics = mechanics.map((m) =>
        m.id === updatedMechanic.id ? updatedMechanic : m
      );
      dispatch(setAct({mechanics: updatedMechanics}));
      mechanicEditedEvent.publish(updatedMechanic);
      this.setState({loading: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alertify({message});
      this.setState({loading: false});
    }
  };

  render() {
    const {visible, loading, name, nickname, branch} = this.state;
    return (
      <EditMechanicModal
        branch={branch}
        loading={loading}
        name={name}
        nickname={nickname}
        onChange={this.change}
        onClose={this.close}
        onSave={this.save}
        visible={visible}
      />
    );
  }
}

export default subscriptionHoc(
  connect((state) => ({
    mechanics: state.mechanic.mechanics,
  }))(EditMechanicContainer)
);
