import styled from 'styled-components';

// Colors
import * as colors from '@matthahn/sally-ui/lib/libs/colors';

const MainIcon = styled.div`
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  padding: 10px 0px;
  color: ${({color}) => colors[color] || colors.blueGrey};
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default MainIcon;
