import React from 'react';
import PropTypes from 'prop-types';

// components
import Li from './components/Li';
import Ul from './components/Ul';

const DecidedVinErrorMessage = ({messages}) => (
  <Ul>
    {messages.map((message, messageIndex) => (
      <Li key={messageIndex}>{message}</Li>
    ))}
  </Ul>
);

DecidedVinErrorMessage.propTypes = {
  message: PropTypes.array,
};

export default DecidedVinErrorMessage;
