import React from 'react';
import PropTypes from 'prop-types';

// Components
import TaskItemLoader from '../../../taskItem/components/TaskItemLoader/TaskItemLoader';

// layout components
import ListItem from '../../../layout/components/ListItem/ListItem';

// Local Components
import Container from './components/Container';

// Lib
import getTaskItemName from '../../lib/getName.lib.taskItem';

const TaskItems = ({
  tax,
  isTaskEditable,
  addingTaskItem,
  taskItems,
  onTaskItemDelete,
  onEdit,
  onAddTaskItem,
}) => (
  <Container>
    {taskItems.map((taskItem) => (
      <ListItem
        key={taskItem.id}
        title={getTaskItemName(taskItem)}
        content={
          !!taskItem?.service
            ? !!taskItem?.description
              ? `[${taskItem?.service?.category}] ${taskItem?.description}`
              : taskItem?.service?.category
            : null
        }
        onClick={isTaskEditable ? onEdit(taskItem) : null}
        // onSwipe={isTaskEditable ? onTaskItemDelete(taskItem) : null}
      />
    ))}
    {addingTaskItem ? (
      <TaskItemLoader />
    ) : (
      <ListItem
        icon="plus"
        content="Add an item"
        onClick={onAddTaskItem}
        withoutClickIcon
      />
    )}
  </Container>
);

TaskItems.propTypes = {
  tax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isTaskEditable: PropTypes.bool,
  addingTaskItem: PropTypes.bool,
  taskItems: PropTypes.array,
  onTaskItemDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

export default TaskItems;
