// task state
import getStates from './getStates.state.task';

// task states
import scheduledState from '../states/scheduled.state.task';

const getTaskStates = (task) => {
  const states = [...getStates({withNoState: !task?.scheduled_at})];
  return !task?.scheduled_at
    ? [...states].filter((state) => state.state !== scheduledState.state)
    : states;
};

export default getTaskStates;
