import styled from 'styled-components';

// colors
import {
  grey,
  green,
  white,
  transparent,
} from '@matthahn/sally-ui/lib/libs/colors';

// media
import {media} from '@matthahn/sally-ui/lib/libs/media';

const Circle = styled.div`
  width: 20px;
  height: 20px;
  font-size: 12px;
  overflow: hidden;
  border-radius: 50%;
  border: ${({selected}) =>
    selected ? `2px solid ${green}` : `2px solid ${grey}`};
  color: ${white};
  background: ${({selected}) => (selected ? green : transparent)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;

  ${media.xs`
    width: 20px;
    height: 20px;
    font-size: 10px;
  `}
`;

export default Circle;
