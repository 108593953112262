// lib
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';

const attachDriver = ({object, drivers}) => {
  const driverId = !!object.driver
    ? fkOrId(object.driver)
    : !!object?.drivers?.length
    ? fkOrId(object.drivers[0])
    : null;
  return [...drivers].find(({id}) => driverId === id) || null;
};

export default attachDriver;
