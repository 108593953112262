import {NUMBER} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

export default attribute({
  type: NUMBER,
  attribute: 'ticket',
  label: {
    default: 'Ticket ID',
    short: 'Ticket ID',
  },
});
