const columns = [
  {
    key: 'label',
    label: 'Status',
  },
  {
    key: 'address',
    label: 'Address',
  },
];

export default columns;
