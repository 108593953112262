// local components
import Action from './components/Action';
import Container from './components/Container';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const TicketActionsRow = ({actions}) =>
  !!actions.length ? (
    <Container>
      {actions.map(({icon, key, label, onClick}) => (
        <Action key={key} onClick={onClick}>
          <i className={`mdi mdi-${icon}`} />
          <div>{label}</div>
        </Action>
      ))}
    </Container>
  ) : null;

TicketActionsRow.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      key: PropTypes.string,
      label: PropTypes.node,
      onClick: PropTypes.func,
    })
  ),
};

export default TicketActionsRow;
