// api lib
import getEverythingFromApi from '@matthahn/sally-fw/lib/api/lib/getEverything.lib.api';

// lib
import foundBySearch from '../../../lib/foundBySearch.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// service api
import listServicesApi from '../../api/list.api.service';

// service components
import ServicesList from '../../components/ServicesList/ServicesList';

// service events
import showCreateServiceModalEvent from '../../events/showCreateServiceModal.event.service';
import showServiceEditorModalEvent from '../../events/showServiceEditorModal.event.service';

// service lib
import getCategories from '../../lib/getCategories.lib.service';

// service redux
import {set as setServiceAction} from '../../redux/actions';

class ServiceItemsContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    loadingServiceItems: PropTypes.bool,
    serviceItems: PropTypes.array,
    serviceItemsCategories: PropTypes.array,
    serviceItemsSearch: PropTypes.string,
  };

  componentDidMount() {
    // this.loadServiceItems();
  }

  loadServiceItems = async () => {
    const {dispatch, loadingServiceItems} = this.props;

    if (loadingServiceItems) return;

    dispatch(setServiceAction({loadingServiceItems: true}));

    try {
      const {data: serviceItems} = await getEverythingFromApi(listServicesApi);
      dispatch(setServiceAction({loadingServiceItems: false, serviceItems}));
    } catch (error) {
      dispatch(setServiceAction({loadingServiceItems: false}));
    }
  };

  actions = () => [
    {
      key: 'costOverride',
      children: 'Add Cost Override for each visible Service',
      icon: 'plus',
      theme: 'grey',
      onClick: () => {},
    },
    {
      key: 'create',
      children: 'Add Item',
      icon: 'plus',
      theme: 'black',
      onClick: () => showCreateServiceModalEvent.publish(),
    },
  ];

  columns = () => [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'category',
      label: 'Category',
    },
    {
      key: 'cost',
      label: 'Default Cost',
    },
  ];

  filters = () => {
    const {dispatch, serviceItems, serviceItemsCategories} = this.props;
    return [
      {
        key: 'category',
        label: 'Category',
        type: 'select',
        value: serviceItemsCategories,
        options: getCategories(serviceItems).map(({id, name}) => ({
          value: id,
          label: name,
        })),
        onChange: (value) =>
          dispatch(setServiceAction({serviceItemsCategories: value})),
      },
    ];
  };

  search = (search) => {
    const {dispatch} = this.props;
    dispatch(setServiceAction({serviceItemsSearch: search}));
  };

  openService = (service) => () =>
    showServiceEditorModalEvent.publish({service});

  services = () => {
    const {
      serviceItemsSearch: search,
      serviceItems,
      serviceItemsCategories,
    } = this.props;
    const searchedServices = !!search.trim().length
      ? [...serviceItems].filter(({name, category, attributes}) =>
          foundBySearch({
            search,
            fields: [
              name,
              category,
              ...attributes.map((attribute) => attribute.name),
            ],
          })
        )
      : serviceItems;
    const filteredServices = !!serviceItemsCategories.length
      ? searchedServices.filter(({category}) =>
          serviceItemsCategories.includes(category)
        )
      : searchedServices;
    return filteredServices;
  };

  render() {
    const {loadingServiceItems, serviceItems, serviceItemsSearch} = this.props;
    return (
      <ServicesList
        actions={this.actions()}
        columns={this.columns()}
        filters={this.filters()}
        loading={loadingServiceItems && !serviceItems.length}
        onService={this.openService}
        onSearch={this.search}
        search={serviceItemsSearch}
        services={this.services()}
      />
    );
  }
}

export default connect((state) => ({
  loadingServiceItems: state.spotlight.servicesLoading,
  serviceItems: state.spotlight.services,
  serviceItemsCategories: state.service.serviceItemsCategories,
  serviceItemsSearch: state.service.serviceItemsSearch,
}))(ServiceItemsContainer);
