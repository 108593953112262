// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// components
import {Card} from '@matthahn/sally-ui';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import CompactDataRow from '../../../layout/components/CompactDataRow/CompactDataRow';

// Vehicle
import VehicleModelSelect from '../VehicleModelSelect/VehicleModelSelect';
import showMedallionNumber from '../../../vehicle/lib/showMedallionNumber.lib.vehicle';

const VehicleEditModal = ({
  children,
  isVehicleEditable,
  license_plate,
  make,
  medallion,
  model,
  onChange,
  onChangeAndStore,
  onClose,
  onSave,
  owner,
  owners,
  phone_number,
  scheduled_at,
  task,
  vehicle,
  visible,
  year,
}) => (
  <Card title={`Task #${task.id}`} visible={visible} onClose={onClose}>
    {(Content) => (
      <Content padding="none">
        {!!scheduled_at && (
          <CompactDataRow
            label={scheduled_at.label.default}
            wrapContent
            noPadding
          >
            {
              <AttributeInput
                value={scheduled_at}
                onChange={onChangeAndStore}
                flat
              />
            }
          </CompactDataRow>
        )}
        <CompactDataRow label={owner.label.default} wrapContent noPadding>
          <AttributeInput
            value={owner}
            onChange={onChangeAndStore}
            options={owners || []}
            disabled={!isVehicleEditable}
            flat
          />
        </CompactDataRow>
        <CompactDataRow label="VIN">{vehicle.vin || '-'}</CompactDataRow>
        {!!vehicle.svid && (
          <CompactDataRow label="SVID">{vehicle.svid || '-'}</CompactDataRow>
        )}
        <CompactDataRow
          label={license_plate.label.default}
          wrapContent
          noPadding
        >
          <AttributeInput
            value={license_plate}
            onChange={onChange}
            onBlur={onSave(license_plate.attribute)}
            disabled={!isVehicleEditable}
            flat
          />
        </CompactDataRow>
        {showMedallionNumber(vehicle) && (
          <CompactDataRow label={medallion.label.default} wrapContent noPadding>
            <AttributeInput
              value={medallion}
              onChange={onChange}
              onBlur={onSave(medallion.attribute)}
              disabled={!isVehicleEditable}
              flat
            />
          </CompactDataRow>
        )}
        <CompactDataRow label={year.label.default} wrapContent noPadding>
          <AttributeInput
            value={year}
            onChange={onChangeAndStore}
            disabled={!isVehicleEditable}
            flat
          />
        </CompactDataRow>
        <CompactDataRow label={make.label.default} wrapContent noPadding>
          <AttributeInput
            value={make}
            onChange={onChange}
            onBlur={onSave(make.attribute)}
            disabled={!isVehicleEditable}
            flat
          />
        </CompactDataRow>
        <CompactDataRow label="Model" wrapContent noPadding>
          <VehicleModelSelect
            model={model}
            onChange={onChangeAndStore}
            disabled={!isVehicleEditable}
            flat
          />
        </CompactDataRow>
        {!!phone_number && (
          <CompactDataRow
            label={phone_number.label.default}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={phone_number}
              onChange={onChange}
              onBlur={onSave(phone_number.attribute)}
              flat
            />
          </CompactDataRow>
        )}
        {children}
      </Content>
    )}
  </Card>
);

VehicleEditModal.propTypes = {
  children: PropTypes.node,
  isVehicleEditable: PropTypes.bool,
  license_plate: PropTypes.object,
  make: PropTypes.object,
  medallion: PropTypes.object,
  model: PropTypes.object,
  onChange: PropTypes.func,
  onChangeAndStore: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  owner: PropTypes.object,
  owners: PropTypes.array,
  phone_number: PropTypes.object,
  task: PropTypes.object,
  vehicle: PropTypes.object,
  visible: PropTypes.bool,
  year: PropTypes.object,
};

export default VehicleEditModal;
