import React from 'react';
import PropTypes from 'prop-types';

// components
import {Notification, Row, Column} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import NotificationContainer from '../../../layout/components/NotificationContainer/NotificationContainer';

// device
import {isMobileOnly} from 'react-device-detect';

// local components
import Error from './components/Error';

const ConfirmMileageValidationModal = ({
  loading,
  onChange,
  onClose,
  onSave,
  validationError,
  vinLastSeven,
  visible,
}) => (
  <NotificationContainer visible={visible} big={!isMobileOnly}>
    <Notification
      title="Validate Mileage"
      primary={{label: 'Cancel', onClick: onClose, disabled: loading}}
      secondary={{label: 'Save', onClick: onSave, loading}}
    >
      {!!validationError && (
        <Row margin>
          <Column>
            <Error>{validationError}</Error>
          </Column>
        </Row>
      )}
      <Row margin>
        <Column>Insert last 7 of VIN in order to set the mileage.</Column>
      </Row>
      <Row>
        <Column>
          <AttributeInput
            value={vinLastSeven}
            onChange={onChange}
            placeholder={vinLastSeven.label.default}
            size="xl"
            disabled={loading}
          />
        </Column>
      </Row>
    </Notification>
  </NotificationContainer>
);

ConfirmMileageValidationModal.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  validationError: PropTypes.string,
  vinLastSeven: PropTypes.object,
  visible: PropTypes.bool,
};

export default ConfirmMileageValidationModal;
