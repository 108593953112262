import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {ScrollContext} from 'react-router-scroll-4';

// Components
import Layout from '../../../layout/components/Layout/Layout';

// drivers
import DriversPage from '../../../driver/pages/DriversPage/DriversPage';
import routeDrivers from '../../../driver/pages/DriversPage/route';

// Home
import HomePage from '../../../dashboard/pages/HomePage/HomePage';
import routeHome from '../../../dashboard/pages/HomePage/route';

// Home
import PublicDailyEntryListPage from '../../../entry/pages/PublicDailyEntryListPage/PublicDailyEntryListPage';
import routePublicDailyEntryList from '../../../entry/pages/PublicDailyEntryListPage/route';

// locations
import LocationsPage from '../../../location/pages/LocationsPage/LocationsPage';
import routeLocations from '../../../location/pages/LocationsPage/route';

// mechanics
import MechanicsPage from '../../../mechanic/pages/MechanicsPage/MechanicsPage';
import routeMechanics from '../../../mechanic/pages/MechanicsPage/route';

// owners
import OwnersPage from '../../../owner/pages/OwnersPage/OwnersPage';
import routeOwners from '../../../owner/pages/OwnersPage/route';

// services
import CustomServiceItemsPage from '../../../service/pages/CustomServiceItemsPage/CustomServiceItemsPage';
import routeCustomServiceItems from '../../../service/pages/CustomServiceItemsPage/route';

import ServiceItemsPage from '../../../service/pages/ServiceItemsPage/ServiceItemsPage';
import routeServiceItems from '../../../service/pages/ServiceItemsPage/route';

// task
import TaskPage from '../../../task/pages/TaskPage/TaskPage';
import routeTask from '../../../task/pages/TaskPage/route';

// tasks
import TasksPage from '../../../task/pages/TasksPage/TasksPage';
import routeTasks from '../../../task/pages/TasksPage/route';

// ticket
import TicketPage from '../../../ticket/pages/TicketPage/TicketPage';
import routeTicket from '../../../ticket/pages/TicketPage/route';

// ticketStatuses
import TicketStatusesPage from '../../../ticketStatus/pages/TicketStatusesPage/TicketStatusesPage';
import routeTicketStatuses from '../../../ticketStatus/pages/TicketStatusesPage/route';

// vehicles
import VehiclesPage from '../../../vehicle/pages/VehiclesPage/VehiclesPage';
import routeVehicles from '../../../vehicle/pages/VehiclesPage/route';

// vehicleaction
import VehicleActionPage from '../../../vehicle/pages/VehicleActionPage/VehicleActionPage';
import routeVehicleAction from '../../../vehicle/pages/VehicleActionPage/route';

// newvehicle
import NewVehiclePage from '../../../vehicle/pages/NewVehiclePage/NewVehiclePage';
import routeNewVehicle from '../../../vehicle/pages/NewVehiclePage/route';

// redirecttohome
import RedirectToHome from '../../../app/components/RedirectToHome/RedirectToHome';

const Routes = () => (
  <ScrollContext>
    <Layout>
      <Switch>
        <Route path={routeHome()} exact>
          <HomePage />
        </Route>
        <Route path={routePublicDailyEntryList()} exact>
          <PublicDailyEntryListPage />
        </Route>
        <Route path={routeTicket()} exact>
          <TicketPage />
        </Route>
        <Route path={routeTasks()} exact>
          <TasksPage />
        </Route>
        <Route path={routeTask()} exact>
          <TaskPage />
        </Route>
        <Route path={routeMechanics()} exact>
          <MechanicsPage />
        </Route>
        <Route path={routeTicketStatuses()} exact>
          <TicketStatusesPage />
        </Route>
        <Route path={routeLocations()} exact>
          <LocationsPage />
        </Route>
        <Route path={routeVehicles()} exact>
          <VehiclesPage />
        </Route>
        <Route path={routeNewVehicle()} exact>
          <NewVehiclePage />
        </Route>
        <Route path={routeDrivers()} exact>
          <DriversPage />
        </Route>
        <Route path={routeServiceItems()} exact>
          <ServiceItemsPage />
        </Route>
        <Route path={routeCustomServiceItems()} exact>
          <CustomServiceItemsPage />
        </Route>
        <Route path={routeOwners()} exact>
          <OwnersPage />
        </Route>
        <Route path={routeVehicleAction()} exact>
          <VehicleActionPage />
        </Route>
        <Route>
          <RedirectToHome />
        </Route>
      </Switch>
    </Layout>
  </ScrollContext>
);

export default Routes;
