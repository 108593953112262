import {DATETIME} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import apiDateTime from '@matthahn/sally-fw/lib/type/types/apiDateTime.type';
import dateTime from '@matthahn/sally-fw/lib/type/types/dateTime.type';

export default attribute({
  type: DATETIME,
  attribute: 'etc',
  display: dateTime,
  input: dateTime,
  api: apiDateTime,
  label: {
    default: 'ETC',
    short: 'ETC',
  },
});
