import styled from 'styled-components';

// colors
import {grey} from '@matthahn/sally-ui/lib/libs/colors';

const UserHeader = styled.div`
  width: 100%;
  padding: 0px 20px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${grey};
`;

export default UserHeader;
