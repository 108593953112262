import styled from 'styled-components';

// colors
import {white} from '@matthahn/sally-ui/lib/libs/colors';

// animation
import {transitions} from 'polished';

// media
import {media} from '@matthahn/sally-ui/lib/libs/media';

const DropDown = styled.div`
  width: 100vw;
  max-width: 600px;
  position: absolute;
  top: 100%;
  left: 50%;
  opacity: ${({visible}) => (visible ? 1 : 0)};
  transform: ${({visible}) =>
    visible ? 'translate(-50%, 10px)' : 'translate(-50%, 0px)'};
  pointer-events: ${({visible}) => (visible ? 'auto' : 'none')};
  background: ${white};
  font-weight: 400;
  z-index: 5;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  ${transitions('opacity .2s ease-in-out, transform .2s ease-in-out')};
  overflow: hidden;
  border-radius: 5px;

  ${media.xs`
    max-width: 300px;
  `}
`;

export default DropDown;
