// Api
import profileApi from '../api/profile.api.auth';
import refreshTokenApi from '../api/refreshToken.api.auth';

// Constants
import {SET_TOKEN} from './constants';

// Events
import unauthorizedEvt from '@matthahn/sally-fw/lib/auth/events/unauthorized.event.auth';

// Storage
import appTokensStorage from '../storage/appTokens.storage.auth';
import tokenStorage from '../storage/token.storage.auth';

// Store
import authStore from '@matthahn/sally-fw/lib/auth/store/auth.store';

// Redux
import reduxStore from '../../redux/store';

// Sockets
import socket from '@matthahn/sally-fw/lib/sockets/socket';

const checkToken =
  ({token}) =>
  async (dispatch) => {
    try {
      const {token: newToken} = await refreshTokenApi(token);
      const user = await profileApi(newToken);
      const appTokens = appTokensStorage.get() || '{}';
      const tokens = JSON.parse(appTokens);
      dispatch(signIn({token: newToken, user, tokens}));
    } catch (error) {
      dispatch(signOut());
    }
  };

export const init = () => (dispatch) => {
  const token = tokenStorage.get();
  if (!token) return dispatch(signOut());
  dispatch(
    checkToken({
      token,
    })
  );
};

export const signIn =
  ({token, user, tokens = {}} = {}) =>
  (dispatch, getState) => {
    try {
      tokenStorage.add(token);
      appTokensStorage.add(JSON.stringify(tokens));
      authStore.setData({token, user, tokens});
      if (!getState().auth.token) socket.authorize(token);
      dispatch({
        type: SET_TOKEN,
        data: {
          token,
          user,
          tokens,
        },
      });
    } catch (error) {
      dispatch(signOut());
    }
  };

export const signOut = () => (dispatch) => {
  tokenStorage.remove();
  appTokensStorage.remove();
  authStore.setData({
    token: null,
    user: null,
    tokens: {},
  });
  socket.deauthorize();
  dispatch({
    type: SET_TOKEN,
    data: {
      token: null,
      user: null,
      tokens: {},
    },
  });
};

unauthorizedEvt.subscribe(() => {
  reduxStore.dispatch(signOut());
});
