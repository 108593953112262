import styled from 'styled-components';

// media
import {media} from '@matthahn/sally-ui/lib/libs/media';

// colors
import {lightBlueGrey} from '@matthahn/sally-ui/lib/libs/colors';

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 65px);
  background: ${lightBlueGrey};
  ${media.m`
    height: calc(100vh - 51px);
  `};
`;

export default Container;
