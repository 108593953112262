// Action constants
import {SET} from './constants';

const DEFAULT_STATE = {
  loading: true,
  branches: [],
  branch: null,
  dealerships: [],
};

const branchReducer = (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case SET: {
      return {
        ...state,
        ...data,
      };
    }

    default: {
      return state;
    }
  }
};

export default branchReducer;
