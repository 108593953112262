import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {black},
  media: {media},
} = lib;

const Input = styled.input`
  width: 100%;
  height: 100%;
  flex: 1;
  padding: 0px;
  margin: 0px;
  display: block;
  outline: none;
  border: none;
  font-family: inherit;
  font-size: 30px;
  color: ${black};
  background: none;

  ${media.xs`
    font-size: 18px;
  `}
`;

export default Input;
