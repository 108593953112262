// alertify
import alertify from '../../../layout/lib/alertify';

// local services
import getExternalInvoiceService from './services/getExternalInvoice.service';
import uploadExternalInvoiceService from './services/uploadExternalInvoice.service';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// ticket components
import ExternalInvoiceCard from '../../components/ExternalInvoiceCard/ExternalInvoiceCard';

class ExternalInvoiceContainer extends Component {
  static propTypes = {
    ticket: PropTypes.object,
  };

  state = {
    loading: true,
    externalInvoice: null,
    uploading: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.getExternalInvoice();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ticket?.id !== this.props.ticket?.id) this.getImages();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getExternalInvoice = async () => {
    const {ticket} = this.props;
    this.setState({loading: true});
    const externalInvoice = await getExternalInvoiceService({ticket});
    if (!this.mounted) return;
    this.setState({externalInvoice, loading: false});
  };

  uploadExternalInvoice = async (file) => {
    if (!file) return;

    const {ticket} = this.props;

    if (this.state.uploading) return;

    this.setState({uploading: true});

    const {error, uploadedExternalInvoice} = await uploadExternalInvoiceService(
      {
        file,
        ticket,
      }
    );

    if (!this.mounted) return;

    if (!!error) alertify({message: error});

    this.setState({
      externalInvoice: !error
        ? uploadedExternalInvoice
        : this.state.externalInvoice,
      uploading: false,
    });
  };

  openExternalInvoice = () => {
    const {externalInvoice} = this.state;
    if (!externalInvoice) return;
    window.open(externalInvoice.doc_file, '_blank');
  };

  render() {
    const {externalInvoice, loading, uploading} = this.state;
    return (
      <ExternalInvoiceCard
        externalInvoice={externalInvoice}
        loading={loading || uploading}
        onDownload={this.openExternalInvoice}
        onUpload={this.uploadExternalInvoice}
      />
    );
  }
}

export default ExternalInvoiceContainer;
