// Action constants
import {SET} from './constants';

const DEFAULT_STATE = {
  pageName: null,
  pageLabelLeft: null,
  pageLabelRight: null,
  headerLineColor: 'white',
  actions: [],
  showNavigation: true,
};

const reducer = (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case SET: {
      return {
        ...state,
        ...data,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
