import georgeOrigin from '../../origins/george.origin.vehicle';
import kuiperOrigin from '../../origins/kuiper.origin.vehicle';
import outsiderOrigin from '../../origins/outsider.origin.vehicle';

const getOrigin = ({george_id, kuiper_id}) => {
  if (!!george_id) return georgeOrigin;
  if (!!kuiper_id) return kuiperOrigin;
  return outsiderOrigin;
};

export default getOrigin;
