// app lib
import isProduction from '../../app/lib/isProduction.lib.app';

// date
import {differenceInDays, parseISO} from 'date-fns';

const checkCalamp = ({vehicle = null} = {}) => {
  const complete = {status: 'done', message: 'CalAmp', progress: 1};
  if (!isProduction()) return complete;
  const {calamp_state, calamp_device_id} = vehicle;

  if (!calamp_device_id || !calamp_state)
    return {
      status: 'warning',
      message: 'No CalAmp device installed',
      progress: 1,
    };

  if (vehicle.calamp_device_id !== calamp_state.device_esn)
    return {
      status: 'error',
      message:
        'CalAmp ESN does not match that in our records. Please check the device.',
      progress: 0,
    };

  if (!calamp_state?.operational_status) {
    if (!calamp_state.last_communication_time)
      return {
        status: 'error',
        message: `CalAmp is not online. It never sent an event (Missing last communication time).`,
        progress: 0,
      };

    const lastCommunication = Math.abs(
      differenceInDays(
        new Date(),
        parseISO(calamp_state.last_communication_time)
      )
    );
    if (lastCommunication > 5)
      return {
        status: 'error',
        message: `CalAmp is not online. Last communication was ${lastCommunication} days ago.`,
        progress: 0,
      };

    return complete;
  }

  const status =
    calamp_state?.operational_status === 'functional'
      ? 'done'
      : calamp_state?.operational_status === 'partial'
      ? 'warning'
      : 'error';
  const completed = ['done', 'warning'].includes(status);
  const message =
    status === 'done'
      ? 'CalAmp'
      : calamp_state?.operational_status_description || 'Unknown CalAmp error';
  const progress = completed ? 1 : 0;

  return {
    completed,
    message,
    progress,
    status,
  };
};

export default checkCalamp;
