// components
import Card from '@matthahn/sally-ui/lib/components/Card/Card';

// document components
import ImageSlider from '../ImageSlider/ImageSlider';

// local components
import ElementContent from './components/Content';
import Grid from './components/Grid';
import HiddenFileInput from './components/HiddenFileInput';
import Element from './components/Element';
import Image from './components/Image';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment, useRef, useState} from 'react';

const ImageGallery = ({
  images,
  loading,
  onClose,
  onUpload,
  title,
  uploading,
  visible,
}) => {
  const fileInput = useRef();
  const [selectedImage, selectImage] = useState(null);

  return (
    <Card
      buttonsRight={
        !selectedImage && !!onUpload
          ? [
              {
                label: 'Take Photo',
                icon: 'camera',
                theme: 'black',
                disabled: loading,
                onClick: () => fileInput?.current?.click?.(),
              },
            ]
          : []
      }
      headerActions={
        !!selectedImage
          ? [
              {
                tooltip: 'Back',
                icon: 'arrowleft',
                onClick: () => selectImage(null),
              },
            ]
          : []
      }
      onClose={() => {
        onClose();
        selectImage(null);
      }}
      title={title}
      visible={visible}
    >
      {(Content) => (
        <Fragment>
          {!!selectedImage ? (
            <Content padding="none" noBorder>
              <ImageSlider
                height="calc(100vh - 74px)"
                images={images}
                initialImage={selectedImage}
              />
            </Content>
          ) : (
            <Content>
              <Grid>
                {loading && (
                  <Element>
                    <ElementContent>
                      <i className="mdi mdi-loading mdi-spin" />
                      Loading
                    </ElementContent>
                  </Element>
                )}
                {!loading && !images.length && !uploading.length && (
                  <Element>
                    <ElementContent>
                      <i className="mdi mdi-close" />
                      No Images
                    </ElementContent>
                  </Element>
                )}
                {images.map((image) => (
                  <Element
                    key={image.id}
                    onClick={() => {
                      selectImage(image);
                    }}
                  >
                    <Image src={image.doc_file} />
                  </Element>
                ))}
                {!!uploading.length && (
                  <Element>
                    <ElementContent>
                      <i className="mdi mdi-loading mdi-spin" />
                      Uploading
                    </ElementContent>
                  </Element>
                )}
              </Grid>
            </Content>
          )}
          <HiddenFileInput
            type="file"
            accept="image/*"
            capture="environment"
            ref={fileInput}
            onChange={(event) => onUpload(event.target.files?.[0])}
          />
        </Fragment>
      )}
    </Card>
  );
};

ImageGallery.propTypes = {
  images: PropTypes.array,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onUpload: PropTypes.func,
  title: PropTypes.node,
  uploading: PropTypes.array,
  visible: PropTypes.bool,
};

export default ImageGallery;
