const parseDecodedVinErrorMessage = (decodedVin) =>
  !!decodedVin?.['Error Text']
    ? `${decodedVin?.['Error Text']}`
        .split('; ')
        .map((errorMessage) =>
          errorMessage.includes(' - ')
            ? errorMessage.split(' - ').slice(-1).join(' - ')
            : errorMessage
        )
    : null;

export default parseDecodedVinErrorMessage;
