import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {version} from '../package.json';

// app
import appStore from '@matthahn/sally-fw/lib/app/store/app.store';
import App from './app/components/App/App';

// redux store
import store from './redux/store';

// styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

appStore.setData({version});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
