import {combineReducers} from 'redux';

// Reducers
import auth from '../auth/redux/reducer';
import branch from '../branch/redux/reducer';
import layout from '../layout/redux/reducer';
import location from '../location/redux/reducer';
import mechanic from '../mechanic/redux/reducer';
import owner from '../owner/redux/reducer';
import service from '../service/redux/reducer';
import spotlight from '../spotlight/redux/reducer';
import tag from '../tag/redux/reducer';
import task from '../task/redux/reducer';
import ticket from '../ticket/redux/reducer';
import ticketStatus from '../ticketStatus/redux/reducer';
import vehicle from '../vehicle/redux/reducer';

// Local Reducers
import navigation from '../layout/containers/NavigationContainer/redux/reducer';
import page from '../layout/containers/Page/redux/reducer';
import scroll from '../layout/containers/PositionToScroll/redux/reducer';
import settings from '../layout/containers/SettingsContainer/redux/reducer';

export default combineReducers({
  auth,
  branch,
  layout,
  location,
  mechanic,
  owner,
  service,
  spotlight,
  tag,
  task,
  ticket,
  ticketStatus,
  vehicle,

  navigation,
  page,
  scroll,
  settings,
});
