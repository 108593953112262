import styled from 'styled-components';

const Plate = styled.div`
  width: 100%;
  font-size: 40px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export default Plate;
