import {TEXT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import vinLastSeven from '@matthahn/sally-fw/lib/type/types/vinLastSeven.type';

export default attribute({
  type: TEXT,
  attribute: 'vinLastSeven',
  display: vinLastSeven,
  input: vinLastSeven,
  api: vinLastSeven,
  label: {
    default: 'Last 7 of VIN',
    short: 'Last 7 of VIN',
  },
});
