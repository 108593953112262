import styled from 'styled-components';

// Colors
import * as colors from '@matthahn/sally-ui/lib/libs/colors';

const Origin = styled.div`
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: ${({background}) => colors[background]};
`;

export default Origin;
