// components
import Column from '@matthahn/sally-ui/lib/components/Grid/Column/Column';
import Form from '@matthahn/sally-ui/lib/components/Form/Form';
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import Row from '@matthahn/sally-ui/lib/components/Grid/Row/Row';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const CreateServiceModal = ({
  category,
  credit,
  default_cost,
  display_priority,
  loading,
  name,
  onChange,
  onClose,
  onSave,
  removed,
  requires_description,
  visible,
}) => (
  <Modal
    visible={visible}
    onClose={onClose}
    title="Create Service Item"
    buttonsRight={[{label: 'Save', theme: 'black', loading, onClick: onSave}]}
  >
    {(Container) => (
      <Container>
        <Form onSubmit={onSave} enter>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                value={name}
                onChange={onChange}
                disabled={loading}
              >
                {name.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={default_cost}
                onChange={onChange}
                preValue="$"
                disabled={loading}
              >
                {default_cost.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                value={category}
                onChange={onChange}
                disabled={loading}
              >
                {category.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={credit}
                onChange={onChange}
                disabled={loading}
              >
                {credit.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                value={requires_description}
                onChange={onChange}
                disabled={loading}
              >
                {requires_description.label.default}
              </AttributeInput>
            </Column>
          </Row>
        </Form>
      </Container>
    )}
  </Modal>
);

CreateServiceModal.propTypes = {
  category: PropTypes.object,
  credit: PropTypes.object,
  default_cost: PropTypes.object,
  display_priority: PropTypes.object,
  loading: PropTypes.bool,
  name: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  removed: PropTypes.object,
  requires_description: PropTypes.object,
  visible: PropTypes.bool,
};

export default CreateServiceModal;
