import f3tch from 'f3tch';

// Constants
import API_URL from '../../api/constants/url.const.api';

// Profiles
import profile from '@matthahn/sally-fw/lib/api/profiles/json.profile.api';

const updateTaskItemApi = ({taskId, taskItemId, taskItem}) =>
  f3tch(`/tasks/${taskId}/items/${taskItemId}/`)
    .profile(
      profile({
        url: API_URL(),
        authorizationType: 'JWT',
        ignoreAppVersionHeader: true,
      })
    )
    .body(taskItem)
    .patch();

export default updateTaskItemApi;
