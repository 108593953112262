import React from 'react';
import PropTypes from 'prop-types';

// layout containers
import Page from '../../../layout/containers/Page/Page';

// local constants
import PAGE from './page';

// task containers
import TaskContainer from '../../containers/TaskContainer/TaskContainer';

// router
import {withRouter} from 'react-router-dom';

const TaskPage = ({
  match: {
    params: {taskId},
  },
}) => (
  <Page page={PAGE}>
    <TaskContainer taskId={taskId} />
  </Page>
);

TaskPage.propTypes = {
  match: PropTypes.object,
};

export default withRouter(TaskPage);
