import React from 'react';
import PropTypes from 'prop-types';

// global components
import {Modal} from '@matthahn/sally-ui';

// layout
import ListItem from '../../../layout/components/ListItem/ListItem';

// local components
import Container from './components/Container';

// type
import dateType from '@matthahn/sally-fw/lib/type/types/date.type';

const VehicleHistoryModal = ({
  visible,
  vehicle,
  tickets,
  onTicket,
  onClose,
}) => (
  <Modal
    visible={visible}
    title={
      !!vehicle
        ? `${
            vehicle.medallion ||
            vehicle.license_plate ||
            vehicle.svid ||
            vehicle.vin
          } history`
        : 'Ticket history'
    }
    onClose={onClose}
  >
    {(Content) => (
      <Content padding="none">
        <Container>
          {tickets.map((ticket) => (
            <ListItem
              key={ticket.id}
              title={dateType(ticket.checkedin_at).format()}
              rightContent={ticket.notes || '-'}
              onClick={onTicket(ticket)}
            />
          ))}
        </Container>
      </Content>
    )}
  </Modal>
);

VehicleHistoryModal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  vehicle: PropTypes.object,
  tickets: PropTypes.array,
  getItems: PropTypes.func,
  onTicket: PropTypes.func,
  onClose: PropTypes.func,
};

export default VehicleHistoryModal;
