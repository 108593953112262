// layout components
import ListItem from '../../../layout/components/ListItem/ListItem';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const VehicleEquipment = ({entries = [], onClose, visible}) =>
  entries.map((entry) => (
    <ListItem
      key={entry.key}
      title={entry.label}
      icon={entry.icon}
      onClick={entry.onClick}
    />
  ));

VehicleEquipment.propTypes = {
  entries: PropTypes.array,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

export default VehicleEquipment;
