import styled from 'styled-components';

// Media
import {media} from '@matthahn/sally-ui/lib/libs/media';

const ButtonContainer = styled.div`
  width: 100%;
  padding: 0px 25px;
  position: absolute;
  top: 100px;
  left: 0px;

  ${media.xs`
    top: 50px;
    padding: 0px 16px;
  `}
`;

export default ButtonContainer;
