const getCalampStatusColor = (status) => {
  const colors = {
    done: 'green',
    warning: 'orange',
    error: 'orange',
    default: 'grey',
  };
  return colors[status] || colors.default;
};

export default getCalampStatusColor;
