// Date
import {getYear} from 'date-fns';

// Lib
import fillArrayWithNumbers from '@matthahn/sally-fw/lib/lib/fillArrayWithNumbers';
import subtract from '@matthahn/sally-fw/lib/lib/subtract';

const availableYears = ({startDate = new Date()} = {}) => {
  const year = getYear(startDate);
  return fillArrayWithNumbers(0, 20).map(
    (yearsToSubtract) => `${subtract(year, yearsToSubtract)}`
  );
};

export default availableYears;
