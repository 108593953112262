import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Alerts
import alertify from '../../../layout/lib/alertify';

// Api
import api from '@matthahn/sally-fw/lib/api/lib/getEverything.lib.api';
import listLocationsApi from '../../api/list.api.location';

// Error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// location events
import locationCreatedEvent from '../../events/created.event.location';
import locationDeletedEvent from '../../events/deleted.event.location';
import locationUpdatedEvent from '../../events/updated.event.location';

// Subscription
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

class LocationsFetchContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    locations: PropTypes.array,
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.init();
    this.props.subscribe(
      locationCreatedEvent.subscribe(this.locationCreated),
      locationDeletedEvent.subscribe(this.locationDeleted),
      locationUpdatedEvent.subscribe(this.locationUpdated)
    );
  }

  init = async () => {
    const {dispatch} = this.props;
    dispatch(setAct({loading: true}));
    try {
      const {data: locations} = await api(listLocationsApi, {
        ordering: '-created_at',
      });
      dispatch(setAct({loading: false, locations}));
    } catch (error) {
      const {message} = parseError(error);
      alertify({message});
      dispatch(setAct({loading: false}));
    }
  };

  locationCreated = (location) => {
    const {loading, locations, dispatch} = this.props;
    if (loading) return;
    const newLocations = [...locations, location];
    dispatch(setAct({locations: newLocations}));
  };

  locationUpdated = (location) => {
    const {loading, locations, dispatch} = this.props;
    if (loading) return;
    const newLocations = [...locations].map((searchingLocation) =>
      searchingLocation.id === location.id ? location : searchingLocation
    );
    dispatch(setAct({locations: newLocations}));
  };

  locationDeleted = (location) => {
    const {loading, locations, dispatch} = this.props;
    if (loading) return;
    const newLocations = [...locations].filter(
      (searchingLocation) => searchingLocation.id !== location.id
    );
    dispatch(setAct({locations: newLocations}));
  };

  render() {
    return <Fragment />;
  }
}

export default subscriptionHoc(
  connect((state) => ({
    loading: state.location.loading,
    locations: state.location.locations,
  }))(LocationsFetchContainer)
);
