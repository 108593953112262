import {TEXT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import apiPhoneNumber from '@matthahn/sally-fw/lib/type/types/apiPhoneNumber.type';
import phoneNumber from '@matthahn/sally-fw/lib/type/types/phoneNumber.type';

export default attribute({
  type: TEXT,
  attribute: 'phone_number',
  display: phoneNumber,
  input: phoneNumber,
  api: apiPhoneNumber,
  label: {
    default: 'Phone Number',
    short: 'Phone',
  },
});
