import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {darkerGrey},
} = lib;

const Action = styled.div`
  color: ${darkerGrey};
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
`;

export default Action;
