import {TEXT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import medallionNumber from '@matthahn/sally-fw/lib/type/types/medallionNumber.type';

export default attribute({
  type: TEXT,
  attribute: 'medallion',
  display: medallionNumber,
  input: medallionNumber,
  api: medallionNumber,
  label: {
    default: 'Medallion Number',
    short: 'Medallion Number',
  },
});
