import styled from 'styled-components';

// Colors
import {black} from '@matthahn/sally-ui/lib/libs/colors';

// Media
import {media} from '@matthahn/sally-ui/lib/libs/media';

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 110px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: ${black};

  ${media.xs`
    height: calc(100vh - 110px);
  `}
`;

export default Container;
