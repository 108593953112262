import React from 'react';

// Global Components
import {Icon} from '@matthahn/sally-ui';

// Local Components
import Container from './components/Container';

const TaskItemLoader = () => (
  <Container>
    <Icon icon="loading2" spin />
  </Container>
);

export default TaskItemLoader;
