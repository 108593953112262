import styled from 'styled-components';

const Tag = styled.div`
  padding: 5px;
  border-radius: 5px;
  font-size: 15px;
  color: ${({color}) => color || '#fff'};
  font-weight: 700;
  background: ${({theme}) => theme};
  cursor: pointer;
`;

export default Tag;
