import React, {Component} from 'react';
import PropTypes from 'prop-types';

// actions
// import {set as setAct} from '../../redux/actions';

// alert
import alertify from '../../../layout/lib/alertify';

// api
import createMechanicApi from '../../api/create.api.mechanic';

// attributes
import nameAttr from '../../attributes/name.attribute.mechanic';
import nicknameAttr from '../../attributes/nickname.attribute.mechanic';

// error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// events
import mechanicCreatedEvent from '../../events/created.event.mechanic';
import showCreateMechanicModalEvent from '../../events/showCreateModal.event.mechanic';

// lib
import extractMechanicToObject from '../../lib/extractToObject.lib.mechanic';

// mechanic components
import EditMechanicModal from '../../components/EditMechanicModal/EditMechanicModal';

// redux
import {connect} from 'react-redux';

// subscription
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

class CreateMechanicContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    mechanics: PropTypes.array,
    subscribe: PropTypes.func,
  };

  state = {
    visible: false,
    loading: false,
    name: nameAttr(''),
    nickname: nicknameAttr(''),
    branch: null,
  };

  componentDidMount() {
    this.props.subscribe(showCreateMechanicModalEvent.subscribe(this.init));
  }

  init = () => {
    this.setState({
      visible: true,
      name: nameAttr(''),
      nickname: nicknameAttr(''),
      branch: null,
    });
  };

  change = (value, key) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  close = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  save = async () => {
    // const {dispatch, mechanics} = this.props;
    const {loading, name, nickname, branch} = this.state;

    if (loading) return;

    const mechanicName = name.api.format();
    const mechanicNickname = nickname.api.format();

    if (!mechanicName.trim().length) return alertify({message: 'Insert name'});
    if (!branch) return alertify({message: 'Insert branch'});

    this.setState({loading: true});

    try {
      const mechanic = await createMechanicApi({
        ...extractMechanicToObject({
          name: mechanicName,
          nickname: mechanicNickname,
        }),
        branch,
      });
      mechanicCreatedEvent.publish(mechanic);
      this.setState({loading: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alertify({message});
      this.setState({loading: false});
    }
  };

  render() {
    const {visible, loading, name, nickname, branch} = this.state;
    return (
      <EditMechanicModal
        branch={branch}
        loading={loading}
        name={name}
        nickname={nickname}
        onChange={this.change}
        onClose={this.close}
        onSave={this.save}
        visible={visible}
      />
    );
  }
}

export default subscriptionHoc(
  connect((state) => ({
    mechanics: state.mechanic.mechanics,
  }))(CreateMechanicContainer)
);
