import f3tch from 'f3tch';

// Constants
import API_URL from '../../api/constants/url.const.api';

// Profiles
import profile from '@matthahn/sally-fw/lib/api/profiles/json.profile.api';

const listTicketStatusesApi = (query = {}) =>
  f3tch('/tickets/statuses/')
    .profile(
      profile({
        url: API_URL(),
        authorizationType: 'JWT',
        ignoreAppVersionHeader: true,
      })
    )
    .query(query)
    .get();

export default listTicketStatusesApi;
