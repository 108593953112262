// local components
import Label from './components/Label';

// react
import React from 'react';

// propTypes
import PropTypes from 'prop-types';

// task state
import getTaskState from '../../state/getTaskState.state.task';

// task states
import noStateState from '../../states/noState.state.task';
import scheduledState from '../../states/scheduled.state.task';

const TaskStateLabel = ({task}) => {
  const taskState = getTaskState(task);
  return [noStateState.state, scheduledState.state].includes(
    taskState.state
  ) ? null : (
    <Label color={taskState.color}>{taskState.label}</Label>
  );
};

TaskStateLabel.propTypes = {
  task: PropTypes.object,
};

export default TaskStateLabel;
