import React, {Component} from 'react';
import PropTypes from 'prop-types';

// alertify
import alertify from '../../../layout/lib/alertify';

// api
import createTaskApi from '../../api/create.api.task';

// attribute
import branchAttribute from '../../attributes/branch.attribute.task';
import notesAttribute from '../../attributes/notes.attribute.task';
import scheduledAtAttribute from '../../attributes/scheduled_at.attribute.task';
import vehicleAttribute from '../../attributes/vehicle.attribute.task';

// components
import CreateTaskModal from '../../components/CreateTaskModal/CreateTaskModal';

// error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// events
import showCreateTaskModalEvent from '../../events/showCreateTaskModal.event.task';

// preparations
import createTaskPreparation from '../../preparations/create.preparation.task';

// redux
import {connect} from 'react-redux';

// routed
import {withRouter} from 'react-router';

// subscribe
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// task permissions
import createTaskPermission from '../../permissions/create.permission.task';

// task routes
import taskRoute from '../../pages/TaskPage/route';

class NewTaskContainer extends Component {
  static propTypes = {
    branch: PropTypes.object,
    subscribe: PropTypes.func,
  };

  static INITIAL_STATE = {
    visible: false,
    loading: false,
    notes: notesAttribute(''),
    scheduled_at: scheduledAtAttribute(''),
    vehicle: null,
  };

  state = {...this.constructor.INITIAL_STATE};

  componentDidMount() {
    this.props.subscribe(showCreateTaskModalEvent.subscribe(this.show));
  }

  show = ({vehicle = null} = {}) => {
    if (!createTaskPermission()) return;
    this.setState({...this.constructor.INITIAL_STATE, visible: true, vehicle});
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  onChange = (value, key) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  create = async () => {
    const {history, branch} = this.props;
    const {loading, notes, scheduled_at, vehicle} = this.state;

    if (loading) return;

    if (!createTaskPermission())
      return alertify({
        message: 'You do not have permission to schedule anything',
      });

    this.setState({loading: true});

    try {
      const preparedTask = await createTaskPreparation({
        notes,
        scheduled_at,
        branch: branchAttribute(branch?.id),
        vehicle: vehicleAttribute(vehicle?.id),
      });
      const createdTask = await createTaskApi(preparedTask);
      this.setState({loading: false, visible: false});
      history.push(taskRoute(createdTask.id));
    } catch (error) {
      const {message} = parseError(error);
      alertify({message});
      this.setState({loading: false});
    }
  };

  render() {
    const {visible, loading, notes, scheduled_at} = this.state;
    return (
      <CreateTaskModal
        visible={visible}
        loading={loading}
        notes={notes}
        scheduled_at={scheduled_at}
        onClose={this.hide}
        onSave={this.create}
        onChange={this.onChange}
      />
    );
  }
}

export default withRouter(
  subscriptionHoc(
    connect((state) => ({branch: state.branch.branch}))(NewTaskContainer)
  )
);
