import styled from 'styled-components';

// colors
import {darkGrey} from '@matthahn/sally-ui/lib/libs/colors';

const IconContainer = styled.div`
  pointer-events: none;
  font-size: 14px;
  color: ${darkGrey};
`;

export default IconContainer;
