import {NUMBER} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import stringNumber from '@matthahn/sally-fw/lib/type/types/stringNumber.type';

export default attribute({
  type: NUMBER,
  attribute: 'ticketId',
  display: stringNumber,
  input: stringNumber,
  api: stringNumber,
  label: {
    default: 'Ticket Number',
    short: 'Ticket Number',
  },
});
