import styled from 'styled-components';

const ActionsRow = styled.div`
  width: 100%;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

export default ActionsRow;
