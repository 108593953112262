// components
import Column from '@matthahn/sally-ui/lib/components/Grid/Column/Column';
import DatePickerInput from '@matthahn/sally-ui/lib/components/Date/DatePickerInput/DatePickerInput';
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import Row from '@matthahn/sally-ui/lib/components/Grid/Row/Row';

// local components
import Clear from './components/Clear';
import InputContainer from './components/InputContainer';
import Label from './components/Label';
import Value from './components/Value';
import ValueContainer from './components/ValueContainer';
import ValueTag from './components/ValueTag';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, Fragment} from 'react';

// types
import dateType from '@matthahn/sally-fw/lib/type/types/date.type';

class DateRangeFilterModal extends Component {
  static propTypes = {
    dateFormat: PropTypes.func,
    label: PropTypes.node,
    onChange: PropTypes.func,
    title: PropTypes.node,
    value: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
  };

  static defaultProps = {
    dateFormat: (date) => dateType(date).format(),
    title: 'Select Date Range',
  };

  state = {
    end: '',
    start: '',
    visible: false,
  };

  componentDidMount() {
    const {value} = this.props;
    if (!value) return;
    this.setState({
      end: value?.end || '',
      start: value?.start || '',
    });
  }

  show = () => {
    const {value} = this.props;
    this.setState({
      end: value?.end || '',
      start: value?.start || '',
      visible: true,
    });
  };

  hide = () => this.setState({visible: false});

  select = () => {
    const {onChange} = this.props;
    const {end, start} = this.state;
    const value = !!end || !!start ? {end, start} : null;
    onChange(value);
    this.setState({visible: false});
  };

  clear = () => {
    this.setState({
      end: '',
      start: '',
    });
  };

  clearAndUpdate = () => {
    this.clear();
    this.props.onChange(null);
  };

  changeDate = (key) => (value) => this.setState({[key]: value});

  render() {
    const {dateFormat, label, title, value} = this.props;
    const {end, start, visible} = this.state;

    return (
      <Fragment>
        <InputContainer onClick={!!value ? null : this.show}>
          {!!label && <Label>{label}</Label>}
          {!!value && (
            <ValueContainer>
              <Value onClick={this.show}>
                <ValueTag>
                  {[!!start && dateFormat(start), !!end && dateFormat(end)]
                    .map((date) => date || '__')
                    .join(' - ')}
                </ValueTag>
              </Value>
              <Clear onClick={this.clearAndUpdate}>
                <i className="mdi mdi-close" />
              </Clear>
            </ValueContainer>
          )}
        </InputContainer>
        <Modal
          visible={visible}
          title={title}
          onClose={this.hide}
          buttonsRight={[
            {label: 'Confirm', theme: 'black', onClick: this.select},
          ]}
          buttonsLeft={[{label: 'Clear', onClick: this.clear}]}
        >
          {(Content) => (
            <Content>
              <Row>
                <Column size={1 / 2}>
                  <DatePickerInput
                    dateFormat={dateFormat}
                    onChange={this.changeDate('start')}
                    value={start}
                  >
                    From
                  </DatePickerInput>
                </Column>
                <Column size={1 / 2}>
                  <DatePickerInput
                    dateFormat={dateFormat}
                    onChange={this.changeDate('end')}
                    value={end}
                  >
                    To
                  </DatePickerInput>
                </Column>
              </Row>
            </Content>
          )}
        </Modal>
      </Fragment>
    );
  }
}

export default DateRangeFilterModal;
