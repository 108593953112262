import prepare from '@matthahn/sally-fw/lib/attribute/prepare.attribute';

const updateItemCostPreparation = async ({
  isSallyVehicle = false,
  ...attributes
}) => {
  const {description, ...itemCostAttributes} = await prepare(attributes, {
    field: 'api',
    required: isSallyVehicle ? ['description'] : ['description'],
  });
  return {
    ...itemCostAttributes,
    description: !!description ? description : '',
  };
};

export default updateItemCostPreparation;
