const columns = [
  {
    key: 'id',
    label: '#',
    span: 1 / 3,
    sortable: true,
  },
  {
    key: 'scheduled_at',
    label: 'Schedule Date',
    span: 1 / 2,
    sortable: true,
  },
  {
    key: 'state',
    label: 'State',
    span: 1 / 2,
  },
  {
    key: 'branch',
    label: 'Branch',
    span: 1 / 2,
  },
  {
    key: 'owner',
    label: 'Owner',
    span: 1 / 2,
  },
  {
    key: 'vehicle',
    label: 'Vehicle',
    span: 1 / 2,
  },
  {
    key: 'model',
    label: 'Model',
  },
  {
    key: 'notes',
    label: 'Notes',
    span: 2,
  },
];

export default columns;
