// components
import Icon from '@matthahn/sally-ui/lib/components/Icon/Icon';

// layout components
import CompactDataRow from '../../../layout/components/CompactDataRow/CompactDataRow';
import ResizableTextArea from '../../../layout/components/ResizableTextArea/ResizableTextArea';

// local components
import Action from './components/Action';
import ActionsRow from './components/ActionsRow';
import Container from './components/Container';
import Date from './components/Date';
import InputBox from './components/InputBox';
import Loader from './components/Loader';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useState, useEffect} from 'react';

// types
import dateTimeType from '@matthahn/sally-fw/lib/type/types/dateTime.type';

const Note = ({
  author,
  canEdit,
  date,
  noBorder = false,
  onDelete,
  onSave,
  saving,
  text,
}) => {
  const [editText, setEditText] = useState(text);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (!saving && editMode) setEditMode(false);
    // eslint-disable-next-line
  }, [saving]);

  return (
    <CompactDataRow
      rowProps={{noBorder}}
      label={
        <Date>
          {dateTimeType(date).format()} by {author}
        </Date>
      }
    >
      <Container>
        {editMode ? (
          <InputBox>
            <ResizableTextArea
              value={editText}
              onChange={(value) => setEditText(value)}
              disabled={saving}
              placeholder="Write a note"
              autoFocus
            />
          </InputBox>
        ) : (
          text
        )}
        {canEdit ? (
          editMode ? (
            <ActionsRow>
              <Action onClick={() => onSave(editText)}>Save</Action>|
              <Action onClick={onDelete}>Delete</Action>|
              <Action onClick={() => setEditMode(false)}>Cancel</Action>
            </ActionsRow>
          ) : (
            <ActionsRow>
              <Action
                onClick={() => {
                  setEditText(text);
                  setEditMode(true);
                }}
              >
                Edit
              </Action>
            </ActionsRow>
          )
        ) : null}
        {saving && (
          <Loader>
            <Icon icon="loading2" spin />
          </Loader>
        )}
      </Container>
    </CompactDataRow>
  );
};

Note.propTypes = {
  author: PropTypes.string,
  canEdit: PropTypes.bool,
  date: PropTypes.string,
  noBorder: PropTypes.bool,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  saving: PropTypes.bool,
  text: PropTypes.string,
};

export default Note;
