import React from 'react';

// layout containers
import Page from '../../../layout/containers/Page/Page';

// local constants
import PAGE from './page';

// service containers
import ServiceItemsContainer from '../../containers/ServiceItemsContainer/ServiceItemsContainer';

const ServiceItemsPage = () => (
  <Page page={PAGE}>
    <ServiceItemsContainer />
  </Page>
);

export default ServiceItemsPage;
