import React from 'react';

// Container
import TicketsContainer from '../../../ticket/containers/TicketsContainer/TicketsContainer';
import Page from '../../../layout/containers/Page/Page';

// Page
import PAGE from '../../../ticket/pages/TicketsPage/page';

const HomePage = () => (
  <Page page={PAGE}>
    <TicketsContainer />
  </Page>
);

export default HomePage;
