import React, {Component} from 'react';
import PropTypes from 'prop-types';

// api
import api from '@matthahn/sally-fw/lib/api/lib/getEverything.lib.api';

// date
import {getDay, isSameDay, parseISO} from 'date-fns';

import dayRange from '../../../date/lib/dayRange.lib.date';

// entry components
import PublicDailyEntries from '../../components/PublicDailyEntries/PublicDailyEntries';

// entry services
import getEntriesService from '../../service/get.service.entry';

// events
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// navigation
import {setPage as setNavigationAction} from '../../../layout/containers/NavigationContainer/redux/actions';

// redux
import {connect} from 'react-redux';

// tasks api
import listTasksApi from '../../../task/api/list.api.task';

// task permissions
import readTaskPermission from '../../../task/permissions/read.permission.task';

// task sockets
import taskCreatedSocket from '../../../task/sockets/created.socket.task';
import taskDeletedSocket from '../../../task/sockets/deleted.socket.task';
import taskUpdatedSocket from '../../../task/sockets/updated.socket.task';

// tickets api
import listTicketsApi from '../../../ticket/api/list.api.ticket';

// ticket permissions
import readTicketPermission from '../../../ticket/permissions/read.permission.ticket';

// ticket sockets
import ticketCreatedSocket from '../../../ticket/sockets/created.socket.ticket';
import ticketDeletedSocket from '../../../ticket/sockets/deleted.socket.ticket';
import ticketUpdatedSocket from '../../../ticket/sockets/updated.socket.ticket';

// uuid
import {v4} from 'uuid';

// visibility
import visibleEvent from '../../../visibility/events/visible.event.visibility';

class PublicDailyEntryListContainer extends Component {
  static propTypes = {
    branch: PropTypes.object,
    drivers: PropTypes.array,
    driversLoading: PropTypes.bool,
    vehicles: PropTypes.array,
    subscribe: PropTypes.func,
  };

  state = {
    loading: true,
    tickets: [],
    tasks: [],
    lastUpdate: new Date(),
    time: new Date(),
  };

  componentDidMount() {
    this.setNavigationItems();
    this.getData();
    this.subscribe();
    this.setupTimer();
  }

  componentWillUnmount() {
    this.unsetTimer();
  }

  apiId = null;

  subscribe = () =>
    this.props.subscribe(
      taskCreatedSocket.subscribe(this.entryCreated('tasks')),
      taskDeletedSocket.subscribe(this.entryDeleted('tasks')),
      taskUpdatedSocket.subscribe(this.entryUpdated('tasks')),
      ticketCreatedSocket.subscribe(this.entryCreated('tickets')),
      ticketDeletedSocket.subscribe(this.entryDeleted('tickets')),
      ticketUpdatedSocket.subscribe(this.entryUpdated('tickets')),
      visibleEvent.subscribe(this.getData)
    );

  setNavigationItems = () => {
    const {dispatch} = this.props;
    dispatch(setNavigationAction({showNavigation: false}));
  };

  setupTimer = () => {
    this.timer = setInterval(this.refresh, 1000);
  };

  unsetTimer = () => {
    if (!this.timer) return;
    clearInterval(this.timer);
  };

  refresh = () => {
    this.setState({time: new Date()});
    if (getDay(this.state.lastUpdate) !== getDay(new Date())) this.getData();
  };

  getData = async () => {
    this.setState({loading: true});

    const apiId = v4();
    this.apiId = apiId;

    const [tickets, tasks] = await Promise.all([
      this.getTickets(),
      this.getTasks(),
    ]);

    if (this.apiId !== apiId) return;

    this.setState({loading: false, tickets, tasks, lastUpdate: new Date()});
  };

  entryCreated = (entryType) => (entry) => {
    if (
      this.state.loading ||
      (entryType === 'tickets' &&
        !isSameDay(parseISO(entry.checkedin_at), new Date())) ||
      (entryType === 'tasks' &&
        !isSameDay(parseISO(entry.scheduled_at), new Date()))
    )
      return;
    this.setState({[entryType]: [...this.state[entryType], entry]});
  };

  entryUpdated = (entryType) => (entry) => {
    if (this.state.loading) return;
    this.setState({
      [entryType]: [...this.state[entryType]].map((existingEntry) =>
        existingEntry.id === entry.id ? entry : existingEntry
      ),
    });
  };

  entryDeleted = (entryType) => (entry) => {
    if (this.state.loading) return;
    this.setState({
      [entryType]: [...this.state[entryType]].filter(
        (existingEntry) => existingEntry.id !== entry.id
      ),
    });
  };

  getTickets = async () => {
    const {branch} = this.props;
    if (!readTicketPermission()) return [];
    try {
      const {data} = await api(listTicketsApi, {
        approved: false,
        vehicle__branch__tag: branch.tag,
        checkedin_at__range: dayRange(),
      });
      return data;
    } catch (error) {
      return [];
    }
  };

  getTasks = async () => {
    const {branch} = this.props;
    if (!readTaskPermission()) return [];
    try {
      const {data} = await api(listTasksApi, {
        branch: branch.id,
        ordering: '-checkedin_at',
        status: 'open',
        scheduled_at__range: dayRange(),
      });
      return data;
    } catch (error) {
      return [];
    }
  };

  isLoading = () => {
    const {driversLoading, drivers} = this.props;
    const {loading} = this.state;
    return (!drivers.length && driversLoading) || loading;
  };

  entries = () => {
    const {vehicles, drivers, branch} = this.props;
    const {tickets, tasks} = this.state;
    return getEntriesService({
      tasks,
      tickets,
      vehicles,
      drivers,
      branchId: branch?.id,
    });
  };

  render() {
    return (
      <PublicDailyEntries loading={this.isLoading()} entries={this.entries()} />
    );
  }
}

export default subscriptionHoc(
  connect((state) => ({
    branch: state.branch.branch,
    drivers: state.spotlight.drivers,
    driversLoading: state.spotlight.driversLoading,
    vehicles: state.spotlight.vehicles,
  }))(PublicDailyEntryListContainer)
);
