import styled from 'styled-components';

// colors
import {grey, darkGrey} from '@matthahn/sally-ui/lib/libs/colors';

// local constants
const SIZE = '16px';

const Clear = styled.div`
  width: ${SIZE};
  max-width: ${SIZE};
  min-width: ${SIZE};
  height: ${SIZE};
  max-height: ${SIZE};
  min-height: ${SIZE};
  border-radius: 50%;
  background: ${grey};
  color: ${darkGrey};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

export default Clear;
