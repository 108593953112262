const numberOfSelectedServices = (services) =>
  [...services]
    .map((service) =>
      !!service?.selectedAttributes?.length
        ? [...service.selectedAttributes].map((attribute) => ({
            ...service,
            attribute,
          }))
        : service
    )
    .flat().length;

export default numberOfSelectedServices;
