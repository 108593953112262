import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Alert} from '@matthahn/sally-ui';

// Local Components
import Container from './components/Container';

const TicketAlerts = ({alerts}) =>
  !!alerts.length ? (
    <Container>
      {alerts.map(({key, content, ...alertProps}) => (
        <Alert key={key} {...alertProps}>
          {content}
        </Alert>
      ))}
    </Container>
  ) : null;

TicketAlerts.propTypes = {
  alerts: PropTypes.array,
  approved: PropTypes.bool,
  approvedAt: PropTypes.string,
  approvedBy: PropTypes.string,
};

export default TicketAlerts;
