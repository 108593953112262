import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {transitions} from 'polished';

const Wrap = styled.div`
  position: fixed;
  top: 0px;
  z-index: 100000000000000010;
  width: 100%;
  height: 100vh;
  right: 0px;
  user-select: none;
  pointer-events: ${({visible}) => (visible ? 'auto' : 'none')};
  opacity: ${({visible}) => (visible ? 1 : 0)};
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  ${transitions('opacity .2s ease-in-out')};
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0px;
  }
`;

const Content = styled.div`
  width: ${({big}) => (big ? '320px' : '270px')};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: ${({visible}) =>
    `translate(-50%, -50%) scale(${visible ? '1' : '0.7'})`};
`;

class NotificationContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    big: PropTypes.bool,
    children: PropTypes.node,
  };

  render() {
    const {visible, big, children} = this.props;
    return (
      <Wrap visible={visible}>
        <Container visible={visible}>
          <Content visible={visible} big={big}>
            {children}
          </Content>
        </Container>
      </Wrap>
    );
  }
}

export default NotificationContainer;
