import React from 'react';

// layout containers
import Page from '../../../layout/containers/Page/Page';

// local constants
import PAGE from './page';

// ticket containers
import MechanicsContainer from '../../containers/MechanicsContainer/MechanicsContainer';

const MechanicsPage = () => (
  <Page page={PAGE}>
    <MechanicsContainer />
  </Page>
);

export default MechanicsPage;
