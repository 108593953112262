// alertify
import alertify from '../../../layout/lib/alertify';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// ticket api
import updateTicketApi from '../../api/update.api.ticket';

// ticket attributes
import externalInvoiceIdAttr from '../../attributes/external_invoice_id.attribute.ticket';

// ticket components
import ExternalIdCard from '../../components/ExternalIdCard/ExternalIdCard';

// ticket lib
import shouldDisplayExternalId from '../../lib/shouldDisplayExternalId.lib.ticket';

// ticket permissions
import updateExternalIdPermission from '../../../ticket/permissions/updateExternalId.permission.ticket';
import updateTicketPermission from '../../../ticket/permissions/update.permission.ticket';

class ExternalIdContainer extends Component {
  static propTypes = {
    syncTicket: PropTypes.func,
    ticket: PropTypes.object,
  };

  state = {
    external_invoice_id: externalInvoiceIdAttr(''),
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = () => {
    const {ticket} = this.props;
    this.setState({
      external_invoice_id: externalInvoiceIdAttr(
        ticket?.external_invoice_id || ''
      ),
    });
  };

  saveTicket = async () => {
    const {ticket, syncTicket} = this.props;
    const {external_invoice_id} = this.state;
    if (!updateTicketPermission()) return;
    try {
      const savedTicket = await updateTicketApi(ticket.id, {
        external_invoice_id: external_invoice_id.api.format() || '',
      });
      const updatedTicket = {
        ...ticket,
        phone_number: savedTicket.phone_number,
        external_invoice_id: savedTicket.external_invoice_id,
      };
      syncTicket(updatedTicket);
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alertify({message});
    }
  };

  change = (external_invoice_id) => this.setState({external_invoice_id});

  render() {
    const {ticket} = this.props;
    const {external_invoice_id} = this.state;
    return shouldDisplayExternalId({ticket}) &&
      updateExternalIdPermission() &&
      (!ticket.approved || !!ticket?.external_invoice_id) ? (
      <ExternalIdCard
        editable={!ticket?.approved}
        external_invoice_id={external_invoice_id}
        onChange={this.change}
        onSave={this.saveTicket}
      />
    ) : null;
  }
}

export default ExternalIdContainer;
