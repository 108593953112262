// Action constants
import {SET} from './constants';

const DEFAULT_STATE = {
  loading: true,
  tickets: [],
  tasks: [],
  closedTickets: [],
  show: 'tickets',

  query: {
    branches: [],
    checkIn: null,
    checkOut: null,
    owners: [],
    page: 1,
    pages: 1,
    search: '',
    services: null,
    sort: {key: 'checkedin_at', direction: 'desc'},
    status: null,
    tags: [],
  },
};

const ticketReducer = (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case SET: {
      return {
        ...state,
        ...data,
      };
    }

    default: {
      return state;
    }
  }
};

export default ticketReducer;
