// components
import CheckBox from '@matthahn/sally-ui/lib/components/CheckBox/CheckBox';

// layout components
import ItemListLayout from '../../../layout/components/ItemListLayout/ItemListLayout';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const CustomServiceItemsList = ({
  actions = [],
  columns,
  loading,
  onPage,
  onSearch,
  onServiceItem,
  page,
  pages,
  search,
  selectedServiceItems,
  serviceItems,
}) => (
  <ItemListLayout
    actions={actions}
    columns={columns}
    onSearch={onSearch}
    search={search}
    searchPlaceholder="Search Items"
    tableProps={{
      loading,
      page,
      pages,
      onPage,
    }}
  >
    {(TableRow) =>
      serviceItems.map((serviceItem) => (
        <TableRow key={serviceItem.id} onClick={onServiceItem(serviceItem)}>
          {(TableCol) => [
            <TableCol key="id">
              <CheckBox
                value={selectedServiceItems.includes(serviceItem.id)}
                onClick={() => {}}
                size="small"
              />
            </TableCol>,
            <TableCol key="name" span={10}>
              {serviceItem.custom_service}
            </TableCol>,
          ]}
        </TableRow>
      ))
    }
  </ItemListLayout>
);

CustomServiceItemsList.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.array,
  loading: PropTypes.bool,
  onPage: PropTypes.func,
  onSearch: PropTypes.func,
  onServiceItem: PropTypes.func,
  page: PropTypes.number,
  pages: PropTypes.number,
  search: PropTypes.string,
  selectedServiceItems: PropTypes.array,
  serviceItems: PropTypes.array,
};

export default CustomServiceItemsList;
