// api lib
import sortQuery from '../../../api/lib/sortQuery.lib.api';

// csv services
import downloadCsvService from '../../../csv/services/download.service.csv';

// events lib
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// item api
import listCustomServiceItemsApi from '../../../item/api/listCustom.api.item';

// local constants
import columns from './constants/columns.constant';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// service components
import CustomServiceItemsList from '../../components/CustomServiceItemsList/CustomServiceItemsList';

// service events
import showConvertServiceItemsModalEvent from '../../events/showConvertCustomItemsModal.event.service';
import serviceItemsConvertedEvent from '../../events/converted.event.service';

// uuid
import {v4} from 'uuid';

class CustomServiceItemsContainer extends Component {
  static propTypes = {
    branches: PropTypes.array,
    subscribe: PropTypes.func,
  };

  state = {
    branch: null,
    downloadingCsv: false,
    loading: true,
    page: 1,
    pages: 1,
    search: '',
    sort: {key: 'custom_service', direction: 'asc'},
    serviceItems: [],
    selectedServiceItems: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.getServiceItems();
    this.props.subscribe(
      serviceItemsConvertedEvent.subscribe(this.serviceItemsConverted)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  apiId = null;

  serviceItemsConverted = () => {
    this.setState({selectedServiceItems: []});
    this.getServiceItems({page: 1});
  };

  getServiceItems = async ({
    branch = this.state.branch,
    page = this.state.page,
    perPage = 50,
    search = this.state.search,
    sort = this.state.sort,
  } = {}) => {
    const apiId = v4();
    this.apiId = apiId;
    this.setState({
      branch,
      loading: true,
      page,
      search,
      sort,
      serviceItems: [],
    });
    try {
      const query = this.generateApiQuery({
        branch,
        page,
        perPage,
        search,
        sort,
      });

      const {results: serviceItems, count} = await listCustomServiceItemsApi(
        query
      );
      if (this.apiId !== apiId || !this.mounted) return;
      this.setState({
        loading: false,
        serviceItems,
        pages: Math.ceil(count / perPage) || 1,
      });
    } catch (error) {
      if (this.apiId !== apiId || !this.mounted) return;
      this.setState({loading: false});
    }
  };

  downloadCsv = async () => {
    const {downloadingCsv, branch, search, sort} = this.state;
    if (downloadingCsv) return;
    this.setState({downloadingCsv: true});
    await downloadCsvService({
      apiCall: listCustomServiceItemsApi,
      fileName: 'serviceItems.csv',
      query: this.generateApiQuery({branch, search, sort}),
      parseData: (serviceItems) =>
        [...serviceItems].map((serviceItem) => ({
          name: `"${serviceItem.custom_service}"`,
        })),
    });
    if (!this.mounted) return;
    this.setState({downloadingCsv: false});
  };

  generateApiQuery = ({branch, page = 1, perPage = 1, search, sort}) => {
    const query = {
      ...sortQuery(sort),
      limit: perPage,
      offset: (page - 1) * perPage,
    };

    if (!!branch) query.branch = branch;
    if (!!search) query.search = search;

    return query;
  };

  setPage = (page) => {
    if (this.state.loading) return;
    this.getServiceItems({page});
  };

  search = (search) => this.getServiceItems({search, page: 1});

  selectServiceItem = (serviceItem) => () => {
    const {selectedServiceItems} = this.state;
    const updatedSelectedServiceItems = selectedServiceItems.includes(
      serviceItem.id
    )
      ? selectedServiceItems.filter((id) => id !== serviceItem.id)
      : [...selectedServiceItems, serviceItem.id];
    this.setState({selectedServiceItems: updatedSelectedServiceItems});
  };

  showConvertServiceItemsModal = () => {
    const {selectedServiceItems, serviceItems} = this.state;
    const selectedServiceItemObjects = selectedServiceItems.map(
      (serviceItemId) => serviceItems.find(({id}) => id === serviceItemId)
    );
    showConvertServiceItemsModalEvent.publish({
      serviceItems: selectedServiceItemObjects,
    });
  };

  setFilter = ({key, value}) =>
    this.getServiceItems({
      [key]: ['all'].includes(value) ? null : value || null,
      page: 1,
    });

  actions = () => {
    const {downloadingCsv, selectedServiceItems} = this.state;
    return [
      {
        key: 'downloadCSV',
        children: 'Download CSV',
        icon: 'download',
        loading: downloadingCsv,
        theme: 'grey',
        onClick: this.downloadCsv,
      },
      {
        key: 'convert',
        children: 'Convert to Service Item',
        icon: 'reload1',
        theme: 'black',
        disabled: !selectedServiceItems.length,
        onClick: this.showConvertServiceItemsModal,
      },
    ];
  };

  render() {
    const {loading, page, pages, search, selectedServiceItems, serviceItems} =
      this.state;
    return (
      <CustomServiceItemsList
        actions={this.actions()}
        columns={columns}
        loading={loading}
        onPage={this.setPage}
        onSearch={this.search}
        onServiceItem={this.selectServiceItem}
        page={page}
        pages={pages}
        search={search}
        selectedServiceItems={selectedServiceItems}
        serviceItems={serviceItems}
      />
    );
  }
}

export default subscriptionHOC(CustomServiceItemsContainer);
