import React from 'react';

// layout containers
import Page from '../../../layout/containers/Page/Page';

// local constants
import PAGE from './page';

// ticket containers
import NewVehicleContainer from '../../containers/NewVehicleContainer/NewVehicleContainer';

const NewVehiclePage = () => (
  <Page page={PAGE}>
    <NewVehicleContainer />
  </Page>
);

export default NewVehiclePage;
