import f3tch from 'f3tch';

// Constants
import API_URL from '../../api/constants/url.const.api';

// Profiles
import profile from '@matthahn/sally-fw/lib/api/profiles/jsonNoResponse.profile.api';

const createItemApi = ({ticketId, itemId}) =>
  f3tch(`/tickets/${ticketId}/items/${itemId}/`)
    .profile(
      profile({
        url: API_URL(),
        authorizationType: 'JWT',
        ignoreAppVersionHeader: true,
      })
    )
    .delete();

export default createItemApi;
