// api
import listLocationsApi from '../../api/list.api.location';

// csv services
import downloadCsvService from '../../../csv/services/download.service.csv';

// events
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// local constants
import columns from './constants/columns.constant';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

// router
import {withRouter} from 'react-router-dom';

// location components
import LocationsList from '../../components/LocationsList/LocationsList';

// location events
import showCreateLocationModalEvent from '../../events/showCreateModal.event.location';
import showUpdateLocationModalEvent from '../../events/showUpdateModal.event.location';
import locationCreatedEvent from '../../events/created.event.location';
import locationDeletedEvent from '../../events/deleted.event.location';
import locationUpdatedEvent from '../../events/updated.event.location';

// location redux actions
import {setQuery as setLocationsQuery} from '../../redux/actions';

// uuid
import {v4} from 'uuid';

class LocationsContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object,
    query: PropTypes.object,
    subscribe: PropTypes.func,
  };

  state = {
    downloadingCsv: false,
    loading: true,
    locations: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.getLocations();
    this.props.subscribe(
      locationCreatedEvent.subscribe(this.locationCreated),
      locationDeletedEvent.subscribe(this.locationDeleted),
      locationUpdatedEvent.subscribe(this.locationUpdated)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  apiId = null;

  getLocations = async ({
    page = this.props.query.page,
    perPage = 50,
    search = this.props.query.search,
  } = {}) => {
    const {dispatch} = this.props;

    const apiId = v4();
    this.apiId = apiId;

    dispatch(setLocationsQuery({page, search}));
    this.setState({
      loading: true,
      locations: [],
    });
    try {
      const query = this.generateApiQuery({page, perPage, search});

      const {results: locations, count} = await listLocationsApi(query);
      if (this.apiId !== apiId || !this.mounted) return;
      this.setState({
        loading: false,
        locations,
      });
      dispatch(setLocationsQuery({pages: Math.ceil(count / perPage) || 1}));
    } catch (error) {
      if (this.apiId !== apiId || !this.mounted) return;
      this.setState({loading: false});
    }
  };

  generateApiQuery = ({page, perPage, search}) => {
    const query = {
      limit: perPage,
      offset: (page - 1) * perPage,
      ordering: 'name',
    };

    if (!!search) query.search = search;

    return query;
  };

  downloadCsv = async () => {
    const {query} = this.props;
    const {downloadingCsv} = this.state;
    if (downloadingCsv) return;
    this.setState({downloadingCsv: true});
    await downloadCsvService({
      apiCall: listLocationsApi,
      fileName: 'locations.csv',
      query: this.generateApiQuery(query),
      parseData: (locations) =>
        [...locations].map((location) => ({
          ...location,
        })),
    });
    if (!this.mounted) return;
    this.setState({downloadingCsv: false});
  };

  setPage = (page) => {
    if (this.state.loading) return;
    this.getLocations({page});
  };

  search = (search) => this.getLocations({search, page: 1});

  locations = () => this.state.locations;

  actions = () => {
    const {downloadingCsv} = this.state;
    return [
      {
        key: 'downloadCSV',
        children: 'Download CSV',
        icon: 'download',
        loading: downloadingCsv,
        theme: 'grey',
        onClick: this.downloadCsv,
      },
      {
        key: 'create',
        children: 'New Status',
        icon: 'plus',
        theme: 'black',
        onClick: () => showCreateLocationModalEvent.publish(),
      },
    ];
  };

  editLocation = (location) => () => {
    showUpdateLocationModalEvent.publish({location});
  };

  locationCreated = (location) => {
    const {loading, locations} = this.state;
    if (loading) return;
    const newLocations = [...locations, location];
    this.setState({locations: newLocations});
  };

  locationUpdated = (location) => {
    const {loading, locations} = this.state;
    if (loading) return;
    const newLocations = [...locations].map((searchingLocation) =>
      searchingLocation.id === location.id ? location : searchingLocation
    );
    this.setState({locations: newLocations});
  };

  locationDeleted = (location) => {
    const {loading, locations} = this.state;
    if (loading) return;
    const newLocations = [...locations].filter(
      (searchingLocation) => searchingLocation.id !== location.id
    );
    this.setState({locations: newLocations});
  };

  render() {
    const {
      query: {page, pages, search},
    } = this.props;
    const {loading} = this.state;
    const locations = this.locations();
    return (
      <LocationsList
        actions={this.actions()}
        columns={columns}
        loading={loading}
        onLocation={this.editLocation}
        onPage={this.setPage}
        onSearch={this.search}
        page={page}
        pages={pages}
        search={search}
        locations={locations}
      />
    );
  }
}

export default subscriptionHoc(
  withRouter(
    connect((state) => ({
      query: state.location.query,
    }))(LocationsContainer)
  )
);
