// app lib
// import isProduction from '../../app/lib/isProduction.lib.app';

// vehicle lib
import isAmpleVehicle from './isAmple.lib.vehicle';

const checkSpireon = ({vehicle = null, spireon = null} = {}) => {
  const complete = {status: 'done', message: 'Spireon', progress: 1};
  // if (!isProduction()) return complete;

  const isSpireonInstalled = isAmpleVehicle(vehicle) || !!spireon;

  return !isSpireonInstalled
    ? {
        status: 'error',
        message: 'Spireon not installed',
        progress: 0,
      }
    : complete;
};

export default checkSpireon;
