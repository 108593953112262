import React from 'react';

// layout containers
import Page from '../../../layout/containers/Page/Page';

// local constants
import PAGE from './page';

// ticket containers
import OwnersContainer from '../../containers/OwnersContainer/OwnersContainer';

const OwnersPage = () => (
  <Page page={PAGE}>
    <OwnersContainer />
  </Page>
);

export default OwnersPage;
