import {DATE} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import apiDate from '@matthahn/sally-fw/lib/type/types/apiDate.type';
import date from '@matthahn/sally-fw/lib/type/types/date.type';

export default attribute({
  type: DATE,
  attribute: 'spireon_install_date',
  display: date,
  input: date,
  api: apiDate,
  label: {
    default: 'Spireon Install Date',
    short: 'Install Date',
  },
});
