import styled from 'styled-components';

// colors
import {grey} from '@matthahn/sally-ui/lib/libs/colors';

const Search = styled.div`
  width: 100%;
  height: 30px;
  border-bottom: 1px solid ${grey};
`;

export default Search;
