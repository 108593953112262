import styled from 'styled-components';

const Border = styled.div`
  width: 100%;
  border-radius: 5px;
  ${'' /* padding: 8px; */}
  position: relative;
  display: flex;
  align-items: stretch;
`;

export default Border;
