import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Alerts
import alertify from '../../../layout/lib/alertify';

// Api
import api from '@matthahn/sally-fw/lib/api/lib/getEverything.lib.api';
import listOwnersApi from '../../api/list.api.owner';

// Error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// Sockets
import ownerCreatedSocket from '../../sockets/created.socket.owner';
import ownerDeletedSocket from '../../sockets/deleted.socket.owner';
import ownerUpdatedSocket from '../../sockets/updated.socket.owner';

// Subscription
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

class OwnerFetchContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    owners: PropTypes.array,
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.init();
    this.props.subscribe(
      ownerCreatedSocket.subscribe(this.ownerCreated),
      ownerDeletedSocket.subscribe(this.ownerDeleted),
      ownerUpdatedSocket.subscribe(this.ownerUpdated)
    );
  }

  init = async () => {
    const {dispatch} = this.props;
    dispatch(setAct({loading: true}));
    try {
      const {data: owners} = await api(listOwnersApi, {
        ordering: '-created_at',
      });
      dispatch(setAct({loading: false, owners}));
    } catch (error) {
      const {message} = parseError(error);
      alertify({message});
      dispatch(setAct({loading: false}));
    }
  };

  ownerCreated = (owner) => {
    const {loading, owners, dispatch} = this.props;
    if (loading) return;
    const newOwners = [...owners, owner];
    dispatch(setAct({owners: newOwners}));
  };

  ownerUpdated = (owner) => {
    const {loading, owners, dispatch} = this.props;
    if (loading) return;
    const newOwners = [...owners].map((searchingOwner) =>
      searchingOwner.id === owner.id ? owner : searchingOwner
    );
    dispatch(setAct({owners: newOwners}));
  };

  ownerDeleted = (owner) => {
    const {loading, owners, dispatch} = this.props;
    if (loading) return;
    const newOwners = [...owners].filter(
      (searchingOwner) => searchingOwner.id !== owner.id
    );
    dispatch(setAct({owners: newOwners}));
  };

  render() {
    return <Fragment />;
  }
}

export default subscriptionHoc(
  connect((state) => ({
    loading: state.owner.loading,
    owners: state.owner.owners,
  }))(OwnerFetchContainer)
);
