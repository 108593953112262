// alertify
import alertify from '../../../layout/lib/alertify';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// item api
import createItemApi from '../../api/create.api.item';
import deleteItemApi from '../../api/delete.api.item';
import setItemAsTaxableApi from '../../api/setTaxable.api.item';
import updateItemApi from '../../api/update.api.item';

// item attributes
import costAttr from '../../attributes/cost.attribute.item';
import quantityAttr from '../../attributes/quantity.attribute.item';
import serviceDescriptionAttr from '../../attributes/service_description.attribute.item';

// item components
import TicketItems from '../../components/TicketItems/TicketItems';
import EditItemModal from '../../components/EditItemModal/EditItemModal';

// item lib
import attachServiceToItem from '../../lib/attachService.lib.item';
import canChargeForItems from '../../lib/canChargeForItems.lib.item';
import getItemName from '../../lib/getName.lib.item';

// item preparations
import updateItemPreparation from '../../preparations/updateCost.preparation.item';

// lib
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';

// math lib
import sum from '@matthahn/sally-fw/lib/lib/sum';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, Fragment} from 'react';

// redux
import {connect} from 'react-redux';

// service api
import getDefaultServiceCostApi from '../../../service/api/defaultCost.api.service';

// service lib
import isDiscountService from '../../../service/lib/isDiscount.lib.service';

// service events
import showSelectServiceModalEvent from '../../../service/events/showSelectServiceModal.event.service';
import serviceSelectedEvent from '../../../service/events/serviceSelected.event.service';

// ticket api
import getTicketByIdApi from '../../../ticket/api/getById.api.ticket';

// ticket lib
import isTicketEditable from '../../../ticket/lib/isEditable.lib.ticket';
import updateTicketItems from '../../../ticket/lib/updateItems.lib.ticket';

// ticket permissions
import updateTicketPermission from '../../../ticket/permissions/update.permission.ticket';

// uuid
import {v4} from 'uuid';

// vehicle lib
import isSallyVehicle from '../../../vehicle/lib/isSallyVehicle.lib.vehicle';

class TicketItemsContainer extends Component {
  static propTypes = {
    services: PropTypes.array,
    syncTicket: PropTypes.func,
    ticket: PropTypes.object,
  };

  state = {
    addingItems: [],
    charge_on_approval: false,
    cost: costAttr(''),
    editModalVisible: false,
    item: null,
    quantity: quantityAttr(''),
    saving: false,
    service_description: serviceDescriptionAttr(''),
    taxable: false,
  };

  componentDidMount() {
    this.props.subscribe(serviceSelectedEvent.subscribe(this.createItems));
  }

  items = () => {
    const {services, ticket} = this.props;
    return !!ticket
      ? [...ticket.items]
          .map((item) => attachServiceToItem({item, services}))
          .filter(({service}) => !isDiscountService(service))
          .sort((a, b) => a.id - b.id)
      : [];
  };

  discounts = () => {
    const {services, ticket} = this.props;
    return !!ticket
      ? [...ticket.items]
          .map((item) => attachServiceToItem({item, services}))
          .filter(({service}) => isDiscountService(service))
          .sort((a, b) => a.id - b.id)
      : [];
  };

  showSelectServiceModal = () => {
    if (!updateTicketPermission()) return;
    showSelectServiceModalEvent.publish();
  };

  onItemDelete = (item) => () => this.deleteItem({item});

  deleteItem = async ({item, prompt = true}) => {
    const {syncTicket, ticket} = this.props;
    const {saving} = this.state;
    if (!isTicketEditable(ticket) || !updateTicketPermission() || saving)
      return;

    if (prompt)
      return notify({
        title: 'Delete item',
        content: `Are you sure you want to delete ${getItemName(item)}?`,
        primary: {label: 'Cancel'},
        secondary: {
          label: 'Delete',
          onClick: () => this.deleteItem({item, prompt: false}),
        },
      });

    this.setState({saving: true});

    try {
      await deleteItemApi({ticketId: ticket.id, itemId: item.id});
      const updatedTicket = await getTicketByIdApi(ticket.id);
      const newTicket = {
        ...ticket,
        items: [...ticket.items].filter(
          (filteredItem) => filteredItem.id !== item.id
        ),
        ticket_cost_summary: updatedTicket?.ticket_cost_summary,
      };

      syncTicket(newTicket);
    } catch (error) {
      const {message} = parseError(error);
      alertify({message});
    }

    this.setState({editModalVisible: false, saving: false});
  };

  setAsTaxable = async (taxable) => {
    const {ticket, syncTicket} = this.props;
    const {item} = this.state;
    if (!isTicketEditable(ticket) || !updateTicketPermission()) return;

    this.setState({taxable});

    try {
      const updatedItem = await setItemAsTaxableApi({
        ticketId: ticket.id,
        itemId: item.id,
        taxable,
      });
      const newTicket = {
        ...ticket,
        items: [...ticket.items].map((itemToUpdate) =>
          itemToUpdate.id === updatedItem.id ? updatedItem : itemToUpdate
        ),
        ticket_cost_summary:
          updatedItem?.ticket_cost_summary || ticket.ticket_cost_summary,
      };

      syncTicket(newTicket);
    } catch (error) {
      const {message} = parseError(error);
      alertify({message});
    }
  };

  saveItem = async () => {
    const {ticket, syncTicket} = this.props;
    const {
      charge_on_approval,
      cost,
      item,
      loading,
      quantity,
      saving,
      service_description,
    } = this.state;

    if (
      !item ||
      loading ||
      saving ||
      !isTicketEditable(ticket) ||
      !updateTicketPermission()
    )
      return;

    this.setState({saving: true});

    try {
      const preparedItem = await updateItemPreparation({
        isSallyVehicle: isSallyVehicle(ticket?.vehicle),
        cost,
        quantity,
        service_description,
      });

      const latestItem = await updateItemApi({
        ticketId: ticket.id,
        itemId: item.id,
        item: {...preparedItem, charge_on_approval},
      });

      const newTicket = {
        ...ticket,
        items: [...ticket.items].map((itemToUpdate) =>
          itemToUpdate.id === latestItem.id ? latestItem : itemToUpdate
        ),
        ticket_cost_summary:
          latestItem?.ticket_cost_summary || ticket.ticket_cost_summary,
      };

      syncTicket(newTicket);

      this.setState({
        saving: false,
        editModalVisible: false,
      });
    } catch (error) {
      syncTicket(ticket);
      const {message} = parseError(error);
      alertify({message});
      this.setState({saving: false});
    }
  };

  createItems = async (services, {prompt = true} = {}) => {
    const preparedServices = [...services]
      .map((service) =>
        !!service?.selectedAttributes?.length
          ? [...service.selectedAttributes].map((attribute) => ({
              ...service,
              attribute,
            }))
          : service
      )
      .flat();
    const apiId = v4();
    const {ticket, syncTicket} = this.props;
    if (!isTicketEditable(ticket) || !updateTicketPermission()) return;

    const serviceExists =
      prompt &&
      !![...ticket.items].find(
        (item) =>
          !!item.service &&
          !![...preparedServices].find(
            (service) =>
              service.id === fkOrId(item.service) &&
              (fkOrId(service.attribute) || null) ===
                (fkOrId(item.attribute) || null)
          )
      );

    if (serviceExists)
      return notify({
        title: 'Duplicated items',
        content:
          'Some selected items appear to already be on the list. Do you want to continue?',
        primary: {
          label: 'Continue',
          onClick: () => this.createItems(services, {prompt: false}),
        },
        secondary: {label: 'Cancel'},
      });

    this.setState({addingItems: [...this.state.addingItems, apiId]});
    const doneAdding = () =>
      [...this.state.addingItems].filter((itemId) => itemId !== apiId);

    const createdItems = await Promise.all(
      [...preparedServices].map((service) => this.createItem({service}))
    );

    const items = [...createdItems].filter((item) => !!item);
    const newTicket = updateTicketItems({ticket, items});
    syncTicket(newTicket);
    this.setState({addingItems: doneAdding()});
  };

  createOrUpdateItem = ({service}) => {
    const {ticket} = this.props;
    const item = [...ticket.items].find(
      (item) =>
        !!item.service &&
        service.id === fkOrId(item.service) &&
        (fkOrId(service.attribute) || null) === (fkOrId(item.attribute) || null)
    );
    return !!item
      ? this.updateItem({item: {...item, quantity: sum(item.quantity, 1)}})
      : this.createItem({service});
  };

  createItem = async ({service, ticket = this.props.ticket}) => {
    try {
      const costQuery = {vehicle: ticket?.vehicle?.id};
      if (!!service?.attribute)
        costQuery.attribute = service?.attribute?.id || null;
      const costResponse = await (service.other
        ? null
        : getDefaultServiceCostApi(service?.id, costQuery));
      const preppedItem = service.other
        ? {
            ticket: ticket.id,
            custom_service: service.content,
            cost: service.cost,
            quantity: service.quantity,
          }
        : {
            ticket: ticket.id,
            service: service.id,
            service_description: service.content || '',
            cost: isDiscountService(service)
              ? service.cost
              : costResponse?.default_cost || null,
            attribute: service?.attribute?.id || null,
            quantity: service?.quantity || undefined,
          };
      const item = await createItemApi({
        ticketId: ticket.id,
        item: preppedItem,
      });
      return item;
    } catch (error) {
      return null;
    }
  };

  updateItem = async ({item, ticket = this.props.ticket}) => {
    try {
      const updatedItem = await updateItemApi({
        ticketId: ticket.id,
        itemId: item.id,
        item,
      });
      return updatedItem;
    } catch (error) {
      return null;
    }
  };

  showEdit = (item) => () => {
    const {ticket} = this.props;
    const {saving} = this.state;

    if (saving || !isTicketEditable(ticket) || !updateTicketPermission())
      return;

    this.setState({
      saving: false,
      editModalVisible: true,
      item,
      charge_on_approval: ticket?.charge_on_approval || false,
      taxable: !!Number(item.tax_rate),
      cost: costAttr(item.cost || ''),
      quantity: quantityAttr(item.quantity || ''),
      service_description: serviceDescriptionAttr(
        (isSallyVehicle(ticket?.vehicle)
          ? item.service_description
          : item.custom_service) || ''
      ),
    });
  };

  closeEdit = () => {
    const {saving} = this.state;
    if (saving) return;
    this.setState({editModalVisible: false});
  };

  change = (value, key) => {
    const {ticket} = this.props;
    if (!isTicketEditable(ticket) || !updateTicketPermission()) return;
    if (key === 'taxable') {
      this.setAsTaxable(value);
      return;
    }
    this.setState({[key]: value});
  };

  render() {
    const {ticket} = this.props;
    const {
      addingItems,
      charge_on_approval,
      cost,
      editModalVisible,
      item,
      quantity,
      saving,
      service_description,
      taxable,
    } = this.state;

    const items = this.items();
    const discounts = this.discounts();

    return (
      <Fragment>
        <TicketItems
          addingItem={!!addingItems.length}
          canAddItem={!ticket.approved}
          charged={!!ticket?.external_charges?.length}
          discounts={discounts}
          isTicketEditable={isTicketEditable(ticket)}
          items={items}
          onAddItem={this.showSelectServiceModal}
          onPriceChange={this.showEdit}
          paymentMethod={ticket?.payment_method}
          taxValue={ticket?.ticket_cost_summary?.total_tax || 0}
          totalValue={ticket?.ticket_cost_summary?.total_amount || 0}
        />
        <EditItemModal
          canBeCharged={canChargeForItems(ticket, {approved: false})}
          charge_on_approval={charge_on_approval}
          cost={cost}
          isDiscount={isDiscountService(item?.service)}
          item={item}
          loading={saving}
          onChange={this.change}
          onClose={this.closeEdit}
          onDelete={!!item ? this.onItemDelete(item) : () => {}}
          onSave={this.saveItem}
          quantity={quantity}
          service_description={service_description}
          taxable={taxable}
          visible={editModalVisible}
        />
      </Fragment>
    );
  }
}

export default subscriptionHoc(
  connect((state) => ({services: state.spotlight.services}))(
    TicketItemsContainer
  )
);
