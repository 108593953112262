import React, {Component} from 'react';
import PropTypes from 'prop-types';

// alert
import alertify from '../../../layout/lib/alertify';

// api
import createTicketStatusApi from '../../api/create.api.ticketStatus';

// attributes
import labelAttr from '../../attributes/label.attribute.ticketStatus';

// error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// events
import ticketStatusCreatedEvent from '../../events/created.event.ticketStatus';
import showCreateTicketStatusModalEvent from '../../events/showCreateModal.event.ticketStatus';

// ticketStatus components
import EditTicketStatusModal from '../../components/EditTicketStatusModal/EditTicketStatusModal';

// subscription
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

class CreateTicketStatusContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  state = {
    visible: false,
    loading: false,
    label: labelAttr(''),
  };

  componentDidMount() {
    this.props.subscribe(showCreateTicketStatusModalEvent.subscribe(this.init));
  }

  init = () => {
    this.setState({
      visible: true,
      label: labelAttr(''),
    });
  };

  change = (value, key) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  close = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  save = async () => {
    const {loading, label} = this.state;

    if (loading) return;

    const ticketStatusLabel = label.api.format();

    if (!ticketStatusLabel.trim().length)
      return alertify({message: 'Insert label'});

    this.setState({loading: true});

    try {
      const ticketStatus = await createTicketStatusApi({
        label: ticketStatusLabel,
      });
      ticketStatusCreatedEvent.publish(ticketStatus);
      this.setState({loading: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alertify({message});
      this.setState({loading: false});
    }
  };

  render() {
    const {visible, loading, label} = this.state;
    return (
      <EditTicketStatusModal
        loading={loading}
        label={label}
        onChange={this.change}
        onClose={this.close}
        onSave={this.save}
        visible={visible}
      />
    );
  }
}

export default subscriptionHoc(CreateTicketStatusContainer);
