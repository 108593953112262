import styled from 'styled-components';

// colors
import {grey} from '@matthahn/sally-ui/lib/libs/colors';

const InputBox = styled.div`
  width: 100%;
  border: 2px solid ${grey};
  border-radius: 4px;
`;

export default InputBox;
