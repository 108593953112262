// alerts
import alert from '@matthahn/sally-ui/lib/libs/alert';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// service api
import createServiceApi from '../../api/create.api.service';

// service attributes
import categoryAttribute from '../../attributes/custom_category.attribute.service';
import creditAttribute from '../../attributes/credit.attribute.service';
import defaultCostAttribute from '../../attributes/default_cost.attribute.service';
import displayPriorityAttribute from '../../attributes/display_priority.attribute.service';
import nameAttribute from '../../attributes/name.attribute.service';
import removedAttribute from '../../attributes/removed.attribute.service';
import requiresDescriptionAttribute from '../../attributes/requires_description.attribute.service';

// service components
import CreateServiceModal from '../../components/CreateServiceModal/CreateServiceModal';

// service events
import showCreateServiceModalEvent from '../../events/showCreateServiceModal.event.service';

// service preparations
import createServicePreparation from '../../preparation/create.preparation.service';

class CreateServiceContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static DEFAULT_STATE = {
    category: categoryAttribute(''),
    credit: creditAttribute(false),
    default_cost: defaultCostAttribute(''),
    display_priority: displayPriorityAttribute(''),
    loading: false,
    name: nameAttribute(''),
    removed: removedAttribute(false),
    requires_description: requiresDescriptionAttribute(false),
    visible: false,
  };

  state = {
    ...this.constructor.DEFAULT_STATE,
  };

  componentDidMount() {
    this.props.subscribe(showCreateServiceModalEvent.subscribe(this.show));
  }

  show = () => {
    this.setState({...this.constructor.DEFAULT_STATE, visible: true});
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  save = async () => {
    const {loading, visible, ...serviceProps} = this.state;

    if (loading || !visible) return;

    this.setState({loading: true});

    try {
      const service = await createServicePreparation(serviceProps);
      await createServiceApi(service);
      this.setState({loading: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  change = (value, key) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  render() {
    const {
      category,
      credit,
      default_cost,
      display_priority,
      loading,
      name,
      removed,
      requires_description,
      visible,
    } = this.state;
    return (
      <CreateServiceModal
        category={category}
        credit={credit}
        default_cost={default_cost}
        display_priority={display_priority}
        loading={loading}
        name={name}
        onChange={this.change}
        onClose={this.hide}
        onSave={this.save}
        removed={removed}
        requires_description={requires_description}
        visible={visible}
      />
    );
  }
}

export default subscriptionHoc(CreateServiceContainer);
