import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// components
import {Column, Row, Card, Form} from '@matthahn/sally-ui';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// lib
import isCustomOwnerSelected from '../../../owner/lib/isCustomOwnerSelected.lib.owner';

// local components
import Container from './components/Container';

// vehicle
import VehicleModelSelect from '../VehicleModelSelect/VehicleModelSelect';

const NewVehicle = ({
  loading,
  displayMore,
  customOwner,
  license_plate,
  make,
  medallion,
  model,
  owner,
  vin,
  year,
  owners,
  onChange,
  onSave,
}) => (
  <Container>
    <Card
      title="New Vehicle"
      buttonsRight={[
        {loading, label: 'Create', onClick: onSave, theme: 'black'},
      ]}
    >
      {(Content) => (
        <Content>
          <Form onSubmit={onSave} enter>
            <Row margin>
              <Column>
                <AttributeInput
                  value={vin}
                  onChange={onChange}
                  disabled={loading}
                >
                  {vin.label.default}
                </AttributeInput>
              </Column>
            </Row>
            <Row margin>
              <Column size={isCustomOwnerSelected(owner) ? 1 / 2 : 1}>
                <AttributeInput
                  value={owner}
                  onChange={onChange}
                  disabled={loading}
                  options={owners}
                >
                  {owner.label.default}
                </AttributeInput>
              </Column>
              {isCustomOwnerSelected(owner) && (
                <Column size={1 / 2}>
                  <AttributeInput
                    value={customOwner}
                    onChange={onChange}
                    disabled={loading}
                  >
                    {customOwner.label.default}
                  </AttributeInput>
                </Column>
              )}
            </Row>
            {/* <Row margin>
              <Column size={isCustomMechanicSelected(mechanic) ? 1 / 2 : 1}>
                <AttributeInput
                  value={mechanic}
                  onChange={onChange}
                  disabled={loading}

                  options={mechanics}
                >
                  {mechanic.label.default}
                </AttributeInput>
              </Column>
              {isCustomMechanicSelected(mechanic) && (
                <Column size={1 / 2}>
                  <AttributeInput
                    value={customMechanic}
                    onChange={onChange}
                    disabled={loading}

                  >
                    {customMechanic.label.default}
                  </AttributeInput>
                </Column>
              )}
            </Row> */}
            <Row margin={displayMore}>
              <Column size={1 / 2}>
                <AttributeInput
                  value={license_plate}
                  onChange={onChange}
                  disabled={loading}
                >
                  {license_plate.label.default}
                </AttributeInput>
              </Column>
              <Column size={1 / 2}>
                <AttributeInput
                  value={medallion}
                  onChange={onChange}
                  disabled={loading}
                >
                  {medallion.label.default}
                </AttributeInput>
              </Column>
            </Row>
            {displayMore && (
              <Fragment>
                <Row margin>
                  <Column size={1 / 2}>
                    <AttributeInput
                      value={year}
                      onChange={onChange}
                      disabled={loading}
                    >
                      {year.label.default}
                    </AttributeInput>
                  </Column>
                  <Column size={1 / 2}>
                    <AttributeInput
                      value={make}
                      onChange={onChange}
                      disabled={loading}
                    >
                      {make.label.default}
                    </AttributeInput>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <VehicleModelSelect
                      model={model}
                      onChange={onChange}
                      disabled={loading}
                    />
                  </Column>
                </Row>
              </Fragment>
            )}
          </Form>
        </Content>
      )}
    </Card>
  </Container>
);

NewVehicle.propTypes = {
  loading: PropTypes.bool,
  displayMore: PropTypes.bool,
  customOwner: PropTypes.object,
  license_plate: PropTypes.object,
  make: PropTypes.object,
  medallion: PropTypes.object,
  model: PropTypes.object,
  owner: PropTypes.object,
  owners: PropTypes.array,
  vin: PropTypes.object,
  year: PropTypes.object,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
};

export default NewVehicle;
