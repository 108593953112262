import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Components
import SpotlightWrapper from '../../components/SpotlightWrapper/SpotlightWrapper';
import Spotlight from '../../components/Spotlight/Spotlight';

// Containers
import DriversSpotlightSync from '../../../driver/containers/DriversSpotlightSync/DriversSpotlightSync';
import ServicesSpotlightSync from '../../../service/containers/ServicesSpotlightSync/ServicesSpotlightSync';
import VehiclesSpotlightSync from '../../../vehicle/containers/VehiclesSpotlightSync/VehiclesSpotlightSync';

// Constants
import DISPLAY_THRESHOLD from '../../constants/displayThreshold.constant.spotlight';

// entry services
import getEntriesService from '../../../entry/service/get.service.entry';

// Events
import focusOnSearchEvt from '../../events/focusOnSearch.event.spotlight';

// Routes
// import vehicleRoute from '../../../vehicle/pages/VehicleActionSelectPage/route';

// Lib
import windowScrollBlocker from '@matthahn/sally-ui/lib/libs/windowScrollBlocker';
import wait from '@matthahn/sally-fw/lib/lib/wait';
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';
import isVehicleCheckedIn from '../../../vehicle/lib/isCheckedIn.lib.vehicle';

// ticket
import TicketSearchContainer from '../../../ticket/containers/TicketSpotlightSync/TicketSearchContainer';

// Types
import stringNumberType from '@matthahn/sally-fw/lib/type/types/stringNumber.type';
import uppercaseLettersType from '@matthahn/sally-fw/lib/type/types/uppercaseLetters.type';

// VehicleAction
import vehicleActionRoute from '../../../vehicle/pages/VehicleActionPage/route';

// Ticket
import ticketRoute from '../../../ticket/pages/TicketPage/route';

// NewTicket
import newTicketRoute from '../../../ticket/pages/NewTicketPage/route';

class SpotlightContainer extends Component {
  static propTypes = {
    vehicles: PropTypes.array,
    allTickets: PropTypes.array,
    branch: PropTypes.object,
    dispatch: PropTypes.func,
    history: PropTypes.object,
  };

  state = {
    visible: false,
    search: '',
    asyncSearch: '',
    showResults: false,
    vehicles: [],
    searching: false,
  };

  options = {
    onSelect: null,
  };

  freeScroll = () => {};

  componentDidMount() {
    this.subscribers = [focusOnSearchEvt.subscribe(this.setVisible)];
  }

  componentDidUpdate(prevProps, prevState) {
    const previousDisplay = this.shouldDisplay({
      search: prevState.search,
      visible: prevState.visible,
    });
    const currentDisplay = this.shouldDisplay({search: this.state.search});
    this.handleWindow(previousDisplay, currentDisplay);
  }

  componentWillUnmount() {
    this.subscribers.forEach((unsubscribe) => unsubscribe());
  }

  subscribers = [];

  setVisible = ({onSelect = null} = {}) => {
    this.setState({visible: true});
    this.options = {onSelect};
  };

  loading = () => {
    const {ticketsLoading} = this.props;
    return ticketsLoading;
  };

  hide = () => {
    this.setState({visible: false, search: '', showResults: false});
  };

  handleWindow(previousShow, currentShow) {
    if (!previousShow && currentShow) {
      this.freeScroll = windowScrollBlocker.add();
    } else if (previousShow && !currentShow && this.freeScroll) {
      this.freeScroll();
    }
  }

  shouldDisplay = ({
    search = this.state.search,
    visible = this.state.visible,
  } = {}) => visible && search.trim().length >= DISPLAY_THRESHOLD;

  onEscape = () => {
    if (!!this.state.search)
      return this.setState({search: '', showResults: false});
  };

  onSearch = (search) => {
    this.setState({search}, () => this.searchForData(search));
  };

  searchForData = async (search) => {
    await wait(300);
    if (this.state.search !== search) return;
    this.setState({showResults: this.shouldDisplay(), asyncSearch: search});
  };

  vehicles = () => {
    const {vehicles, branch, allTickets} = this.props;
    const {search: rawSearch} = this.state;
    const search = rawSearch.toLowerCase();
    return !this.shouldDisplay()
      ? []
      : [...vehicles]
          .filter(
            ({
              license_plate,
              make,
              medallion,
              model,
              vin,
              svid,
              year,
              branch: vehicleBranch,
            }) =>
              fkOrId(vehicleBranch) === branch.id &&
              [
                vin,
                license_plate,
                make,
                medallion,
                stringNumberType(medallion).format(),
                uppercaseLettersType(medallion).format(),
                model,
                year,
                svid,
              ].some(
                (attr) => !!attr && `${attr}`.toLowerCase().includes(search)
              )
          )
          .sort((a, b) => {
            if (a.plate > b.plate) return 1;
            if (a.plate < b.plate) return -1;
            return 0;
          })
          .slice(0, 10)
          .map((vehicle) => ({
            ...vehicle,
            isCheckedIn: isVehicleCheckedIn({vehicle, tickets: allTickets}),
          }));
  };

  tickets = () => {
    const {vehicles, tickets} = this.props;
    return getEntriesService({
      tickets,
      vehicles,
      orderBy: 'checkedin_at',
      orderDirection: 'desc',
    });
  };

  clearAndClose = () => {
    this.hide();
  };

  vehicleClick = (vehicle) => () => {
    const {onSelect} = this.options;
    this.clearAndClose();
    if (!!onSelect) return onSelect(vehicle);
    this.props.history.push(vehicleActionRoute(vehicle.id));
  };

  newTicket = () => {
    this.clearAndClose();
    this.props.history.push(newTicketRoute());
  };

  goToTicket = (ticket) => () => {
    this.clearAndClose();
    this.props.history.push(ticketRoute(ticket.id));
  };

  render() {
    const {visible, search, showResults, asyncSearch} = this.state;
    return (
      <Fragment>
        <SpotlightWrapper visible={visible}>
          <Spotlight
            loading={this.loading()}
            showResults={showResults}
            visible={visible}
            search={search}
            vehicles={this.vehicles()}
            tickets={this.tickets()}
            onSearch={this.onSearch}
            onClose={this.hide}
            onEscape={this.onEscape}
            onVehicle={this.vehicleClick}
            onNewTicket={this.newTicket}
            onTicket={this.goToTicket}
          />
        </SpotlightWrapper>
        <DriversSpotlightSync />
        <ServicesSpotlightSync />
        <VehiclesSpotlightSync />
        <TicketSearchContainer search={asyncSearch} />
      </Fragment>
    );
  }
}

export default withRouter(
  connect((state) => ({
    ...state.spotlight,
    branch: state.branch.branch,
    allTickets: state.ticket.tickets,
  }))(SpotlightContainer)
);
