import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  padding: 10px;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
`;

export default Container;
