import React from 'react';
import PropTypes from 'prop-types';

// components
import {Modal, Row, Column} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// device
import {isMobileOnly} from 'react-device-detect';

const CreateTaskModal = ({
  visible,
  loading,
  notes,
  scheduled_at,
  onClose,
  onSave,
  onChange,
}) => (
  <Modal
    title="Schedule"
    visible={visible}
    big={!isMobileOnly}
    onClose={onClose}
    buttonsRight={[
      {label: 'Schedule', onClick: onSave, loading, theme: 'black'},
    ]}
  >
    {(Content) => (
      <Content>
        <Row margin>
          <Column>
            <AttributeInput
              value={notes}
              onChange={onChange}
              placeholder={notes.label.default}
              size="large"
              preValue="$"
              disabled={loading}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <AttributeInput
              value={scheduled_at}
              onChange={onChange}
              placeholder={scheduled_at.label.default}
              size="large"
              disabled={loading}
            />
          </Column>
        </Row>
      </Content>
    )}
  </Modal>
);

CreateTaskModal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  notes: PropTypes.object,
  scheduled_at: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
};

export default CreateTaskModal;
