// colors
import {grey, darkGrey} from '@matthahn/sally-ui/lib/libs/colors';

// local components
import Container from './components/Container';
import Tag from './components/Tag';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const TicketTags = ({adding, onAdd, onEdit, small, tags = []}) => (
  <Container>
    {tags.map((tag) => (
      <Tag small={small} key={tag.id} onClick={onEdit(tag)} theme={tag.color}>
        {tag.label}
      </Tag>
    ))}
    <Tag
      small={small}
      onClick={onAdd}
      theme={grey}
      color={darkGrey}
      disabled={adding}
    >
      {adding ? 'Storing...' : '+ Add tag'}
    </Tag>
  </Container>
);

TicketTags.propTypes = {
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  small: PropTypes.bool,
  tags: PropTypes.array,
};

export default TicketTags;
