// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// api lib
import sortQuery from '../../../api/lib/sortQuery.lib.api';

// date lib
import setEndOfDay from '../../../date/lib/setEndOfDay.lib.date';
import setStartOfDay from '../../../date/lib/setStartOfDay.lib.date';

// entry services
import groupEntriesByDateService from '../../../entry/service/groupByDate.service.entry';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// events
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// file lib
import blobToFile from '@matthahn/sally-fw/lib/lib/blobToFile';
import downloadFile from '@matthahn/sally-fw/lib/lib/downloadFile';

// lib
import sortByString from '@matthahn/sally-fw/lib/lib/sortByString';

// local constants
import columns from './constants/columns.constant';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

// router
import {withRouter} from 'react-router-dom';

// service lib
// import getServicesAsOptions from '../../../service/lib/getServicesAsOptions.lib.service';

// ticket api
import listTicketsApi from '../../api/list.api.ticket';
import downloadTicketsCsvApi from '../../api/downloadCsv.api.ticket';

// ticket components
import TicketsList from '../../components/TicketsList/TicketsList';

// ticket redux actions
import {setQuery as setTicketsQuery} from '../../redux/actions';

// ticket routes
import ticketRoute from '../../pages/TicketPage/route';

// ticket permissions
import readTicketPermission from '../../../ticket/permissions/read.permission.ticket';

// ticket sockets
import ticketCreatedSocket from '../../sockets/created.socket.ticket';
import ticketDeletedSocket from '../../sockets/deleted.socket.ticket';
import ticketUpdatedSocket from '../../sockets/updated.socket.ticket';

// uuid
import {v4} from 'uuid';

// vehicle lib
import attachVehicleToObjects from '../../../vehicle/lib/attachVehicleToObjects.lib.vehicle';

// vehicle routes
import vehiclesRoute from '../../../vehicle/pages/VehiclesPage/route';

class TicketsContainer extends Component {
  static propTypes = {
    branches: PropTypes.array,
    dispatch: PropTypes.func,
    drivers: PropTypes.array,
    history: PropTypes.object,
    loadingTags: PropTypes.bool,
    owners: PropTypes.array,
    query: PropTypes.object,
    subscribe: PropTypes.func,
    tags: PropTypes.array,
  };

  state = {
    downloadingCsv: false,
    loading: true,
    tickets: [],
  };

  componentDidMount() {
    this.mounted = true;
    if (!readTicketPermission()) return;
    this.getTickets();
    this.props.subscribe(
      ticketCreatedSocket.subscribe(this.addTicket),
      ticketDeletedSocket.subscribe(this.deleteTicket),
      ticketUpdatedSocket.subscribe(this.updateTicket)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  apiId = null;

  getTickets = async ({
    branches = this.props.query.branches,
    checkIn = this.props.query.checkIn,
    checkOut = this.props.query.checkOut,
    owners = this.props.query.owners,
    page = this.props.query.page,
    perPage = 50,
    search = this.props.query.search,
    services = this.props.query.services,
    sort = this.props.query.sort,
    status = this.props.query.status,
    tags = this.props.query.tags,
  } = {}) => {
    const apiId = v4();
    this.apiId = apiId;
    const {dispatch} = this.props;
    dispatch(
      setTicketsQuery({
        branches,
        checkIn,
        checkOut,
        owners,
        page,
        search,
        services,
        sort,
        status,
        tags,
      })
    );

    this.setState({
      loading: true,
      tickets: [],
    });

    try {
      const query = this.generateApiQuery({
        branches,
        checkIn,
        checkOut,
        owners,
        page,
        perPage,
        search,
        services,
        sort,
        status,
        tags,
      });

      const {results: tickets, count} = await listTicketsApi(query);
      if (this.apiId !== apiId || !this.mounted) return;
      this.setState({
        loading: false,
        tickets,
      });
      dispatch(setTicketsQuery({pages: Math.ceil(count / perPage) || 1}));
    } catch (error) {
      if (this.apiId !== apiId || !this.mounted) return;
      this.setState({loading: false});
    }
  };

  downloadCsv = async () => {
    const {query} = this.props;
    const {downloadingCsv} = this.state;
    if (downloadingCsv) return;
    this.setState({downloadingCsv: true});
    try {
      const apiQuery = this.generateApiQuery(query);
      const csvFile = await downloadTicketsCsvApi(apiQuery);
      const file = blobToFile(csvFile);
      downloadFile(file, 'tickets.csv', {newWindow: true});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
    }
    if (!this.mounted) return;
    this.setState({downloadingCsv: false});
  };

  generateApiQuery = ({
    branches,
    checkIn,
    checkOut,
    owners,
    page = 1,
    perPage = 1,
    search,
    services,
    sort,
    status,
    tags,
  }) => {
    const query = {
      ...sortQuery(sort),
      limit: perPage,
      offset: (page - 1) * perPage,
    };

    if (!!branches.length) query.vehicle__branch__tag__in = branches.join(',');
    if (!!tags.length) query.with_tags = tags.join(',');
    if (!!owners) query['vehicle__owner__in'] = owners.join(',');
    if (!!search) query.search = search;
    if (!!services) query.items__service__in = services;
    if (!!status) query.approved = status === 'closed';

    if (!!checkIn?.start && !!checkIn?.end)
      query.checkedin_at__range = [
        setStartOfDay({date: checkIn.start}),
        setEndOfDay({date: checkIn.end}),
      ].join(',');
    else if (!!checkIn?.start)
      query.checkedin_at__gte = setStartOfDay({date: checkIn.start});
    else if (!!checkIn?.end)
      query.checkedin_at__lte = setEndOfDay({date: checkIn.end});

    if (!!checkOut?.start && !!checkOut?.end)
      query.approved_at__range = [
        setStartOfDay({date: checkOut.start}),
        setEndOfDay({date: checkOut.end}),
      ].join(',');
    else if (!!checkOut?.start)
      query.approved_at__gte = setStartOfDay({date: checkOut.start});
    else if (!!checkOut?.end)
      query.approved_at__lte = setEndOfDay({date: checkOut.end});

    return query;
  };

  addTicket = (ticket) => {
    const {loading, vehicles, drivers} = this.props;
    if (loading) return;
    const tickets = [...this.state.tickets, ticket];
    this.setState({
      tickets: attachVehicleToObjects({vehicles, objects: tickets, drivers}),
    });
  };

  updateTicket = (ticket) => {
    const {loading, vehicles, drivers} = this.props;
    if (loading) return;
    const tickets = [...this.state.tickets].map((ticketToUpdate) =>
      ticketToUpdate.id === ticket.id ? ticket : ticketToUpdate
    );
    this.setState({
      tickets: attachVehicleToObjects({vehicles, objects: tickets, drivers}),
    });
  };

  deleteTicket = ({id}) => {
    const {loading} = this.state;
    if (loading) return;
    const tickets = [...this.state.tickets].filter(
      (ticket) => ticket.id !== id
    );
    this.setState({tickets});
  };

  setPage = (page) => {
    if (this.state.loading) return;
    this.getTickets({page});
  };

  sort = (sort) => this.getTickets({sort, page: 1});

  search = (search) => this.getTickets({search, page: 1});

  tickets = (tickets) => [...this.entries(tickets)];

  entries = (tickets = this.state.tickets) => {
    const {vehicles, drivers} = this.props;
    return groupEntriesByDateService({
      vehicles,
      drivers,
      tickets,
    });
  };

  openTicket = (ticket) => () => {
    const {history} = this.props;
    history.push(ticketRoute(ticket.id));
  };

  selectBranches = (branches) => {
    this.setFilter({key: 'branches', value: branches});
  };

  setFilter = ({key, value}) =>
    this.getTickets({
      [key]: ['all'].includes(value) ? null : value || null,
      page: 1,
    });

  serviceItemSelected = (serviceItemId) => {
    const {
      query: {services},
    } = this.props;
    const newServices = services === serviceItemId ? null : serviceItemId;
    this.setFilter({key: 'services', value: newServices});
  };

  alertAndGoToVehicles = (message) => () => {
    alert.info(message);
    this.props.history.push(vehiclesRoute());
  };

  actions = () => {
    const {downloadingCsv} = this.state;
    return [
      {
        key: 'downloadCSV',
        children: 'Download CSV',
        icon: 'download',
        loading: downloadingCsv,
        theme: 'grey',
        onClick: this.downloadCsv,
      },
      {
        key: 'create',
        children: 'New Ticket',
        icon: 'plus',
        theme: 'black',
        onClick: this.alertAndGoToVehicles(
          'Select a vehicle to create a ticket'
        ),
      },
    ];
  };

  filters = () => {
    const {
      branches,
      owners,
      loadingTags,
      tags,
      query: {
        branches: selectedBranches,
        tags: selectedTags,
        checkIn,
        checkOut,
        owners: selectedOwners,
        // services,
        status,
      },
    } = this.props;
    return [
      {
        key: 'checkInRange',
        label: 'Check In',
        title: 'Check In Date Range',
        type: 'dateRange',
        value: checkIn,
        onChange: (checkInRange) =>
          this.setFilter({
            key: 'checkIn',
            value: checkInRange,
          }),
      },
      {
        key: 'checkOutRange',
        label: 'Check Out',
        title: 'Check Out Date Range',
        type: 'dateRange',
        value: checkOut,
        onChange: (checkOutRange) =>
          this.setFilter({
            key: 'checkOut',
            value: checkOutRange,
          }),
      },
      {
        key: 'status',
        label: 'Status',
        type: 'select',
        value: status,
        options: [
          {
            value: 'closed',
            label: 'Closed',
          },
          {
            value: 'open',
            label: 'Open',
          },
        ],
        onChange: (selectedOptions) =>
          this.setFilter({
            key: 'status',
            value: selectedOptions,
          }),
      },
      {
        key: 'branches',
        label: 'Branch',
        type: 'select',
        value: selectedBranches,
        options: [
          ...branches.map(({tag, name}) => ({value: tag, label: name})),
        ],
        onChange: (selectedOptions) =>
          this.setFilter({
            key: 'branches',
            value: selectedOptions,
          }),
      },
      {
        key: 'tags',
        label: 'Tag',
        type: 'select',
        value: selectedTags,
        options: loadingTags
          ? []
          : [...tags.map(({id, label}) => ({value: id, label: label}))],
        onChange: (selectedTags) =>
          this.setFilter({
            key: 'tags',
            value: selectedTags,
          }),
      },
      {
        key: 'owners',
        label: 'Owner',
        type: 'select',
        value: selectedOwners,
        options: [
          ...owners
            .map(({id, name}) => ({value: `${id}`, label: name}))
            .sort(sortByString({key: 'label'})),
        ],
        onChange: (selectedOptions) =>
          this.setFilter({
            key: 'owners',
            value: selectedOptions,
          }),
      },
      // {
      //   key: 'serviceItems',
      //   // filterSize: 'large',
      //   label: 'Service Items',
      //   type: 'select',
      //   value: services,
      //   options: getServicesAsOptions(),
      //   // notFilterable: false,
      //   dropdownOptions: {
      //     size: 500,
      //   },
      //   onChange: this.serviceItemSelected,
      // },
    ];
  };

  render() {
    const {
      query: {page, pages, search, sort},
    } = this.props;
    const {loading} = this.state;
    const tickets = this.tickets();

    return (
      <TicketsList
        actions={this.actions()}
        columns={columns}
        filters={this.filters()}
        loading={loading}
        onPage={this.setPage}
        onSearch={this.search}
        onSort={this.sort}
        onTicket={this.openTicket}
        page={page}
        pages={pages}
        search={search}
        sort={sort}
        tickets={tickets}
      />
    );
  }
}

export default subscriptionHoc(
  withRouter(
    connect((state) => ({
      branches: state.branch.branches,
      drivers: state.spotlight.drivers,
      loadingTags: state.tag.loading,
      owners: state.owner.owners,
      query: state.ticket.query,
      tags: state.tag.tags,
      vehicles: state.spotlight.vehicles,
    }))(TicketsContainer)
  )
);
