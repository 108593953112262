// task states
import createTaskState from '../state/create.state.task';

const waitingOnPart = createTaskState({
  state: 'waiting_on_part',
  label: 'Waiting on part',
  color: 'orange',
});

export default waitingOnPart;
