// date
import {isToday, parseISO} from 'date-fns';

// entry services
import getEntriesService from './get.service.entry';

// types
import dateFormat from '@matthahn/sally-fw/lib/type/types/apiDate.type';

const groupEntriesByDate = ({
  tasks = [],
  tickets = [],
  drivers = [],
  vehicles = [],
  orderBy,
  orderDirection,
  entryFilter,
  ignoreGroupingAndSorting = true,
}) => {
  const entries = getEntriesService({
    tasks,
    tickets,
    drivers,
    vehicles,
    orderBy,
    orderDirection,
    ignoreGroupingAndSorting,
  });
  const filteredEntries = !!entryFilter
    ? [...entries].filter(entryFilter)
    : entries;
  return ignoreGroupingAndSorting
    ? filteredEntries
    : Object.values(
        [...filteredEntries].reduce((combined, entry) => {
          const date = dateFormat(entry.date).format();
          if (!combined[date])
            combined[date] = {
              date,
              isToday: isToday(parseISO(entry.date)),
              entries: [],
            };
          return {
            ...combined,
            [date]: {
              ...combined[date],
              entries: [...combined[date].entries, entry],
            },
          };
        }, {})
      );
};

export default groupEntriesByDate;
