import styled from 'styled-components';

// Colors
import * as colors from '@matthahn/sally-ui/lib/libs/colors';

const Line = styled.div`
  width: 100%;
  height: 5px;
  bottom: 0px;
  left: 0px;
  position: absolute;
  background: ${({color}) => colors[color]};
`;

export default Line;
