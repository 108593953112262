import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

// Actions
import {init as initAuthAct} from '../../../auth/redux/actions';

// auth pages
import TraditionalSignInPage from '../../../auth/pages/TraditionalSignInPage/TraditionalSignInPage';

// auth routes
import routeTraditionalSignIn from '../../../auth/pages/TraditionalSignInPage/route';

// Components
import {
  Page,
  NotificationSystem,
  Portal,
  AlertSystem,
} from '@matthahn/sally-ui';
import PageSetupLoader from '../PageSetupLoader/PageSetupLoader';
import Routes from '../../../routes/components/Routes/Routes';
import GlobalStyle from '../GlobalStyle/GlobalStyle';

// Containers
import SSOContainer from '../../../auth/containers/SSOContainer/SSOContainer';
import Visibility from '../../../visibility/containers/Visibility/Visibility';

// Device
import {isMobileOnly} from 'react-device-detect';

// Sockets
import socket from '@matthahn/sally-fw/lib/sockets/socket';

// css
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class App extends Component {
  static propTypes = {
    initialized: PropTypes.bool,
    token: PropTypes.string,
    username: PropTypes.string,
    dispatch: PropTypes.func,
  };

  componentDidMount() {
    this.props.dispatch(initAuthAct());
    socket.connect(process.env.REACT_APP_SOCKETS_URL);
  }

  renderContent() {
    const {token} = this.props;
    return (
      <Fragment>
        <BrowserRouter>
          {!token ? (
            <Switch>
              <Route
                exact
                path={routeTraditionalSignIn()}
                component={TraditionalSignInPage}
              />
              <Route>
                <SSOContainer />
              </Route>
            </Switch>
          ) : (
            <Route component={Routes} />
          )}
        </BrowserRouter>
      </Fragment>
    );
  }

  render() {
    return (
      <Page noMinHeight>
        <GlobalStyle />
        {this.renderContent()}
        <Visibility />
        <Portal container={Portal.assetsContainer.secondary} always>
          <NotificationSystem notificationProps={{big: !isMobileOnly}} />
          <AlertSystem />
          <PageSetupLoader />
        </Portal>
      </Page>
    );
  }
}

export default connect((state) => ({
  ...state.auth,
}))(App);
