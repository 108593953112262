// task states
import createTaskState from '../state/create.state.task';

const callDriver = createTaskState({
  state: 'call_driver',
  label: 'Call driver',
  color: 'red',
});

export default callDriver;
