// dealership lib
import getBranchDealerships from './getBranchDealerships.lib.dealership';

const getBranchDealershipOptions = ({branch, withEmpty = false}) => {
  const dealerships = [...getBranchDealerships({branch})].map(({id, name}) => ({
    value: `${id}`,
    label: name,
  }));
  return withEmpty
    ? [...dealerships, {value: 'sally', label: 'SALLY'}]
    : dealerships;
};

export default getBranchDealershipOptions;
