import {SELECT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import uppercase from '@matthahn/sally-fw/lib/type/types/uppercase.type';

export default attribute({
  type: SELECT,
  attribute: 'model',
  display: uppercase,
  input: uppercase,
  api: uppercase,
  label: {
    default: 'Model',
    short: 'Model',
  },
  options: [
    'ALTIMA SV',
    'CAMRY HYBRID LE',
    'CAMRY GAS',
    'CAMRY HYBRID SE',
    'CAMRY L',
    'CAMRY LE',
    'CAMRY SE',
    'HIGHLANDER HYBRID XLE',
    'HIGHLANDER LE',
    'HIGHLANDER XLE',
    'LEXUS ES-300H',
    'METRIS',
    'NV200',
    'PRIUS HYBRID',
    'RAV4 HYBRID LE',
    'RAV4 XLE',
    'SIENNA L',
    'SIENNA XLE',
    'SONATA',
    'COROLLA LE',
    'COROLLA SE',
    'MODEL 3',
    'RAV4 LE',
    'KONA EV',
    'NIRO EX PREM-EV',
  ],
});
