import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal} from '@matthahn/sally-ui';
import ListItem from '../../../layout/components/ListItem/ListItem';
import Search from '../../../layout/components/Search/Search';
import SelectServiceItem from '../SelectServiceItem/SelectServiceItem';

// Local Components
import Container from './components/Container';
import ListContainer from './components/ListContainer';
import SearchContainer from './components/SearchContainer';

// types
import amount from '@matthahn/sally-fw/lib/type/types/amount.type';

const SelectServiceModal = ({
  categories,
  closable,
  descriptionOnly,
  numberOfSelectedServices,
  onAttribute,
  onCategory,
  onClose,
  onConfirm,
  onSearch,
  onService,
  open,
  otherServices,
  priorityServices,
  search,
  selected,
  servicesWithoutCategories,
  title,
  visible,
}) => (
  <Modal
    visible={visible}
    closable={closable}
    title={title}
    onClose={onClose}
    buttonsRight={
      !!numberOfSelectedServices
        ? [
            {
              label: `Select ${numberOfSelectedServices} item${
                numberOfSelectedServices === 1 ? '' : 's'
              }`,
              onClick: onConfirm,
            },
          ]
        : []
    }
  >
    {(Content) => (
      <Content padding="none">
        <Container>
          <SearchContainer>
            <Search
              search={search}
              onSearch={onSearch}
              onEscape={() => onSearch('')}
              threshold={0}
            />
          </SearchContainer>
          <ListContainer>
            {priorityServices.map((service) => (
              <SelectServiceItem
                key={service.id}
                service={service}
                search={search}
                open={open}
                selected={selected}
                onService={onService}
                onAttribute={onAttribute}
                icon="staro"
                bold
              />
            ))}
            {categories.map((category) => {
              const isCategoryOpen =
                !!search.length ||
                !![...open].find((object) => object.id === category.id);
              return (
                <Fragment key={category.id}>
                  <ListItem
                    canBeOpened
                    isOpen={isCategoryOpen}
                    title={category.name}
                    onClick={onCategory(category)}
                  />
                  {isCategoryOpen &&
                    !!category?.services?.length &&
                    category.services.map((service) => (
                      <SelectServiceItem
                        key={service.id}
                        level={1}
                        service={service}
                        search={search}
                        open={open}
                        selected={selected}
                        onService={onService}
                        onAttribute={onAttribute}
                      />
                    ))}
                </Fragment>
              );
            })}
            {servicesWithoutCategories.map((service) => (
              <SelectServiceItem
                key={service.id}
                service={service}
                search={search}
                open={open}
                selected={selected}
                onService={onService}
                onAttribute={onAttribute}
              />
            ))}
            <ListItem
              title="Other"
              onClick={onCategory({id: null, other: true})}
            />
            {otherServices.map((service) => (
              <ListItem
                key={service.id}
                selectable
                selected
                title={service.content}
                content={
                  descriptionOnly
                    ? null
                    : `${service.quantity} x $${amount(service.cost).format()}`
                }
                onClick={onService(service)}
              />
            ))}
          </ListContainer>
        </Container>
      </Content>
    )}
  </Modal>
);

SelectServiceModal.propTypes = {
  closable: PropTypes.bool,
  descriptionOnly: PropTypes.bool,
  numberOfSelectedServices: PropTypes.number,
  onAttribute: PropTypes.func,
  onCategory: PropTypes.func,
  onClose: PropTypes.func,
  onSearch: PropTypes.func,
  onService: PropTypes.func,
  open: PropTypes.array,
  otherServices: PropTypes.array,
  priorityServices: PropTypes.array,
  search: PropTypes.string,
  selected: PropTypes.array,
  services: PropTypes.array,
  servicesWithoutCategories: PropTypes.array,
  title: PropTypes.string,
  visible: PropTypes.bool,
};

SelectServiceModal.defaultProps = {
  closable: true,
  open: [],
  priorityServices: [],
  selected: [],
  servicesWithoutCategories: [],
};

export default SelectServiceModal;
