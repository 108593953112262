import styled from 'styled-components';

// colors
import {white} from '@matthahn/sally-ui/lib/libs/colors';

const Welcome = styled.div`
  font-size: 90px;
  font-weight: 700;
  color: ${white};
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 100;
  pointer-events: none;
`;

export default Welcome;
