// lib
import sortByString from '@matthahn/sally-fw/lib/lib/sortByString';

const getBranchMechanics = ({mechanics, branchId}) => {
  const filteredMechanics = [...mechanics]
    .filter((mechanicToFilter) => mechanicToFilter?.branch === branchId)
    .map(({id, display_name}) => ({
      value: `${id}`,
      label: display_name,
    }));
  const {mechanicsWithoutOther, otherMechanic} = [...filteredMechanics].reduce(
    (data, mechanic) =>
      mechanic.label.toLowerCase() === 'other'
        ? {...data, otherMechanic: mechanic}
        : {
            ...data,
            mechanicsWithoutOther: [...data.mechanicsWithoutOther, mechanic],
          },
    {mechanicsWithoutOther: [], otherMechanic: null}
  );
  const sortedMechanics = [...mechanicsWithoutOther].sort(
    sortByString({key: 'label'})
  );
  return !!otherMechanic
    ? [...sortedMechanics, otherMechanic]
    : sortedMechanics;
};

export default getBranchMechanics;
