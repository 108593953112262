// driver lib
import attachDriver from '../../driver/lib/attachDriverToObject.lib.driver';

// lib
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';

// vehicle
import getVehicleOrigin from '../origin/lib/getOrigin.lib.origin.vehicle';

const attachVehicle = ({object, vehicles, drivers = []}) => {
  const vehicle =
    [...vehicles].find(({id}) => fkOrId(object.vehicle) === id) || null;
  const driver = attachDriver({object, drivers});
  const objectWithDriver = {
    ...object,
    driver,
  };
  return !!vehicle
    ? {
        ...objectWithDriver,
        vehicle: {
          ...vehicle,
          origin: getVehicleOrigin(vehicle),
        },
      }
    : objectWithDriver;
};

export default attachVehicle;
