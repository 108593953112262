import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';

// Containers
import Navigation from '../../containers/NavigationContainer/NavigationContainer';
// import BranchContainer from '../../../branch/containers/BranchContainer/BranchContainer';
import BranchFetchContainer from '../../../branch/containers/BranchFetchContainer/BranchFetchContainer';
import GoToTicketContainer from '../../../ticket/containers/GoToTicketContainer/GoToTicketContainer';
import MechanicFetchContainer from '../../../mechanic/containers/MechanicFetchContainer/MechanicFetchContainer';
import OwnerFetchContainer from '../../../owner/containers/OwnerFetchContainer/OwnerFetchContainer';
import SettingsContainer from '../../../layout/containers/SettingsContainer/SettingsContainer';
import SpotlightContainer from '../../../spotlight/containers/SpotlightContainer/SpotlightContainer';

// location containers
import CreateLocationContainer from '../../../location/containers/CreateLocationContainer/CreateLocationContainer';
import EditLocationContainer from '../../../location/containers/EditLocationContainer/EditLocationContainer';
import LocationsFetchContainer from '../../../location/containers/LocationsFetchContainer/LocationsFetchContainer';

// mechanic containers
import CreateMechanicContainer from '../../../mechanic/containers/CreateMechanicContainer/CreateMechanicContainer';
import EditMechanicContainer from '../../../mechanic/containers/EditMechanicContainer/EditMechanicContainer';

// owner
import CreateOwnerContainer from '../../../owner/containers/CreateOwnerContainer/CreateOwnerContainer';
import UpdateOwnerContainer from '../../../owner/containers/UpdateOwnerContainer/UpdateOwnerContainer';

// permission containers
import PermissionsContainer from '../../../permission/containers/PermissionsContainer/PermissionsContainer';

// service containers
import ConvertCustomServiceItemsContainer from '../../../service/containers/ConvertCustomServiceItemsContainer/ConvertCustomServiceItemsContainer';
import CreateServiceContainer from '../../../service/containers/CreateServiceContainer/CreateServiceContainer';
import ServiceEditorContainer from '../../../service/containers/ServiceEditorContainer/ServiceEditorContainer';

// tag containers
import LoadTagsContainer from '../../../tag/containers/LoadTagsContainer/LoadTagsContainer';
import SelectTagContainer from '../../../tag/containers/SelectTagContainer/SelectTagContainer';

// task
import NewTaskContainer from '../../../task/containers/NewTaskContainer/NewTaskContainer';

// ticket
import ClosedTicketsContainer from '../../../ticket/containers/ClosedTicketsContainer/ClosedTicketsContainer';

// ticketStatus containers
import CreateTicketStatusContainer from '../../../ticketStatus/containers/CreateTicketStatusContainer/CreateTicketStatusContainer';
import EditTicketStatusContainer from '../../../ticketStatus/containers/EditTicketStatusContainer/EditTicketStatusContainer';
import TicketStatusesFetchContainer from '../../../ticketStatus/containers/TicketStatusesFetchContainer/TicketStatusesFetchContainer';

// vehicle
import VehicleHistoryContainer from '../../../vehicle/containers/VehicleHistoryContainer/VehicleHistoryContainer';

const Layout = ({children}) => (
  <Fragment>
    <BranchFetchContainer>
      {/* <BranchContainer> */}
      <LoadTagsContainer>
        <Container>
          <Navigation>{children}</Navigation>
        </Container>
      </LoadTagsContainer>
      {/* </BranchContainer> */}
    </BranchFetchContainer>

    <ConvertCustomServiceItemsContainer />
    <CreateServiceContainer />
    <ServiceEditorContainer />
    <MechanicFetchContainer />
    <TicketStatusesFetchContainer />
    <CreateTicketStatusContainer />
    <EditTicketStatusContainer />
    <LocationsFetchContainer />
    <CreateLocationContainer />
    <EditLocationContainer />
    <OwnerFetchContainer />
    <CreateMechanicContainer />
    <EditMechanicContainer />
    <CreateOwnerContainer />
    <UpdateOwnerContainer />
    <VehicleHistoryContainer />
    <ClosedTicketsContainer />
    <NewTaskContainer />
    <PermissionsContainer />
    <GoToTicketContainer />
    <SettingsContainer />
    <SpotlightContainer />
    <SelectTagContainer />
  </Fragment>
);

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
