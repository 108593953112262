// Action constants
import {SET} from './constants';

const DEFAULT_STATE = {
  loading: true,
  owners: [],

  query: {
    page: 1,
    pages: 1,
    search: '',
  },
};

const ownerReducer = (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case SET: {
      return {
        ...state,
        ...data,
      };
    }

    default: {
      return state;
    }
  }
};

export default ownerReducer;
