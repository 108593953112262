import React, {Component} from 'react';
import PropTypes from 'prop-types';

// api
import listTicketsApi from '../../../ticket/api/list.api.ticket';

// components
import VehicleHistoryModal from '../../components/VehicleHistoryModal/VehicleHistoryModal';

// events
import setVehicleHistoryIdEvent from '../../events/setVehicleHistoryId.event.vehicle';
import showVehicleHistoryEvent from '../../events/showVehicleHistory.event.vehicle';

// router
import {withRouter} from 'react-router-dom';

// subscription
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// ticket permissions
import readTicketPermission from '../../../ticket/permissions/read.permission.ticket';

// ticket routes
import ticketRoute from '../../../ticket/pages/TicketPage/route';

// uuid
import {v4} from 'uuid';

class VehicleHistoryContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static PER_PAGE = 20;

  static INITIAL_STATE = {
    loading: false,
    visible: false,
    page: 1,
    more: false,
    tickets: [],
    vehicle: null,
  };

  state = {...this.constructor.INITIAL_STATE};

  componentDidMount() {
    this.props.subscribe(
      setVehicleHistoryIdEvent.subscribe(this.setId),
      showVehicleHistoryEvent.subscribe(this.show)
    );
  }

  apiId = null;

  setId = ({vehicle}) => this.setState({vehicle}, this.getTickets);

  show = ({vehicle = null} = {}) => {
    if (!readTicketPermission()) return;
    const state = !!vehicle
      ? {
          ...this.constructor.INITIAL_STATE,
          visible: true,
          vehicle,
        }
      : {
          visible: true,
        };
    this.setState(state, () => {
      if (!vehicle) return;
      this.getTickets();
    });
  };

  hide = () => {
    this.setState({visible: false});
  };

  getTickets = async ({page = this.state.page} = {}) => {
    const {PER_PAGE} = this.constructor;
    const {vehicle} = this.state;
    const apiId = v4();
    const reset = page === 1;

    this.apiId = apiId;

    this.setState({
      loading: true,
      page,
      tickets: reset ? [] : [...this.state.tickets],
    });

    try {
      const {results, next} = await listTicketsApi({
        vehicle: vehicle?.id,
        ordering: '-checkedin_at',
        offset: PER_PAGE * (page - 1),
        limit: PER_PAGE,
      });
      if (apiId !== this.apiId) return;
      this.setState({
        loading: false,
        more: !!next,
        tickets: [...this.state.tickets, ...results],
      });
    } catch (error) {
      if (apiId !== this.apiId) return;
      this.setState({loading: false, more: false});
    }
  };

  loadMore = () => this.getTickets({page: this.state.page + 1});

  selectTicket = (ticket) => () => {
    this.props.history.push(ticketRoute(ticket.id));
    this.hide();
  };

  render() {
    const {loading, visible, page, more, tickets, vehicle} = this.state;
    return (
      <VehicleHistoryModal
        loading={loading}
        visible={visible}
        page={page}
        more={more}
        tickets={tickets}
        vehicle={vehicle}
        onTicket={this.selectTicket}
        onClose={this.hide}
      />
    );
  }
}

export default withRouter(subscriptionHoc(VehicleHistoryContainer));
