// branch lib
import getBranchName from '../../../branch/lib/getBranchName.lib.branch';

// layout components
import ItemListLayout from '../../../layout/components/ItemListLayout/ItemListLayout';

// owner lib
import getOwnerName from '../../../owner/lib/getName.lib.owner';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// task components
import TaskStateLabel from '../TaskStateLabel/TaskStateLabel';

// vehicle components
import VehicleModelInfo from '../../../vehicle/components/VehicleModelInfo/VehicleModelInfo';

// vehicle lib
import displayVehicleFieldFromObject from '../../../vehicle/lib/displayVehicleFieldFromObject.lib.vehicle';
import vehicleOrVehicleFields from '../../../vehicle/lib/vehicleOrVehicleFields.lib.vehicle';

// types
import dateTimeType from '@matthahn/sally-fw/lib/type/types/dateTime.type';

const TasksList = ({
  actions = [],
  columns,
  filters = [],
  loading,
  onPage,
  onSearch,
  onSort,
  onTask,
  page,
  pages,
  search,
  sort,
  tasks,
}) => (
  <ItemListLayout
    actions={actions}
    columns={columns}
    filters={filters}
    onSearch={onSearch}
    onSort={onSort}
    search={search}
    searchPlaceholder="Search Tasks"
    sort={sort}
    tableProps={{
      loading,
      page,
      pages,
      onPage,
    }}
  >
    {(TableRow) =>
      tasks.map((task) => (
        <TableRow key={task.id} onClick={onTask(task)}>
          {(TableCol) => [
            <TableCol key="id" span={1 / 3}>
              {task.id}
            </TableCol>,
            <TableCol key="date" span={1 / 2}>
              {dateTimeType(task.date || task.scheduled_at).format()}
            </TableCol>,
            <TableCol key="state" span={1 / 2}>
              <TaskStateLabel task={task} />
            </TableCol>,
            <TableCol key="branch" span={1 / 2}>
              {getBranchName(task?.branch || task?.vehicle?.branch) || '-'}
            </TableCol>,
            <TableCol key="owner" span={1 / 2}>
              {getOwnerName({ownerId: task?.vehicle?.owner})}
            </TableCol>,
            <TableCol key="vehicle" span={1 / 2}>
              {displayVehicleFieldFromObject({
                object: {
                  vehicle: task.vehicle,
                  vehicle_fields: task.vehicle_fields,
                },
                field: 'license_plate',
                empty: 'Unknown plate',
              })}
            </TableCol>,
            <TableCol key="model">
              <VehicleModelInfo
                {...vehicleOrVehicleFields({
                  vehicle: task.vehicle,
                  vehicle_fields: task.vehicle_fields,
                })}
                withoutMake
              />
            </TableCol>,
            <TableCol key="notes" span={2}>
              {task.notes || '-'}
            </TableCol>,
          ]}
        </TableRow>
      ))
    }
  </ItemListLayout>
);

TasksList.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.array,
  filters: PropTypes.array,
  loading: PropTypes.bool,
  onPage: PropTypes.func,
  onSearch: PropTypes.func,
  onSort: PropTypes.func,
  onTask: PropTypes.func,
  page: PropTypes.number,
  pages: PropTypes.number,
  search: PropTypes.string,
  sort: PropTypes.object,
  tasks: PropTypes.array,
};

export default TasksList;
