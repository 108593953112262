// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// api lib
import sortQuery from '../../../api/lib/sortQuery.lib.api';

// branch lib
import getBranchName from '../../../branch/lib/getBranchName.lib.branch';

// csv services
import downloadCsvService from '../../../csv/services/download.service.csv';

// entry services
import groupEntriesByDateService from '../../../entry/service/groupByDate.service.entry';

// events
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// local constants
import columns from './constants/columns.constant';

// owner lib
import getOwnerName from '../../../owner/lib/getName.lib.owner';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

// router
import {withRouter} from 'react-router-dom';

// service lib
import getServicesAsOptions from '../../../service/lib/getServicesAsOptions.lib.service';

// task api
import listTasksApi from '../../api/list.api.task';

// task components
import TasksList from '../../components/TasksList/TasksList';

// task redux actions
import {setQuery as setTasksQuery} from '../../redux/actions';

// task routes
import taskRoute from '../../pages/TaskPage/route';

// task permissions
import readTaskPermission from '../../../task/permissions/read.permission.task';

// task sockets
import taskCreatedSocket from '../../sockets/created.socket.task';
import taskDeletedSocket from '../../sockets/deleted.socket.task';
import taskUpdatedSocket from '../../sockets/updated.socket.task';

// task states
import getTaskState from '../../state/getStates.state.task';

// uuid
import {v4} from 'uuid';

// vehicle lib
import attachVehicleToObjects from '../../../vehicle/lib/attachVehicleToObjects.lib.vehicle';

// vehicle routes
import vehiclesRoute from '../../../vehicle/pages/VehiclesPage/route';

// types
import dateTimeType from '@matthahn/sally-fw/lib/type/types/dateTime.type';

class TasksContainer extends Component {
  static propTypes = {
    branches: PropTypes.array,
    dispatch: PropTypes.func,
    drivers: PropTypes.array,
    history: PropTypes.object,
    owners: PropTypes.array,
    query: PropTypes.object,
    subscribe: PropTypes.func,
  };

  state = {
    downloadingCsv: false,
    loading: true,
    tasks: [],
  };

  componentDidMount() {
    this.mounted = true;
    if (!readTaskPermission()) return;
    this.getTasks();
    this.props.subscribe(
      taskCreatedSocket.subscribe(this.addTask),
      taskDeletedSocket.subscribe(this.deleteTask),
      taskUpdatedSocket.subscribe(this.updateTask)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  apiId = null;

  getTasks = async ({
    branches = this.props.query.branches,
    owner = this.props.query.owner,
    page = this.props.query.page,
    perPage = 50,
    search = this.props.query.search,
    services = this.props.query.services,
    sort = this.props.query.sort,
    state = this.props.query.state,
  } = {}) => {
    const {dispatch} = this.props;

    const apiId = v4();
    this.apiId = apiId;

    dispatch(
      setTasksQuery({branches, page, search, owner, services, sort, state})
    );

    this.setState({
      loading: true,
      tasks: [],
    });
    try {
      const query = this.generateApiQuery({
        branches,
        owner,
        page,
        perPage,
        search,
        services,
        sort,
        state,
      });

      const {results: tasks, count} = await listTasksApi(query);
      if (this.apiId !== apiId || !this.mounted) return;

      this.setState({
        loading: false,
        tasks,
      });
      dispatch(setTasksQuery({pages: Math.ceil(count / perPage) || 1}));
    } catch (error) {
      if (this.apiId !== apiId || !this.mounted) return;
      this.setState({loading: false});
    }
  };

  generateApiQuery = ({
    branches,
    owner,
    page,
    perPage,
    search,
    services,
    sort,
    state,
  }) => {
    const query = {
      ...sortQuery(sort),
      limit: perPage,
      offset: (page - 1) * perPage,
    };

    if (!!branches.length) query.branch__in = branches.join(',');
    if (!!owner) query['vehicle.owner'] = owner;
    if (!!search) query.search = search;
    if (!!services) query['items__service__in'] = services;
    if (!!state) query.state = state;

    return query;
  };

  downloadCsv = async () => {
    const {query} = this.props;
    const {downloadingCsv} = this.state;
    if (downloadingCsv) return;
    this.setState({downloadingCsv: true});
    await downloadCsvService({
      apiCall: listTasksApi,
      fileName: 'tasks.csv',
      query: this.generateApiQuery(query),
      parseData: (tasks) =>
        [...this.tasks(tasks)].map((task) => ({
          id: task.id,
          scheduled_at: dateTimeType(task.date || task.scheduled_at).format(),
          state: task.state || '',
          branch: getBranchName(task?.branch || task?.vehicle?.branch) || '',
          owner: getOwnerName({ownerId: task?.vehicle?.owner}),
          vin: task?.vehicle?.vin || '',
          svid: task?.vehicle?.svid || '',
          license_plate: task?.vehicle?.license_plate || '',
          medallion: task?.vehicle?.medallion || '',
          make: task?.vehicle?.make || '',
          model: task?.vehicle?.model || '',
          year: task?.vehicle?.year || '',
          george_id: task?.vehicle?.george_id || '',
          kuiper_id: task?.vehicle?.kuiper_id || '',
          notes: `"${task.notes || ''}"`,
        })),
    });
    if (!this.mounted) return;
    this.setState({downloadingCsv: false});
  };

  addTask = (task) => {
    const {loading, vehicles, drivers} = this.props;
    if (loading) return;
    const tasks = [...this.state.tasks, task];
    this.setState({
      tasks: attachVehicleToObjects({vehicles, objects: tasks, drivers}),
    });
  };

  updateTask = (task) => {
    const {loading, vehicles, drivers} = this.props;
    if (loading) return;
    const tasks = [...this.state.tasks].map((taskToUpdate) =>
      taskToUpdate.id === task.id ? task : taskToUpdate
    );
    this.setState({
      tasks: attachVehicleToObjects({vehicles, objects: tasks, drivers}),
    });
  };

  deleteTask = ({id}) => {
    const {loading} = this.props;
    if (loading) return;
    const tasks = [...this.state.tasks].filter((task) => task.id !== id);
    this.setState({tasks});
  };

  setPage = (page) => {
    if (this.state.loading) return;
    this.getTasks({page});
  };

  sort = (sort) => this.getTasks({sort, page: 1});

  search = (search) => this.getTasks({search, page: 1});

  tasks = (tasks) => [...this.entries(tasks)];

  entries = (tasks = this.state.tasks) => {
    const {vehicles, drivers} = this.props;
    return groupEntriesByDateService({
      vehicles,
      drivers,
      tasks,
    });
  };

  openTask = (task) => () => {
    const {history} = this.props;
    history.push(taskRoute(task.id));
  };

  setFilter = ({key, value}) =>
    this.getTasks({
      [key]: value,
      page: 1,
    });

  serviceItemSelected = (serviceItemId) => {
    const {services} = this.props.query;
    const newServices = services === serviceItemId ? null : serviceItemId;
    this.setFilter({key: 'services', value: newServices});
  };

  alertAndGoToVehicles = (message) => () => {
    alert.info(message);
    this.props.history.push(vehiclesRoute());
  };

  actions = () => {
    const {downloadingCsv} = this.state;
    return [
      {
        key: 'downloadCSV',
        children: 'Download CSV',
        icon: 'download',
        loading: downloadingCsv,
        theme: 'grey',
        onClick: this.downloadCsv,
      },
      {
        key: 'create',
        children: 'New Task',
        icon: 'plus',
        theme: 'black',
        onClick: this.alertAndGoToVehicles('Select a vehicle to create a task'),
      },
    ];
  };

  filters = () => {
    const {
      branches,
      owners,
      query: {branches: selectedBranches, owner, services, state},
    } = this.props;
    return [
      {
        key: 'state',
        label: 'State',
        type: 'select',
        value: state,
        options: [
          ...getTaskState({withNoState: false}).map((taskState) => ({
            value: taskState.state,
            label: taskState.label,
          })),
        ],
        onChange: (selectedStatus) =>
          this.setFilter({
            key: 'state',
            value: selectedStatus,
          }),
      },
      {
        key: 'branch',
        label: 'Branch',
        type: 'select',
        value: selectedBranches,
        options: [
          ...branches.map(({id, name}) => ({value: `${id}`, label: name})),
        ],
        onChange: (value) =>
          this.setFilter({
            key: 'branches',
            value: value,
          }),
      },
      {
        key: 'owner',
        label: 'Owner',
        type: 'select',
        value: owner,
        options: [
          ...owners.map(({id, name}) => ({value: `${id}`, label: name})),
        ],
        onChange: (value) =>
          this.setFilter({
            key: 'owner',
            value: value,
          }),
      },
      {
        key: 'serviceItems',
        filterSize: 'large',
        label: 'Service Items',
        type: 'select',
        value: services,
        options: getServicesAsOptions(),
        dropdownOptions: {
          size: 500,
        },
        onChange: this.serviceItemSelected,
      },
    ];
  };

  render() {
    const {loading, page, pages, search, sort} = this.state;
    const tasks = this.tasks();
    return (
      <TasksList
        actions={this.actions()}
        columns={columns}
        filters={this.filters()}
        loading={loading}
        onPage={this.setPage}
        onSearch={this.search}
        onSort={this.sort}
        onTask={this.openTask}
        page={page}
        pages={pages}
        search={search}
        sort={sort}
        tasks={tasks}
      />
    );
  }
}

export default subscriptionHoc(
  withRouter(
    connect((state) => ({
      branches: state.branch.branches,
      drivers: state.spotlight.drivers,
      owners: state.owner.owners,
      query: state.task.query,
      vehicles: state.spotlight.vehicles,
    }))(TasksContainer)
  )
);
