import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Alerts
import alertify from '../../../layout/lib/alertify';

// Api
import api from '@matthahn/sally-fw/lib/api/lib/getEverything.lib.api';
import listTicketStatusesApi from '../../api/list.api.ticketStatus';

// Error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// ticketStatus events
import ticketStatusCreatedEvent from '../../events/created.event.ticketStatus';
import ticketStatusDeletedEvent from '../../events/deleted.event.ticketStatus';
import ticketStatusUpdatedEvent from '../../events/updated.event.ticketStatus';

// Subscription
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

class TicketStatusesFetchContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    ticketStatuses: PropTypes.array,
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.init();
    this.props.subscribe(
      ticketStatusCreatedEvent.subscribe(this.ticketStatusCreated),
      ticketStatusDeletedEvent.subscribe(this.ticketStatusDeleted),
      ticketStatusUpdatedEvent.subscribe(this.ticketStatusUpdated)
    );
  }

  init = async () => {
    const {dispatch} = this.props;
    dispatch(setAct({loading: true}));
    try {
      const {data: ticketStatuses} = await api(listTicketStatusesApi, {
        ordering: '-created_at',
      });
      dispatch(setAct({loading: false, ticketStatuses}));
    } catch (error) {
      const {message} = parseError(error);
      alertify({message});
      dispatch(setAct({loading: false}));
    }
  };

  ticketStatusCreated = (ticketStatus) => {
    const {loading, ticketStatuses, dispatch} = this.props;
    if (loading) return;
    const newTicketStatuses = [...ticketStatuses, ticketStatus];
    dispatch(setAct({ticketStatuses: newTicketStatuses}));
  };

  ticketStatusUpdated = (ticketStatus) => {
    const {loading, ticketStatuses, dispatch} = this.props;
    if (loading) return;
    const newTicketStatuses = [...ticketStatuses].map((searchingTicketStatus) =>
      searchingTicketStatus.id === ticketStatus.id
        ? ticketStatus
        : searchingTicketStatus
    );
    dispatch(setAct({ticketStatuses: newTicketStatuses}));
  };

  ticketStatusDeleted = (ticketStatus) => {
    const {loading, ticketStatuses, dispatch} = this.props;
    if (loading) return;
    const newTicketStatuses = [...ticketStatuses].filter(
      (searchingTicketStatus) => searchingTicketStatus.id !== ticketStatus.id
    );
    dispatch(setAct({ticketStatuses: newTicketStatuses}));
  };

  render() {
    return <Fragment />;
  }
}

export default subscriptionHoc(
  connect((state) => ({
    loading: state.ticketStatus.loading,
    ticketStatuses: state.ticketStatus.ticketStatuses,
  }))(TicketStatusesFetchContainer)
);
