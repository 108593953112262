// document api
import createDocumentApi from '../../../../document/api/create.api.document';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// ticket documents
import repairImageDocument from '../../../documents/repairImage.document.ticket';

// ticket lib
import generateRepairImageFileName from '../../../lib/generateRepairImageFileName.lib.ticket';

const uploadImage = async ({
  fileType = repairImageDocument,
  image,
  ticket,
  uploadDocument = createDocumentApi,
}) => {
  const fileName = generateRepairImageFileName({ticket, image});

  const formMeta = new FormData();

  formMeta.append('doc_file', image, fileName);
  formMeta.append('name', fileName);
  formMeta.append('use_ocr', false);
  formMeta.append('ticket', ticket.id);
  formMeta.append('vehicle', ticket.vehicle.id);
  // if (ticket.vehicle.george_id) formMeta.append('georgeVehicle', ticket.vehicle.george_id);

  formMeta.append('type', fileType);

  try {
    const uploadedImage = await uploadDocument(formMeta);
    return {error: null, uploadedImage};
  } catch (error) {
    const {message} = parseError(error);
    return {error: message, uploadedImage: null};
  }
};

export default uploadImage;
