import styled, {css} from 'styled-components';
import {transitions} from 'polished';

// Media
import {media} from '@matthahn/sally-ui/lib/libs/media';

const Container = styled.div`
  width: 100%;
  height: ${({showResults}) => (showResults ? 'calc(100vh)' : '100px')};
  position: relative;
  overflow: hidden;
  ${transitions('height .2s ease-in-out')};
  background: rgba(250, 250, 250, 0.95);
  backdrop-filter: blur(3px);
  ${'' /* box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.3); */}

  ${media.xs`
    ${css`
      height: ${({showResults}) => (showResults ? 'calc(100vh)' : '50px')};
    `};
  `};
`;

export default Container;
