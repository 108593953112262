// lib
import isObjectInArray from './isObjectInArray.lib';

const addOrRemoveObjectFromArray = ({array, newObject, key = 'id'}) =>
  isObjectInArray({
    array,
    value: newObject[key],
  })
    ? [...array].filter((object) => object[key] !== newObject[key])
    : [...array, newObject];

export default addOrRemoveObjectFromArray;
