import styled from 'styled-components';

// colors
import {darkGrey} from '@matthahn/sally-ui/lib/libs/colors';

const ItemDetail = styled.div`
  text-align: right;
  color: ${darkGrey};
`;

export default ItemDetail;
