// vehicle constants
import MILEAGE_SINCE_LAST_SEEN from '../constants/mileageSinceLastSeen.constant.vehicle';

// vehicle lib
import milesSinceLastSeen from './milesSinceLastSeen.lib.vehicle';

const shouldAlertMilesSinceLastSeen = ({vehicle}) =>
  !!vehicle && milesSinceLastSeen({vehicle}) >= MILEAGE_SINCE_LAST_SEEN;

export default shouldAlertMilesSinceLastSeen;
