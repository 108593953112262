import styled from 'styled-components';

// colors
import {darkGrey} from '@matthahn/sally-ui/lib/libs/colors';

const Label = styled.div`
  pointer-events: none;
  width: 100%;
  color: ${darkGrey};
  font-size: 12px;
`;

export default Label;
