import React from 'react';

// layout containers
import Page from '../../../layout/containers/Page/Page';

// local constants
import PAGE from './page';

// ticketStatus containers
import TicketStatusesContainer from '../../containers/TicketStatusesContainer/TicketStatusesContainer';

const TicketStatusesPage = () => (
  <Page page={PAGE}>
    <TicketStatusesContainer />
  </Page>
);

export default TicketStatusesPage;
