// components
import Select from '@matthahn/sally-ui/lib/components/Select/Select';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

class BranchSelect extends Component {
  static propTypes = {
    branch: PropTypes.string,
    branches: PropTypes.array,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    children: 'Branch',
  };

  render() {
    const {branch, branches, children, onChange, ...props} = this.props;
    return (
      <Select
        value={branch}
        onChange={(value) => onChange(value, 'branch')}
        options={[...branches].map(({id, name}) => ({
          value: `${id}`,
          label: name,
        }))}
        notFilterable
        {...props}
      >
        {children}
      </Select>
    );
  }
}

export default connect((state) => ({
  branches: state.branch.branches,
}))(BranchSelect);
