// components
import CompactDataRow from '../../../layout/components/CompactDataRow/CompactDataRow';

// device
import {isMobileOnly} from 'react-device-detect';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// textarea
import TextareaAutosize from 'react-textarea-autosize';

const NotesCard = ({editable, notes, onSave, onChange}) => (
  <CompactDataRow label="Ticket Description" noPadding={editable}>
    {editable ? (
      <TextareaAutosize
        minRows={1}
        value={notes.display.format()}
        size={isMobileOnly ? 'normal' : 'large'}
        onChange={(e) =>
          onChange(notes.reinit(e.target.value), notes.attribute)
        }
        onBlur={onSave}
        placeholder={notes.label.default}
        style={{
          width: '100%',
          height: '100%',
          display: 'block',
          flex: 1,
          padding: '6px',
          margin: '0px',
          background: 'none',
          border: 'none',
          outline: 'none',
          fontFamily: 'ProximaNova',
          fontSize: 'inherit',
          color: 'black',
          resize: 'none',
        }}
      />
    ) : (
      notes.display.format() || '-'
    )}
  </CompactDataRow>
);

NotesCard.propTypes = {
  editable: PropTypes.bool,
  notes: PropTypes.object,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
};

export default NotesCard;
