import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal} from '@matthahn/sally-ui';
import Logo from './components/Logo';
import ListItem from '../ListItem/ListItem';

const SettingsModal = ({actions, onClose, visible}) => (
  <Modal title={<Logo />} visible={visible} onClose={onClose}>
    {(Content) => (
      <Fragment>
        <Content padding="none" noBorder>
          {actions.map(({key, ...action}) => (
            <ListItem key={key} {...action} />
          ))}
        </Content>
      </Fragment>
    )}
  </Modal>
);

SettingsModal.propTypes = {
  actions: PropTypes.array,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

export default SettingsModal;
