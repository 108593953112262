import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {darkerGrey},
  media: {media},
} = lib;

const Action = styled.div`
  color: ${darkerGrey};
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  cursor: pointer;

  ${media.xs`
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    font-size: 24px;
  `}
`;

export default Action;
