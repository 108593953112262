import styled from 'styled-components';

// colors
import {darkerGrey, black} from '@matthahn/sally-ui/lib/libs/colors';

const Action = styled.div`
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
  color: ${darkerGrey};

  &:hover {
    color: ${black};
  }
`;

export default Action;
