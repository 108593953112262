import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// alerts
import alertify from '../../../layout/lib/alertify';

// api
import api from '@matthahn/sally-fw/lib/api/lib/getEverything.lib.api';

// branch actions
import {set as setAct} from '../../redux/actions';

// branch api
import listBranchesApi from '../../api/list.api.branch';

// dealerships api
import listDealershipsApi from '../../../dealership/api/list.api.dealership';

// error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

class BranchFetchContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    children: PropTypes.node,
    dispatch: PropTypes.func,
  };

  componentDidMount() {
    this.init();
  }

  init = async () => {
    const {dispatch} = this.props;
    dispatch(setAct({loading: true}));
    try {
      const [{data: branches}, {data: dealerships}] = await Promise.all([
        api(listBranchesApi, {
          ordering: 'name',
        }),
        api(listDealershipsApi, {
          ordering: 'name',
        }),
      ]);
      dispatch(setAct({loading: false, branches, dealerships}));
    } catch (error) {
      const {message} = parseError(error);
      alertify({message});
      dispatch(setAct({loading: false}));
    }
  };

  render() {
    const {loading, children} = this.props;
    return loading ? null : children;
  }
}

export default connect((state) => ({loading: state.branch.loading}))(
  BranchFetchContainer
);
