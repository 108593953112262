// components
// import Column from '@matthahn/sally-ui/lib/components/Grid/Column/Column';
// import Form from '@matthahn/sally-ui/lib/components/Form/Form';
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
// import Row from '@matthahn/sally-ui/lib/components/Grid/Row/Row';

// layout components
// import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const ServiceEditorModal = ({
  loading,
  onChange,
  onClose,
  onSave,
  service,
  visible,
}) => (
  <Modal
    visible={visible}
    onClose={onClose}
    title={`Edit ${service?.name || 'Service'}`}
    buttonsRight={[{label: 'Save', theme: 'black', loading, onClick: onSave}]}
  >
    {(Container) => <Container>Editor</Container>}
  </Modal>
);

ServiceEditorModal.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  service: PropTypes.object,
  visible: PropTypes.bool,
};

export default ServiceEditorModal;
