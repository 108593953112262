// layout components
import CompactDataRow from '../../../layout/components/CompactDataRow/CompactDataRow';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

// vehicle containers
import VehicleEquipmentContainer from '../../../vehicle/containers/VehicleEquipmentContainer/VehicleEquipmentContainer';

const TicketActionsCard = ({actions = [], georgeVehicle, onSync, ticket}) => (
  <Fragment>
    {actions.map((action) => (
      <CompactDataRow
        key={action.key}
        label={action.label}
        rowProps={{onClick: action.onClick}}
      >
        {action.outcome}
      </CompactDataRow>
    ))}
    {!!georgeVehicle && !ticket?.approved && (
      <VehicleEquipmentContainer
        ticket={ticket}
        vehicle={georgeVehicle}
        onSync={onSync}
      >
        {({dom, label}) => (
          <CompactDataRow label={label} noPadding>
            {dom}
          </CompactDataRow>
        )}
      </VehicleEquipmentContainer>
    )}
  </Fragment>
);

// const TicketActionsCard = ({actions = [], georgeVehicle, onSync, ticket}) => (
//   <EntryActions actions={actions} readOnly={ticket.approved}>
//     {({ActionContainer, Action, IconContainer, Content, Label}) =>
//       !!georgeVehicle && !ticket?.approved ? (
//         <VehicleEquipmentContainer vehicle={georgeVehicle} onSync={onSync}>
//           {({status, onClick, icon, label}) => (
//             <ActionContainer>
//               <Action status={status} onClick={onClick}>
//                 <IconContainer status={status}>
//                   {!!icon && <Icon icon={icon} spin={icon === 'loading2'} />}
//                 </IconContainer>
//                 <Content>
//                   <Label>{label}</Label>
//                 </Content>
//               </Action>
//             </ActionContainer>
//           )}
//         </VehicleEquipmentContainer>
//       ) : null
//     }
//   </EntryActions>
// );

TicketActionsCard.propTypes = {
  actions: PropTypes.array,
  georgeVehicle: PropTypes.object,
  onSpireonSync: PropTypes.func,
  onUpdateGeorgeVehicle: PropTypes.func,
  ticket: PropTypes.object,
};

export default TicketActionsCard;
