// Lib
import parseDecodedVinTrim from './parseDecodedVinTrim.lib.vehicle';
import parseDecodedVinModel from './parseDecodedVinModel.lib.vehicle';

const parseDecodedVin = (decodedVin) =>
  !!decodedVin
    ? {
        make: !!decodedVin['Make'] ? decodedVin['Make'].toUpperCase() : null,
        model: parseDecodedVinModel({
          model: decodedVin['Model'],
          trim: parseDecodedVinTrim(decodedVin['Trim']),
        }),
        year: !!decodedVin['Model Year']
          ? decodedVin['Model Year'].toUpperCase()
          : null,
      }
    : null;

export default parseDecodedVin;
