import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Components
import {FullScreenLoader} from '@matthahn/sally-ui';

class PageSetupLoader extends Component {
  static propTypes = {
    authInitialized: PropTypes.bool,
    loadingBranches: PropTypes.bool,
    loadingMechanics: PropTypes.bool,
    loadingOwners: PropTypes.bool,
    signedIn: PropTypes.bool,
  };

  state = {
    authInitialized: false,
  };

  componentDidMount() {
    if (this.props.authInitialized) this.authInitialized();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.authInitialized && this.props.authInitialized)
      this.authInitialized();
  }

  authInitialized = () => {
    setTimeout(() => {
      this.setState({authInitialized: true});
    }, 300);
  };

  show = () => {
    const {
      signedIn,
      loadingBranches,
      vehicleLoaded,
      serviceLoaded,
      loadingMechanics,
      loadingOwners,
    } = this.props;
    const {authInitialized} = this.state;
    return (
      !authInitialized ||
      (signedIn &&
        (!vehicleLoaded ||
          !serviceLoaded ||
          loadingBranches ||
          loadingMechanics ||
          loadingOwners))
    );
  };

  render() {
    return this.show() ? <FullScreenLoader gradient fixed /> : null;
  }
}

export default connect((state) => ({
  authInitialized: state.auth.initialized,
  signedIn: !!state.auth.token,
  vehicleLoaded: state.spotlight.vehicleLoaded,
  serviceLoaded: state.spotlight.serviceLoaded,
  loadingBranches: state.branch.loading,
  loadingMechanics: state.mechanic.loading,
  loadingOwners: state.owner.loading,
}))(PageSetupLoader);
