import React from 'react';
import PropTypes from 'prop-types';

// Containers
import TicketContainer from '../../containers/TicketContainer/TicketContainer';
import Page from '../../../layout/containers/Page/Page';

// Page
import PAGE from './page';

// Router
import {withRouter} from 'react-router-dom';

const TicketPage = ({
  match: {
    params: {ticketId},
  },
}) => (
  <Page page={PAGE}>
    <TicketContainer ticketId={ticketId} />
  </Page>
);

TicketPage.propTypes = {
  match: PropTypes.object,
};

export default withRouter(TicketPage);
