const getName = (taskItem) => {
  const name = !!taskItem?.service
    ? !!taskItem?.attribute
      ? `${taskItem?.service?.name} - ${taskItem?.attribute?.name}`
      : taskItem?.service?.name
    : taskItem?.description;
  return name || '-';
};

export default getName;
