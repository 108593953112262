// components
import {Modal, Row, Column} from '@matthahn/sally-ui';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const EditTicketStatusModal = ({
  loading,
  label,
  onChange,
  onClose,
  onSave,
  visible,
}) => (
  <Modal
    visible={visible}
    onClose={onClose}
    title="Ticket Status Editor"
    buttonsRight={[{label: 'Save', onClick: onSave, loading}]}
    size="small"
  >
    {(Content) => (
      <Content>
        <Row>
          <Column>
            <AttributeInput
              value={label}
              disabled={loading}
              onChange={onChange}
              placeholder="Status"
            >
              {label.label.default}
            </AttributeInput>
          </Column>
        </Row>
      </Content>
    )}
  </Modal>
);

EditTicketStatusModal.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  visible: PropTypes.bool,
};

export default EditTicketStatusModal;
