// alertify
import alertify from '../../../layout/lib/alertify';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// ticket api
import updateTicketApi from '../../api/markAsPaid.api.ticket';

// ticket attributes
import paymentMethodAttr from '../../attributes/payment_method.attribute.ticket';

// ticket components
import TicketPaymentOptionsModal from '../../components/TicketPaymentOptionsModal/TicketPaymentOptionsModal';

// ticket events
import showTicketPaymentOptionsModalEvent from '../../events/showTicketPaymentOptionsModal.event.ticket';

// ticket permissions
import updateTicketPermission from '../../../ticket/permissions/update.permission.ticket';

class TicketPaymentOptionsContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
    syncTicket: PropTypes.func,
    ticket: PropTypes.object,
  };

  state = {
    visible: false,
    payment_method: '',
  };

  componentDidMount() {
    this.mounted = true;
    this.props.subscribe(
      showTicketPaymentOptionsModalEvent.subscribe(this.show)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  show = () => {
    const {ticket} = this.props;
    this.setState({
      visible: true,
      payment_method: ticket?.payment_method || '',
    });
  };

  hide = () => {
    this.setState({visible: false});
  };

  saveTicket = async (payment_method) => {
    const {ticket, syncTicket} = this.props;
    if (!updateTicketPermission()) return;

    this.setState({payment_method, visible: false});
    const updatedTicket = {
      ...ticket,
      payment_method,
    };
    const paid = !!ticket?.payment_method;

    syncTicket(updatedTicket);

    try {
      await updateTicketApi(ticket.id, {
        paid,
        payment_method: updatedTicket.payment_method,
      });
    } catch (error) {
      if (!this.mounted || this.props.ticket?.id !== ticket?.id) return;
      const {message} = parseError(error);
      alertify({message});
      syncTicket(ticket);
    }
  };

  options = () => {
    const {payment_method} = this.state;
    return !!payment_method
      ? [
          ...paymentMethodAttr.additional.options,
          {label: 'Not paid yet', value: ''},
        ]
      : paymentMethodAttr.additional.options;
  };

  render() {
    const {visible, payment_method} = this.state;
    return (
      <TicketPaymentOptionsModal
        onClose={this.hide}
        onSelect={this.saveTicket}
        options={this.options()}
        selected={payment_method}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(TicketPaymentOptionsContainer);
