// components
import DropDown from '@matthahn/sally-ui/lib/components/DropDown/DropDown';
import Icon from '@matthahn/sally-ui/lib/components/Icon/Icon';

// layout components
import CompactDataRow from '../../../layout/components/CompactDataRow/CompactDataRow';

// local components
import Container from './components/Container';
import IconContainer from './components/IconContainer';
import StateLabel from './components/StateLabel';
import StateRow from './components/StateRow';

// react
import React, {Component} from 'react';

// propTypes
import PropTypes from 'prop-types';

// task attributes
import stateAttr from '../../attributes/state.attribute.task';

// task state
import getTaskState from '../../state/getTaskState.state.task';
import getTaskStates from '../../state/getTaskStates.state.task';

class TaskStateCard extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    state: PropTypes.object,
    task: PropTypes.object,
  };

  state = {visible: false};

  setVisibility = (visible) => () => this.setState({visible});

  selectState = (state) => () => {
    this.props.onChange(stateAttr(state.state));
    this.setState({visible: false});
  };

  getTask = () => {
    const {state, task} = this.props;
    return {...task, state: state.api.format()};
  };

  content = () => {
    const {visible} = this.state;
    const taskState = getTaskState(this.getTask());
    return (
      <Container>
        <StateLabel color={taskState.color}>{taskState.label}</StateLabel>
        <IconContainer>
          <Icon icon={visible ? 'up' : 'down'} />
        </IconContainer>
      </Container>
    );
  };

  render() {
    const {visible} = this.state;

    const state = getTaskState(this.getTask());

    return (
      <CompactDataRow label="State" noPadding>
        <DropDown
          visible={visible}
          onOpen={this.setVisibility(true)}
          onClose={this.setVisibility(false)}
          trigger={this.content()}
          block
        >
          {getTaskStates(this.getTask()).map((taskState, index) => {
            const selected = state.state === taskState.state;
            return (
              <StateRow
                key={taskState.state || 'noState'}
                noBorder={!index}
                onClick={this.selectState(taskState)}
              >
                <StateLabel color={selected ? taskState.color : 'grey'}>
                  {taskState.label}
                </StateLabel>
                {selected && (
                  <IconContainer>
                    <Icon icon="check" />
                  </IconContainer>
                )}
              </StateRow>
            );
          })}
        </DropDown>
      </CompactDataRow>
    );
  }
}

export default TaskStateCard;
