// date
import {startOfDay, endOfDay} from 'date-fns';
import toUtcString from './toUtcString.lib.date';

const dayRange = ({day = new Date()} = {}) => {
  const start = toUtcString(startOfDay(day));
  const end = toUtcString(endOfDay(day));
  return [start, end].join(',');
};

export default dayRange;
