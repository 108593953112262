import React, {Component} from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

// Components
import {P, Icon, Button} from '@matthahn/sally-ui';
import ListItem from '../../../layout/components/ListItem/ListItem';

// Local Components
import Action from './components/Action';
import ButtonContainer from './components/ButtonContainer';
import Container from './components/Container';
import SearchContainer from './components/SearchContainer';
import Input from './components/Input';
import Content from './components/Content';
import NoResults from './components/NoResults';
import Loader from './components/Loader';

// types
import dateTime from '@matthahn/sally-fw/lib/type/types/dateTime.type';

// Vehicle
import getVehicleOrigin from '../../../vehicle/origin/lib/getOrigin.lib.origin.vehicle';
import showBranchOrOwner from '../../../vehicle/lib/branchOrOwner.lib.vehicle';

class Spotlight extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    visible: PropTypes.bool,
    showResults: PropTypes.bool,
    search: PropTypes.string,
    vehicles: PropTypes.array,
    tickets: PropTypes.array,
    onSearch: PropTypes.func,
    onEscape: PropTypes.func,
    onClose: PropTypes.func,
    onVehicle: PropTypes.func,
    onNewTicket: PropTypes.func,
    onTicket: PropTypes.func,
  };

  state = {
    numeric: true,
  };

  componentDidMount() {
    if (this.props.visible) this.focus();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.setToNumeric();
    if (!prevProps.visible && this.props.visible) return this.focus();
    if (prevProps.visible && !this.props.visible) return this.blur();
  }

  setToNumeric = () => {
    this.setState({numeric: true});
  };

  focus = () => {
    if (!this.input) return;
    this.input.focus();
  };

  blur = () => {
    if (!this.input) return;
    this.input.blur();
  };

  handleClickOutside = () => {
    this.props.onClose();
  };

  onChange = (e) => {
    this.props.onSearch(e.target.value);
  };

  onKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.props.onEscape();
      e.preventDefault();
      return false;
    }
  };

  onRef = (input) => {
    this.input = input;
  };

  onEscape = () => {
    this.focus();
    this.props.onEscape();
  };

  toggleKeyboard = () => {
    this.setState({numeric: !this.state.numeric});
    this.focus();
  };

  render() {
    const {
      loading,
      tickets,
      showResults,
      search,
      vehicles,
      onVehicle,
      onClose,
      onNewTicket,
      onTicket,
    } = this.props;
    const {numeric} = this.state;
    return (
      <Container showResults={showResults}>
        <SearchContainer>
          <Action>
            <Icon icon="search1" />
          </Action>
          <Input
            ref={this.onRef}
            type="text"
            inputMode={numeric ? 'numeric' : 'search'}
            value={search}
            onKeyDown={this.onKeyDown}
            onChange={this.onChange}
            placeholder="Search"
          />
          {search.length > 2 && (
            <Action onClick={this.onEscape}>
              <Icon icon="close" />
            </Action>
          )}
          <Action onClick={this.toggleKeyboard}>
            <span className={`mdi mdi-${numeric ? 'keyboard' : 'dialpad'}`} />
          </Action>
        </SearchContainer>
        <ButtonContainer>
          <Button
            icon="plus"
            size="large"
            theme="darkGrey"
            onClick={onNewTicket}
            noRadius
            block
          >
            Add vehicle
          </Button>
        </ButtonContainer>
        <Content>
          {!loading && !vehicles.length && !tickets.length && (
            <NoResults onClick={onClose}>
              <P theme="darkGrey" size="6">
                No Results found
              </P>
            </NoResults>
          )}
          {!!vehicles.length &&
            vehicles.map((vehicle) => (
              <ListItem
                key={vehicle.id}
                iconColor="green"
                icon={vehicle.isCheckedIn ? 'login' : null}
                title={vehicle.vin}
                content={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                additionalContent={[
                  showBranchOrOwner(vehicle),
                  vehicle.svid,
                  vehicle.medallion,
                ].filter((text) => !!text)}
                rightContent={vehicle.license_plate || 'No plate'}
                line={getVehicleOrigin(vehicle).color}
                noBorder={getVehicleOrigin(vehicle).origin !== 'outsider'}
                onClick={onVehicle(vehicle)}
              />
            ))}
          {!loading &&
            !!tickets.length &&
            tickets.map((ticket) => (
              <ListItem
                key={ticket.id}
                title={ticket?.vehicle?.vin || '-'}
                content={`${ticket?.vehicle?.year} ${ticket?.vehicle?.make} ${ticket?.vehicle?.model}`}
                additionalContent={[
                  ticket.notes,
                  dateTime(ticket.checkedin_at).format(),
                  ticket.created_by,
                ].filter((text) => !!text)}
                onClick={onTicket(ticket)}
              />
            ))}
          {loading && (
            <Loader>
              <Icon icon="loading2" spin />
            </Loader>
          )}
        </Content>
      </Container>
    );
  }
}

export default onClickOutside(Spotlight);
