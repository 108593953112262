import styled from 'styled-components';

// constants
const SIZE = '100px';

const Element = styled.div`
  width: ${SIZE};
  max-width: ${SIZE};
  min-width: ${SIZE};
  height: ${SIZE};
  max-height: ${SIZE};
  min-height: ${SIZE};
  border-radius: 5px;
  overflow: hidden;
`;

export default Element;
