// layout components
import ItemListLayout from '../../../layout/components/ItemListLayout/ItemListLayout';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const TicketStatusesList = ({
  actions = [],
  columns,
  loading,
  onTicketStatus,
  onPage,
  onSearch,
  page,
  pages,
  search,
  ticketStatuses,
}) => (
  <ItemListLayout
    actions={actions}
    columns={columns}
    onSearch={onSearch}
    search={search}
    searchPlaceholder="Search Statuses"
    tableProps={{
      loading,
      page,
      pages,
      onPage,
    }}
  >
    {(TableRow) =>
      ticketStatuses.map((ticketStatus) => (
        <TableRow key={ticketStatus.id} onClick={onTicketStatus(ticketStatus)}>
          {(TableCol) => [
            <TableCol key="label">{ticketStatus.label || '-'}</TableCol>,
          ]}
        </TableRow>
      ))
    }
  </ItemListLayout>
);

TicketStatusesList.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.array,
  filters: PropTypes.array,
  loading: PropTypes.bool,
  onPage: PropTypes.func,
  onSearch: PropTypes.func,
  page: PropTypes.number,
  pages: PropTypes.number,
  search: PropTypes.string,
  ticketStatuses: PropTypes.array,
};

export default TicketStatusesList;
