import styled from 'styled-components';
import {transitions} from 'polished';

const SpotlightWrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1010;
  user-select: none;
  transform: ${({visible}) => (visible ? 'none' : 'translateY(-100%)')};
  pointer-events: ${({visible}) => (visible ? 'auto' : 'none')};
  ${transitions('transform .2s ease-in-out')}
`;

export default SpotlightWrapper;
