import styled from 'styled-components';

// Media
import {media} from '@matthahn/sally-ui/lib/libs/media';

const Container = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  flex-wrap: wrap;

  ${media.xs`
    padding: 8px;
  `}
`;

export default Container;
