import React from 'react';
import PropTypes from 'prop-types';

// layout components
import CompactDataRow from '../../../layout/components/CompactDataRow/CompactDataRow';

// Local Components
import Container from './components/Container';

// task components
import TaskStateCard from '../../../task/components/TaskStateCard//TaskStateCard';

// textarea
import TextareaAutosize from 'react-textarea-autosize';

const VehicleTaskInfo = ({
  isTaskEditable,
  task,
  notes,
  state,
  onChange,
  onSave,
  onClick,
  onChangeAndSaveTask,
}) => (
  <Container>
    <TaskStateCard task={task} state={state} onChange={onChangeAndSaveTask} />
    <CompactDataRow label="Notes" noPadding={isTaskEditable}>
      {isTaskEditable ? (
        <TextareaAutosize
          minRows={1}
          value={notes.display.format()}
          onChange={(e) =>
            onChange(notes.reinit(e.target.value), notes.attribute)
          }
          onBlur={onSave}
          placeholder={notes.label.default}
          style={{
            width: '100%',
            height: '100%',
            display: 'block',
            flex: 1,
            padding: '6px',
            margin: '0px',
            background: 'none',
            border: 'none',
            outline: 'none',
            fontFamily: 'ProximaNova',
            fontSize: 'inherit',
            color: 'black',
            resize: 'none',
          }}
        />
      ) : (
        notes.display.format() || '-'
      )}
    </CompactDataRow>
  </Container>
);

VehicleTaskInfo.propTypes = {
  isTaskEditable: PropTypes.bool,
  task: PropTypes.object,
  notes: PropTypes.object,
  state: PropTypes.object,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onClick: PropTypes.func,
  onChangeAndSaveTask: PropTypes.func,
};

export default VehicleTaskInfo;
