import React from 'react';
import PropTypes from 'prop-types';

// components
import {Row, Column, Switch, Modal} from '@matthahn/sally-ui';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// local components
import LeftText from './components/LeftText';

const EditItemModal = ({
  canBeCharged,
  charge_on_approval,
  cost,
  isDiscount,
  item,
  loading,
  onChange,
  onClose,
  onDelete,
  onSave,
  quantity,
  service_description,
  taxable,
  visible,
}) => (
  <Modal
    title={item?.custom_service || item?.service?.name || 'Item change'}
    visible={visible}
    onClose={onClose}
    buttonsRight={[{label: 'Change', onClick: onSave, loading, theme: 'black'}]}
    buttonsLeft={
      !!onDelete
        ? [{label: 'Delete Item', onClick: onDelete, icon: 'delete'}]
        : []
    }
  >
    {(Content) => (
      <Content>
        <LeftText>
          <Row margin>
            <Column>
              <AttributeInput
                value={cost}
                onChange={onChange}
                placeholder={isDiscount ? 'Discount' : 'Price'}
                preValue="$"
                disabled={loading}
              >
                Price
              </AttributeInput>
            </Column>
          </Row>
          {!isDiscount && (
            <Row margin>
              <Column>
                <AttributeInput
                  value={quantity}
                  onChange={onChange}
                  placeholder="Quantity"
                  preValue="x"
                  disabled={loading}
                >
                  Quantity
                </AttributeInput>
              </Column>
            </Row>
          )}
          {!isDiscount && (
            <Row margin>
              <Column size={1 / 2}>
                <Switch
                  value={taxable}
                  onChange={() => onChange(!taxable, 'taxable')}
                  disabled={loading}
                >
                  Taxable
                </Switch>
              </Column>
              {canBeCharged && (
                <Column size={1 / 2}>
                  <Switch
                    value={charge_on_approval}
                    onChange={() =>
                      onChange(!charge_on_approval, 'charge_on_approval')
                    }
                    disabled={loading}
                  >
                    Charge after checkout
                  </Switch>
                </Column>
              )}
            </Row>
          )}
          <Row>
            <Column>
              <AttributeInput
                value={service_description}
                onChange={onChange}
                placeholder="Description"
                disabled={loading}
              >
                Add Line Item Description
              </AttributeInput>
            </Column>
          </Row>
        </LeftText>
      </Content>
    )}
  </Modal>
);

EditItemModal.propTypes = {
  canBeCharged: PropTypes.bool,
  charge_on_approval: PropTypes.bool,
  cost: PropTypes.object,
  isDiscount: PropTypes.bool,
  item: PropTypes.object,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  service_description: PropTypes.object,
  taxable: PropTypes.bool,
  visible: PropTypes.bool,
};

export default EditItemModal;
