// date lib
import orderByDate from '@matthahn/sally-fw/lib/lib/orderByDate';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// layout lib
import alertify from '../../../layout/lib/alertify';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// ticketNote api
import createTicketNoteApi from '../../api/create.api.ticketNote';
import deleteTicketNoteApi from '../../api/delete.api.ticketNote';
import updateTicketNoteApi from '../../api/update.api.ticketNote';

// ticketNote attributes
import authorAttr from '../../attributes/author.attribute.ticketNote';
import textAttr from '../../attributes/text.attribute.ticketNote';
import ticketAttr from '../../attributes/ticket.attribute.ticketNote';

// ticketNote components
import TicketNotesCard from '../../components/TicketNotesCard/TicketNotesCard';

// ticketNote preparations
import createTicketNotePreparation from '../../preparations/create.preparation.ticketNote';
import updateTicketNotePreparation from '../../preparations/update.preparation.ticketNote';

class TicketNotesContainer extends Component {
  static propTypes = {
    syncTicket: PropTypes.func,
    ticket: PropTypes.object,
    user: PropTypes.object,
  };

  state = {
    creating: false,
    notes: [],
    saving: [],
    text: textAttr(''),
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = async () => {
    const {ticket} = this.props;
    const notes = orderByDate(
      [...(ticket?.ticketnotes || [])],
      'created_at',
      'desc'
    );
    this.setState({notes});
  };

  change = (value, key) => {
    if (this.state.creating) return;
    this.setState({[key]: value});
  };

  createNote = async () => {
    const {ticket, user} = this.props;
    const {creating, text} = this.state;

    if (creating) return;

    this.setState({creating: true});

    try {
      const ticketNoteBody = await createTicketNotePreparation({
        author: authorAttr(user.username),
        text,
        ticket: ticketAttr(ticket.id),
      });
      const ticketNote = await createTicketNoteApi(ticketNoteBody);
      if (!this.mounted) return;
      const notes = [ticketNote, ...this.state.notes];
      this.setState({
        creating: false,
        notes,
        text: textAttr(''),
      });
      this.syncNotes(notes);
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alertify({message});
      this.setState({creating: false});
    }
  };

  updateNote = (noteId) => async (text) => {
    const {user} = this.props;

    if (this.state.saving.includes(noteId)) return;

    this.setState({saving: [...this.state.saving, noteId]});
    const doneSaving = () =>
      [...this.state.saving].filter((id) => id !== noteId);

    try {
      const ticketNoteBody = await updateTicketNotePreparation({
        author: authorAttr(user.username),
        text: textAttr(text),
      });
      const ticketNote = await updateTicketNoteApi({
        ticketNoteId: noteId,
        ticketNote: ticketNoteBody,
      });
      if (!this.mounted) return;
      const notes = [...this.state.notes].map((note) =>
        note.id === ticketNote.id ? ticketNote : note
      );
      this.setState({
        notes,
        saving: doneSaving(),
        text: textAttr(''),
      });
      this.syncNotes(notes);
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alertify({message});
      this.setState({saving: doneSaving()});
    }
  };

  deleteNote = (noteId) => async () => {
    if (this.state.saving.includes(noteId)) return;

    this.setState({saving: [...this.state.saving, noteId]});
    const doneSaving = () =>
      [...this.state.saving].filter((id) => id !== noteId);

    try {
      await deleteTicketNoteApi(noteId);
      if (!this.mounted) return;
      const notes = [...this.state.notes].filter((note) => note.id !== noteId);
      this.setState({notes, saving: doneSaving()});
      this.syncNotes(notes);
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alertify({message});
      this.setState({saving: doneSaving()});
    }
  };

  syncNotes = (notes) => {
    const {syncTicket, ticket} = this.props;
    const updatedTicket = {
      ...ticket,
      ticketnotes: [...notes],
    };
    syncTicket(updatedTicket);
  };

  render() {
    const {user} = this.props;
    const {creating, notes, text, saving} = this.state;
    return (
      <TicketNotesCard
        creating={creating}
        notes={notes}
        onChange={this.change}
        onCreate={this.createNote}
        onDelete={this.deleteNote}
        onSave={this.updateNote}
        saving={saving}
        text={text}
        user={user.username}
      />
    );
  }
}

export default connect((state) => ({user: state.auth.user}))(
  TicketNotesContainer
);
