import styled from 'styled-components';

// colors
import {orange, black} from '@matthahn/sally-ui/lib/libs/colors';

const Medallion = styled.div`
  width: 70px;
  height: 30px;
  border-radius: 0px;
  background: ${orange};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 700;
  color: ${black};
  font-size: 20px;
`;

export default Medallion;
