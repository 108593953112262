import styled from 'styled-components';

// Media
import {media} from '@matthahn/sally-ui/lib/libs/media';

const Plate = styled.div`
  width: 100%;
  font-size: 30px;
  font-weight: 700;

  ${media.xs`
    font-size: 24px;
  `}
`;

export default Plate;
