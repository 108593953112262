import React from 'react';
import PropTypes from 'prop-types';

import P from '@matthahn/sally-ui/lib/components/P/P';
import TableRow from '@matthahn/sally-ui/lib/components/Table/components/Row.Table';

const DataRow = ({operation, indication}) => (
  <TableRow portions={4}>
    {(TableColumn) => [
      <TableColumn key="operation">
        <P weight="normal" color="black">
          {operation}
        </P>
      </TableColumn>,
      <TableColumn key="indication" span={3}>
        <P weight="normal" color="black">
          {indication}
        </P>
      </TableColumn>,
    ]}
  </TableRow>
);

DataRow.propTypes = {
  indication: PropTypes.node,
  operation: PropTypes.node,
};

export default DataRow;
