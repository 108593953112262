import f3tch from 'f3tch';

// Constants
import API_URL from '../../api/constants/url.const.api';

// Profiles
import profile from '@matthahn/sally-fw/lib/api/profiles/json.profile.api';

const updateUserApi = (id = null, body = {}) =>
  f3tch(`/users/${id}/`)
    .profile(
      profile({
        url: API_URL(),
        authorizationType: 'JWT',
        ignoreAppVersionHeader: true,
      })
    )
    .body(body)
    .patch();

export default updateUserApi;
