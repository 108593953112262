import styled from 'styled-components';

// colors
import * as colors from '@matthahn/sally-ui/lib/libs/colors';

const Label = styled.div`
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 400;
  padding: 0px 4px;
  border-radius: 4px;
  font-size: 12px;
  background: ${({color}) => colors[color] || color};
  color: ${({color}) =>
    !color ||
    [
      '',
      'transparent',
      'white',
      'grey',
      'lightBlueGrey',
      'superLightBlueGrey',
    ].includes(color)
      ? colors.darkGrey
      : colors.white};
`;

export default Label;
