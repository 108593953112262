// color picker
import {SketchPicker} from 'react-color';

// components
import Column from '@matthahn/sally-ui/lib/components/Grid/Column/Column';
import Input from '@matthahn/sally-ui/lib/components/Input/Input';
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import Row from '@matthahn/sally-ui/lib/components/Grid/Row/Row';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const EditTagModal = ({
  color = '',
  deleting,
  label = '',
  onChange,
  onClose,
  onDelete,
  onSave,
  saving,
  visible,
}) => (
  <Modal
    visible={visible}
    title="Edit Tag"
    onClose={onClose}
    size="small"
    buttonsLeft={[
      {label: 'Delete', onClick: onDelete, disabled: saving, loading: deleting},
    ]}
    buttonsRight={[
      {
        label: 'Save',
        onClick: onSave,
        disabled: deleting,
        loading: saving,
        theme: 'black',
      },
    ]}
  >
    {(Content) => (
      <Content>
        <Row margin>
          <Column>
            <Input
              value={label}
              onChange={onChange('label')}
              disabled={saving || deleting}
            >
              Label
            </Input>
          </Column>
        </Row>
        <Row>
          <Column>
            <SketchPicker
              color={color}
              onChange={({hex}) => onChange('color')(hex)}
            />
          </Column>
        </Row>
      </Content>
    )}
  </Modal>
);

EditTagModal.propTypes = {
  color: PropTypes.string,
  deleting: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  saving: PropTypes.bool,
  visible: PropTypes.bool,
};

export default EditTagModal;
