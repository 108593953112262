import React from 'react';
import PropTypes from 'prop-types';

// components
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import SimpleMenu from '@matthahn/sally-ui/lib/components/SimpleMenu/SimpleMenu';

const TicketPaymentOptionsModal = ({
  onClose,
  onSelect,
  options,
  selected,
  visible,
}) => (
  <Modal title="Payment options" visible={visible} onClose={onClose}>
    {(Content) => (
      <Content padding="none">
        <SimpleMenu
          options={options}
          open="all"
          height={300}
          selected={!!selected ? [selected] : null}
          onSelect={onSelect}
        />
      </Content>
    )}
  </Modal>
);

TicketPaymentOptionsModal.propTypes = {
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  options: PropTypes.array,
  selected: PropTypes.any,
  visible: PropTypes.bool,
};

export default TicketPaymentOptionsModal;
