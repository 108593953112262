import {TEXT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import calampEsn from '@matthahn/sally-fw/lib/type/types/calampEsn.type';

export default attribute({
  type: TEXT,
  attribute: 'calamp_device_id',
  display: calampEsn,
  input: calampEsn,
  api: calampEsn,
  label: {
    default: 'CalAmp ESN',
    short: 'ESN',
  },
});
