import styled from 'styled-components';

// Colors
import {white, grey} from '@matthahn/sally-ui/lib/libs/colors';

const Container = styled.div`
  width: 100%;
  border-radius: 4px;
  background: ${white};
  cursor: pointer;
  padding: 8px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${grey};
  user-select: none;
`;

export default Container;
