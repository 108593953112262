import styled from 'styled-components';

// colors
import {grey, white} from '@matthahn/sally-ui/lib/libs/colors';

const StateRow = styled.div`
  width: 100%;
  padding: 10px;
  border-top: ${({noBorder}) => (noBorder ? 'none' : `1px solid ${grey}`)};
  background: ${white};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default StateRow;
