import React, {Component} from 'react';
import PropTypes from 'prop-types';

// alertify
import alertify from '../../../layout/lib/alertify';

// attributes
import descriptionAttr from '../../../taskItem/attributes/description.attribute.taskItem';
import notesAttr from '../../attributes/notes.attribute.task';
import stateAttr from '../../attributes/state.attribute.task';
import scheduledAtAttr from '../../attributes/scheduled_at.attribute.task';

// api
import getTaskById from '../../api/getById.api.task';
import deleteTaskApi from '../../api/delete.api.task';
import updateTaskApi from '../../api/update.api.task';
import createTicketFromTaskApi from '../../api/createTicket.api.task';
import createTaskItemApi from '../../../taskItem/api/create.api.taskItem';
import deleteTaskItemApi from '../../../taskItem/api/delete.api.taskItem';
import updateTaskItemApi from '../../../taskItem/api/update.api.taskItem';

// components
import Container from '@matthahn/sally-ui/lib/components/Grid/Container/Container';
import Row from '@matthahn/sally-ui/lib/components/Grid/Row/Row';
import Column from '@matthahn/sally-ui/lib/components/Grid/Column/Column';

// layout components
import HeaderOptions from '../../../layout/components/HeaderOptions/HeaderOptions';
import PageLoader from '../../../layout/components/PageLoader/PageLoader';
import VehicleEditModal from '../../../vehicle/components/VehicleEditModal/VehicleEditModal';
import VehicleTaskInfo from '../../../vehicle/components/VehicleTaskInfo/VehicleTaskInfo';
import TaskItems from '../../../taskItem/components/TaskItems/TaskItems';
import UpdateTaskItemModal from '../../../taskItem/components/UpdateTaskItemModal/UpdateTaskItemModal';

// containers
import SelectServiceContainer from '../../../service/containers/SelectServiceContainer/SelectServiceContainer';

// error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// events
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';
import showSelectServiceModalEvent from '../../../service/events/showSelectServiceModal.event.service';
import serviceSelectedEvent from '../../../service/events/serviceSelected.event.service';
import setVehicleHistoryIdEvent from '../../../vehicle/events/setVehicleHistoryId.event.vehicle';
import showVehicleHistoryEvent from '../../../vehicle/events/showVehicleHistory.event.vehicle';

// taskItem prep
import updateTaskItemPreparation from '../../../taskItem/preparations/update.preparation.taskItem';

// layout
import HeaderIcon from '../../../layout/components/HeaderIcon/HeaderIcon';

// lib
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';
import isTaskCompletable from '../../lib/isCompletable.lib.task';
import isTaskDeletable from '../../lib/isDeletable.lib.task';
import isTaskEditable from '../../lib/isEditable.lib.task';
import attachServiceToTaskItem from '../../../taskItem/lib/attachService.lib.taskItem';

// navigation
import {setPage as setNavigationAction} from '../../../layout/containers/NavigationContainer/redux/actions';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// origins
import getVehicleOrigin from '../../../vehicle/origin/lib/getOrigin.lib.origin.vehicle';

// owner constants
import CUSTOM_OWNER_VALUE from '../../../owner/constants/customOwnerValue.lib.owner';

// owner events
import ownerCreatedEvent from '../../../owner/events/created.event.owner';
import showCreateOwnerModalEvent from '../../../owner/events/showCreateModal.event.owner';

// owner prep
import prepareOwnersForSelect from '../../../owner/lib/prepareForSelect.lib.owner';

// preparations
import generalTaskPrep from '../../preparations/general.preparation.task';

// redux
import {connect} from 'react-redux';

// router
import {withRouter} from 'react-router-dom';

// sockets
import taskDeletedSocket from '../../sockets/deleted.socket.task';
import taskUpdatedSocket from '../../sockets/updated.socket.task';
import ticketCreatedSocket from '../../../ticket/sockets/created.socket.ticket';

// task permissions
import destroyTaskPermission from '../../permissions/destroy.permission.task';
import readTaskPermission from '../../permissions/read.permission.task';
import updateTaskPermission from '../../permissions/update.permission.task';

// ticket events
import showGoToTicketModalEvent from '../../../ticket/events/showGoToTicketModal.event.ticket';

// ticket permissions
import createTicketPermission from '../../../ticket/permissions/create.permission.ticket';

// ticket routes
import ticketRoute from '../../../ticket/pages/TicketPage/route';
import ticketsRoute from '../../../ticket/pages/TicketsPage/route';

// uuid
import {v4} from 'uuid';

// vehicle
import getVehicleByIdApi from '../../../vehicle/api/getById.api.vehicle';

// vehicle attributes
import licensePlateAttr from '../../../vehicle/attributes/license_plate.attribute.vehicle';
import makeAttr from '../../../vehicle/attributes/make.attribute.vehicle';
import medallionAttr from '../../../vehicle/attributes/medallion.attribute.vehicle';
import modelAttr from '../../../vehicle/attributes/model.attribute.vehicle';
import ownerAttr from '../../../vehicle/attributes/owner.attribute.vehicle';
import yearAttr from '../../../vehicle/attributes/year.attribute.vehicle';

// vehicle api
import updateVehicleApi from '../../../vehicle/api/update.api.vehicle';

// vehicle lib
import isVehicleEditable from '../../../vehicle/lib/isEditable.lib.vehicle';
import isSallyVehicle from '../../../vehicle/lib/isSallyVehicle.lib.vehicle';

// vehicle preparations
import generalVehiclePrep from '../../../vehicle/preparations/general.preparation.vehicle';
import {isDesktop} from 'react-device-detect';

class TaskContainer extends Component {
  static propTypes = {
    taskId: PropTypes.string,
    services: PropTypes.array,
    owners: PropTypes.array,
    branch: PropTypes.object,
    previousPages: PropTypes.array,
    dispatch: PropTypes.func,
    history: PropTypes.object,
    subscribe: PropTypes.func,
  };

  state = {
    loading: true,
    task: null,
    addingTaskItems: [],
    updateNavigation: 0,
    paid: false,
    notes: notesAttr(''),
    state: stateAttr(''),
    scheduled_at: scheduledAtAttr(''),

    vehicleEditVisible: false,

    updatingTaskItem: false,
    updateTaskItemModalVisible: false,
    taskItem: null,
    description: descriptionAttr(''),

    vehicle: {
      license_plate: licensePlateAttr(''),
      make: makeAttr(''),
      medallion: medallionAttr(''),
      model: modelAttr(''),
      owner: ownerAttr(''),
      year: yearAttr(''),
    },
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
    this.props.subscribe(
      serviceSelectedEvent.subscribe(this.createTaskItems),
      taskUpdatedSocket.subscribe(this.taskUpdated),
      taskDeletedSocket.subscribe(this.taskDeleted),
      ticketCreatedSocket.subscribe(this.ticketCreated),
      ownerCreatedEvent.subscribe(this.ownerCreated)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = () => {
    this.loadTask();
  };

  ownerCreated = (owner) => {
    this.setState(
      {vehicle: {...this.state.vehicle, owner: ownerAttr(`${owner.id}`)}},
      () => {
        this.saveVehicleInfo('owner')();
      }
    );
  };

  loadTask = async () => {
    const {taskId, history} = this.props;

    if (!readTaskPermission()) return history.replace(ticketsRoute());

    this.setState({loading: true});

    try {
      const task = await getTaskById(taskId);
      const vehicle = await getVehicleByIdApi(fkOrId(task.vehicle));
      if (!this.mounted) return;
      vehicle.origin = getVehicleOrigin(vehicle);
      task.vehicle = vehicle;
      setVehicleHistoryIdEvent.publish({vehicle});
      this.setState({
        loading: false,
        task,
        paid: task.marked_paid,
        state: stateAttr(task.state || ''),
        notes: notesAttr(task.notes || ''),
        scheduled_at: scheduledAtAttr(task.scheduled_at || ''),
        vehicle: {
          license_plate: licensePlateAttr(vehicle.license_plate || ''),
          make: makeAttr(vehicle.make || ''),
          medallion: medallionAttr(vehicle.medallion || ''),
          model: modelAttr(vehicle.model || ''),
          owner: ownerAttr(!!vehicle.owner ? `${vehicle.owner}` : ''),
          year: yearAttr(vehicle.year || ''),
        },
      });
    } catch (error) {
      const {message} = parseError(error);
      if (!this.mounted) return;
      alertify({message});
      history.replace(ticketsRoute());
    }
  };

  taskUpdated = (task) => {
    const {history} = this.props;
    const {
      task: currentTask,
      loading,
      addingTaskItems,
      updatingTaskItem,
    } = this.state;
    if (
      loading ||
      updatingTaskItem ||
      currentTask?.id !== task.id ||
      !this.mounted ||
      !!addingTaskItems.length
    )
      return;
    if (!!currentTask && !currentTask.approved && task.approved) {
      alertify({message: 'Schedule got completed out by another user'});
      return history.replace(ticketsRoute());
    }
    const newTask = {...task, vehicle: currentTask.vehicle};
    this.setState({task: newTask});
  };

  taskDeleted = (task) => {
    const {history} = this.props;
    const {task: currentTask, loading} = this.state;
    if (loading || currentTask?.id !== task.id || !this.mounted) return;
    alertify({message: 'Schedule got deleted by another user'});
    history.replace(ticketsRoute());
  };

  ticketCreated = (ticket) => {
    const {history} = this.props;
    const {task, loading} = this.state;
    if (loading || ticket?.task !== task.id || !this.mounted) return;
    alertify({message: 'Schedule converted to a ticket'});
    history.replace(ticketsRoute());
  };

  onBack = () => {
    const {history, previousPages} = this.props;
    const goToRoute =
      previousPages.length > 1
        ? () => history.goBack()
        : () => history.push(ticketsRoute());
    goToRoute();
  };

  showSelectServiceModal = () =>
    showSelectServiceModalEvent.publish({ignoreDiscount: true});

  reopenTask = async ({prompt = true} = {}) => {
    const {loading, task} = this.state;
    if (loading || !task) return;
    if (!updateTaskPermission())
      return alertify({message: 'You do not have permission to do that'});

    if (prompt)
      return notify({
        title: `Reopen task #${task.id}`,
        content: 'Are you sure you want to reopen this task?',
        primary: {label: 'Cancel'},
        secondary: {
          label: 'Reopen',
          onClick: () => this.reopenTask({prompt: false}),
        },
      });

    this.setState({loading: true});

    try {
      const newTask = await updateTaskApi(task.id, {
        status: 'open',
      });
      this.setState({
        loading: false,
        task: {...newTask, vehicle: {...task.vehicle}},
      });
    } catch (error) {
      const {message} = parseError(error);
      if (!this.mounted) return;
      alertify({message});
      this.setState({loading: false});
    }
  };

  openGoToTicketModal = () => showGoToTicketModalEvent.publish();

  setNavigationTaskItems = () => {
    const {loading, task} = this.state;
    const vehicle = loading ? null : task?.vehicle || null;
    // const isdeletable = istaskdeletable(task);
    const isEditable = isTaskEditable(task);
    this.props.dispatch(
      setNavigationAction({
        pageName: !!task ? (
          <HeaderOptions
            key="headerOptions"
            options={[
              {
                key: 'history',
                label: 'Display History',
                onClick: () => showVehicleHistoryEvent.publish(),
              },
            ]}
          >
            Schedule #{task.id}
          </HeaderOptions>
        ) : (
          'Loading schedule...'
        ),
        pageLabelLeft: <HeaderIcon icon="arrowleft" onClick={this.onBack} />,
        headerLineColor: !!vehicle
          ? vehicle?.origin?.color || 'white'
          : 'white',
        actions: [
          // {
          //   empty: loading || !task || !isdeletable || !destroytaskpermission(),
          //   key: 'delete',
          //   label: 'delete',
          //   icon: 'delete',
          //   onclick: this.delete,
          // },
          {
            key: 'goToTicket',
            label: 'Ticket',
            icon: 'tago',
            onClick: this.openGoToTicketModal,
          },
          {
            empty:
              loading ||
              !task ||
              (!destroyTaskPermission() && !createTicketPermission()) ||
              !isTaskCompletable({...task}),
            key: 'complete',
            label: 'Complete',
            icon: 'check',
            onClick: this.displayActions,
          },
          {
            empty: loading || !task || !updateTaskPermission(),
            key: 'add',
            label: isEditable ? 'Add' : 'Reopen',
            icon: isEditable ? 'plus' : 'reload1',
            color: isEditable ? 'red' : null,
            onClick: isEditable ? this.showSelectServiceModal : this.reopenTask,
          },
          {
            empty: loading || !task || !readTaskPermission(),
            key: 'search',
            label: 'Search',
            icon: 'search1',
            onClick: this.search,
          },
        ],
      })
    );
  };

  changeVehicleInfo = (value, key) => {
    if (
      [
        notesAttr.attribute,
        stateAttr.attribute,
        scheduledAtAttr.attribute,
      ].includes(key)
    )
      return this.changeTask(value);
    const {task, vehicle} = this.state;
    if (!isTaskEditable(task) || !updateTaskPermission()) return;
    this.setState({vehicle: {...vehicle, [key]: value}});
  };

  changeAndStoreVehicleInfo = (value, key) => {
    if (
      key === ownerAttr.attribute &&
      value.api.format() === CUSTOM_OWNER_VALUE
    ) {
      this.setState({vehicleEditVisible: false});
      return showCreateOwnerModalEvent.publish();
    }
    if (
      [
        notesAttr.attribute,
        stateAttr.attribute,
        scheduledAtAttr.attribute,
      ].includes(key)
    )
      return this.setState({[key]: value}, () => {
        this.saveTask(key);
      });
    const {task, vehicle} = this.state;
    if (!isTaskEditable(task) || !updateTaskPermission()) return;
    this.setState({vehicle: {...vehicle, [key]: value}}, () => {
      this.saveVehicleInfo(key)();
    });
  };

  saveVehicleInfo = (key) => async () => {
    if (
      [
        notesAttr.attribute,
        stateAttr.attribute,
        scheduledAtAttr.attribute,
      ].includes(key)
    )
      return this.saveTask(key);
    const {task, vehicle} = this.state;
    if (!isTaskEditable(task) || !updateTaskPermission()) return;
    const attribute = vehicle[key];
    try {
      const preppedVehicleAttribute = await generalVehiclePrep({
        attributes: [attribute],
        required: [attribute.attribute],
      });
      const newVehicle = await updateVehicleApi(
        task.vehicle.id,
        preppedVehicleAttribute
      );
      if (!this.mounted) return;
      const newTask = {...task, vehicle: newVehicle};
      this.setState({task: newTask});
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alertify({message});
    }
  };

  changeTask = (attribute) => {
    if (!isTaskEditable(this.state.task) || !updateTaskPermission()) return;
    this.setState({[attribute.attribute]: attribute});
  };

  formatTask = async (attribute) => {
    try {
      const preppedMileageAttribute = await generalTaskPrep({
        attributes: [attribute],
        required: [attribute.attribute],
      });
      return preppedMileageAttribute;
    } catch (error) {
      return null;
    }
  };

  changeAndSaveTask = (attribute) => {
    if (!isTaskEditable(this.state.task) || !updateTaskPermission()) return;
    this.setState({[attribute.attribute]: attribute}, () =>
      this.saveTask(attribute.attribute)
    );
  };

  saveTask = async (key) => {
    const {task} = this.state;
    if (!isTaskEditable(task) || !updateTaskPermission()) return;
    const attribute = this.state[key];
    const preppedMileageAttribute = await this.formatTask(attribute);
    try {
      const updatedTask = await updateTaskApi(task.id, preppedMileageAttribute);
      const computedTask = {...task, scheduled_at: updatedTask.scheduled_at};
      this.setState({
        updateNavigation: new Date().getTime(),
        task: computedTask,
      });
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alertify({message});
    }
  };

  createTaskItems = async (services) => {
    const preparedServices = [...services]
      .map((service) =>
        !!service?.selectedAttributes?.length
          ? [...service.selectedAttributes].map((attribute) => ({
              ...service,
              attribute,
            }))
          : service
      )
      .flat();
    const apiId = v4();
    const {task} = this.state;
    if (!isTaskEditable(task) || !updateTaskPermission()) return;
    this.setState({
      addingTaskItems: [...this.state.addingTaskItems, apiId],
    });
    const doneAdding = () =>
      [...this.state.addingTaskItems].filter(
        (taskItemId) => taskItemId !== apiId
      );
    const createdTaskItems = await Promise.all(
      [...preparedServices].map((service) => this.createTaskItem({service}))
    );
    const taskItems = [...createdTaskItems].filter((taskItem) => !!taskItem);
    const newTask = {
      ...task,
      items: [...task.items, ...taskItems],
    };
    this.setState({task: newTask, addingTaskItems: doneAdding()});
  };

  createTaskItem = async ({service, task = this.state.task}) => {
    try {
      const preppedTaskItem = service.other
        ? {
            task: task.id,
            description: service.content,
          }
        : {
            task: task.id,
            service: service.id,
            description: service.content || '',
            attribute: service?.attribute?.id || null,
          };
      const taskItem = await createTaskItemApi({
        taskId: task.id,
        taskItem: preppedTaskItem,
      });
      return taskItem;
    } catch (error) {
      return null;
    }
  };

  onTaskItemDelete = (taskItem) => () => this.deleteTaskItem({taskItem});

  deleteTaskItem = async ({taskItem, prompt = true}) => {
    const {task} = this.state;
    if (!isTaskEditable(task) || !updateTaskPermission()) return;

    const newTask = {
      ...task,
      items: [...task.items].filter(
        (filteredTaskItem) => filteredTaskItem.id !== taskItem.id
      ),
    };
    this.setState({
      task: newTask,
      updateNavigation: new Date().getTime(),
      updateTaskItemModalVisible: false,
    });

    try {
      await deleteTaskItemApi({
        taskId: task.id,
        taskItemId: taskItem.id,
      });
    } catch (error) {
      const {message} = parseError(error);
      if (!this.mounted) return;
      alertify({message});
    }
  };

  displayActions = () => {
    const {task} = this.state;
    if (
      (!destroyTaskPermission() && !createTicketPermission()) ||
      !isTaskEditable(task)
    )
      return;

    if (!destroyTaskPermission() || !isTaskDeletable(task))
      return this.completeOrConvert();
    if (!createTicketPermission()) return this.delete();

    notify({
      title: 'Select action',
      content: 'What would you like to do?',
      primary: {
        label: 'Cancel',
      },
      secondary: {
        label: 'Convert',
        onClick: this.completeOrConvert,
      },
      additionalButtons: [{label: 'Delete', onClick: this.delete}],
    });
  };

  completeOrConvert = () => {
    notify({
      title: 'Convert',
      content: 'Are you sure you want to convert this schedule to a ticket?',
      primary: {
        label: 'Cancel',
      },
      secondary: {
        label: 'Convert',
        onClick: this.convert,
      },
    });
  };

  complete = async () => {
    const {history} = this.props;
    const {loading, task} = this.state;
    if (loading || !isTaskEditable(task) || !updateTaskPermission()) return;

    this.setState({loading: true});

    try {
      await updateTaskApi(task.id, {status: 'closed'});
      history.replace(ticketsRoute());
    } catch (error) {
      const {message} = parseError(error);
      if (!this.mounted) return;
      alertify({message});
      this.setState({loading: false});
    }
  };

  convert = async () => {
    const {history} = this.props;
    const {loading, task} = this.state;
    if (loading || !isTaskEditable(task) || !createTicketPermission()) return;

    this.setState({loading: true});

    try {
      await updateTaskApi(task.id, {
        status: 'closed',
      });
      const ticket = await createTicketFromTaskApi(task.id);
      history.replace(ticketRoute(ticket.id));
    } catch (error) {
      const {message} = parseError(error);
      if (!this.mounted) return;
      alertify({message});
      this.setState({loading: false});
    }
  };

  delete = async ({prompt = true} = {}) => {
    const {history} = this.props;
    const {loading, task} = this.state;
    if (loading || !isTaskEditable(task) || !destroyTaskPermission()) return;

    if (prompt)
      return notify({
        title: 'Are you sure?',
        content:
          'This ticket will be deleted. Are you sure you want to proceed?',
        primary: {label: 'Cancel'},
        secondary: {
          label: 'Delete',
          onClick: () => this.delete({prompt: false}),
        },
      });

    this.setState({loading: true});

    try {
      await deleteTaskApi(task.id);
      alertify({message: 'Schedule deleted'});
      history.replace(ticketsRoute());
    } catch (error) {
      const {message} = parseError(error);
      if (!this.mounted) return;
      alertify({message});
      this.setState({loading: false});
    }
  };

  taskItems = () => {
    const {services} = this.props;
    const {task} = this.state;
    return !!task
      ? [...task.items]
          .map((taskItem) => attachServiceToTaskItem({taskItem, services}))
          .sort((a, b) => a.id - b.id)
      : [];
  };

  showTaskItemUpdateModal = (taskItem) => () => {
    const {loading, updatingTaskItem, task} = this.state;
    if (
      loading ||
      updatingTaskItem ||
      !isTaskEditable(task) ||
      !updateTaskPermission()
    )
      return;
    this.setState({
      updatingTaskItem: false,
      updateTaskItemModalVisible: true,
      taskItem: taskItem,
      description: descriptionAttr(taskItem.description || ''),
    });
  };

  hideTaskItemUpdateModal = () => {
    if (
      this.state.loading ||
      this.state.updatingTaskItem ||
      !isTaskEditable(this.state.task)
    )
      return;
    this.setState({
      updateTaskItemModalVisible: false,
    });
  };

  showVehicleEditModal = () => {
    if (
      !!this.state?.task?.vehicle_fields ||
      // !isVehicleEditable(this.state?.task?.vehicle) ||
      !isTaskEditable(this.state.task) ||
      !updateTaskPermission()
    )
      return;
    this.setState({vehicleEditVisible: true});
  };

  hideVehicleEditModal = () => {
    this.setState({vehicleEditVisible: false});
  };

  updateTaskItem = async () => {
    const {loading, updatingTaskItem, description, task, taskItem} = this.state;

    if (
      !taskItem ||
      loading ||
      updatingTaskItem ||
      !isTaskEditable(task) ||
      !updateTaskPermission()
    )
      return;

    this.setState({updatingTaskItem: true});

    try {
      const preparedTaskItem = await updateTaskItemPreparation({
        isSallyVehicle: isSallyVehicle(task?.vehicle),
        description,
      });
      const updatedTaskItem = await updateTaskItemApi({
        taskId: task.id,
        taskItemId: taskItem.id,
        taskItem: preparedTaskItem,
      });
      if (!this.mounted) return;
      const newTask = {
        ...task,
        items: [...task.items].map((taskItemToUpdate) =>
          taskItemToUpdate.id === updatedTaskItem.id
            ? updatedTaskItem
            : taskItemToUpdate
        ),
      };
      this.setState({
        updatingTaskItem: false,
        updateTaskItemModalVisible: false,
        task: newTask,
      });
    } catch (error) {
      const {message} = parseError(error);
      if (!this.mounted) return;
      alertify({message});
      this.setState({updatingTaskItem: false});
    }
  };

  render() {
    const {owners} = this.props;
    const {
      loading,
      task,
      addingTaskItems,
      vehicle,
      mileage,
      notes,
      state,
      updatingTaskItem,
      updateTaskItemModalVisible,
      taskItem,
      description,
      vehicleEditVisible,
      paid,
      scheduled_at,
    } = this.state;
    return loading ? (
      <PageLoader />
    ) : (
      <Container>
        <Row margin />
        <Row>
          <Column size={1 / 3}>
            <VehicleEditModal
              {...vehicle}
              isVehicleEditable={isVehicleEditable(task?.vehicle)}
              onChange={this.changeVehicleInfo}
              onChangeAndStore={this.changeAndStoreVehicleInfo}
              onClose={this.hideVehicleEditModal}
              onSave={this.saveVehicleInfo}
              owners={prepareOwnersForSelect({owners, enableCustom: true})}
              scheduled_at={scheduled_at}
              task={task}
              vehicle={task.vehicle}
              visible={vehicleEditVisible}
            >
              <VehicleTaskInfo
                approved_for_work={task?.vehicle?.approved_for_work}
                task={task}
                paid={paid}
                mileage={mileage}
                notes={notes}
                state={state}
                isTaskEditable={isTaskEditable(task)}
                onChange={this.changeVehicleInfo}
                onSave={this.saveVehicleInfo}
                onClick={this.showVehicleEditModal}
                onChangeAndSaveTask={this.changeAndSaveTask}
              />
            </VehicleEditModal>
            <UpdateTaskItemModal
              visible={updateTaskItemModalVisible}
              loading={updatingTaskItem}
              taskItem={taskItem}
              description={description}
              onChange={this.changeTask}
              onClose={this.hideTaskItemUpdateModal}
              onSave={this.updateTaskItem}
              onDelete={
                isDesktop && !!taskItem
                  ? this.onTaskItemDelete(taskItem)
                  : () => {}
              }
            />
            <SelectServiceContainer descriptionOnly />
          </Column>
          <Column size={2 / 3}>
            <TaskItems
              isTaskEditable={isTaskEditable(task)}
              addingTaskItem={!!addingTaskItems.length}
              taskItems={this.taskItems()}
              onTaskItemDelete={this.onTaskItemDelete}
              onEdit={this.showTaskItemUpdateModal}
              onAddTaskItem={this.showSelectServiceModal}
            />
          </Column>
        </Row>
      </Container>
    );
  }
}

export default subscriptionHoc(
  withRouter(
    connect((state) => ({
      services: state.spotlight.services,
      owners: state.owner.owners,
      branch: state.branch.branch,
      previousPages: state.page.previousPages,
    }))(TaskContainer)
  )
);
