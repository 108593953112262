const columns = [
  {
    key: 'first_name',
    label: 'Name',
    sortable: true,
  },
  {
    key: 'phone_number',
    label: 'Phone Number',
  },
  {
    key: 'profile',
    label: 'Profile',
    span: 1 / 3,
  },
];

export default columns;
