// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// event HOCs
import subscribeHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// string lib
import sortByString from '@matthahn/sally-fw/lib/lib/sortByString';

// tag api
import createTagApi from '../../api/create.api.tag';

// tag components
import SelectTagModal from '../../components/SelectTagModal/SelectTagModal';

// tag events
import showSelectTagModalEvent from '../../events/showSelectTagModal.event.tag';
import tagCreatedEvent from '../../events/created.event.tag';
import tagSelectedEvent from '../../events/selected.event.tag';

// tag redux actions
import {set as setAction} from '../../redux/actions';

class SelectTagContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
    tags: PropTypes.array,
  };

  state = {
    color: '',
    excludeTags: [],
    label: '',
    saving: false,
    search: '',
    showCreateView: false,
    ticket: null,
    visible: false,
  };

  componentDidMount() {
    this.props.subscribe(showSelectTagModalEvent.subscribe(this.show));
  }

  show = ({excludeTags = [], ticket = null} = {}) => {
    this.setState({
      color: '',
      excludeTags,
      label: '',
      search: '',
      showCreateView: false,
      ticket,
      visible: true,
    });
  };

  hide = () => {
    if (this.state.saving) return;
    this.setState({visible: false});
  };

  change = (key) => (value) => {
    this.setState({[key]: value});
  };

  select = (tag) => () => {
    const {saving, ticket} = this.state;
    if (saving) return;
    this.setState({visible: false});
    tagSelectedEvent.publish({tag, ticket});
  };

  search = (search) => {
    this.setState({search});
  };

  save = async () => {
    const {dispatch, tags} = this.props;
    const {label, color, ticket} = this.state;

    if (!label || !color) return alert.warning('Fill out all fields.');

    this.setState({saving: true});

    try {
      const tag = await createTagApi({label, color});
      tagCreatedEvent.publish(tag);
      const updatedTags = [...tags, tag];
      dispatch(setAction({tags: updatedTags}));
      tagSelectedEvent.publish({tag, ticket});
      this.setState({saving: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({saving: false});
    }
  };

  showCreateView = () => {
    this.setState({showCreateView: true});
  };

  hideCreateView = () => {
    if (this.state.saving) return;
    this.setState({showCreateView: false});
  };

  tags = () => {
    const {tags} = this.props;
    const {search, excludeTags} = this.state;
    const filteredTags = !!excludeTags.length
      ? [...tags]
      : [...tags].filter((tag) => !excludeTags.includes(tag.id));
    const searchedTags = !!search.trim().length
      ? [...filteredTags].filter((tag) =>
          tag.label.toLowerCase().includes(search.toLowerCase())
        )
      : [...filteredTags];
    return searchedTags.sort(sortByString({key: 'label'}));
  };

  render() {
    const {color, label, saving, showCreateView, visible, search} = this.state;
    return (
      <SelectTagModal
        color={color}
        label={label}
        onChange={this.change}
        onHide={this.hide}
        onHideCreateView={this.hideCreateView}
        onSave={this.save}
        onSearch={this.search}
        onSelect={this.select}
        onShowCreateView={this.showCreateView}
        saving={saving}
        search={search}
        showCreateView={showCreateView}
        tags={this.tags()}
        visible={visible}
      />
    );
  }
}

export default connect((state) => ({tags: state.tag.tags}))(
  subscribeHOC(SelectTagContainer)
);
