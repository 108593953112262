import React from 'react';
import PropTypes from 'prop-types';

// components
import {Icon} from '@matthahn/sally-ui';

// local components
import Container from './components/Containers';

const HeaderIcon = ({icon, onClick}) => (
  <Container onClick={onClick}>
    <Icon icon={icon} />
  </Container>
);

HeaderIcon.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
};

export default HeaderIcon;
