// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// item api
import chargeForMultipleItemsApi from '../api/chargeMultiple.api.item';

const chargeForItemsService = async ({itemIds, ticket}) => {
  try {
    const {errors, ticket: latestTicket} = await chargeForMultipleItemsApi({
      ticketId: ticket.id,
      items: itemIds,
    });
    const updatedTicket = {
      ...ticket,
      items: [...latestTicket.items],
      external_charges: [...latestTicket.external_charges],
    };
    const error = !!errors.length ? `${errors.join('. ')}.` : null;
    const {updatedItems, failedItems} = [...itemIds].reduce(
      (combined, itemId) => {
        const item = [...ticket.items].find(({id}) => id === itemId);
        if (!item) return combined;
        const key = !!item.external_charge ? 'updatedItems' : 'failedItems';
        return {...combined, [key]: [...combined[key], item]};
      },
      {updatedItems: [], failedItems: []}
    );
    return {updatedTicket, error, updatedItems, failedItems};
  } catch (error) {
    const {message} = parseError(error);
    const failedItems = [...itemIds]
      .map((itemId) => [...ticket.items].find((item) => item.id === itemId))
      .filter((item) => !!item);
    return {
      updatedTicket: ticket,
      error: message,
      updatedItems: [],
      failedItems,
    };
  }
};

export default chargeForItemsService;
