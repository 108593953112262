// layout components
import ItemListLayout from '../../../layout/components/ItemListLayout/ItemListLayout';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// types
import phoneNumberType from '@matthahn/sally-fw/lib/type/types/phoneNumber.type';

const OwnersList = ({
  actions = [],
  columns,
  loading,
  onOwner,
  onPage,
  onSearch,
  page,
  pages,
  search,
  owners,
}) => (
  <ItemListLayout
    actions={actions}
    columns={columns}
    onSearch={onSearch}
    search={search}
    searchPlaceholder="Search Owners"
    tableProps={{
      loading,
      page,
      pages,
      onPage,
    }}
  >
    {(TableRow) =>
      owners.map((owner) => (
        <TableRow key={owner.id} onClick={onOwner(owner)}>
          {(TableCol) => [
            <TableCol key="name">{owner.name}</TableCol>,
            <TableCol key="email">{owner.email || '-'}</TableCol>,
            <TableCol key="phone_number">
              {phoneNumberType(owner.phone_number).format() || '-'}
            </TableCol>,
            <TableCol key="empty" span={3} />,
          ]}
        </TableRow>
      ))
    }
  </ItemListLayout>
);

OwnersList.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.array,
  loading: PropTypes.bool,
  onOwner: PropTypes.func,
  onPage: PropTypes.func,
  onSearch: PropTypes.func,
  page: PropTypes.number,
  pages: PropTypes.number,
  search: PropTypes.string,
  owners: PropTypes.array,
};

export default OwnersList;
