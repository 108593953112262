import React from 'react';
import PropTypes from 'prop-types';

// global components
import {Modal} from '@matthahn/sally-ui';

// ticket components
import TicketCard from '../TicketCard/TicketCard';

// layout
import PageLoader from '../../../layout/components/PageLoader/PageLoader';

// local components
import Card from './components/Card';
import Container from './components/Container';

const ClosedTicketsModal = ({visible, loading, tickets, onClose, onTicket}) => (
  <Modal
    title="Last closed tickets"
    visible={visible}
    onClose={onClose}
    theme="lightBlueGrey"
  >
    {(Content) =>
      loading ? (
        <Content padding="none">
          <PageLoader />
        </Content>
      ) : (
        <Content padding="none">
          <Container>
            {tickets.map((ticket) => (
              <Card key={ticket.id}>
                <TicketCard {...ticket} onClick={onTicket(ticket)} />
              </Card>
            ))}
          </Container>
        </Content>
      )
    }
  </Modal>
);

ClosedTicketsModal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  tickets: PropTypes.array,
  onClose: PropTypes.func,
  onTicket: PropTypes.func,
};

export default ClosedTicketsModal;
