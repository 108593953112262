import {SELECT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

export default attribute({
  type: SELECT,
  attribute: 'display_priority',
  label: {
    default: 'Priority',
    short: 'Priority',
  },
  options: [
    {
      value: '0',
      label: 'Low',
    },
    {
      value: '1',
      label: 'Medium',
    },
    {
      value: '2',
      label: 'High',
    },
  ],
});
