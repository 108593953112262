// driver components
import DriverProfileButton from '../DriverProfileButton/DriverProfileButton';

// layout components
import ItemListLayout from '../../../layout/components/ItemListLayout/ItemListLayout';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// types
import phoneNumberType from '@matthahn/sally-fw/lib/type/types/phoneNumber.type';

const DriversList = ({
  actions = [],
  columns,
  loading,
  onPage,
  onSearch,
  onSort,
  page,
  pages,
  search,
  sort,
  drivers,
}) => (
  <ItemListLayout
    actions={actions}
    columns={columns}
    onSearch={onSearch}
    onSort={onSort}
    search={search}
    searchPlaceholder="Search Drivers"
    sort={sort}
    tableProps={{
      loading,
      page,
      pages,
      onPage,
    }}
  >
    {(TableRow) =>
      drivers.map((driver) => (
        <TableRow key={driver.id}>
          {(TableCol) => [
            <TableCol key="name">
              {driver?.first_name} {driver?.last_name}
            </TableCol>,
            <TableCol key="phone_number">
              {!!driver?.phone_number
                ? phoneNumberType(driver?.phone_number).format()
                : '-'}
            </TableCol>,
            <TableCol key="profile" span={1 / 3}>
              <DriverProfileButton driver={driver} />
            </TableCol>,
          ]}
        </TableRow>
      ))
    }
  </ItemListLayout>
);

DriversList.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.array,
  loading: PropTypes.bool,
  onPage: PropTypes.func,
  onSearch: PropTypes.func,
  onSort: PropTypes.func,
  page: PropTypes.number,
  pages: PropTypes.number,
  search: PropTypes.string,
  sort: PropTypes.object,
  drivers: PropTypes.array,
};

export default DriversList;
