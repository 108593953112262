// Notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

const alertify = ({title = 'Error', message = ''} = {}) =>
  notify({
    title,
    content: message,
    secondary: {label: 'Close'},
  });

export default alertify;
