const DATABASE_FIELDS = [
  'branch_location',
  'created_at',
  'created_by',
  'george_id',
  'id',
  'kuiper_id',
  'license_plate',
  'make',
  'model',
  'modified_at',
  'svid',
  'vin',
  'year',
];

export default DATABASE_FIELDS;
