import React from 'react';
import PropTypes from 'prop-types';

// Containers
import VehicleActionContainer from '../../containers/VehicleActionContainer/VehicleActionContainer';
import Page from '../../../layout/containers/Page/Page';

// Page
import PAGE from './page';

// Router
import {withRouter} from 'react-router-dom';

const VehicleActionPage = ({
  match: {
    params: {vehicleId},
  },
}) => (
  <Page page={PAGE}>
    <VehicleActionContainer vehicleId={vehicleId} />
  </Page>
);

VehicleActionPage.propTypes = {
  match: PropTypes.object,
};

export default withRouter(VehicleActionPage);
