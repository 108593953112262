import styled from 'styled-components';

// Media
import {media} from '@matthahn/sally-ui/lib/libs/media';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;

  ${media.xs`
    gap: 4px;
  `}
`;

export default Container;
