import styled from 'styled-components';

// colors
import * as colors from '@matthahn/sally-ui/lib/libs/colors';

const StateLabel = styled.div`
  display: inline-block;
  padding: 10px 5px;
  border-radius: 4px;
  background: ${({color}) => colors[color] || color};
  color: ${({color}) =>
    !color ||
    [
      '',
      'transparent',
      'white',
      'grey',
      'lightBlueGrey',
      'superLightBlueGrey',
    ].includes(color)
      ? colors.darkGrey
      : colors.white};
`;

export default StateLabel;
