// layout components
import CompactDataRow from '../../../layout/components/CompactDataRow/CompactDataRow';

// local components
import HiddenFileInput from './components/HiddenFileInput';

// local handlers
import clickHandler from './handlers/click.handler';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment, useRef} from 'react';

const ExternalInvoiceCard = ({
  externalInvoice,
  loading,
  onDownload,
  onUpload,
}) => {
  const fileInput = useRef();

  const fileInputClick = () => fileInput?.current?.click?.();

  return (
    <Fragment>
      <CompactDataRow
        label="External Invoice"
        highlight={!!externalInvoice}
        rowProps={{
          onClick: clickHandler({
            download: onDownload,
            externalInvoice,
            fileInputClick,
          }),
        }}
      >
        <i
          className={[
            'mdi',
            loading
              ? 'mdi-loading mdi-spin'
              : !!externalInvoice
              ? 'mdi-download'
              : 'mdi-receipt-text-outline',
          ].join(' ')}
        />{' '}
        {!!externalInvoice ? 'Download' : 'Upload'}
      </CompactDataRow>
      <HiddenFileInput
        type="file"
        accept="image/*,application/pdf"
        capture="environment"
        ref={fileInput}
        onChange={(event) => onUpload(event.target.files?.[0])}
      />
    </Fragment>
  );
};

ExternalInvoiceCard.propTypes = {
  externalInvoice: PropTypes.object,
  loading: PropTypes.bool,
  onDownload: PropTypes.func,
  onUpload: PropTypes.func,
};

export default ExternalInvoiceCard;
