// api lib
import sortQuery from '../../../api/lib/sortQuery.lib.api';

// csv services
import downloadCsvService from '../../../csv/services/download.service.csv';

// events
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// local constants
import columns from './constants/columns.constant';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// driver api
import listDriversApi from '../../api/list.api.driver';

// driver components
import DriversList from '../../components/DriversList/DriversList';

// driver sockets
import driverCreatedSocket from '../../sockets/created.socket.driver';
import driverDeletedSocket from '../../sockets/deleted.socket.driver';
import driverUpdatedSocket from '../../sockets/updated.socket.driver';

// uuid
import {v4} from 'uuid';

class DriversContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  state = {
    downloadingCsv: false,
    drivers: [],
    loading: true,
    page: 1,
    pages: 1,
    search: '',
    sort: {key: 'first_name', direction: 'asc'},
  };

  componentDidMount() {
    this.mounted = true;
    this.getDrivers();
    this.props.subscribe(
      driverCreatedSocket.subscribe(this.addDriver),
      driverDeletedSocket.subscribe(this.deleteDriver),
      driverUpdatedSocket.subscribe(this.updateDriver)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  apiId = null;

  getDrivers = async ({
    page = this.state.page,
    perPage = 50,
    search = this.state.search,
    sort = this.state.sort,
  } = {}) => {
    const apiId = v4();
    this.apiId = apiId;
    this.setState({
      loading: true,
      page,
      search,
      sort,
      drivers: [],
    });
    try {
      const query = this.generateApiQuery({page, perPage, search, sort});

      const {results: drivers, count} = await listDriversApi(query);
      if (this.apiId !== apiId || !this.mounted) return;
      this.setState({
        loading: false,
        drivers,
        pages: Math.ceil(count / perPage) || 1,
      });
    } catch (error) {
      if (this.apiId !== apiId || !this.mounted) return;
      this.setState({loading: false});
    }
  };

  generateApiQuery = ({sort, perPage, page, search}) => {
    const query = {
      ...sortQuery(sort),
      limit: perPage,
      offset: (page - 1) * perPage,
    };

    if (!!search) query.search = search;

    return query;
  };

  addDriver = (driver) => {
    const {loading} = this.props;
    if (loading) return;
    const drivers = [...this.state.drivers, driver];
    this.setState({drivers});
  };

  updateDriver = (driver) => {
    const {loading} = this.props;
    if (loading) return;
    const drivers = [...this.state.drivers].map((driverToUpdate) =>
      driverToUpdate.id === driver.id ? driver : driverToUpdate
    );
    this.setState({drivers});
  };

  deleteDriver = ({id}) => {
    const {loading} = this.props;
    if (loading) return;
    const drivers = [...this.state.drivers].filter(
      (driver) => driver.id !== id
    );
    this.setState({drivers});
  };

  setPage = (page) => {
    if (this.state.loading) return;
    this.getDrivers({page});
  };

  sort = (sort) => this.getDrivers({sort, page: 1});

  search = (search) => this.getDrivers({search, page: 1});

  drivers = () => this.state.drivers;

  actions = () => {
    const {downloadingCsv} = this.state;
    return [
      {
        key: 'downloadCSV',
        children: 'Download CSV',
        icon: 'download',
        loading: downloadingCsv,
        theme: 'grey',
        onClick: this.downloadCsvService,
      },
    ];
  };

  downloadCsvService = async () => {
    const {downloadingCsv, search, sort} = this.state;
    if (downloadingCsv) return;
    this.setState({downloadingCsv: true});
    await downloadCsvService({
      apiCall: listDriversApi,
      fileName: 'drivers.csv',
      query: this.generateApiQuery({sort, search}),
    });
    if (!this.mounted) return;
    this.setState({downloadingCsv: false});
  };

  render() {
    const {loading, page, pages, search, sort} = this.state;
    const drivers = this.drivers();
    return (
      <DriversList
        actions={this.actions()}
        columns={columns}
        loading={loading}
        onPage={this.setPage}
        onSearch={this.search}
        onSort={this.sort}
        page={page}
        pages={pages}
        search={search}
        sort={sort}
        drivers={drivers}
      />
    );
  }
}

export default subscriptionHoc(DriversContainer);
