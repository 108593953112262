import styled from 'styled-components';

// Colors
import {white} from '@matthahn/sally-ui/lib/libs/colors';

const Container = styled.div`
  width: 100%;
  background: ${white};
  cursor: pointer;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
`;

export default Container;
