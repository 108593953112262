import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Avatar, Icon} from '@matthahn/sally-ui';
import VehicleModelInfo from '../../../vehicle/components/VehicleModelInfo/VehicleModelInfo';

// driver
import getDriverName from '../../../driver/lib/getName.lib.driver';

// Local Components
import Container from './components/Container';
import Date from './components/Date';
import Driver from './components/Driver';
import Info from './components/Info';
import Medallion from './components/Medallion';
import Origin from './components/Origin';
import Plate from './components/Plate';
import Vin from './components/Vin';

// Type
import shortDateTime from '@matthahn/sally-fw/lib/type/types/shortDateTime.type';

// Vehicle
import shouldShowOwner from '../../../vehicle/lib/shouldShowOwner.lib.vehicle';
import showBranchOrOwner from '../../../vehicle/lib/branchOrOwner.lib.vehicle';
import displayVehicleFieldFromObject from '../../../vehicle/lib/displayVehicleFieldFromObject.lib.vehicle';
import vehicleOrVehicleFields from '../../../vehicle/lib/vehicleOrVehicleFields.lib.vehicle';

// types
import phoneNumberType from '@matthahn/sally-fw/lib/type/types/phoneNumber.type';

const TicketCard = ({
  big,
  checkedin_at,
  date,
  driver,
  external_invoice_id,
  noLine,
  notes,
  onClick,
  phone_number,
  vehicle_fields,
  vehicle,
  withoutNotes,
}) => {
  const driverName = getDriverName({driver});
  const phoneNumber = phoneNumberType(phone_number).format();
  return (
    <Container onClick={onClick}>
      <Info big={big}>
        <Plate>
          {displayVehicleFieldFromObject({
            object: {vehicle, vehicle_fields},
            field: 'license_plate',
            empty: 'Unknown plate',
          })}
        </Plate>
        {!withoutNotes && <Vin>{notes || '-'}</Vin>}
        <Vin>
          {displayVehicleFieldFromObject({
            object: {vehicle, vehicle_fields},
            field: 'vin',
          })}
        </Vin>
        <div>{vehicle?.svid || '-'}</div>
        {shouldShowOwner(vehicle) && showBranchOrOwner(vehicle)}
        <div>
          <VehicleModelInfo
            {...vehicleOrVehicleFields({vehicle, vehicle_fields})}
            withoutMake
          />
        </div>
        {!!driverName && (
          <Driver>
            <Icon icon="user" /> {driverName}
          </Driver>
        )}
        {!!phoneNumber && (
          <Driver>
            <Icon icon="phone" /> {phoneNumber}
          </Driver>
        )}
        {!!external_invoice_id && (
          <Driver>External ID: {external_invoice_id}</Driver>
        )}
        <Date>{shortDateTime(date || checkedin_at).format()}</Date>
      </Info>
      {!!vehicle.medallion && (
        <Medallion>
          <Avatar
            avatar={displayVehicleFieldFromObject({
              object: {vehicle, vehicle_fields},
              field: 'medallion',
            })}
            size="xl"
            theme="orange"
            textDisplay="full"
            oval
          />
        </Medallion>
      )}
      {!noLine && <Origin background={vehicle?.origin?.color} />}
    </Container>
  );
};

TicketCard.propTypes = {
  big: PropTypes.bool,
  checkedin_at: PropTypes.string,
  date: PropTypes.string,
  driver: PropTypes.object,
  external_invoice_id: PropTypes.string,
  noLine: PropTypes.bool,
  notes: PropTypes.string,
  onClick: PropTypes.func,
  phone_number: PropTypes.string,
  vehicle_fields: PropTypes.object,
  vehicle: PropTypes.any,
  withoutNotes: PropTypes.bool,
  withoutPhoneNumber: PropTypes.bool,
};

export default TicketCard;
