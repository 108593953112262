import styled from 'styled-components';

// Media
import {media} from '@matthahn/sally-ui/lib/libs/media';

const SearchContainer = styled.div`
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;

  ${media.xs`
    height: 50px;
  `}
`;

export default SearchContainer;
