import styled from 'styled-components';

// Colors
import {white, red, grey} from '@matthahn/sally-ui/lib/libs/colors';

const Container = styled.div`
  width: 100%;
  height: 50px;
  font-size: 30px;
  background: ${white};
  color: ${red};
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${grey};
`;

export default Container;
