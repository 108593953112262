import styled from 'styled-components';

// colors
import {darkGrey} from '@matthahn/sally-ui/lib/libs/colors';

const TypeIcon = styled.div`
  font-size: 24px;
  transform: translateY(-1px);
  color: ${darkGrey};
`;

export default TypeIcon;
