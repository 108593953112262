// layout components
import ItemListLayout from '../../../layout/components/ItemListLayout/ItemListLayout';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const LocationsList = ({
  actions = [],
  columns,
  loading,
  onLocation,
  onPage,
  onSearch,
  page,
  pages,
  search,
  locations,
}) => (
  <ItemListLayout
    actions={actions}
    columns={columns}
    onSearch={onSearch}
    search={search}
    searchPlaceholder="Search"
    tableProps={{
      loading,
      page,
      pages,
      onPage,
    }}
  >
    {(TableRow) =>
      locations.map((location) => (
        <TableRow key={location.id} onClick={onLocation(location)}>
          {(TableCol) => [
            <TableCol key="name">{location.name || '-'}</TableCol>,
            <TableCol key="address">
              <div>{location.address_street}</div>
              <div>
                {location.address_zip} {location.address_city}
              </div>
              <div>{location.address_state}</div>
            </TableCol>,
          ]}
        </TableRow>
      ))
    }
  </ItemListLayout>
);

LocationsList.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.array,
  filters: PropTypes.array,
  loading: PropTypes.bool,
  onPage: PropTypes.func,
  onSearch: PropTypes.func,
  page: PropTypes.number,
  pages: PropTypes.number,
  search: PropTypes.string,
  locations: PropTypes.array,
};

export default LocationsList;
