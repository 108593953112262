// task state
import getState from './getState.state.task';

// task states
import noStateState from '../states/noState.state.task';
import scheduledState from '../states/scheduled.state.task';

const getTaskState = (task) => {
  const state = getState(task.state);
  return [noStateState.state, scheduledState.state].includes(state.state)
    ? !!task?.scheduled_at
      ? scheduledState
      : noStateState
    : state;
};

export default getTaskState;
