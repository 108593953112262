import styled from 'styled-components';

// colors
import {grey, darkGrey} from '@matthahn/sally-ui/lib/libs/colors';

// media
import {media} from '@matthahn/sally-ui/lib/libs/media';

const Containers = styled.div`
  color: ${darkGrey};
  background: ${grey};
  width: 42px;
  height: 42px;
  font-size: 24px;
  transform: translateY(6px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.xs`
    width: 32px;
    height: 32px;
    font-size: 12px;
    transform: translateY(6px);
  `};
`;

export default Containers;
