import {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Profile
import homeRoute from '../../../dashboard/pages/HomePage/route';

class RedirectToHome extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  componentDidMount() {
    this.props.history.replace(homeRoute());
  }

  render() {
    return null;
  }
}

export default withRouter(RedirectToHome);
