import React from 'react';
import PropTypes from 'prop-types';

// components
import {Button, Row, Column} from '@matthahn/sally-ui';

// device
import {isMobileOnly} from 'react-device-detect';

// entry components
import EntryCard from '../../../entry/components/EntryCard/EntryCard';

// local components
import Container from './components/Container';
import Padding from './components/Padding';

// ticket components
import TicketCard from '../../../ticket/components/TicketCard/TicketCard';

const VehicleOverview = ({
  canCreateTask,
  canCreateTicket,
  canReadTask,
  canReadTicket,
  activeTicket,
  vehicle,
  entries,
  ticketExists,
  onEntry,
  onCreateTicket,
  onCreateTask,
  onHistory,
}) => (
  <Container>
    <Padding block>
      <TicketCard
        date={null}
        created_at={null}
        vehicle={vehicle}
        onClick={() => {}}
        big={!isMobileOnly}
        driver={activeTicket?.driver}
        withoutNotes
        noLine
      />
    </Padding>
    <Padding block>
      <Row>
        {canCreateTicket && !ticketExists && (
          <Column size={1 / 2} xs={1} margin>
            <Button
              onClick={onCreateTicket}
              icon="plus"
              theme="red"
              size="xl"
              block
              noRadius
            >
              Create ticket
            </Button>
          </Column>
        )}
        {canCreateTask && (
          <Column size={1 / 2} xs={1} margin>
            <Button
              onClick={onCreateTask}
              icon="calendar"
              theme="darkGrey"
              size="xl"
              block
              noRadius
            >
              Schedule work
            </Button>
          </Column>
        )}
        {canReadTicket && (
          <Column size={1 / 2} xs={1}>
            <Button
              onClick={onHistory}
              icon="clockcircleo"
              theme="darkGrey"
              size="xl"
              block
              noRadius
              outline
            >
              Show history
            </Button>
          </Column>
        )}
      </Row>
    </Padding>
    {!!entries.length && (
      <Padding>
        {entries.map((entry) => (
          <EntryCard key={entry.entryId} {...entry} onClick={onEntry(entry)} />
        ))}
      </Padding>
    )}
  </Container>
);

VehicleOverview.propTypes = {
  canCreateTask: PropTypes.bool,
  canCreateTicket: PropTypes.bool,
  canReadTask: PropTypes.bool,
  canReadTicket: PropTypes.bool,
  activeTicket: PropTypes.object,
  vehicle: PropTypes.object,
  entries: PropTypes.array,
  ticketExists: PropTypes.bool,
  getItems: PropTypes.func,
  onEntry: PropTypes.func,
  onTicket: PropTypes.func,
  onCreateTicket: PropTypes.func,
  onCreateTask: PropTypes.func,
  onHistory: PropTypes.func,
};

export default VehicleOverview;
