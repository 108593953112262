import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: auto;
  padding: 25px;
  min-height: calc(100vh - 110px);
`;

export default Container;
