import React, {Component} from 'react';
import PropTypes from 'prop-types';

// alert
import alertify from '../../../layout/lib/alertify';

// api
import createLocationApi from '../../api/create.api.location';

// attributes
import addressCityAttribute from '../../attributes/address_city.attribute.location';
import addressStateAttribute from '../../attributes/address_state.attribute.location';
import addressStreetAttribute from '../../attributes/address_street.attribute.location';
import addressZipAttribute from '../../attributes/address_zip.attribute.location';
import nameAttr from '../../attributes/name.attribute.location';

// error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// events
import locationCreatedEvent from '../../events/created.event.location';
import showCreateLocationModalEvent from '../../events/showCreateModal.event.location';

// location components
import EditLocationModal from '../../components/EditLocationModal/EditLocationModal';

// subscription
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

class CreateLocationContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  state = {
    address_city: addressCityAttribute(''),
    address_state: addressStateAttribute(''),
    address_street: addressStreetAttribute(''),
    address_zip: addressZipAttribute(''),
    loading: false,
    name: nameAttr(''),
    visible: false,
  };

  componentDidMount() {
    this.props.subscribe(showCreateLocationModalEvent.subscribe(this.init));
  }

  init = () => {
    this.setState({
      address_city: addressCityAttribute(''),
      address_state: addressStateAttribute(''),
      address_street: addressStreetAttribute(''),
      address_zip: addressZipAttribute(''),
      name: nameAttr(''),
      visible: true,
    });
  };

  change = (value, key) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  close = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  save = async () => {
    const {
      address_city: addressCity,
      address_state: addressState,
      address_street: addressStreet,
      address_zip: addressZip,
      loading,
      name,
    } = this.state;

    if (loading) return;

    const locationName = name.api.format();
    const address_city = addressCity.api.format();
    const address_state = addressState.api.format();
    const address_street = addressStreet.api.format();
    const address_zip = addressZip.api.format();

    if (!locationName.trim().length) return alertify({message: 'Insert name'});
    if (
      [address_city, address_state, address_street, address_zip].find(
        (attr) => !attr.trim().length
      )
    )
      return alertify({message: 'Insert address'});

    this.setState({loading: true});

    try {
      const location = await createLocationApi({
        name: locationName,
        address_city,
        address_state,
        address_street,
        address_zip,
      });
      locationCreatedEvent.publish(location);
      this.setState({loading: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alertify({message});
      this.setState({loading: false});
    }
  };

  render() {
    const {
      address_city,
      address_state,
      address_street,
      address_zip,
      loading,
      name,
      visible,
    } = this.state;
    return (
      <EditLocationModal
        address_city={address_city}
        address_state={address_state}
        address_street={address_street}
        address_zip={address_zip}
        loading={loading}
        name={name}
        onChange={this.change}
        onClose={this.close}
        onSave={this.save}
        visible={visible}
      />
    );
  }
}

export default subscriptionHoc(CreateLocationContainer);
