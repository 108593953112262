import styled from 'styled-components';

// colors
import {white} from '@matthahn/sally-ui/lib/libs/colors';

const Container = styled.div`
  width: 100%;
  background: ${white};
  cursor: pointer;
  padding: 10px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default Container;
