import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {
  set as setAct,
  syncDrivers as syncDriversAct,
  removeDriver as removeDriverAct,
  removeUnusedDrivers as removeUnusedDriversAct,
} from '../../../spotlight/redux/actions';

// Api
import listDriversApi from '../../api/list.api.driver';

// Database
import DB_FIELDS from '../../database/fields.database.driver';
import driversModel from '../../database/model.database.driver';

// Events
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// Sockets
import driverCreatedSocket from '../../sockets/created.socket.driver';
import driverDeletedSocket from '../../sockets/deleted.socket.driver';
import driverUpdatedSocket from '../../sockets/updated.socket.driver';

class DriversSpotlightSync extends Component {
  static propTypes = {
    driversLoaded: PropTypes.bool,
    drivers: PropTypes.array,
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.init();
    this.props.subscribe(
      driverCreatedSocket.subscribe(this.updateDriver),
      driverDeletedSocket.subscribe(this.deleteDriver),
      driverUpdatedSocket.subscribe(this.updateDriver)
    );
  }

  init = async () => {
    const {dispatch} = this.props;
    const drivers = await this.getStoredDrivers();
    dispatch(syncDriversAct(drivers, {storeToDb: false}));
    dispatch(setAct({driversLoading: true}));
    const freshDrivers = await this.fetch();
    dispatch(removeUnusedDriversAct(freshDrivers));
    dispatch(setAct({driversLoading: false, driversLoaded: true}));
  };

  updateDriver = (driver) => {
    if (!this.props.driversLoaded) return;
    this.props.dispatch(syncDriversAct([driver], {storeToDb: false}));
  };

  deleteDriver = ({id}) => {
    if (!this.props.driversLoaded) return;
    this.props.dispatch(removeDriverAct(id));
  };

  getStoredDrivers = async () => {
    try {
      const drivers = await driversModel().toArray();
      return drivers;
    } catch (error) {
      return [];
    }
  };

  fetch = async ({drivers = [], limit = 100, offset = 0} = {}) => {
    try {
      const {results, next} = await listDriversApi({
        limit,
        offset,
        fields: DB_FIELDS.join(','),
        ordering: '-id',
      });
      const newList = [...drivers, ...results];
      this.props.dispatch(syncDriversAct(results, {storeToDb: false}));
      if (!!next)
        return this.fetch({
          drivers: newList,
          limit,
          offset: offset + limit,
        });
      return newList;
    } catch (error) {
      return drivers;
    }
  };

  render() {
    return null;
  }
}

export default connect((state) => ({
  driversLoaded: state.spotlight.driversLoaded,
  drivers: state.spotlight.drivers,
}))(subscriptionHoc(DriversSpotlightSync));
