// date
import {isBefore, parseISO, isToday, differenceInMinutes} from 'date-fns';
import orderByDate from '@matthahn/sally-fw/lib/lib/orderByDate';

// vehicle lib
import attachVehicleToObjects from '../../vehicle/lib/attachVehicleToObjects.lib.vehicle';

// lib
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';

const getEntries = ({
  tasks = [],
  tickets = [],
  vehicles = [],
  drivers = [],
  orderBy = 'date',
  orderDirection = 'asc',
  branchId = null,
  ignoreGroupingAndSorting = true,
}) => {
  const formattedTasks = [...tasks].map((task) => ({
    ...task,
    date: task.scheduled_at,
    entryId: `task${task.id}`,
    isTask: true,
    origin: task,
  }));
  const formattedTickets = [...tickets].map((ticket) => ({
    ...ticket,
    date: ticket.approved ? ticket.approved_at : ticket.checkedin_at,
    entryId: `ticket${ticket.id}`,
    isTask: false,
    origin: ticket,
  }));
  const entries = attachVehicleToObjects({
    objects: [...formattedTasks, ...formattedTickets],
    vehicles,
    drivers,
  })
    .map((entry) => {
      const minutesDifference = differenceInMinutes(
        parseISO(entry.date),
        new Date()
      );
      return {
        ...entry,
        in30Minutes: minutesDifference <= 30,
        isToday: isToday(parseISO(entry.date)),
        isDue: entry.isTask && isBefore(parseISO(entry.date), new Date()),
      };
    })
    .filter(
      ({vehicle}) =>
        !!vehicle && (!branchId || branchId === fkOrId(vehicle?.branch))
    );

  return ignoreGroupingAndSorting
    ? entries
    : orderByDate(entries, orderBy, orderDirection);
};

export default getEntries;
