import {createGlobalStyle} from 'styled-components';

// Colors
// import {lightBlueGrey} from '@matthahn/sally-ui/lib/libs/colors';

const GlobalStyle = createGlobalStyle`
  html, body {
    ${'' /* background: ${lightBlueGrey} */}
  }
`;

export default GlobalStyle;
