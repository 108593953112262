import {TEXTAREA} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

export default attribute({
  type: TEXTAREA,
  attribute: 'text',
  label: {
    default: 'Note',
    short: 'Note',
  },
});
