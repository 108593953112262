import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import Top from './components/Top';
import BottomScroller from './components/BottomScroller';
import Bottom from './components/Bottom';
import TopRight from './components/TopRight';
import Logo from './components/Logo';
import Avatar from './components/Avatar';
import Action from './components/Action';
import Button from './components/Button';
import NotLive from './components/NotLive';
import Wrapper from './components/Wrapper';

const Navigation = ({
  actions,
  avatar,
  children,
  live,
  logoUrl,
  onAvatarClick,
  onPage,
  pages,
}) => (
  <Wrapper>
    <Container live={live}>
      <Top>
        <Logo live={live} url={logoUrl} />
        {!live && <NotLive>Not Live</NotLive>}
        <TopRight>
          <Avatar avatar={avatar || ''} onClick={onAvatarClick} live={live} />
          {actions.map((action) => (
            <Action key={action.icon} live={live} {...action} />
          ))}
        </TopRight>
      </Top>
      <BottomScroller>
        <Bottom>
          {pages.map((page) => (
            <Button
              key={`${page.icon}-${page.url}`}
              icon={page.icon}
              url={page.url}
              selected={page.selected}
              badge={page.badge || 0}
              onClick={onPage(page)}
            >
              {page.page}
            </Button>
          ))}
        </Bottom>
      </BottomScroller>
    </Container>
    {children}
  </Wrapper>
);

Navigation.propTypes = {
  logoUrl: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      badge: PropTypes.number,
      onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    })
  ),
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      url: PropTypes.string,
      selected: PropTypes.boolean,
      page: PropTypes.string,
    })
  ),
  live: PropTypes.bool,
  avatar: PropTypes.string,
  onAvatarClick: PropTypes.func,
  onPage: PropTypes.func,
};

export default Navigation;
