import {Component} from 'react';

// Events
import changedEvt from '../../events/changed.event.visibility';
import hiddenEvt from '../../events/hidden.event.visibility';
import visibleEvt from '../../events/visible.event.visibility';

class Visibility extends Component {
  static EVENT = 'visibilitychange';

  componentDidMount() {
    document.addEventListener(this.constructor.EVENT, this.checkVisibility);
  }

  componentWillUnmount() {
    document.removeEventListener(this.constructor.EVENT, this.checkVisibility);
  }

  checkVisibility = () => {
    const visible = document.visibilityState === 'visible';
    changedEvt.publish(visible);
    const evt = visible ? visibleEvt : hiddenEvt;
    evt.publish(visible);
  };

  render() {
    return null;
  }
}

export default Visibility;
