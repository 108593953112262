import styled from 'styled-components';

// colors
import {darkerGrey} from '@matthahn/sally-ui/lib/libs/colors';

const Driver = styled.div`
  width: 100%;
  margin-top: 4px;
  color: ${darkerGrey};
`;

export default Driver;
