import {SELECT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

export default attribute({
  type: SELECT,
  attribute: 'category',
  label: {
    default: 'Category',
    short: 'Category',
  },
});
