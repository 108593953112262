// api
import api from '@matthahn/sally-fw/lib/api/lib/getEverything.lib.api';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import {Component} from 'react';

// redux
import {connect} from 'react-redux';

// tag api
import listTagsApi from '../../api/list.api.tag';

// tag events
import tagCreatedEvent from '../../events/created.event.tag';
import tagUpdatedEvent from '../../events/updated.event.tag';

// tag redux actions
import {set as setAction} from '../../redux/actions';

class LoadTagsContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    dispatch: PropTypes.func,
    loading: PropTypes.bool,
    subscribe: PropTypes.func,
    tags: PropTypes.array,
  };

  static defaultProps = {
    children: null,
  };

  componentDidMount() {
    this.loadTags();
    this.props.subscribe(
      tagCreatedEvent.subscribe(this.tagCreated),
      tagUpdatedEvent.subscribe(this.tagUpdated)
    );
  }

  loadTags = async () => {
    const {dispatch} = this.props;
    dispatch(setAction({loading: true}));
    try {
      const {data: tags} = await api(listTagsApi, {
        ordering: 'label',
      });
      dispatch(setAction({loading: false, tags}));
    } catch (error) {
      dispatch(setAction({loading: false, tags: []}));
    }
  };

  tagCreated = (tag) => {
    const tags = [...this.props.tags, tag];
    this.props.dispatch(setAction({tags}));
  };

  tagUpdated = (tag) => {
    const tags = [...this.props.tags].map((t) => (t.id === tag.id ? tag : t));
    this.props.dispatch(setAction({tags}));
  };

  render() {
    const {children, loading} = this.props;
    return loading ? null : children;
  }
}

export default connect((state) => ({
  loading: state.tag.loading,
  tags: state.tag.tags,
}))(subscriptionHOC(LoadTagsContainer));
