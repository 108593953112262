import styled from 'styled-components';

// Media
import {media} from '@matthahn/sally-ui/lib/libs/media';

const Content = styled.div`
  width: 100%;
  margin-top: 160px;
  height: calc(100% - 160px);
  overflow: scroll;
  padding: 10px 0px;
  -webkit-overflow-scrolling: touch;

  ${media.xs`
    margin-top: 90px;
    height: calc(100% - 90px);
  `}
`;

export default Content;
