// api lib
import api from '@matthahn/sally-fw/lib/api/lib/getEverything.lib.api';

// csv lib
import downloadCsv from '../lib/download.lib.csv';

const downloadCsvService = async ({
  apiCall,
  fileName = 'csv.csv',
  parseData = null,
  query = {},
} = {}) => {
  const {data} = await api(apiCall, query);
  const parsedData = !!parseData ? parseData(data) : data;
  const headers = (!!parsedData?.length ? Object.keys(parsedData[0]) : []).join(
    ','
  );
  const rows = [...parsedData].map((row) => Object.values(row).join(','));
  const csvRows = [headers, ...rows];
  const content = 'data:text/csv;charset=utf-8,' + csvRows.join('\n');
  downloadCsv({content, fileName});
  return parsedData;
};

export default downloadCsvService;
