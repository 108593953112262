import styled from 'styled-components';

// media
import {media} from '@matthahn/sally-ui/lib/libs/media';

const SearchContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;

  ${media.xs`
    height: 50px;
  `}
`;

export default SearchContainer;
