// item lib
import canChargeItem from './canCharge.lib.item';

const canChargeForItems = (ticket, {approved = true} = {}) => {
  return (
    !!ticket?.driver?.george_id &&
    ticket.approved === approved &&
    ticket.items.some((item) => canChargeItem(item))
  );
};

export default canChargeForItems;
