// alertify
import alertify from '../../../layout/lib/alertify';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// item components
import ChargeItemsModal from '../../components/ChargeItemsModal/ChargeItemsModal';

// item events
import showChargeModalEvent from '../../events/showChargeModal.event.item';

// item lib
import attachServiceToItem from '../../lib/attachService.lib.item';
import canChargeForItems from '../../lib/canChargeForItems.lib.item';
import canChargeItem from '../../lib/canCharge.lib.item';

// item service
import chargeForItemsService from '../../services/chargeForItems.service.item';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

// service lib
import isDiscountService from '../../../service/lib/isDiscount.lib.service';

class ChargeItemsContainer extends Component {
  static propTypes = {services: PropTypes.array, subscribe: PropTypes.func};

  state = {
    visible: false,
    charging: false,
    ticket: null,
    items: [],
    selected: [],
  };

  componentDidMount() {
    this.props.subscribe(showChargeModalEvent.subscribe(this.show));
  }

  sync = () => {};

  show = ({ticket, sync = () => {}}) => {
    const items = [...ticket.items].filter((item) => canChargeItem(item));
    if (!canChargeForItems(ticket) || !items.length)
      return alertify({
        title: 'Warning',
        message: 'Can not charge any items',
      });
    this.sync = sync;
    this.setState({visible: true, ticket: {...ticket}, items, selected: []});
  };

  hide = () => {
    if (this.state.charging) return;
    this.setState({visible: false});
  };

  selectItem = (item) => () => {
    const {charging, selected} = this.state;
    if (charging) return;
    const newSelected = selected.includes(item.id)
      ? [...selected].filter((itemId) => itemId !== item.id)
      : [...selected, item.id];
    this.setState({selected: newSelected});
  };

  chargeItems = async () => {
    const {charging, ticket, selected} = this.state;

    if (charging || !ticket) return;

    if (!selected.length)
      return alertify({message: 'No selected items', title: 'Warning'});

    this.setState({charging: true});

    const {updatedTicket, updatedItems, failedItems, error} =
      await chargeForItemsService({ticket, itemIds: selected});

    this.sync(updatedTicket);

    const message = !!error
      ? {
          title: 'Warning',
          message: [
            !!updatedItems.length
              ? `${updatedItems.length} ${
                  updatedItems.length === 1 ? 'item' : 'items'
                } charged`
              : 'Could not charge for any items',
            error,
            !!updatedItems.length &&
              !!failedItems.length &&
              'The items that were not charged for are still selected in the list',
          ]
            .filter((statement) => !!statement)
            .join('. '),
        }
      : {message: 'Charged successfully', title: 'Success'};

    alertify(message);

    const newSelected = [...selected].filter(
      (itemId) =>
        ![...updatedItems].find((updatedItem) => updatedItem.id === itemId)
    );

    this.setState({
      charging: false,
      selected: newSelected,
      visible: !!error,
    });
  };

  items = () => {
    const {services} = this.props;
    const {items} = this.state;
    return [...items]
      .map((item) => attachServiceToItem({item, services}))
      .filter(({service}) => !isDiscountService(service))
      .sort((a, b) => a.id - b.id);
  };

  render() {
    const {visible, charging, ticket, selected} = this.state;
    return (
      <ChargeItemsModal
        charging={charging}
        items={this.items()}
        onClose={this.hide}
        onSave={this.chargeItems}
        onSelect={this.selectItem}
        selected={selected}
        ticket={ticket}
        visible={visible}
      />
    );
  }
}

export default connect((state) => ({services: state.spotlight.services}))(
  subscriptionHOC(ChargeItemsContainer)
);
