// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

const clickHandler =
  ({download, externalInvoice, fileInputClick}) =>
  () => {
    if (!!externalInvoice)
      return notify({
        title: 'External Invoice',
        content: '',
        primary: {label: 'Cancel'},
        additionalButtons: [
          {
            label: 'Download',
            onClick: () => download(),
          },
          {
            label: 'Upload',
            onClick: () => fileInputClick(),
          },
        ],
      });

    fileInputClick();
  };

export default clickHandler;
