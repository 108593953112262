import styled, {css} from 'styled-components';

// colors
import {white, red, orange} from '@matthahn/sally-ui/lib/libs/colors';

const Charged = styled.div`
  display: flex;
  text-transform: uppercase;
  font-weight: 700;
  padding: 3px;
  border-radius: 3px;
  font-size: 14px;
  color: ${white};
  background: ${({warning}) => (warning ? orange : red)};

  ${({small}) =>
    small
      ? css`
          width: fit-content;
          height: 20px;
          padding: 0px 6px;
          display: block;
          font-size: 10px;
          self-align: flex-start;
          text-align: center;
        `
      : ''}
`;

export default Charged;
