import styled from 'styled-components';

// colors
import {darkGrey} from '@matthahn/sally-ui/lib/libs/colors';

const EntryDate = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${darkGrey};
  text-transform: uppercase;
`;

export default EntryDate;
