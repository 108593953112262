import {TEXT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import zip from '@matthahn/sally-fw/lib/type/types/zip.type';

export default attribute({
  type: TEXT,
  attribute: 'address_zip',
  display: zip,
  input: zip,
  api: zip,
  label: {
    default: 'ZIP',
    short: 'ZIP',
  },
});
