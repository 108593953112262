// mechanic lib
import getMechanicName from '../../../mechanic/lib/getName.lib.mechanic';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

// ticket attributes
import mileageAttr from '../../attributes/mileage.attribute.ticket';

// ticket components
import TicketActionsCard from '../../components/TicketActionsCard/TicketActionsCard';

// ticket events
import showMileageEditModalEvent from '../../events/showMileageEditModal.event.ticket';
import showSelectMechanicModalEvent from '../../events/showSelectMechanicModal.event.ticket';

class TicketActionsContainer extends Component {
  static propTypes = {
    georgeVehicle: PropTypes.object,
    onSpireonSync: PropTypes.func,
    onUpdateGeorgeVehicle: PropTypes.func,
    onSync: PropTypes.func,
    ticket: PropTypes.object,
  };

  actions = () => {
    const {mechanics, ticket} = this.props;
    const mechanic = getMechanicName({
      mechanicId: ticket.mechanic,
      mechanics,
      empty: null,
    });
    const mileageSet = !!ticket.mileage && !['0'].includes(ticket.mileage);
    return [
      {
        key: 'mileage',
        label: 'Mileage',
        outcome: mileageSet
          ? mileageAttr(ticket.mileage).display.format()
          : '-',
        icon: 'dashboard',
        status: mileageSet ? 'completed' : 'pending',
        onClick: () => showMileageEditModalEvent.publish(),
      },
      {
        key: 'mechanic',
        label: 'Mechanic',
        outcome: mechanic || '-',
        icon: 'team',
        status: !!mechanic ? 'completed' : 'pending',
        onClick: () => showSelectMechanicModalEvent.publish(),
      },
    ];
  };

  syncData = (key) => (data) => this.props.onSync({[key]: data});

  render() {
    const {georgeVehicle, ticket} = this.props;
    return (
      <TicketActionsCard
        actions={this.actions()}
        georgeVehicle={georgeVehicle}
        onSync={this.syncData}
        ticket={ticket}
      />
    );
  }
}

export default connect((state) => ({mechanics: state.mechanic.mechanics}))(
  TicketActionsContainer
);
