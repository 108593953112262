// alertify
import alertify from '../../../layout/lib/alertify';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, Fragment} from 'react';

// ticket api
import updateTicketApi from '../../api/update.api.ticket';
import validateTicketMileageApi from '../../api/validateMileage.api.ticket';

// ticket attributes
import mileageAttr from '../../attributes/mileage.attribute.ticket';
import vinLastSevenAttr from '../../attributes/vinLastSeven.attribute.ticket';

// ticket components
import ConfirmMileageValidationModal from '../../components/ConfirmMileageValidationModal/ConfirmMileageValidationModal';
import MileageModal from '../../components/MileageModal/MileageModal';

// ticket events
import showMileageEditModalEvent from '../../events/showMileageEditModal.event.ticket';

// ticket lib
import isTicketEditable from '../../lib/isEditable.lib.ticket';
import vinValidation from '../../lib/vinValidation.lib.ticket';

// ticket permissions
import updateTicketPermission from '../../permissions/update.permission.ticket';

// ticket preparations
import generalTicketPrep from '../../preparations/general.preparation.ticket';

class MileageContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
    syncTicket: PropTypes.func,
    ticket: PropTypes.object,
  };

  static MILEAGE_ASK_FOR_CONFIRMATION_AFTER = 1;

  state = {
    mileage: mileageAttr(''),
    mileageModalVisible: false,
    mileageValidationModalVisible: false,
    validatingMileage: false,
    vinLastSeven: vinLastSevenAttr(''),
    validationError: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
    this.props.subscribe(
      showMileageEditModalEvent.subscribe(this.showMileageModal)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  mileageRetry = 0;

  init = () => {
    const {ticket} = this.props;
    this.mileageRetry = 0;
    this.setState({
      mileage: mileageAttr(ticket?.mileage || ''),
    });
  };

  saveMileage =
    ({shouldValidateMileage = true} = {}) =>
    async () => {
      const {ticket, syncTicket} = this.props;
      const {mileage, vinLastSeven, validatingMileage} = this.state;

      if (
        validatingMileage ||
        !isTicketEditable(ticket) ||
        !updateTicketPermission()
      )
        return;

      if (
        !shouldValidateMileage &&
        !vinValidation({
          vinLastSeven: vinLastSeven.api.format(),
          vin: ticket?.vehicle?.vin,
        })
      )
        return alertify({message: 'Invalid VIN'});

      this.setState({validatingMileage: true});

      try {
        const preppedMileageAttribute = await generalTicketPrep({
          attributes: [mileage],
          required: [mileage.attribute],
        });
        if (shouldValidateMileage)
          await validateTicketMileageApi({
            ticketId: ticket.id,
            mileage: preppedMileageAttribute.mileage,
          });
        const updatedTicket = await updateTicketApi(ticket.id, {
          ...preppedMileageAttribute,
          confirmed_mileage: !shouldValidateMileage,
        });
        syncTicket({...ticket, mileage: updatedTicket.mileage});
        this.mileageRetry = 0;
        this.setState({
          validatingMileage: false,
          mileageValidationModalVisible: false,
        });
      } catch (error) {
        if (!this.mounted) return;
        const {message} = parseError(error);
        this.mileageRetry = this.mileageRetry + 1;

        if (
          this.mileageRetry >
          this.constructor.MILEAGE_ASK_FOR_CONFIRMATION_AFTER
        )
          return this.setState({
            mileageValidationModalVisible: true,
            vinLastSeven: vinLastSevenAttr(''),
            validatingMileage: false,
            validationError: message,
          });

        alertify({message});
        this.setState({
          validatingMileage: false,
          validationError: null,
        });
      }
    };

  change = (value, key) => {
    if (this.state.validatingMileage) return;
    this.setState({[key]: value});
  };

  showMileageModal = () => {
    if (this.state.validatingMileage) return;
    this.setState({mileageModalVisible: true});
  };

  hideMileageModal = () => {
    if (this.state.validatingMileage) return;
    this.setState({mileageModalVisible: false});
  };

  showMileageValidationModal = () => {
    if (this.state.validatingMileage) return;
    this.setState({mileageValidationModalVisible: true});
  };

  hideMileageValidationModal = () => {
    if (this.state.validatingMileage) return;
    this.setState({mileageValidationModalVisible: false});
  };

  render() {
    const {
      mileage,
      mileageModalVisible,
      mileageValidationModalVisible,
      validatingMileage,
      validationError,
      vinLastSeven,
    } = this.state;
    return (
      <Fragment>
        <MileageModal
          visible={mileageModalVisible}
          mileage={mileage}
          onChange={this.change}
          onClose={this.hideMileageModal}
          onSave={this.saveMileage()}
        />
        <ConfirmMileageValidationModal
          loading={validatingMileage}
          onChange={this.change}
          onClose={this.hideMileageValidationModal}
          onSave={this.saveMileage({shouldValidateMileage: false})}
          validationError={validationError}
          vinLastSeven={vinLastSeven}
          visible={mileageValidationModalVisible}
        />
      </Fragment>
    );
  }
}

export default subscriptionHOC(MileageContainer);
