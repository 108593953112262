// components
import Icon from '@matthahn/sally-ui/lib/components/Icon/Icon';

// local components
import Arrow from './components/Arrow';
import Content from './components/Content';
import Image from './components/Image';
import Slide from './components/Slide';
import Wrapper from './components/Wrapper';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// slider
import Slider from 'react-slick';

// Settings
const settings = {
  arrows: false,
  dots: false,
  centerMode: true,
  centerPadding: '0px',
  infinite: true,
  speed: 250,
  slidesToShow: 1,
  slidesToScroll: 1,
};

class ImageSlider extends Component {
  static propTypes = {
    height: PropTypes.string,
    images: PropTypes.array,
    initialImage: PropTypes.object,
  };

  static defaultProps = {
    height: '500px',
  };

  slider = null;

  next = () => {
    if (!this.slider) return;
    this.slider.slickNext();
  };

  previous = () => {
    if (!this.slider) return;
    this.slider.slickPrev();
  };

  sliderRef = (slider) => {
    const {images, initialImage} = this.props;
    this.slider = slider;
    const imageIndex = [...images].findIndex(
      (image) => image.id === initialImage?.id
    );
    if (!slider || imageIndex < 0) return;
    slider.slickGoTo(imageIndex);
  };

  render() {
    const {height, images} = this.props;
    return (
      <Content>
        <Wrapper>
          <Slider {...settings} ref={this.sliderRef}>
            {images.map((picture) => (
              <Slide key={picture.id} height={height}>
                <Image src={picture.doc_file} />
              </Slide>
            ))}
          </Slider>
          <Arrow left onClick={this.previous}>
            <Icon icon="left" />
          </Arrow>
          <Arrow onClick={this.next}>
            <Icon icon="right" />
          </Arrow>
        </Wrapper>
      </Content>
    );
  }
}

export default ImageSlider;
