import {SELECT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import string from '@matthahn/sally-fw/lib/type/types/string.type';

export default attribute({
  type: SELECT,
  attribute: 'payment_method',
  display: string,
  input: string,
  api: string,
  label: {
    default: 'Paid with',
    short: 'Paid with',
  },
  options: [
    {label: 'CASH', value: 'CASH'},
    {label: 'CREDIT', value: 'CREDIT'},
    {label: 'CHECK', value: 'CHECK'},
  ],
});
