import styled, {css} from 'styled-components';

const Level = styled.div`
  ${({level}) => {
    const width = `${level * 20}px`;
    return css`
      width: ${width};
      min-width: ${width};
      max-width: ${width};
    `;
  }}
`;

export default Level;
