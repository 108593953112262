// Constants
import CUSTOM_OWNER_VALUE from '../constants/customOwnerValue.lib.owner';

// Lib
import isObject from 'is-object';

const isCustomOwnerSelected = (owner) =>
  (isObject(owner) ? owner.api.format() : owner) === CUSTOM_OWNER_VALUE;

export default isCustomOwnerSelected;
