import React from 'react';
import PropTypes from 'prop-types';

// components
import Form from '@matthahn/sally-ui/lib/components/Form/Form';
import Notification from '@matthahn/sally-ui/lib/components/Notification/Notification';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import NotificationContainer from '../../../layout/components/NotificationContainer/NotificationContainer';

// device
import {isMobileOnly} from 'react-device-detect';

const GoToTicketModal = ({visible, ticketId, onChange, onClose, onGo}) => (
  <NotificationContainer visible={visible} big={!isMobileOnly}>
    <Notification
      title="Go to ticket"
      secondary={{label: 'Cancel', onClick: onClose}}
      primary={{label: 'Go', onClick: onGo}}
    >
      <Form onSubmit={onGo} enter>
        <AttributeInput
          value={ticketId}
          onChange={onChange}
          placeholder="Ticket Number"
          size="xl"
          autoFocus={visible}
        />
      </Form>
    </Notification>
  </NotificationContainer>
);

GoToTicketModal.propTypes = {
  visible: PropTypes.bool,
  ticketId: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onGo: PropTypes.func,
};

export default GoToTicketModal;
