const columns = [
  {
    key: 'vin',
    label: 'VIN',
  },
  {
    key: 'svid',
    label: 'SVID',
  },
  {
    key: 'plate',
    label: 'Plate',
  },
  {
    key: 'medallion',
    label: 'Medallion',
  },
  {
    key: 'model',
    label: 'Model',
  },
  {
    key: 'branch',
    label: 'Branch',
  },
  {
    key: 'owner',
    label: 'Owner',
  },
];

export default columns;
