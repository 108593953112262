import {TEXT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import uppercase from '@matthahn/sally-fw/lib/type/types/uppercase.type';

export default attribute({
  type: TEXT,
  attribute: 'name',
  display: uppercase,
  input: uppercase,
  api: uppercase,
  label: {
    default: 'Name',
    short: 'Name',
  },
});
