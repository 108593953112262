// Constants
import {SET} from './constants';

// Database
import driversModel from '../../driver/database/model.database.driver';
import servicesModel from '../../service/database/model.database.service';
import vehiclesModel from '../../vehicle/database/model.database.vehicle';

// Sync
import syncObjectByModificationDate from '@matthahn/sally-fw/lib/sync/lib/syncObjectByModificationDate';

export const set = (data = {}) => ({
  type: SET,
  data,
});

export const show = () => set({visible: true});

export const hide = () => set({visible: false});

// Service

const syncServiceStorage = (services) => {
  servicesModel().bulkPut(services);
};

export const syncServices =
  (newData, {storeToDb = true} = {}) =>
  (dispatch, getState) => {
    const oldData = getState().spotlight.services;
    const list = syncObjectByModificationDate(oldData, newData);
    if (storeToDb) syncServiceStorage(list);
    dispatch({
      type: SET,
      data: {services: list, serviceLoaded: true},
    });
  };

export const removeService = (id) => (dispatch, getState) => {
  const oldList = getState().spotlight.services;
  const list = [...oldList].filter((obj) => obj.id !== id);
  syncServiceStorage(list);
  dispatch({type: SET, data: {services: list}});
};

export const removeUnusedServices = (newList) => (dispatch, getState) => {
  const combinedList = getState().spotlight.services;
  const list = [...combinedList].filter(
    (obj) => !!newList.find(({id}) => obj.id === id)
  );
  syncServiceStorage(list);
  dispatch({type: SET, data: {services: list}});
};

// Vehicle

const syncVehicleStorage = (vehicles) => {
  vehiclesModel().bulkPut(vehicles);
};

export const syncVehicles =
  (newData, {storeToDb = true} = {}) =>
  (dispatch, getState) => {
    const oldData = getState().spotlight.vehicles;
    const list = syncObjectByModificationDate(oldData, newData);
    if (storeToDb) syncVehicleStorage(list);
    dispatch({
      type: SET,
      data: {vehicles: list, vehicleLoaded: true},
    });
  };

export const removeVehicle = (id) => (dispatch, getState) => {
  const oldList = getState().spotlight.vehicles;
  const list = [...oldList].filter((obj) => obj.id !== id);
  syncVehicleStorage(list);
  dispatch({type: SET, data: {vehicles: list}});
};

export const removeUnusedVehicles = (newList) => (dispatch, getState) => {
  const combinedList = getState().spotlight.vehicles;
  const list = [...combinedList].filter(
    (obj) => !!newList.find(({id}) => obj.id === id)
  );
  syncVehicleStorage(list);
  dispatch({type: SET, data: {vehicles: list}});
};

// Driver

const syncDriverStorage = (drivers) => {
  driversModel().bulkPut(drivers);
};

export const syncDrivers =
  (newData, {storeToDb = true} = {}) =>
  (dispatch, getState) => {
    const oldData = getState().spotlight.drivers;
    const list = syncObjectByModificationDate(oldData, newData);
    if (storeToDb) syncDriverStorage(list);
    dispatch({
      type: SET,
      data: {drivers: list, driverLoaded: true},
    });
  };

export const removeDriver = (id) => (dispatch, getState) => {
  const oldList = getState().spotlight.drivers;
  const list = [...oldList].filter((obj) => obj.id !== id);
  syncDriverStorage(list);
  dispatch({type: SET, data: {drivers: list}});
};

export const removeUnusedDrivers = (newList) => (dispatch, getState) => {
  const combinedList = getState().spotlight.drivers;
  const list = [...combinedList].filter(
    (obj) => !!newList.find(({id}) => obj.id === id)
  );
  syncDriverStorage(list);
  dispatch({type: SET, data: {drivers: list}});
};
