const columns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'phone_number',
    label: 'Phone Number',
  },
  {
    key: 'empty',
    label: '',
    span: 3,
  },
];

export default columns;
