import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Notification, Row, Column} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import NotificationContainer from '../../../layout/components/NotificationContainer/NotificationContainer';

// Device
import {isMobileOnly} from 'react-device-detect';

const UpdateTaskItemModal = ({
  visible,
  loading,
  taskItem,
  description,
  onChange,
  onSave,
  onClose,
  onDelete,
}) => (
  <NotificationContainer visible={visible} big={!isMobileOnly}>
    <Notification
      title={
        taskItem?.custom_service || taskItem?.service?.name || 'Item change'
      }
      primary={{label: 'Cancel', onClick: onClose, disabled: loading}}
      secondary={{label: 'Save', onClick: onSave, loading}}
      additionalButtons={
        !!onDelete ? [{label: 'Delete', onClick: onDelete}] : []
      }
    >
      <Row>
        <Column>
          <AttributeInput
            value={description}
            onChange={onChange}
            placeholder="Description"
            disabled={loading}
          />
        </Column>
      </Row>
    </Notification>
  </NotificationContainer>
);

UpdateTaskItemModal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  taskItem: PropTypes.object,
  cost: PropTypes.object,
  description: PropTypes.object,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
};

export default UpdateTaskItemModal;
