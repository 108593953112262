import styled from 'styled-components';

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
`;

export default Background;
