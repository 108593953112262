import styled, {css} from 'styled-components';

// Colors
import {lightBlueGrey, white} from '@matthahn/sally-ui/lib/libs/colors';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 6px 0px;
  cursor: pointer;
  user-select: none;
  background: ${white};
  font-weight: ${({bold}) => (bold ? 700 : 400)};
  position: relative;
  ${({noBorder}) =>
    noBorder
      ? ''
      : css`
          border-top: 1px solid ${lightBlueGrey};
        `};
  ${({swiping}) =>
    !swiping
      ? ''
      : css`
          height: 100%;
        `};
`;

export default Container;
