import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  flex: 1;
  padding-left: ${({padding}) => (padding ? '25px' : '0px')};
  line-height: 1.5em;
`;

export default Content;
