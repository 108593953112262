const columns = [
  {
    key: 'check',
    label: '',
  },
  {
    key: 'name',
    label: 'Name',
    span: 10,
  },
];

export default columns;
