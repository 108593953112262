import styled from 'styled-components';

// Colors
import {black, white} from '@matthahn/sally-ui/lib/libs/colors';

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  cursor: pointer;
  font-size: 40px;
  color: ${white};
  user-select: none;
  transform: translateY(-50%);
  text-shadow: 0px 0px 4px ${black};
  ${({left}) => `${left ? 'left' : 'right'}: 10px;`};
`;

export default Arrow;
