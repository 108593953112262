import styled from 'styled-components';

// colors
import {white} from '@matthahn/sally-ui/lib/libs/colors';

const Header = styled.div`
  width: 100%;
  font-size: 100px;
  font-weight: 700;
  text-transform: uppercase;
  z-index: 10;
  padding: 0px 6px;
  margin-bottom: -12px;
  color: ${white};
`;

export default Header;
