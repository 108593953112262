import f3tch from 'f3tch';

// Constants
import API_URL from '../../api/constants/url.const.api';

// Profiles
import profile from '@matthahn/sally-fw/lib/api/profiles/json.profile.api';

const updateVehicleApi = (vehicleId, vehicle) =>
  f3tch(`/vehicles/${vehicleId}/`)
    .profile(
      profile({
        url: API_URL(),
        authorizationType: 'JWT',
        ignoreAppVersionHeader: true,
      })
    )
    .body(vehicle)
    .patch();

export default updateVehicleApi;
