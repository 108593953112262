import styled from 'styled-components';

// Colors
import {darkGrey} from '@matthahn/sally-ui/lib/libs/colors';

const Date = styled.div`
  width: 100%;
  font-size: 16px;
  color: ${darkGrey};
  margin-top: 6px;
`;

export default Date;
