import f3tch from 'f3tch';

// api profiles
import profile from '@matthahn/sally-fw/lib/api/profiles/json.profile.api';

// api lib
import getEndpoint from '../../api/lib/getEndpoint.lib.api';

const updateVehicleCalampApi = (vehicleId, vehicle) =>
  f3tch(`/vehicles/${vehicleId}/update_calamp/`)
    .profile(
      profile({
        url: getEndpoint('george'),
        accessToken: 'george',
        authorizationType: 'JWT',
        ignoreAppVersionHeader: true,
      })
    )
    .body(vehicle)
    .post();

export default updateVehicleCalampApi;
