import styled from 'styled-components';

// Components
import ActualLogo from '../../Logo/Logo';

const Logo = styled(ActualLogo)`
  height: 16px;
`;

export default Logo;
