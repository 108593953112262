import React, {Component} from 'react';
import PropTypes from 'prop-types';

// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// Input Types
import {
  DATE,
  DATETIME,
  EXPLICIT_SWITCH,
  NUMBER,
  SELECT,
  SWITCH,
  TEXT,
  TEXTAREA,
  TIME,
  TOGGLE,
  WEEK,
} from '@matthahn/sally-fw/lib/inputTypes';

// Components
import {
  Input,
  Select,
  DatePickerInput,
  ExplicitCheckBox,
  TimePickerInput,
  DateTimePickerInput,
  CheckBox,
  Switch,
  Textarea,
  WeekPickerInput,
} from '@matthahn/sally-ui';
import AttributeText from '../AttributeText/AttributeText';

import Bold from './components/Bold';

// Lib
import prepareSelectOptions from './prepareSelectOptions';
import isBoolean from '@matthahn/sally-fw/lib/lib/isBoolean';

// layout components
import ResizableTextArea from '../ResizableTextArea/ResizableTextArea';

class AttributeInput extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    value: PropTypes.object,
    errors: PropTypes.array,
    possibleOptions: PropTypes.array,
    options: PropTypes.array,
    removeOptions: PropTypes.array,
    required: PropTypes.array,
    auth: PropTypes.array,

    initialized: PropTypes.bool,
    token: PropTypes.string,
    username: PropTypes.string,
    roles: PropTypes.object,
    computedValues: PropTypes.object,
    customComponent: PropTypes.func,
    useOption: PropTypes.bool,
    resizableTextArea: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    errors: [],
    possibleOptions: [],
    options: [],
    removeOptions: [],
    required: [],
    children: null,
    auth: [],
    computedValues: {},
    customComponent: null,
    useOption: false,
  };

  static INPUTS = {
    [DATE]: DatePickerInput,
    [DATETIME]: DateTimePickerInput,
    [EXPLICIT_SWITCH]: ExplicitCheckBox,
    [NUMBER]: Input,
    [SELECT]: Select,
    [SWITCH]: CheckBox,
    [TEXT]: Input,
    [TEXTAREA]: Textarea,
    [TIME]: TimePickerInput,
    [TOGGLE]: Switch,
    [WEEK]: WeekPickerInput,
  };

  // isAuthorized = () => {
  //   const {initialized, token, roles, value, auth} = this.props;
  //   return (
  //     !auth.includes(value.attribute) ||
  //     (initialized && !!token && roles.is_superuser)
  //   );
  // };

  onDateError = (error) => alert.warning(error);

  isAuthorized = () => true;

  component = () => {
    const {value, customComponent, resizableTextArea} = this.props;
    return (
      customComponent ||
      (value.type === TEXTAREA && resizableTextArea
        ? ResizableTextArea
        : this.constructor.INPUTS[value.type])
    );
  };

  onChange = (newValue) => {
    const {onChange, disabled, value, errors, computedValues} = this.props;
    if (disabled) return;
    const attr = value.reinit(newValue, value, computedValues);
    onChange(
      attr,
      attr.attribute,
      errors.filter((e) => e !== attr.attribute)
    );
  };

  value = () => {
    if (this.props.value.type === SELECT) {
      const options =
        !!this.props.value.additional.options && !this.props.useOption
          ? prepareSelectOptions(this.props.value.additional.options)
          : prepareSelectOptions([...this.props.options]);
      const possibleOptions = prepareSelectOptions(this.props.possibleOptions);
      const finalOptions = possibleOptions
        .reduce(
          (combined, opt) => {
            if (!combined.find((o) => o.value === opt.value))
              return [...combined, opt];
            return [...combined];
          },
          [...options]
        )
        .filter((opt) => !this.props.removeOptions.includes(opt.value));
      return {
        value: this.props.value.input.format() || '',
        options: finalOptions,
      };
    }
    const additional = !!this.props.value.additional
      ? {...this.props.value.additional}
      : {};
    return [DATE, DATETIME, TIME, WEEK].includes(this.props.value.type)
      ? {
          value: this.props.value.value(),
          dateFormat: this.props.value.input.format,
          ...additional,
        }
      : {
          value: isBoolean(this.props.value.input.format())
            ? this.props.value.input.format()
            : this.props.value.input.format() || '',
          ...additional,
        };
  };

  content = () => {
    const {required, children, value} = this.props;
    if (!children) return null;
    if (!required.includes(value.attribute)) return children;
    return <Bold>{children} *</Bold>;
  };

  additionalInputAttributes = () => {
    if (this.props.value.type === NUMBER)
      return {type: 'text', inputMode: 'decimal'};
    return {};
  };

  input = () => {
    const Component = this.component();
    const {
      onChange,
      disabled,
      value,
      errors,
      required,
      children,
      customComponent,
      ...props
    } = this.props;
    const {
      possibleOptions,
      options,
      removeOptions,
      auth,
      initialized,
      token,
      username,
      roles,
      computedValues,
      ...filteredProps
    } = props;
    if ([DATE, DATETIME].includes(value.type)) {
      props.onError = this.onDateError;
    }
    return !!customComponent ? (
      <Component
        {...this.value()}
        onChange={(e) => this.onChange(e.target.value)}
        {...filteredProps}
      />
    ) : (
      <Component
        {...props}
        disabled={disabled}
        onChange={this.onChange}
        error={errors.includes(value.attribute)}
        {...this.additionalInputAttributes()}
        {...this.value()}
      >
        {this.content()}
      </Component>
    );
  };

  text = () => {
    const {
      initialized,
      token,
      username,
      roles,
      onChange,
      disabled,
      value,
      errors,
      possibleOptions,
      options,
      removeOptions,
      required,
      customComponent,
      ...props
    } = this.props;
    return <AttributeText attribute={value} {...props} />;
  };

  render() {
    return this.isAuthorized() ? this.input() : this.text();
  }
}

export default AttributeInput;
