import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {Icon} from '@matthahn/sally-ui';

// Local Components
import Action from './components/Action';
import SearchContainer from './components/SearchContainer';
import Input from './components/Input';

class Search extends Component {
  static propTypes = {
    search: PropTypes.string,
    onSearch: PropTypes.func,
    onEscape: PropTypes.func,
    threshold: PropTypes.number,
  };

  static defaultProps = {
    threshold: 1,
  };

  state = {
    numeric: false,
  };

  onRef = (input) => {
    this.input = input;
  };

  onEscape = () => {
    this.focus();
    this.props.onEscape();
  };

  toggleKeyboard = () => {
    this.setState({numeric: !this.state.numeric});
    this.focus();
  };

  focus = () => {
    if (!this.input) return;
    this.input.focus();
  };

  blur = () => {
    if (!this.input) return;
    this.input.blur();
  };

  onChange = (e) => {
    this.props.onSearch(e.target.value);
  };

  onKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.props.onEscape();
      e.preventDefault();
      return false;
    }
  };

  render() {
    const {search, threshold} = this.props;
    const {numeric} = this.state;
    return (
      <SearchContainer>
        <Action>
          <Icon icon="search1" />
        </Action>
        <Input
          ref={this.onRef}
          type="text"
          inputMode={numeric ? 'numeric' : 'search'}
          value={search}
          onKeyDown={this.onKeyDown}
          onChange={this.onChange}
          placeholder="Search"
        />
        {search.length > threshold && (
          <Action onClick={this.onEscape}>
            <Icon icon="close" />
          </Action>
        )}
        <Action onClick={this.toggleKeyboard}>
          <span className={`mdi mdi-${numeric ? 'keyboard' : 'dialpad'}`} />
        </Action>
      </SearchContainer>
    );
  }
}

export default Search;
