import styled, {css} from 'styled-components';

const Tag = styled.div`
  ${({small}) =>
    small
      ? css`
          padding: 2px;
          border-radius: 2px;
          font-size: 11px;
        `
      : css`
          padding: 4px;
          border-radius: 4px;
          font-size: 13px;
          font-weight: 700;
        `}
  color: ${({color}) => color || '#fff'};
  background: ${({theme}) => theme};
  cursor: pointer;
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
`;

export default Tag;
