import {Component} from 'react';
import PropTypes from 'prop-types';

// api
import listTicketsApi from '../../api/list.api.ticket';

// redux
import {connect} from 'react-redux';

// spotlight redux
import {set as setSpotlightAction} from '../../../spotlight/redux/actions';

class TicketSpotlightSync extends Component {
  static propTypes = {
    search: PropTypes.string,
    dispatch: PropTypes.func,
  };

  componentDidMount() {
    this.search();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.search !== this.props.search) this.search();
  }

  search = async () => {
    const {search, dispatch} = this.props;

    if (!search.trim().length)
      return dispatch(setSpotlightAction({ticketsLoading: false, tickets: []}));

    dispatch(setSpotlightAction({ticketsLoading: true}));

    try {
      const {results} = await listTicketsApi({
        search,
        ordering: '-checkedin_at',
      });
      dispatch(setSpotlightAction({ticketsLoading: false, tickets: results}));
    } catch (error) {
      dispatch(setSpotlightAction({ticketsLoading: false, tickets: []}));
    }
  };

  render() {
    return null;
  }
}

export default connect()(TicketSpotlightSync);
