import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// components
import {Print, Button, Row, Column, Heading, Info} from '@matthahn/sally-ui';

// local components
import Alerts from './components/Alerts';
import DataRow from './components/DataRow';
import ItemDetail from './components/ItemDetail';
import Owner from './components/Owner';

// lib
import getItemName from '../../../item/lib/getName.lib.item';

// type
import amountType from '@matthahn/sally-fw/lib/type/types/amount.type';
import dateType from '@matthahn/sally-fw/lib/type/types/date.type';
import dateTimeType from '@matthahn/sally-fw/lib/type/types/dateTime.type';

// vehicle lib
import shouldShowOwner from '../../lib/shouldShowOwner.lib.vehicle';
import showBranchOrOwner from '../../lib/branchOrOwner.lib.vehicle';

const VehicleSummaryModal = ({
  calamp,
  getItems,
  historicTickets,
  milesSinceLastSeen,
  notes,
  onPrintTicket,
  shouldAlertMilesSinceLastSeen,
  shouldChangeOil,
  spireon,
  ticket,
}) => (
  <Fragment>
    {!!ticket && (
      <Print
        trigger={({print}) => (
          <Row>
            <Column
              size={!!ticket?.items?.length ? 2 / 3 : 1}
              offset={!!ticket?.items?.length ? 1 / 3 : 0}
            >
              <Button
                size="large"
                theme="lightBlueGrey"
                color="darkGrey"
                icon="printer"
                onClick={onPrintTicket(print)}
                block
                noRadius
              >
                Print Summary
              </Button>
            </Column>
          </Row>
        )}
      >
        <Row margin>
          <Column>
            <Heading size="3">
              {ticket?.vehicle?.license_plate || 'Unknown plate'}
            </Heading>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <DataRow operation="Ticket #" indication={ticket.id} />
            <DataRow
              operation="Check-in"
              indication={dateTimeType(ticket.checkedin_at).format()}
            />
            <DataRow
              operation={!!ticket?.vehicle?.medallion ? 'Medallion' : 'SVID'}
              indication={
                ticket?.vehicle?.svid || ticket?.vehicle?.medallion || '-'
              }
            />
            <DataRow operation="VIN" indication={ticket?.vehicle?.vin} />
            <DataRow
              operation="Vehicle"
              indication={`${ticket?.vehicle?.year} ${ticket?.vehicle?.make} ${ticket?.vehicle?.model}`}
            />
            {shouldShowOwner(ticket.vehicle) && (
              <DataRow
                operation="Owner"
                indication={<Owner>{showBranchOrOwner(ticket.vehicle)}</Owner>}
              />
            )}
            <DataRow
              operation="Driver"
              indication={
                !!ticket?.driver
                  ? `${ticket?.driver?.first_name} ${ticket?.driver?.last_name}`
                  : '-'
              }
            />
            <DataRow operation="Mileage" indication={ticket?.mileage || '-'} />
            <DataRow operation="Notes" indication={notes || '-'} />
          </Column>
        </Row>
        <Row margin>
          <Column>
            <Alerts>
              {!!calamp && calamp?.status !== 'done' && (
                <Info type={calamp.status} flat>
                  {calamp.message}
                </Info>
              )}
              {!!spireon && spireon?.status !== 'done' && (
                <Info type={spireon.status} flat>
                  {spireon.message}
                </Info>
              )}
              {shouldChangeOil && (
                <Info type="error" flat>
                  Change oil
                </Info>
              )}
              {shouldAlertMilesSinceLastSeen && (
                <Info type="error" flat>
                  Last seen ${amountType(milesSinceLastSeen).format()} miles ago
                </Info>
              )}
            </Alerts>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <Heading size="3">History (Latest 10 tickets)</Heading>
          </Column>
        </Row>
        {historicTickets.map((ticket) => (
          <DataRow
            key={ticket.id}
            operation={dateType(ticket.checkedin_at).format()}
            indication={
              ticket.notes ||
              getItems(ticket).map((item) => (
                <ItemDetail key={item.id}>{getItemName(item)}</ItemDetail>
              ))
            }
          />
        ))}
      </Print>
    )}
  </Fragment>
);

VehicleSummaryModal.propTypes = {
  calamp: PropTypes.object,
  getItems: PropTypes.func,
  historicTickets: PropTypes.array,
  milesSinceLastSeen: PropTypes.number,
  notes: PropTypes.string,
  onPrintTicket: PropTypes.func,
  shouldAlertMilesSinceLastSeen: PropTypes.bool,
  shouldChangeOil: PropTypes.bool,
  spireon: PropTypes.object,
  ticket: PropTypes.object,
};

export default VehicleSummaryModal;
