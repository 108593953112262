// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// textarea
import TextareaAutosize from 'react-textarea-autosize';

const ResizableTextArea = ({value, onChange, ...props}) => (
  <TextareaAutosize
    minRows={1}
    value={value}
    size="large"
    onChange={(e) => onChange(e.target.value)}
    style={{
      width: '100%',
      height: '100%',
      display: 'block',
      flex: 1,
      padding: '6px',
      margin: '0px',
      background: 'none',
      border: 'none',
      outline: 'none',
      fontFamily: 'ProximaNova',
      fontSize: 'inherit',
      color: 'black',
      resize: 'none',
    }}
    {...props}
  />
);

ResizableTextArea.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default ResizableTextArea;
