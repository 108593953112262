// components
import DropDown from '@matthahn/sally-ui/lib/components/DropDown/DropDown';
import Input from '@matthahn/sally-ui/lib/components/Input/Input';
import SimpleMenu from '@matthahn/sally-ui/lib/components/SimpleMenu/SimpleMenu';

// local components
import Clear from './components/Clear';
import InputContainer from './components/InputContainer';
import Label from './components/Label';
import Search from './components/Search';
import Value from './components/Value';
import ValueContainer from './components/ValueContainer';
import ValueTag from './components/ValueTag';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class SelectDropdown extends Component {
  static propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    dropdownOptions: PropTypes.object,
    multipleSelectedLabel: PropTypes.node,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({value: PropTypes.any, label: PropTypes.node})
    ),
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.any,
      PropTypes.arrayOf(PropTypes.any),
    ]),
  };

  static defaultProps = {
    multipleSelectedLabel: 'Multiple Selected',
  };

  state = {
    dropdownVisible: false,
    highlight: null,
    search: '',
  };

  showDropdown = () => {
    this.setState({dropdownVisible: true});
  };

  hideDropdown = () => {
    this.setState({dropdownVisible: false});
  };

  isMultipleSelection = () => {
    const {value} = this.props;
    const isArray = Array.isArray(value);
    return isArray;
  };

  values = () => {
    const {value} = this.props;
    return this.isMultipleSelection() ? value : [value];
  };

  parsedValues = () => {
    const {value, options} = this.props;
    return this.isMultipleSelection()
      ? [...value]
          .map((selectedValue) =>
            [...options].find((option) => option.value === selectedValue)
          )
          .filter((selectedValue) => !!selectedValue)
      : [[...options].find((option) => option.value === value)].filter(
          (selectedValue) => !!selectedValue
        );
  };

  change = (value) => {
    const {disabled, onChange} = this.props;
    if (disabled) return;
    const values = this.values();
    const updatedValues = this.isMultipleSelection()
      ? values.includes(value)
        ? [...values].filter((existingValue) => existingValue !== value)
        : [...values, value]
      : this.props.value === value
      ? null
      : value;
    onChange(updatedValues);
  };

  clearSelection = () => {
    const {disabled, onChange} = this.props;
    if (disabled) return;
    onChange(this.isMultipleSelection() ? [] : null);
  };

  onHighlight = (highlight) => this.setState({highlight});

  onUnhighlight = () => this.setState({highlight: null});

  renderContent = () => {
    const {children, multipleSelectedLabel} = this.props;
    const values = this.parsedValues();
    return (
      <InputContainer onClick={!!values.length ? null : this.showDropdown}>
        {!!children && <Label>{children}</Label>}
        {this.isMultipleSelection() && values.length > 1 ? (
          <ValueContainer>
            <Value onClick={this.showDropdown}>
              <ValueTag>{multipleSelectedLabel}</ValueTag>
            </Value>
            <Clear onClick={this.clearSelection}>
              <i className="mdi mdi-close" />
            </Clear>
          </ValueContainer>
        ) : (
          values.map((value) => (
            <ValueContainer key={value.value}>
              <Value onClick={this.showDropdown}>
                <ValueTag>{value.label}</ValueTag>
              </Value>
              <Clear onClick={() => this.change(value.value)}>
                <i className="mdi mdi-close" />
              </Clear>
            </ValueContainer>
          ))
        )}
      </InputContainer>
    );
  };

  search = (search) => {
    if (this.props.disabled) return;
    this.setState({search});
  };

  getFilteredOptions = () => {
    const {options} = this.props;
    const {search} = this.state;
    const searchLower = search.toLowerCase();
    return !!search.trim().length
      ? [...options].filter((option) =>
          option.label.toLowerCase().includes(searchLower)
        )
      : options;
  };

  render() {
    const {disabled, value} = this.props;
    const {dropdownVisible, highlight, search} = this.state;
    return (
      <DropDown
        visible={dropdownVisible && !disabled}
        trigger={this.renderContent()}
        onClose={this.hideDropdown}
        block
        flat
      >
        <SimpleMenu
          options={this.getFilteredOptions()}
          open="all"
          height={300}
          selected={this.isMultipleSelection() ? value : [value]}
          onSelect={this.change}
          highlight={highlight}
          onHighlight={this.onHighlight}
          onUnhighlight={this.onUnhighlight}
        >
          <Search>
            <Input
              value={search}
              onChange={this.search}
              placeholder="Search"
              flat
            />
          </Search>
        </SimpleMenu>
      </DropDown>
    );
  }
}

export default SelectDropdown;
