const getCategories = (services) =>
  [...services].reduce(
    (categories, service) =>
      service.removed || !service.category
        ? categories
        : !!categories.find(
            (existingCategory) => existingCategory.name === service.category
          )
        ? categories
        : [
            ...categories,
            {id: service.category, name: service.category, service},
          ],
    []
  );

export default getCategories;
