import React, {Component} from 'react';
import PropTypes from 'prop-types';

// actions
import {set as setAct} from '../../redux/actions';

// alert
import alertify from '../../../layout/lib/alertify';

// api
import updateOwnerApi from '../../api/update.api.owner';

// attributes
import emailAttr from '../../attributes/email.attribute.owner';
import nameAttr from '../../attributes/name.attribute.owner';
import phoneNumberAttribute from '../../attributes/phone_number.attribute.owner';

// error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// events
import ownerUpdatedEvent from '../../events/updated.event.owner';
import showUpdateOwnerModalEvent from '../../events/showUpdateModal.event.owner';

// owner components
import OwnerEditorModal from '../../components/OwnerEditorModal/OwnerEditorModal';

// owner preparations
import saveOwnerPreparation from '../../preparations/save.preparation.owner';

// redux
import {connect} from 'react-redux';

// subscription
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

class UpdateOwnerContainer extends Component {
  static propTypes = {
    owners: PropTypes.array,
    subscribe: PropTypes.func,
    dispatch: PropTypes.func,
  };

  state = {
    email: emailAttr(''),
    loading: false,
    name: nameAttr(''),
    owner: null,
    phone_number: phoneNumberAttribute(''),
    visible: false,
  };

  componentDidMount() {
    this.props.subscribe(showUpdateOwnerModalEvent.subscribe(this.init));
  }

  init = ({owner}) => {
    this.setState({
      email: emailAttr(owner?.email || ''),
      name: nameAttr(owner?.name || ''),
      owner,
      phone_number: phoneNumberAttribute(owner?.phone_number || ''),
      visible: true,
    });
  };

  change = (value, key) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  close = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  save = async () => {
    const {dispatch, owners} = this.props;
    const {email, loading, name, owner, phone_number} = this.state;

    if (loading) return;

    this.setState({loading: true});

    try {
      const ownerBody = await saveOwnerPreparation({email, name, phone_number});
      const updatedOwner = await updateOwnerApi(owner.id, ownerBody);
      const updatedOwners = [...owners].map((o) =>
        o.id === owner.id ? updatedOwner : o
      );
      dispatch(setAct({owners: updatedOwners}));
      ownerUpdatedEvent.publish(updatedOwner);
      this.setState({loading: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alertify({message});
      this.setState({loading: false});
    }
  };

  render() {
    const {email, loading, name, phone_number, visible} = this.state;
    return (
      <OwnerEditorModal
        email={email}
        loading={loading}
        name={name}
        onChange={this.change}
        onClose={this.close}
        onSave={this.save}
        phone_number={phone_number}
        title="Edit Owner"
        visible={visible}
      />
    );
  }
}

export default subscriptionHoc(
  connect((state) => ({owners: state.owner.owners}))(UpdateOwnerContainer)
);
