// lib
import getDriver from './get.lib.driver';

const getDriverName = ({drivers = [], driver = null} = {}) => {
  const foundDriver = driver || getDriver({drivers});
  return !!foundDriver
    ? `${foundDriver.first_name} ${foundDriver.last_name}`
    : null;
};

export default getDriverName;
