import styled from 'styled-components';

const Loader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  background: rgba(255, 255, 255, 0.8);
  font-size: 20px;
`;

export default Loader;
