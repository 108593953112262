import {TEXTAREA} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import string from '@matthahn/sally-fw/lib/type/types/string.type';

export default attribute({
  type: TEXTAREA,
  attribute: 'description',
  display: string,
  input: string,
  api: string,
  label: {
    default: 'Description',
    short: 'Description',
  },
});
