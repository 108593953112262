// Owner
import getOwnerName from '../../owner/lib/getName.lib.owner';

const branchOrOwner = ({
  branch_location,
  owner,
  george_id,
  kuiper_id,
  empty = '-',
}) =>
  (!!kuiper_id || !!george_id
    ? branch_location || getOwnerName({ownerId: owner})
    : getOwnerName({ownerId: owner})) || empty;

export default branchOrOwner;
