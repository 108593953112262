// Action constants
import {SET} from './constants';

const DEFAULT_STATE = {
  query: {
    branches: [],
    owner: null,
    page: 1,
    pages: 1,
    search: '',
    services: null,
    sort: {key: 'scheduled_at', direction: 'desc'},
    state: null,
  },
};

const ownerReducer = (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case SET: {
      return {
        ...state,
        ...data,
      };
    }

    default: {
      return state;
    }
  }
};

export default ownerReducer;
