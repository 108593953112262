import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// components
import ListItem from '../../../layout/components/ListItem/ListItem';

const SelectServiceItem = ({
  level,
  service,
  search,
  open,
  selected,
  onService,
  onAttribute,
  additionalContent,
  bold,
  icon,
}) => {
  const isServiceOpen =
    !!search.length || !![...open].find((object) => object.id === service.id);
  return (
    <Fragment>
      <ListItem
        selectable={!service.hasAttributes}
        selected={!![...selected].find((object) => object.id === service.id)}
        canBeOpened={service.hasAttributes}
        isOpen={isServiceOpen}
        title={service.name}
        content={additionalContent({service})}
        level={level}
        onClick={onService(service)}
        bold={bold}
        icon={icon}
      />
      {isServiceOpen &&
        !!service?.hasAttributes &&
        service.computedAttributes.map((attribute) => (
          <ListItem
            key={attribute.id}
            selectable
            selected={
              !![...selected].find(
                (object) =>
                  object.id === service.id &&
                  !![...object.selectedAttributes].find(
                    (subObject) => subObject.id === attribute.id
                  )
              )
            }
            title={attribute.name}
            level={level + 1}
            onClick={onAttribute({service, attribute})}
          />
        ))}
    </Fragment>
  );
};

SelectServiceItem.propTypes = {
  level: PropTypes.number,
  search: PropTypes.string,
  service: PropTypes.object,
  open: PropTypes.array,
  selected: PropTypes.array,
  onService: PropTypes.func,
  onAttribute: PropTypes.func,
  additionalContent: PropTypes.func,
  bold: PropTypes.bool,
  icon: PropTypes.string,
};

SelectServiceItem.defaultProps = {
  level: 0,
  additionalContent: () => null,
};

export default SelectServiceItem;
