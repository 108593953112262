// components
import Button from '@matthahn/sally-ui/lib/components/Button/Button';
import Card from '@matthahn/sally-ui/lib/components/Card/Card';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import CompactDataRow from '../../../layout/components/CompactDataRow/CompactDataRow';

// note components
import Note from '../../../note/components/Note/Note';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const TicketNotesCard = ({
  creating,
  notes,
  onChange,
  onCreate,
  onDelete,
  onSave,
  saving,
  text,
  user,
}) => (
  <Card>
    {(Content) => (
      <Content padding="none" noBorder>
        {notes.map((note, index) => (
          <Note
            author={note.author}
            canEdit={user === note.author}
            date={note.created_at}
            key={note.id}
            noBorder={!index}
            onDelete={onDelete(note.id)}
            onSave={onSave(note.id)}
            saving={saving.includes(note.id)}
            text={note.text}
          />
        ))}
        <CompactDataRow
          rowProps={{noBorder: !notes.length}}
          label="Add note"
          noPadding
        >
          <AttributeInput
            value={text}
            onChange={onChange}
            disabled={creating}
            placeholder="Write a note"
            resizableTextArea
            flat
          />
        </CompactDataRow>
        <CompactDataRow>
          <Button
            theme="black"
            size="small"
            loading={creating}
            onClick={onCreate}
          >
            Save
          </Button>
        </CompactDataRow>
      </Content>
    )}
  </Card>
);

TicketNotesCard.propTypes = {
  creating: PropTypes.bool,
  notes: PropTypes.array,
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  saving: PropTypes.array,
  text: PropTypes.object,
  user: PropTypes.string,
};

export default TicketNotesCard;
