import styled from 'styled-components';

const PaymentMethod = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  text-align: right;
`;

export default PaymentMethod;
