import prepare from '@matthahn/sally-fw/lib/attribute/prepare.attribute';

const updateItemCostPreparation = async ({
  isSallyVehicle = false,
  ...attributes
}) => {
  const {service_description, ...itemCostAttributes} = await prepare(
    attributes,
    {
      field: 'api',
      required: isSallyVehicle
        ? ['cost', 'quantity']
        : ['cost', 'quantity', 'service_description'],
    }
  );
  const descriptionKey = isSallyVehicle
    ? 'service_description'
    : 'custom_service';
  return {
    ...itemCostAttributes,
    [descriptionKey]: !!service_description ? service_description : '',
  };
};

export default updateItemCostPreparation;
