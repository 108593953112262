import styled from 'styled-components';

// Media
import {media} from '@matthahn/sally-ui/lib/libs/media';

const Container = styled.div`
  width: 100%;
  padding: 24px;
  gap: 24px;
  display: ${({block}) => (block ? 'block' : 'flex')};
  flex-wrap: wrap;

  ${media.xs`
    padding: 16px;
    gap: 16px;
  `}
`;

export default Container;
