import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

const VehicleModelInfo = ({year, model, make, withoutMake}) =>
  !year && !model && !make ? (
    '-'
  ) : (
    <Fragment>
      {year} {model}
      {withoutMake ? '' : ` ${make}`}
    </Fragment>
  );

VehicleModelInfo.propTypes = {
  year: PropTypes.string,
  model: PropTypes.string,
  make: PropTypes.string,
  withoutMake: PropTypes.bool,
};

VehicleModelInfo.defaultProps = {
  withoutMake: false,
};

export default VehicleModelInfo;
