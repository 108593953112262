import React from 'react';
import PropTypes from 'prop-types';
import isString from 'is-string';

// Components
import {Icon, LongPress} from '@matthahn/sally-ui';
import Circle from './components/Circle';
import Container from './components/Container';
import Content from './components/Content';
import CustomContent from './components/CustomContent';
import Header from './components/Header';
import Level from './components/Level';
import Line from './components/Line';
import MainIcon from './components/MainIcon';
import OpenIcon from './components/OpenIcon';
import SwipeToDelete from 'react-swipe-to-delete-ios';
import Text from './components/Text';

// Colors
import {red} from '@matthahn/sally-ui/lib/libs/colors';

// Device
import {isMobileOnly, isDesktop} from 'react-device-detect';

// Helpers
const swipeSize = ({title, content, rightContent}) =>
  isMobileOnly
    ? !!title && (!!content || !['', '-'].includes(rightContent))
      ? 67
      : 46
    : !!title && (!!content || !['', '-'].includes(rightContent))
    ? 81
    : 53;

const ListItem = ({
  selectable,
  selected,
  isOpen,
  canBeOpened,
  bold,
  noBorder,
  iconColor,
  icon,
  title,
  rightContent,
  content,
  additionalContent,
  line,
  withoutClickIcon,
  onClick,
  onHold,
  onSwipe,
  level,
}) => {
  const swiping = !!onSwipe && !isDesktop;
  const dom = (
    <Container
      onClick={onHold ? null : onClick}
      noBorder={noBorder}
      bold={bold}
      swiping={swiping}
    >
      <Level level={level} />
      {(selectable || canBeOpened || (!!icon && isString(icon))) && (
        <MainIcon color={iconColor}>
          {canBeOpened ? (
            <Icon icon={isOpen ? 'down' : 'right'} />
          ) : selectable ? (
            <Circle selected={selected}>
              {selected && <Icon icon="check" />}
            </Circle>
          ) : icon.includes('mdi') ? (
            <i className={`mdi ${icon}`} />
          ) : (
            <Icon icon={icon} spin={['loading', 'loading2'].includes(icon)} />
          )}
        </MainIcon>
      )}
      {!selectable && !!icon && !isString(icon) && icon}
      <Content padding={!selectable && !icon && !canBeOpened}>
        {!!title && <Header bold={bold}>{title}</Header>}
        {!!content && <Text>{content}</Text>}
        {additionalContent.map((extraContent, index) => (
          <Text key={index}>{extraContent}</Text>
        ))}
      </Content>
      {!!rightContent ? (
        <CustomContent>{rightContent}</CustomContent>
      ) : !!onClick && !withoutClickIcon && !canBeOpened && !selectable ? (
        <OpenIcon>
          <Icon icon={isOpen ? 'down' : 'right'} />
        </OpenIcon>
      ) : null}
      {!!line && <Line color={line} />}
    </Container>
  );

  const wrapHold = (holdDom) =>
    !!onHold ? (
      <LongPress time={500} onLongPress={onHold} onPress={() => {}}>
        {holdDom}
      </LongPress>
    ) : (
      holdDom
    );

  const wrapSwipe = (swipeDom) =>
    swiping ? (
      <SwipeToDelete
        onDelete={onSwipe} // required
        height={swipeSize({title, content, rightContent})} // required
        // optional
        transitionDuration={200}
        deleteWidth={80}
        deleteColor={red}
        disabled={false}
        rtl={false}
      >
        {swipeDom}
      </SwipeToDelete>
    ) : (
      swipeDom
    );

  return wrapSwipe(wrapHold(dom));
};

ListItem.propTypes = {
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  noBorder: PropTypes.bool,
  withoutClickIcon: PropTypes.bool,
  iconColor: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.node,
  content: PropTypes.node,
  additionalContent: PropTypes.node,
  rightContent: PropTypes.node,
  onClick: PropTypes.func,
  onHold: PropTypes.func,
  onSwipe: PropTypes.func,
  bold: PropTypes.bool,
  line: PropTypes.string,
  isOpen: PropTypes.bool,
  canBeOpened: PropTypes.bool,
  level: PropTypes.number,
};

ListItem.defaultProps = {
  additionalContent: [],
  level: 0,
};

export default ListItem;
