import {TEXT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import vin from '@matthahn/sally-fw/lib/type/types/vin.type';

export default attribute({
  type: TEXT,
  attribute: 'vin',
  display: vin,
  input: vin,
  api: vin,
  label: {
    default: 'VIN',
    short: 'VIN',
  },
});
