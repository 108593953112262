// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import CompactDataRow from '../../../layout/components/CompactDataRow/CompactDataRow';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const ExternalIdCard = ({editable, external_invoice_id, onSave, onChange}) => (
  <CompactDataRow
    label={external_invoice_id.label.default}
    wrapContent={editable}
    noPadding={editable}
  >
    {editable ? (
      <AttributeInput
        value={external_invoice_id}
        onChange={onChange}
        onBlur={onSave}
        flat
      />
    ) : (
      external_invoice_id.display.format() || '-'
    )}
  </CompactDataRow>
);

ExternalIdCard.propTypes = {
  editable: PropTypes.bool,
  external_invoice_id: PropTypes.object,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
};

export default ExternalIdCard;
