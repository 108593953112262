// alertify
import alertify from '../../../layout/lib/alertify';

// document components
import ImageGallery from '../../../document/components/ImageGallery/ImageGallery';

// local services
import getImagesService from './services/getImages.service';
import uploadImageService from './services/uploadImage.service';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// uuid
import {v4} from 'uuid';

class RepairImagesContainer extends Component {
  static propTypes = {
    ticket: PropTypes.object,
  };

  state = {
    images: [],
    loading: true,
    uploading: [],
    visible: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.getImages();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ticket?.id !== this.props.ticket?.id) this.getImages();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  show = () => this.setState({visible: true});

  hide = () => this.setState({visible: false});

  getImages = async () => {
    const {ticket} = this.props;
    this.setState({loading: true});
    const images = await getImagesService({ticket});
    if (!this.mounted) return;
    this.setState({images, loading: false});
  };

  uploadImage = async (image) => {
    if (!image) return;

    const {ticket} = this.props;
    const {uploading} = this.state;

    const fileId = v4();
    const updatedUploading = [...uploading, {fileId, ...image}];

    this.setState({uploading: updatedUploading});

    const {error, uploadedImage} = await uploadImageService({
      image,
      ticket,
    });

    if (!this.mounted) return;

    if (!!error) alertify({message: error});

    const images = [...this.state.images];
    const updatedImages = !!error ? images : [...images, uploadedImage];
    const newUploadingStatus = [...this.state.uploading].filter(
      (uploadingFile) => uploadingFile.fileId !== fileId
    );

    this.setState({
      images: updatedImages,
      uploading: newUploadingStatus,
    });
  };

  render() {
    const {images, loading, uploading, visible} = this.state;
    return (
      <ImageGallery
        images={images}
        loading={loading}
        onClose={this.hide}
        onUpload={this.uploadImage}
        title="Repair Images"
        uploading={uploading}
        visible={visible}
      />
    );
  }
}

export default RepairImagesContainer;
