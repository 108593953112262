import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {setPage as setPageAct} from './redux/actions';

class Page extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    children: PropTypes.node,
    page: PropTypes.string,
  };

  componentDidMount() {
    const {dispatch, page} = this.props;
    dispatch(setPageAct(page));
  }

  render() {
    return this.props.children;
  }
}

export default connect()(Page);
