import React, {Component} from 'react';
import PropTypes from 'prop-types';

// components
import Switch from '@matthahn/sally-ui/lib/components/Switch/Switch';

// local components
import Container from './components/Container';
import DropDown from './components/DropDown';
import Option from './components/Option';

// outside click
import onClickOutside from 'react-onclickoutside';

class HeaderOptions extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.any,
        label: PropTypes.node,
        isToggle: PropTypes.bool,
        isSelected: PropTypes.bool,
        onClick: PropTypes.func,
      })
    ),
    children: PropTypes.node,
  };

  static defaultProps = {
    options: [],
  };

  state = {
    visible: false,
  };

  handleClickOutside = () => this.hide();

  toggleShow = () => this.setState({visible: !this.state.visible});

  show = () => {
    if (this.state.visible) return;
    this.setState({visible: true});
  };

  hide = () => {
    if (!this.state.visible) return;
    this.setState({visible: false});
  };

  render() {
    const {children, options} = this.props;
    const {visible} = this.state;
    return (
      <Container onClick={this.toggleShow}>
        {children}
        {!!options.length && (
          <DropDown visible={visible}>
            {options.map((option, index) => (
              <Option
                key={option.key}
                border={!!index}
                onClick={option.onClick}
              >
                {option.label}
                {option.isToggle && (
                  <Switch
                    size="normal"
                    value={option.isSelected}
                    onChange={() => null}
                  />
                )}
              </Option>
            ))}
          </DropDown>
        )}
      </Container>
    );
  }
}

export default onClickOutside(HeaderOptions);
