const SETTINGS = (settings = {}) => ({
  accessibility: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 30000,
  dots: false,
  draggable: false,
  fade: true,
  infinite: true,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 5000,
  ...settings,
});

export default SETTINGS;
