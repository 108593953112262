import React, {Component} from 'react';
import PropTypes from 'prop-types';

// alertify
import alertify from '../../../layout/lib/alertify';

// lib
import attachServiceToItem from '../../../item/lib/attachService.lib.item';

// redux
import {connect} from 'react-redux';

// tickets api
import listTicketsApi from '../../../ticket/api/list.api.ticket';

// vehicle components
import VehicleSummaryModal from '../../components/VehicleSummaryModal/VehicleSummaryModal';

// vehicle lib
import checkCalamp from '../../lib/checkCalamp.lib.vehicle.js';
import checkSpireon from '../../lib/checkSpireon.lib.vehicle.js';
import milesSinceLastSeen from '../../lib/milesSinceLastSeen.lib.vehicle';
import shouldAlertMilesSinceLastSeen from '../../lib/shouldAlertMilesSinceLastSeen.lib.vehicle';
import shouldChangeOil from '../../lib/shouldChangeOil.lib.vehicle';

class VehicleSummaryContainer extends Component {
  static propTypes = {
    georgeVehicle: PropTypes.object,
    spireon: PropTypes.object,
    ticket: PropTypes.object,
  };

  state = {
    loading: true,
    historicTickets: [],
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ticket?.id !== this.props.ticket?.id) this.init();
  }

  init = async () => {
    await this.getTickets();
  };

  getTickets = async () => {
    const {ticket} = this.props;

    this.setState({loading: true});

    try {
      const {results} = await listTicketsApi({
        vehicle: ticket?.vehicle?.id,
        ordering: '-checkedin_at',
        limit: 10,
      });
      this.setState({
        loading: false,
        historicTickets: [...results],
      });
    } catch (error) {
      this.setState({loading: true});
    }
  };

  getItems = (ticket) => {
    const {services} = this.props;
    return !!ticket?.items?.length
      ? [...ticket.items]
          .map((item) => attachServiceToItem({item, services}))
          .sort((a, b) => a.id - b.id)
      : [];
  };

  printTicket = (print) => () => {
    const {loading} = this.state;
    if (loading)
      return alertify({message: 'Data is still loading. Try again later.'});
    print();
  };

  historicTickets = () => [...this.state.historicTickets].slice(0, 3);

  render() {
    const {ticket, georgeVehicle, spireon: spireonStatus} = this.props;
    const calamp = !!georgeVehicle
      ? checkCalamp({vehicle: georgeVehicle})
      : null;
    const spireon = !!georgeVehicle
      ? checkSpireon({vehicle: georgeVehicle, spireon: spireonStatus})
      : null;
    return (
      <VehicleSummaryModal
        calamp={calamp}
        getItems={this.getItems}
        historicTickets={this.historicTickets()}
        milesSinceLastSeen={milesSinceLastSeen({vehicle: georgeVehicle})}
        notes={ticket?.notes}
        onPrintTicket={this.printTicket}
        shouldAlertMilesSinceLastSeen={shouldAlertMilesSinceLastSeen({
          vehicle: georgeVehicle,
        })}
        shouldChangeOil={shouldChangeOil({vehicle: georgeVehicle})}
        spireon={spireon}
        ticket={ticket}
      />
    );
  }
}

export default connect((state) => ({services: state.spotlight.services}))(
  VehicleSummaryContainer
);
