import React from 'react';

// layout containers
import Page from '../../../layout/containers/Page/Page';

// local constants
import PAGE from './page';

// ticket containers
import TasksContainer from '../../containers/TasksContainer/TasksContainer';

const TasksPage = () => (
  <Page page={PAGE}>
    <TasksContainer />
  </Page>
);

export default TasksPage;
