import React, {Component} from 'react';
import PropTypes from 'prop-types';

// alertify
import alertify from '../../../layout/lib/alertify';

// api
import api from '@matthahn/sally-fw/lib/api/lib/getEverything.lib.api';
import createTicketApi from '../../../ticket/api/create.api.ticket';
import listTicketsApi from '../../../ticket/api/list.api.ticket';

// components
import PageLoader from '../../../layout/components/PageLoader/PageLoader';

// entry services
import getEntriesService from '../../../entry/service/get.service.entry';

// error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// home
import homeRoute from '../../../dashboard/pages/HomePage/route';

// item lib
import attachServiceToItem from '../../../item/lib/attachService.lib.item';

// redux
import {connect} from 'react-redux';

// router
import {withRouter} from 'react-router-dom';

// task api
import listTasksApi from '../../../task/api/list.api.task';

// task events
import showCreateTaskModalEvent from '../../../task/events/showCreateTaskModal.event.task';

// task permissions
import createTaskPermission from '../../../task/permissions/create.permission.task';
import readTaskPermission from '../../../task/permissions/read.permission.task';

// task route
import taskRoute from '../../../task/pages/TaskPage/route';

// ticket permissions
import createTicketPermission from '../../../ticket/permissions/create.permission.ticket';
import readTicketPermission from '../../../ticket/permissions/read.permission.ticket';

// ticket route
import ticketRoute from '../../../ticket/pages/TicketPage/route';

// vehicle api
import getVehicleByIdApi from '../../../vehicle/api/getById.api.vehicle';

// vehicle components
import VehicleOverview from '../../components/VehicleOverview/VehicleOverview';

// vehicle events
import setVehicleHistoryIdEvent from '../../../vehicle/events/setVehicleHistoryId.event.vehicle';
import showVehicleHistoryEvent from '../../../vehicle/events/showVehicleHistory.event.vehicle';

// vehicle origin
import getVehicleOrigin from '../../../vehicle/origin/lib/getOrigin.lib.origin.vehicle';

class VehicleActionContainer extends Component {
  static propTypes = {
    vehicleId: PropTypes.string,
    services: PropTypes.array,
    history: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    loading: true,
    activeTicket: null,
    vehicle: null,
    openTickets: [],
    openTasks: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.vehicleId !== this.props.vehicleId) return this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = () => {
    this.act();
  };

  act = async () => {
    const {vehicleId, history} = this.props;
    this.setState({loading: true});
    try {
      const vehicle = await getVehicleByIdApi(vehicleId);
      vehicle.origin = getVehicleOrigin(vehicle);
      setVehicleHistoryIdEvent.publish({vehicle});
      const [{data: openTickets}, {data: openTasks}] = await Promise.all([
        readTicketPermission()
          ? api(listTicketsApi, {
              vehicle: vehicleId,
              ordering: '-created_at',
              approved: false,
            })
          : {data: []},
        readTaskPermission()
          ? api(listTasksApi, {
              vehicle: vehicleId,
              ordering: '-created_at',
              status: 'open',
            })
          : {data: []},
      ]);
      if (!this.mounted) return;
      const activeTicket = !!openTickets.length ? {...openTickets[0]} : null;
      this.setState({
        loading: false,
        activeTicket,
        vehicle,
        openTickets,
        openTasks,
      });
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alertify({message});
      history.replace(homeRoute());
    }
  };

  createTicket = async () => {
    const {loading, vehicle} = this.state;
    if (loading) return;
    if (!createTicketPermission())
      return alertify({
        message: 'You do not have permission to create a ticket',
      });
    this.setState({loading: true});
    try {
      const ticket = await createTicketApi({vehicle: vehicle.id});
      if (!this.mounted) return;
      this.props.history.push(ticketRoute(ticket.id));
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alertify({message});
      this.setState({loading: false});
    }
  };

  createTask = () => {
    if (!createTaskPermission())
      return alertify({
        message: 'You do not have permission to schedule anything',
      });
    const {vehicle} = this.state;
    showCreateTaskModalEvent.publish({vehicle});
  };

  entries = () => {
    const {vehicle, openTasks, openTickets} = this.state;
    return getEntriesService({
      tasks: openTasks,
      tickets: openTickets,
      vehicles: [vehicle],
    });
  };

  getItems = (ticket) => {
    const {services} = this.props;
    return !!ticket
      ? [...ticket.items]
          .map((item) => attachServiceToItem({item, services}))
          .sort((a, b) => a.id - b.id)
      : [];
  };

  ticketExists = () => !![...this.entries()].find(({isTask}) => !isTask);

  goToEntry = (entry) =>
    entry.isTask ? this.goToTask(entry) : this.goToTicket(entry);

  goToTicket = (ticket) => () =>
    this.props.history.push(ticketRoute(ticket.id));

  goToTask = (task) => () => this.props.history.push(taskRoute(task.id));

  openHistory = () => showVehicleHistoryEvent.publish();

  render() {
    const {loading, vehicle, activeTicket} = this.state;
    return loading || !vehicle ? (
      <PageLoader />
    ) : (
      <VehicleOverview
        activeTicket={activeTicket}
        canCreateTask={createTaskPermission()}
        canCreateTicket={createTicketPermission()}
        canReadTask={readTaskPermission()}
        canReadTicket={readTicketPermission()}
        vehicle={vehicle}
        entries={this.entries()}
        ticketExists={this.ticketExists()}
        getItems={this.getItems}
        onEntry={this.goToEntry}
        onTicket={this.goToTicket}
        onCreateTask={this.createTask}
        onCreateTicket={this.createTicket}
        onHistory={this.openHistory}
      />
    );
  }
}

export default withRouter(
  connect((state) => ({
    services: state.spotlight.services,
  }))(VehicleActionContainer)
);
