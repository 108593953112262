// date fns
import {format, isDate, parse, endOfDay} from 'date-fns';

// date lib
import toUtcString from './toUtcString.lib.date';

const setEndOfDay = ({
  date,
  inputDateFormat = 'yyyy-MM-dd',
  outputDateFormat = "yyyy-MM-dd'T'HH:mm:ssXXX",
  toUtc = true,
}) => {
  const parsedDate = endOfDay(
    isDate(date) ? date : parse(date, inputDateFormat, new Date())
  );
  return toUtc ? toUtcString(parsedDate) : format(parsedDate, outputDateFormat);
};

export default setEndOfDay;
