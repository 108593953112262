// documents api
import listDocumentsApi from '../../../../document/api/list.api.document';

// ticket documents
import externalInvoiceDocument from '../../../documents/externalInvoice.document.ticket';

const getExternalInvoiceService = async ({
  fileType = externalInvoiceDocument,
  getDocuments = listDocumentsApi,
  ticket,
}) => {
  try {
    const {results} = await getDocuments({
      ticket: ticket.id,
      type: fileType,
      ordering: '-id',
      limit: 1,
    });
    return results?.[0] || null;
  } catch (error) {
    return null;
  }
};

export default getExternalInvoiceService;
