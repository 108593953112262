import {SET} from './constants';

export const set = (data = {}) => ({
  type: SET,
  data,
});
export const setQuery =
  (data = {}) =>
  (dispatch, getState) => {
    const query = {
      ...getState().ticket.query,
      ...data,
    };
    dispatch({
      type: SET,
      data: {query},
    });
  };
