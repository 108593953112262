const downloadCsv = ({content, fileName}) => {
  const a = document.createElement('a');
  a.style = 'display: none';
  document.body.appendChild(a);

  const url = encodeURI(content);
  a.href = url;
  a.download = fileName;
  a.click();

  document.body.removeChild(a);
};

export default downloadCsv;
