import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Alerts
import alertify from '../../../layout/lib/alertify';

// Api
import api from '@matthahn/sally-fw/lib/api/lib/getEverything.lib.api';
import listMechanicsApi from '../../api/list.api.mechanic';

// Error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// Sockets
import mechanicCreatedSocket from '../../sockets/created.socket.mechanic';
import mechanicDeletedSocket from '../../sockets/deleted.socket.mechanic';
import mechanicUpdatedSocket from '../../sockets/updated.socket.mechanic';

// Subscription
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

class MechanicFetchContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    mechanics: PropTypes.array,
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.init();
    this.props.subscribe(
      mechanicCreatedSocket.subscribe(this.mechanicCreated),
      mechanicDeletedSocket.subscribe(this.mechanicDeleted),
      mechanicUpdatedSocket.subscribe(this.mechanicUpdated)
    );
  }

  init = async () => {
    const {dispatch} = this.props;
    dispatch(setAct({loading: true}));
    try {
      const {data: mechanics} = await api(listMechanicsApi, {
        ordering: '-created_at',
      });
      dispatch(setAct({loading: false, mechanics}));
    } catch (error) {
      const {message} = parseError(error);
      alertify({message});
      dispatch(setAct({loading: false}));
    }
  };

  mechanicCreated = (mechanic) => {
    const {loading, mechanics, dispatch} = this.props;
    if (loading) return;
    const newMechanics = [...mechanics, mechanic];
    dispatch(setAct({mechanics: newMechanics}));
  };

  mechanicUpdated = (mechanic) => {
    const {loading, mechanics, dispatch} = this.props;
    if (loading) return;
    const newMechanics = [...mechanics].map((searchingMechanic) =>
      searchingMechanic.id === mechanic.id ? mechanic : searchingMechanic
    );
    dispatch(setAct({mechanics: newMechanics}));
  };

  mechanicDeleted = (mechanic) => {
    const {loading, mechanics, dispatch} = this.props;
    if (loading) return;
    const newMechanics = [...mechanics].filter(
      (searchingMechanic) => searchingMechanic.id !== mechanic.id
    );
    dispatch(setAct({mechanics: newMechanics}));
  };

  render() {
    return <Fragment />;
  }
}

export default subscriptionHoc(
  connect((state) => ({
    loading: state.mechanic.loading,
    mechanics: state.mechanic.mechanics,
  }))(MechanicFetchContainer)
);
