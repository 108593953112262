import {NUMBER} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import amount from '@matthahn/sally-fw/lib/type/types/amount.type';
import formattedNumber from '@matthahn/sally-fw/lib/type/types/formattedNumber.type';
import number from '@matthahn/sally-fw/lib/type/types/number.type';

export default attribute({
  type: NUMBER,
  attribute: 'calamp_instalation_mileage',
  display: amount,
  input: formattedNumber,
  api: number,
  label: {
    default: 'CalAmp Installation Mileage',
    short: 'Installation Mileage',
  },
});
