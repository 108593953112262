import styled from 'styled-components';

const OpenIcon = styled.div`
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  text-align: center;
  padding: 10px 0px;
  color: #c4c4c6;
  font-size: 18px;
  font-weight: 700;
`;

export default OpenIcon;
