import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';

const attachService = ({taskItem, services}) => {
  const service =
    [...services].find(
      (serviceToFind) => serviceToFind.id === fkOrId(taskItem.service)
    ) || null;
  const attribute =
    !!service && !!taskItem.attribute
      ? [...service.attributes].find(
          (attributeToFind) => attributeToFind.id === fkOrId(taskItem.attribute)
        )
      : null;
  return {...taskItem, service, attribute};
};

export default attachService;
