import styled from 'styled-components';

// Colors
import {grey} from '@matthahn/sally-ui/lib/libs/colors';

const SearchContainer = styled.div`
  width: 100%;
  background: ${grey};
`;

export default SearchContainer;
