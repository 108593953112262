import styled from 'styled-components';

// colors
import {grey, darkGrey} from '@matthahn/sally-ui/lib/libs/colors';

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid ${grey};
  color: ${darkGrey};

  & i {
    font-size: 30px;
  }
`;

export default Content;
