// ticket attributes
import displayPriorityAttribute from '../attributes/display_priority.attribute.ticket';

const getPriorityLabel = ({
  priority,
  priorities = displayPriorityAttribute.additional.options,
}) =>
  [...priorities].find(({value}) => `${value}` === `${priority}`)?.label ||
  null;

export default getPriorityLabel;
