const permissionTree = {
  Tickets: [
    'approve_third_party_tickets_permission',
    'create_tickets_permission',
    'destroy_tickets_permission',
    'read_tickets_permission',
    'unapprove_tickets_permission',
    'update_tickets_permission',
  ],
  Tasks: [
    'create_tasks_permission',
    'destroy_tasks_permission',
    'read_tasks_permission',
    'update_tasks_permission',
  ],
  User: ['user_permissions'],
  Permissions: [
    // 'permission_control_permission',
    'restrict_to_local_branch_permission',
  ],
};

export default permissionTree;
