// lib
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';

// redux store
import store from '../../redux/store';

const getBranchDealerships = ({
  branch,
  dealerships = store.getState().branch.dealerships,
}) =>
  !!branch
    ? [...dealerships].filter(
        (dealership) => `${fkOrId(dealership.branch)}` === `${fkOrId(branch)}`
      )
    : [];

export default getBranchDealerships;
