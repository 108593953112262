// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, Fragment} from 'react';

// vehicle components
import Calamp from '../../components/Calamp/Calamp';
import Spireon from '../../components/Spireon/Spireon';
import VehicleEquipment from '../../components/VehicleEquipment/VehicleEquipment';

class VehicleEquipmentContainer extends Component {
  static propTypes = {
    children: PropTypes.func,
    onSync: PropTypes.func,
    ticket: PropTypes.object,
    vehicle: PropTypes.object,
  };

  static defaultProps = {
    children: () => null,
  };

  state = {
    visible: false,
  };

  show = () => this.setState({visible: true});

  hide = () => this.setState({visible: false});

  clickAndHide = (fn) => () => {
    this.hide();
    fn();
  };

  generateStatus = ({vehicle, calamp, spireonStatus, loadingSpireon, dom}) => {
    const statuses = [calamp.status, spireonStatus.status];
    const completed =
      statuses.every((status) => status === 'done') && !vehicle?.repair_etc;
    const status = loadingSpireon
      ? 'pending'
      : completed
      ? 'completed'
      : 'warning';

    return {
      status,
      onClick: status === 'pending' ? () => {} : this.show,
      icon:
        status === 'pending'
          ? 'loading2'
          : status === 'completed'
          ? 'check'
          : 'warning',
      label: 'Vehicle',
      dom,
    };
  };

  entries = ({
    calamp,
    loadingSpireon,
    onCalampClick,
    onSpireonClick,
    spireonStatus,
  }) => {
    return [
      {
        key: 'calamp',
        label: 'Calamp',
        icon: calamp.status === 'done' ? 'check' : 'warning',
        onClick: this.clickAndHide(onCalampClick),
      },
      {
        key: 'spireon',
        label: 'Spireon',
        icon: loadingSpireon
          ? 'loading2'
          : spireonStatus.status === 'done'
          ? 'check'
          : 'warning',
        onClick: this.clickAndHide(onSpireonClick),
      },
    ];
  };

  render() {
    const {children, vehicle, onSync} = this.props;
    const {visible} = this.state;
    return (
      <Calamp vehicle={vehicle} onSync={onSync('georgeVehicle')}>
        {({calamp, onClick: onCalampClick}) => (
          <Spireon onSync={onSync} vehicle={vehicle}>
            {({
              loading: loadingSpireon,
              spireon,
              spireonStatus,
              onClick: onSpireonClick,
            }) => (
              <Fragment>
                {children(
                  this.generateStatus({
                    vehicle,
                    calamp,
                    loadingSpireon,
                    spireon,
                    spireonStatus,
                    dom: (
                      <VehicleEquipment
                        entries={this.entries({
                          calamp,
                          loadingSpireon,
                          onCalampClick,
                          onSpireonClick,
                          spireon,
                          spireonStatus,
                          vehicle,
                        })}
                        visible={visible}
                        onClose={this.hide}
                      />
                    ),
                  })
                )}
              </Fragment>
            )}
          </Spireon>
        )}
      </Calamp>
    );
  }
}

export default VehicleEquipmentContainer;
