import React, {Component} from 'react';
import PropTypes from 'prop-types';

// attributes
import ticketIdAttr from '../../attributes/ticketId.attribute.ticket';

// components
import GoToTicketModal from '../../components/GoToTicketModal/GoToTicketModal';

// events
import showGoToTicketModalEvent from '../../events/showGoToTicketModal.event.ticket';

// router
import {withRouter} from 'react-router-dom';

// subscription
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// ticket
import ticketRoute from '../../pages/TicketPage/route';

class GoToTicketContainer extends Component {
  static propTypes = {
    history: PropTypes.object,
    subscribe: PropTypes.func,
  };

  state = {
    visible: false,
    ticketId: ticketIdAttr(''),
  };

  componentDidMount() {
    this.props.subscribe(showGoToTicketModalEvent.subscribe(this.show));
  }

  show = () => {
    this.setState({visible: true, ticketId: ticketIdAttr('')});
  };

  hide = () => this.setState({visible: false});

  onChange = (value, key) => this.setState({[key]: value});

  go = () => {
    const {history} = this.props;
    const {visible, ticketId} = this.state;
    if (!visible || !ticketId.api.format().trim().length) return;
    this.hide();
    history.push(ticketRoute(ticketId.api.format()));
  };

  render() {
    const {visible, ticketId} = this.state;
    return (
      <GoToTicketModal
        visible={visible}
        ticketId={ticketId}
        onChange={this.onChange}
        onClose={this.hide}
        onGo={this.go}
      />
    );
  }
}

export default withRouter(subscriptionHoc(GoToTicketContainer));
