import styled from 'styled-components';

// colors
import {darkGrey} from '@matthahn/sally-ui/lib/libs/colors';

// media
import {media} from '@matthahn/sally-ui/lib/libs/media';

const PriceContainer = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${darkGrey};

  ${media.xs`
    width: 130px;
  `}
`;

export default PriceContainer;
