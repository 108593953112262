import styled from 'styled-components';

// Colors
import {white} from '@matthahn/sally-ui/lib/libs/colors';

const Container = styled.div`
  width: 100%;
  ${'' /* border-radius: 10px; */}
  background: ${white};
  cursor: pointer;
  overflow: hidden;
  position: relative;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
`;

export default Container;
