import {SET} from './constants';

export const set = (data = {}) => ({
  type: SET,
  data,
});

export const setPage = ({
  actions = [],
  headerLineColor = 'white',
  pageLabelLeft = null,
  pageLabelRight = null,
  pageName = null,
  showNavigation = true,
}) =>
  set({
    actions,
    headerLineColor,
    pageLabelLeft,
    pageLabelRight,
    pageName,
    showNavigation,
  });
