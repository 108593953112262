import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

// item attributes
import costAttribute from '../../../item/attributes/cost.attribute.item';
import quantityAttribute from '../../../item/attributes/quantity.attribute.item';
import serviceDescriptionAttribute from '../../../item/attributes/service_description.attribute.item';

// components
import {Notification, Row, Column} from '@matthahn/sally-ui';
import SelectServiceModal from '../../components/SelectServiceModal/SelectServiceModal';
import NotificationContainer from '../../../layout/components/NotificationContainer/NotificationContainer';

// device
import {isMobileOnly} from 'react-device-detect';

// events
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';
import serviceSelectedModal from '../../events/serviceSelected.event.service';
import showSelectServiceModal from '../../events/showSelectServiceModal.event.service';

// layout
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// lib
import sortByString from '@matthahn/sally-fw/lib/lib/sortByString';
import addOrRemoveObjectFromArray from '../../../lib/addOrRemoveObjectFromArray.lib';
import isObjectInArray from '../../../lib/isObjectInArray.lib';
import getCategories from '../../lib/getCategories.lib.service';
import getServicesByCategory from '../../lib/getServicesByCategory.lib.service';
import numberOfSelectedServices from './lib/numberOfSelectedServices';
import foundBySearch from '../../../lib/foundBySearch.lib';

// redux
import {connect} from 'react-redux';

// service lib
import isDiscountService from '../../lib/isDiscount.lib.service';

// uuid
import {v4} from 'uuid';

class SelectServiceContainer extends Component {
  static propTypes = {
    descriptionOnly: PropTypes.bool,
    services: PropTypes.array,
    subscribe: PropTypes.func,
  };

  static INITIAL_STATE = {
    visible: false,
    otherVisible: false,
    search: '',

    open: [],
    selected: [],

    ignoreDiscount: false,

    selectedService: null,
    cost: costAttribute(''),
    quantity: quantityAttribute('1'),
    service_description: serviceDescriptionAttribute(''),
  };

  state = {
    ...this.constructor.INITIAL_STATE,
  };

  inputDom = null;

  componentDidMount() {
    this.props.subscribe(showSelectServiceModal.subscribe(this.show));
  }

  show = ({ignoreDiscount = false} = {}) => {
    this.setState({
      ...this.constructor.INITIAL_STATE,
      visible: true,
      ignoreDiscount,
    });
  };

  hide = () => {
    this.setState({visible: false});
  };

  hideOtherModal = () => this.setState({otherVisible: false});

  showDescriptionInput = ({service = null} = {}) => {
    this.setState({
      otherVisible: true,
      selectedService: service,
      cost: costAttribute(''),
      quantity: quantityAttribute('1'),
      service_description: serviceDescriptionAttribute(''),
    });
    if (this.inputDom)
      setTimeout(() => {
        this.inputDom.focus();
      }, 300);
  };

  selectCategory =
    ({other = false, ...category}) =>
    () => {
      if (other) return this.showDescriptionInput();
      this.setState({
        open: addOrRemoveObjectFromArray({
          array: this.state.open,
          newObject: category,
        }),
      });
    };

  selectService =
    (service) =>
    ({ignoreRequiredDescription = false} = {}) => {
      const attributes = !!service?.attributes
        ? [...service.attributes].filter(({removed}) => !removed)
        : [];

      const alreadySelected = isObjectInArray({
        array: this.state.selected,
        value: service.id,
      });

      if (!!attributes.length && !!service.category)
        return this.setState({
          open: addOrRemoveObjectFromArray({
            array: this.state.open,
            newObject: service,
          }),
        });

      if (
        !alreadySelected &&
        !ignoreRequiredDescription &&
        service.requires_description
      )
        return this.showDescriptionInput({service});

      this.setState({
        selected: addOrRemoveObjectFromArray({
          array: this.state.selected,
          newObject: service,
        }),
      });
    };

  selectAttribute =
    ({service, attribute}) =>
    () => {
      const foundService = [...this.state.selected].find(
        ({id}) => id === service.id
      );
      const newService = !!foundService
        ? {
            ...foundService,
            selectedAttributes: addOrRemoveObjectFromArray({
              array: foundService.selectedAttributes,
              newObject: attribute,
            }),
          }
        : {...service, selectedAttributes: [attribute]};
      this.setState({
        selected: !foundService
          ? [...this.state.selected, newService]
          : !newService.selectedAttributes.length
          ? [...this.state.selected].filter(
              (object) => object.id !== service.id
            )
          : [...this.state.selected].map((object) =>
              object.id === service.id ? newService : object
            ),
      });
    };

  onSearch = (search) => this.setState({search});

  onChange = (value, key) => this.setState({[key]: value});

  selectOther = () => {
    const {descriptionOnly} = this.props;
    const {selectedService, cost, quantity, service_description} = this.state;
    const content = service_description.api.format();
    const parsedCost = cost.api.format() || '';
    const parsedQuantity = quantity.api.format();
    if (!content.trim().length) return;
    if (!descriptionOnly && !parsedQuantity) return;

    const service = !!selectedService
      ? isDiscountService(selectedService)
        ? {
            ...selectedService,
            content,
            quantity: parsedQuantity,
            cost: parsedCost,
          }
        : {...selectedService, content, quantity: parsedQuantity}
      : {
          id: v4(),
          other: true,
          content,
          quantity: parsedQuantity,
          cost: parsedCost,
        };
    this.setState({otherVisible: false});
    this.selectService(service)({ignoreRequiredDescription: true});
  };

  getServices = () => {
    const {services} = this.props;
    const {search, ignoreDiscount} = this.state;
    const filteredServices = [...services]
      .filter(
        (service) =>
          !service.removed && (!ignoreDiscount || !isDiscountService(service))
      )
      .map((service) => ({
        ...service,
        hasAttributes: !!service.attributes.length,
        attributes: [...service.attributes].filter(({removed}) => !removed),
      }));
    const searchedServices = !!search.trim().length
      ? [...filteredServices].filter(({name, category, attributes}) =>
          foundBySearch({
            search,
            fields: [
              name,
              category,
              ...attributes.map((attribute) => attribute.name),
            ],
          })
        )
      : filteredServices;
    return searchedServices;
  };

  categories = () =>
    getCategories(this.getServices())
      .map((category) => ({
        ...category,
        services: this.services({
          category: category.id,
          categoryMatch: foundBySearch({
            search: this.state.search,
            fields: [category.name],
          }),
        }),
      }))
      .filter(({services}) => !!services.length)
      .sort(sortByString({key: 'name', direction: 'asc'}));

  priorityServices = () => {
    return [...this.getServices()]
      .filter((service) => !!service.display_priority)
      .map((service) => ({
        ...service,
        computedAttributes: this.attributes({service}),
      }))
      .sort(sortByString({key: 'name', direction: 'asc'}));
  };

  servicesWithoutCategories = () => {
    return [...this.getServices()]
      .filter(({category}) => !category)
      .map(({attributes, hasAttributes, ...service}) => ({
        ...service,
        computedAttributes: this.attributes({service}),
      }))
      .sort(sortByString({key: 'name', direction: 'asc'}));
  };

  services = ({
    services = this.getServices(),
    category = this.state.category,
    categoryMatch = false,
  } = {}) =>
    getServicesByCategory({services, category})
      .map((service) => ({
        ...service,
        computedAttributes: this.attributes({
          service,
          serviceMatch:
            categoryMatch ||
            foundBySearch({
              search: this.state.search,
              fields: [service.name],
            }),
        }),
      }))
      .filter(
        ({computedAttributes, removed, hasAttributes}) =>
          !removed &&
          (categoryMatch || !hasAttributes || !!computedAttributes.length)
      )
      .sort(sortByString({key: 'name', direction: 'asc'}));

  otherServices = ({
    services = this.state.selected,
    search = this.state.search,
  } = {}) => {
    const preparedServices = [...services].filter(({other}) => other);
    return (
      !!search.trim().length
        ? [...preparedServices].filter(({content}) =>
            foundBySearch({search, fields: [content]})
          )
        : preparedServices
    ).sort(sortByString({key: 'content', direction: 'asc'}));
  };

  attributes = ({
    rawSearch = this.state.search,
    service = this.state.service,
    serviceMatch = false,
  } = {}) => {
    const search = rawSearch.toLowerCase();
    const attributes = !service?.attributes
      ? []
      : [...service?.attributes].filter(({removed}) => !removed);
    return (
      !serviceMatch && !!search.trim().length
        ? [...attributes].filter(({name}) =>
            name.toLowerCase().includes(search)
          )
        : attributes
    ).sort(sortByString({key: 'name', direction: 'asc'}));
  };

  onInputDom = (ref) => {
    this.inputDom = ref;
  };

  confirmSelected = () => {
    const {selected} = this.state;
    serviceSelectedModal.publish(selected);
    this.hide();
  };

  render() {
    const {descriptionOnly} = this.props;
    const {
      visible,
      search,
      otherVisible,
      selectedService,
      cost,
      quantity,
      service_description,
      open,
      selected,
    } = this.state;
    const isDiscount = isDiscountService(selectedService);
    return (
      <Fragment>
        <SelectServiceModal
          categories={this.categories()}
          closable={visible && !otherVisible}
          descriptionOnly={descriptionOnly}
          numberOfSelectedServices={numberOfSelectedServices(selected)}
          onAttribute={this.selectAttribute}
          onCategory={this.selectCategory}
          onClose={this.hide}
          onConfirm={this.confirmSelected}
          onSearch={this.onSearch}
          onService={this.selectService}
          open={open}
          otherServices={this.otherServices()}
          priorityServices={this.priorityServices()}
          search={search}
          selected={selected}
          servicesWithoutCategories={this.servicesWithoutCategories()}
          title="Add items"
          visible={visible && !otherVisible}
        />
        <NotificationContainer visible={otherVisible} big={!isMobileOnly}>
          <Notification
            title={isDiscount ? 'Discount' : 'Service'}
            primary={{label: 'Cancel', onClick: this.hideOtherModal}}
            secondary={{label: 'Add', onClick: this.selectOther}}
          >
            <Row margin={!descriptionOnly || isDiscount}>
              <Column>
                <AttributeInput
                  inputRef={this.onInputDom}
                  value={service_description}
                  onChange={this.onChange}
                  placeholder={
                    isDiscount
                      ? 'Insert discount description'
                      : 'Insert service description'
                  }
                  size="xl"
                />
              </Column>
            </Row>
            {(isDiscount || (!descriptionOnly && !selectedService)) && (
              <Row margin={!descriptionOnly && !isDiscount}>
                <Column>
                  <AttributeInput
                    value={cost}
                    onChange={this.onChange}
                    placeholder="Price"
                    size="xl"
                    preValue="$"
                  />
                </Column>
              </Row>
            )}
            {!descriptionOnly && !isDiscount && (
              <Row>
                <Column>
                  <AttributeInput
                    value={quantity}
                    onChange={this.onChange}
                    placeholder="Quantity"
                    size="xl"
                    postValue="x"
                  />
                </Column>
              </Row>
            )}
          </Notification>
        </NotificationContainer>
      </Fragment>
    );
  }
}

export default subscriptionHoc(
  connect((state) => ({services: state.spotlight.services}))(
    SelectServiceContainer
  )
);
