// task states
import createTaskState from '../state/create.state.task';

const scheduled = createTaskState({
  state: 'scheduled',
  label: 'Scheduled',
  color: 'green',
});

export default scheduled;
