import styled from 'styled-components';

// colors
import {
  lightBlueGrey,
  black,
  red,
  white,
} from '@matthahn/sally-ui/lib/libs/colors';

const Date = styled.div`
  width: 180px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: ${({highlight}) => (highlight ? red : lightBlueGrey)};
  color: ${({highlight}) => (highlight ? white : black)};
  text-align: right;
  font-size: 36px;
  font-weight: 700;
  ${'' /* border-radius: 10px; */}
`;

export default Date;
