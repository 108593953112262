// document api
import createDocumentApi from '../../../../document/api/create.api.document';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// ticket documents
import externalInvoiceDocument from '../../../documents/externalInvoice.document.ticket';

// ticket lib
import generateExternalInvoiceFileName from '../../../lib/generateExternalInvoiceFileName.lib.ticket';

const uploadExternalInvoiceService = async ({
  file,
  fileType = externalInvoiceDocument,
  ticket,
  uploadDocument = createDocumentApi,
}) => {
  const fileName = generateExternalInvoiceFileName({ticket, file});

  const formMeta = new FormData();

  formMeta.append('doc_file', file, fileName);
  formMeta.append('name', fileName);
  formMeta.append('use_ocr', false);
  formMeta.append('ticket', ticket.id);
  formMeta.append('vehicle', ticket.vehicle.id);
  // if (ticket.vehicle.george_id) formMeta.append('georgeVehicle', ticket.vehicle.george_id);

  formMeta.append('type', fileType);

  try {
    const uploadedExternalInvoice = await uploadDocument(formMeta);
    return {error: null, uploadedExternalInvoice};
  } catch (error) {
    const {message} = parseError(error);
    return {error: message, uploadedExternalInvoice: null};
  }
};

export default uploadExternalInvoiceService;
