// components
import TextColor from '@matthahn/sally-ui/lib/components/TextColor/TextColor';

// layout components
import ItemListLayout from '../../../layout/components/ItemListLayout/ItemListLayout';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const ServicesList = ({
  actions = [],
  columns,
  filters = [],
  loading,
  onService,
  onSearch,
  search,
  services,
}) => (
  <ItemListLayout
    actions={actions}
    columns={columns}
    filters={filters}
    onSearch={onSearch}
    search={search}
    searchPlaceholder="Search Services"
    tableProps={{
      loading,
    }}
  >
    {(TableRow) =>
      services.map((service) => (
        <TableRow key={service.id} onClick={onService(service)}>
          {(TableCol) => [
            <TableCol key="name">{service.name}</TableCol>,
            <TableCol key="category">{service.category}</TableCol>,
            <TableCol key="cost">
              <TextColor theme={!!service.credit ? 'green' : 'black'}>
                $
                {!!service.credit
                  ? `(${service.default_cost})`
                  : service.default_cost}
              </TextColor>
            </TableCol>,
          ]}
        </TableRow>
      ))
    }
  </ItemListLayout>
);

ServicesList.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.array,
  filters: PropTypes.array,
  loading: PropTypes.bool,
  onSearch: PropTypes.func,
  search: PropTypes.string,
  services: PropTypes.array,
};

export default ServicesList;
