// api
import listOwnersApi from '../../api/list.api.owner';

// csv services
import downloadCsvService from '../../../csv/services/download.service.csv';

// events
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// local constants
import columns from './constants/columns.constant';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// router
import {withRouter} from 'react-router-dom';

// owner components
import OwnersList from '../../components/OwnersList/OwnersList';

// owner events
import showCreateOwnerModalEvent from '../../events/showCreateModal.event.owner';
import showUpdateOwnerModalEvent from '../../events/showUpdateModal.event.owner';

// owner redux actions
import {setQuery as setOwnersQuery} from '../../redux/actions';

// owner sockets
import ownerCreatedSocket from '../../sockets/created.socket.owner';
import ownerDeletedSocket from '../../sockets/deleted.socket.owner';
import ownerUpdatedSocket from '../../sockets/updated.socket.owner';

// uuid
import {v4} from 'uuid';

class OwnersContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object,
    query: PropTypes.object,
    subscribe: PropTypes.func,
  };

  state = {
    downloadingCsv: false,
    loading: true,
    owners: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.getOwners();
    this.props.subscribe(
      ownerCreatedSocket.subscribe(this.addOwner),
      ownerDeletedSocket.subscribe(this.deleteOwner),
      ownerUpdatedSocket.subscribe(this.updateOwner)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  apiId = null;

  getOwners = async ({
    page = this.props.query.page,
    perPage = 50,
    search = this.props.query.search,
  } = {}) => {
    const {dispatch} = this.props;
    const apiId = v4();
    this.apiId = apiId;

    dispatch(setOwnersQuery({page, search}));

    this.setState({
      loading: true,
      owners: [],
    });

    try {
      const query = this.generateApiQuery({page, perPage, search});

      const {results: owners, count} = await listOwnersApi(query);
      if (this.apiId !== apiId || !this.mounted) return;
      this.setState({
        loading: false,
        owners,
      });
      dispatch(setOwnersQuery({pages: Math.ceil(count / perPage) || 1}));
    } catch (error) {
      if (this.apiId !== apiId || !this.mounted) return;
      this.setState({loading: false});
    }
  };

  generateApiQuery = ({page, perPage, search}) => {
    const query = {
      limit: perPage,
      offset: (page - 1) * perPage,
      ordering: 'name',
    };

    if (!!search) query.search = search;

    return query;
  };

  downloadCsv = async () => {
    const {query} = this.props;
    const {downloadingCsv} = this.state;
    if (downloadingCsv) return;
    this.setState({downloadingCsv: true});
    await downloadCsvService({
      apiCall: listOwnersApi,
      fileName: 'owners.csv',
      query: this.generateApiQuery(query),
    });
    if (!this.mounted) return;
    this.setState({downloadingCsv: false});
  };

  addOwner = (owner) => {
    const {loading} = this.props;
    if (loading) return;
    const owners = [...this.state.owners, owner];
    this.setState({owners});
  };

  updateOwner = (owner) => {
    const {loading} = this.props;
    if (loading) return;
    const owners = [...this.state.owners].map((ownerToUpdate) =>
      ownerToUpdate.id === owner.id ? owner : ownerToUpdate
    );
    this.setState({owners});
  };

  deleteOwner = ({id}) => {
    const {loading} = this.props;
    if (loading) return;
    const owners = [...this.state.owners].filter((owner) => owner.id !== id);
    this.setState({owners});
  };

  setPage = (page) => {
    if (this.state.loading) return;
    this.getOwners({page});
  };

  search = (search) => this.getOwners({search, page: 1});

  owners = () => this.state.owners;

  actions = () => {
    const {downloadingCsv} = this.state;
    return [
      {
        key: 'downloadCSV',
        children: 'Download CSV',
        icon: 'download',
        loading: downloadingCsv,
        theme: 'grey',
        onClick: this.downloadCsv,
      },
      {
        key: 'create',
        children: 'New Owner',
        icon: 'plus',
        theme: 'black',
        onClick: () => showCreateOwnerModalEvent.publish(),
      },
    ];
  };

  updateOwner = (owner) => () => showUpdateOwnerModalEvent.publish({owner});

  render() {
    const {
      query: {page, pages, search},
    } = this.props;
    const {loading} = this.state;
    const owners = this.owners();
    return (
      <OwnersList
        actions={this.actions()}
        columns={columns}
        loading={loading}
        onOwner={this.updateOwner}
        onPage={this.setPage}
        onSearch={this.search}
        page={page}
        pages={pages}
        search={search}
        owners={owners}
      />
    );
  }
}

export default subscriptionHoc(
  withRouter(
    connect((state) => ({
      query: state.owner.query,
    }))(OwnersContainer)
  )
);
