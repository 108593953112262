import callDriverState from '../states/callDriver.state.task';
import noStateState from '../states/noState.state.task';
import scheduledState from '../states/scheduled.state.task';
import waitingOnPartState from '../states/waitingOnPart.state.task';

const getStates = ({withNoState = true} = {}) =>
  [
    callDriverState,
    waitingOnPartState,
    scheduledState,
    withNoState && noStateState,
  ].filter((state) => !!state);

export default getStates;
