import store from '../redux/store';

const createPermission =
  (permission, {excludeSuperUser = false} = {}) =>
  (user = store.getState().auth.user) =>
    !!user &&
    ((excludeSuperUser && user[permission]) ||
      (!excludeSuperUser && (user[permission] || user.is_superuser)));

export default createPermission;
