import prepare from '@matthahn/sally-fw/lib/attribute/prepare.attribute';

const calampPrep = ({noTelematics = false, ...attributes}) =>
  noTelematics
    ? {
        calamp_device_id: null,
      }
    : prepare(attributes, {
        field: 'api',
        required: ['calamp_device_id', 'calamp_instalation_mileage'],
      });

export default calampPrep;
