import styled from 'styled-components';

// colors
import {black, white} from '@matthahn/sally-ui/lib/libs/colors';

// constants
const WIDTH = '120px';
const HEIGHT = '80px';

const Action = styled.div`
  width: ${WIDTH};
  max-width: ${WIDTH};
  min-width: ${WIDTH};
  height: ${HEIGHT};
  max-height: ${HEIGHT};
  min-height: ${HEIGHT};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  border-radius: 5px;
  background: ${black};
  color: ${white};
  gap: 8px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  user-select: none;

  & > i {
    font-size: 26px;
    font-weight: 400;
  }
`;

export default Action;
