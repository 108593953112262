import styled from 'styled-components';

// colors
import {grey, black} from '@matthahn/sally-ui/lib/libs/colors';

const ValueTag = styled.div`
  max-width: 100%;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  background: ${grey};
  color: ${black};
  border-radius: 3px;
  white-space: nowrap;
  padding: 0px 4px;
  user-select: none;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default ValueTag;
