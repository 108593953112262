import styled from 'styled-components';

// colors
import {black} from '@matthahn/sally-ui/lib/libs/colors';

const Driver = styled.div`
  width: 100%;
  margin-bottom: 0px;
  color: ${black};
  font-weight: 700;
  font-size: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: ellipsis;
`;

export default Driver;
