import React, {Component} from 'react';
import PropTypes from 'prop-types';

// components
import {Notification} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import NotificationContainer from '../../../layout/components/NotificationContainer/NotificationContainer';

// device
import {isMobileOnly} from 'react-device-detect';

class MileageModal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    mileage: PropTypes.object,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
  };

  render() {
    const {visible, mileage, onChange, onClose, onSave} = this.props;
    return (
      <NotificationContainer visible={visible} big={!isMobileOnly}>
        <Notification
          title="Mileage"
          primary={{
            label: 'Save',
            onClick: () => {
              onClose();
              onSave();
            },
          }}
          secondary={{
            label: 'Close',
            onClick: onClose,
          }}
        >
          <AttributeInput
            value={mileage}
            onChange={onChange}
            placeholder="Mileage"
            size="xl"
            autoFocus={visible}
          />
        </Notification>
      </NotificationContainer>
    );
  }
}

export default MileageModal;
