// clor picker
import {SketchPicker} from 'react-color';

// components
import Column from '@matthahn/sally-ui/lib/components/Grid/Column/Column';
import Input from '@matthahn/sally-ui/lib/components/Input/Input';
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import Row from '@matthahn/sally-ui/lib/components/Grid/Row/Row';

// local components
import Container from './components/Container';
import Tag from './components/Tag';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

const SelectTagModal = ({
  color,
  label,
  onChange,
  onHide,
  onHideCreateView,
  onSave,
  onSearch,
  onSelect,
  onShowCreateView,
  saving,
  search,
  showCreateView,
  tags,
  visible,
}) => (
  <Modal
    visible={visible}
    title="Select Tag"
    onClose={onHide}
    size="small"
    headerActions={
      showCreateView
        ? []
        : [{icon: 'plus', tooltip: 'Create Tag', onClick: onShowCreateView}]
    }
    buttonsLeft={
      showCreateView
        ? [{label: 'Cancel', onClick: onHideCreateView, disabled: saving}]
        : []
    }
    buttonsRight={
      showCreateView
        ? [{label: 'Save', onClick: onSave, loading: saving, theme: 'black'}]
        : []
    }
  >
    {(Content) =>
      showCreateView ? (
        <Content>
          <Row margin>
            <Column>
              <Input
                value={label}
                onChange={onChange('label')}
                disabled={saving}
              >
                Label
              </Input>
            </Column>
          </Row>
          <Row>
            <Column>
              <SketchPicker
                color={color}
                onChange={({hex}) => onChange('color')(hex)}
              />
            </Column>
          </Row>
        </Content>
      ) : (
        <Fragment>
          <Content>
            <Input value={search} onChange={onSearch} placeholder="Search..." />
          </Content>
          <Content>
            <Container>
              {tags.map((tag) => (
                <Tag key={tag.id} onClick={onSelect(tag)} theme={tag.color}>
                  {tag.label}
                </Tag>
              ))}
              {!tags.length && 'No tags found.'}
            </Container>
          </Content>
        </Fragment>
      )
    }
  </Modal>
);

SelectTagModal.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onHide: PropTypes.func,
  onHideCreateView: PropTypes.func,
  onSave: PropTypes.func,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  onShowCreateView: PropTypes.func,
  saving: PropTypes.bool,
  search: PropTypes.string,
  showCreateView: PropTypes.bool,
  tags: PropTypes.array,
  visible: PropTypes.bool,
};

export default SelectTagModal;
