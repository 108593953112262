import React from 'react';
import PropTypes from 'prop-types';

// components
import {Row, Column} from '@matthahn/sally-ui';

// item components
import ItemLoader from '../../../item/components/ItemLoader/ItemLoader';

// layout components
import ListItem from '../../../layout/components/ListItem/ListItem';

// local components
import Charged from './components/Charged';
import Container from './components/Container';
import Content from './components/Content';
import PaymentMethod from './components/PaymentMethod';
import Price from './components/Price';
import PriceContainer from './components/PriceContainer';

// item lib
import getItemName from '../../lib/getName.lib.item';
import isItemTaxed from '../../lib/isTaxed.lib.item';
import itemPrice from '../../lib/price.lib.item';

// Types
import amount from '@matthahn/sally-fw/lib/type/types/amount.type';

const TicketItems = ({
  addingItem,
  canAddItem,
  charged,
  discounts,
  isTicketEditable,
  items,
  onAddItem,
  onPriceChange,
  paymentMethod,
  taxValue,
  totalValue,
}) => (
  <Container>
    {items.map((item) => (
      <ListItem
        key={item.id}
        title={getItemName(item)}
        content={
          <Content>
            {item?.service_description || null}
            {!!item.external_charge ? (
              <Charged small>Charged</Charged>
            ) : !!item.charge_on_approval ? (
              <Charged warning small>
                Pending charge
              </Charged>
            ) : null}
          </Content>
        }
        rightContent={
          item.cost !== null ? (
            <PriceContainer>
              {item.quantity}
              <Price>
                {isItemTaxed(item) && 'T '}${amount(itemPrice(item)).format()}
              </Price>
            </PriceContainer>
          ) : (
            '-'
          )
        }
        onClick={isTicketEditable ? onPriceChange(item) : null}
      />
    ))}
    {discounts.map((item) => (
      <ListItem
        key={item.id}
        title={item?.service_description || getItemName(item) || null}
        rightContent={
          item.cost !== null ? (
            <PriceContainer>
              <Price discount>$-{amount(itemPrice(item)).format()}</Price>
            </PriceContainer>
          ) : (
            '-'
          )
        }
        onClick={isTicketEditable ? onPriceChange(item) : null}
      />
    ))}
    {addingItem ? (
      <ItemLoader />
    ) : canAddItem ? (
      <ListItem
        icon="plus"
        content="Add an item"
        onClick={onAddItem}
        withoutClickIcon
      />
    ) : null}
    {!!Number(taxValue) && (
      <Row>
        <Column size={2 / 3} offset={1 / 3}>
          <ListItem
            title="TAX"
            rightContent={`$${amount(taxValue).format()}`}
          />
        </Column>
      </Row>
    )}
    {!!Number(totalValue) && (
      <Row>
        <Column size={2 / 3} offset={1 / 3}>
          <ListItem
            title="TOTAL"
            rightContent={`$${amount(totalValue).format()}`}
            bold
          />
        </Column>
      </Row>
    )}
    {(!!paymentMethod || charged) && (
      <Row>
        <Column size={2 / 3} offset={1 / 3}>
          <ListItem
            title={!!paymentMethod && 'Payment'}
            rightContent={
              <PaymentMethod>
                {paymentMethod}
                {!!charged && <Charged>Driver Charged</Charged>}
              </PaymentMethod>
            }
          />
        </Column>
      </Row>
    )}
  </Container>
);

TicketItems.propTypes = {
  addingItem: PropTypes.bool,
  canAddItem: PropTypes.bool,
  charged: PropTypes.bool,
  discounts: PropTypes.array,
  isTicketEditable: PropTypes.bool,
  items: PropTypes.array,
  onPriceChange: PropTypes.func,
  paymentMethod: PropTypes.string,
  taxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default TicketItems;
