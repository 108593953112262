// Lib
// import isCustomMechanicSelected from '../../mechanic/lib/isCustomMechanicSelected.lib.mechanic';
import isCustomOwnerSelected from '../../owner/lib/isCustomOwnerSelected.lib.owner';

// Prep
import prepare from '@matthahn/sally-fw/lib/attribute/prepare.attribute';

const createVehiclePreparation = async ({full = false, ...attributes} = {}) => {
  const rawRequiredAttributes = full
    ? ['vin', 'owner', 'year', 'model', 'make']
    : ['vin', 'owner'];
  // ? ['vin', 'owner', 'mechanic', 'year', 'model', 'make']
  // : ['vin', 'owner', 'mechanic'];
  const requiredAttributes = [
    ...rawRequiredAttributes,
    isCustomOwnerSelected(attributes?.owner) && 'customOwner',
    // isCustomMechanicSelected(attributes?.mechanic) && 'customMechanic',
  ].filter((attribute) => !!attribute);
  const vehicle = await prepare(attributes, {
    field: 'api',
    required: requiredAttributes,
  });
  return vehicle;
};

export default createVehiclePreparation;
