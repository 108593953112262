import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {
  set as setAct,
  syncVehicles as syncVehiclesAct,
  removeVehicle as removeVehicleAct,
  removeUnusedVehicles as removeUnusedVehiclesAct,
} from '../../../spotlight/redux/actions';

// Api
import listVehiclesApi from '../../api/list.api.vehicle';

// Database
import DB_FIELDS from '../../database/fields.database.vehicle';
import vehiclesModel from '../../database/model.database.vehicle';

// Events
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// Sockets
import vehicleCreatedSocket from '../../sockets/created.socket.vehicle';
import vehicleDeletedSocket from '../../sockets/deleted.socket.vehicle';
import vehicleUpdatedSocket from '../../sockets/updated.socket.vehicle';

class VehiclesSpotlightSync extends Component {
  static propTypes = {
    vehiclesLoaded: PropTypes.bool,
    vehicles: PropTypes.array,
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.init();
    this.props.subscribe(
      vehicleCreatedSocket.subscribe(this.updateVehicle),
      vehicleDeletedSocket.subscribe(this.deleteVehicle),
      vehicleUpdatedSocket.subscribe(this.updateVehicle)
    );
  }

  init = async () => {
    const {dispatch} = this.props;
    const vehicles = await this.getStoredVehicles();
    dispatch(syncVehiclesAct(vehicles, {storeToDb: false}));
    dispatch(setAct({vehiclesLoading: true}));
    const freshVehicles = await this.fetch();
    dispatch(removeUnusedVehiclesAct(freshVehicles));
    dispatch(setAct({vehiclesLoading: false, vehiclesLoaded: true}));
  };

  updateVehicle = (vehicle) => {
    if (!this.props.vehiclesLoaded) return;
    this.props.dispatch(syncVehiclesAct([vehicle], {storeToDb: false}));
  };

  deleteVehicle = ({id}) => {
    if (!this.props.vehiclesLoaded) return;
    this.props.dispatch(removeVehicleAct(id));
  };

  getStoredVehicles = async () => {
    try {
      const vehicles = await vehiclesModel().toArray();
      return vehicles;
    } catch (error) {
      return [];
    }
  };

  fetch = async ({vehicles = [], limit = 100, offset = 0} = {}) => {
    try {
      const {results, next} = await listVehiclesApi({
        limit,
        offset,
        fields: DB_FIELDS.join(','),
        ordering: '-id',
      });
      const newList = [...vehicles, ...results];
      this.props.dispatch(syncVehiclesAct(results, {storeToDb: false}));
      if (!!next)
        return this.fetch({
          vehicles: newList,
          limit,
          offset: offset + limit,
        });
      return newList;
    } catch (error) {
      return vehicles;
    }
  };

  render() {
    return null;
  }
}

export default connect((state) => ({
  vehiclesLoaded: state.spotlight.vehiclesLoaded,
  vehicles: state.spotlight.vehicles,
}))(subscriptionHoc(VehiclesSpotlightSync));
