import React from 'react';
import PropTypes from 'prop-types';

// components
import {Icon} from '@matthahn/sally-ui';
import EntryDate from '../EntryDate/EntryDate';

// local components
import Container from './components/Container';
import Content from './components/Content';
import DateRow from './components/DateRow';
import Notes from './components/Notes';
import TypeIcon from './components/TypeIcon';

// type
import dateFormat from '@matthahn/sally-fw/lib/type/types/dayDateTime.type';

const EntryCard = ({date, vehicle, notes, isDue, isTask, isToday, onClick}) => (
  <Container isTask={isTask} onClick={onClick}>
    <Content>
      <Notes>{notes || '-'}</Notes>
      <DateRow>
        <EntryDate isDue={isDue} isToday={isToday}>
          {dateFormat(date).format()}
        </EntryDate>
      </DateRow>
    </Content>
    {isTask && (
      <TypeIcon>
        <Icon icon={isTask ? 'calendar' : 'car'} />
      </TypeIcon>
    )}
  </Container>
);

EntryCard.propTypes = {
  date: PropTypes.string,
  isDue: PropTypes.bool,
  isTask: PropTypes.bool,
  isToday: PropTypes.bool,
  notes: PropTypes.string,
  onClick: PropTypes.func,
  vehicle: PropTypes.object,
};

export default EntryCard;
