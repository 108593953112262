import styled from 'styled-components';

// colors
import {grey} from '@matthahn/sally-ui/lib/libs/colors';

const SearchContainer = styled.div`
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid ${grey};
`;

export default SearchContainer;
