// components
import Column from '@matthahn/sally-ui/lib/components/Grid/Column/Column';
import Form from '@matthahn/sally-ui/lib/components/Form/Form';
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import Row from '@matthahn/sally-ui/lib/components/Grid/Row/Row';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const ConvertCustomServiceItemsModal = ({
  categories,
  category,
  default_cost,
  loading,
  onChange,
  onClose,
  onSave,
  serviceItems,
  visible,
}) => (
  <Modal
    title="Convert Custom Service Items"
    visible={visible}
    onClose={onClose}
    buttonsRight={[{label: 'Save', loading, onClick: onSave}]}
  >
    {(Content) => (
      <Content>
        <Form onSubmit={onSave} enter>
          <Row margin>
            <Column>
              <div>Following items will get converted:</div>
              {serviceItems.map((serviceItem) => (
                <div key={serviceItem.id}>- {serviceItem.custom_service}</div>
              ))}
            </Column>
          </Row>
          <Row>
            <Column size={1 / 2}>
              <AttributeInput
                value={category}
                onChange={onChange}
                options={categories}
                disabled={loading}
              >
                {category.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={default_cost}
                onChange={onChange}
                disabled={loading}
              >
                {default_cost.label.default}
              </AttributeInput>
            </Column>
          </Row>
        </Form>
      </Content>
    )}
  </Modal>
);

ConvertCustomServiceItemsModal.propTypes = {
  categories: PropTypes.array,
  category: PropTypes.object,
  default_cost: PropTypes.object,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  serviceItems: PropTypes.array,
  visible: PropTypes.bool,
};

export default ConvertCustomServiceItemsModal;
