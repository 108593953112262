import styled from 'styled-components';

const Entries = styled.div`
  width: 33.33%;
  max-width: 33.33%;
  min-width: 33.33%;
  padding: 6px;
`;

export default Entries;
