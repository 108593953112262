// branch lib
import getBranchName from '../../../branch/lib/getBranchName.lib.branch';

// layout components
import ItemListLayout from '../../../layout/components/ItemListLayout/ItemListLayout';

// owner lib
import getOwnerName from '../../../owner/lib/getName.lib.owner';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// vehicle components
import VehicleModelInfo from '../VehicleModelInfo/VehicleModelInfo';
import VehicleProfileButton from '../VehicleProfileButton/VehicleProfileButton';

// vehicle lib
import displayVehicleFieldFromObject from '../../lib/displayVehicleFieldFromObject.lib.vehicle';
import vehicleOrVehicleFields from '../../lib/vehicleOrVehicleFields.lib.vehicle';

const VehiclesList = ({
  actions = [],
  columns,
  filters = [],
  loading,
  onPage,
  onSearch,
  onSort,
  onVehicle,
  page,
  pages,
  search,
  sort,
  vehicles,
}) => (
  <ItemListLayout
    actions={actions}
    columns={columns}
    filters={filters}
    onSearch={onSearch}
    onSort={onSort}
    search={search}
    searchPlaceholder="Search Vehicles"
    sort={sort}
    tableProps={{
      loading,
      page,
      pages,
      onPage,
    }}
  >
    {(TableRow) =>
      vehicles.map((vehicle) => (
        <TableRow key={vehicle.id} onClick={onVehicle(vehicle)}>
          {(TableCol) => [
            <TableCol key="vin">
              {displayVehicleFieldFromObject({
                object: {
                  vehicle,
                },
                field: 'vin',
                empty: '-',
              })}
            </TableCol>,
            <TableCol key="svid">
              {displayVehicleFieldFromObject({
                object: {
                  vehicle,
                },
                field: 'svid',
                empty: '-',
              })}
            </TableCol>,
            <TableCol key="plate">
              {displayVehicleFieldFromObject({
                object: {
                  vehicle,
                },
                field: 'license_plate',
                empty: '-',
              })}
            </TableCol>,
            <TableCol key="medallion">
              {displayVehicleFieldFromObject({
                object: {
                  vehicle,
                },
                field: 'medallion',
                empty: '-',
              })}
            </TableCol>,
            <TableCol key="model">
              <VehicleModelInfo
                {...vehicleOrVehicleFields({
                  vehicle,
                })}
                withoutMake
              />
            </TableCol>,
            <TableCol key="branch">
              {getBranchName(vehicle?.branch) || '-'}
            </TableCol>,
            <TableCol key="owner">
              <VehicleProfileButton
                vehicle={vehicle}
                noProfileDom={getOwnerName({ownerId: vehicle?.owner}) || '-'}
              />
            </TableCol>,
          ]}
        </TableRow>
      ))
    }
  </ItemListLayout>
);

VehiclesList.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.array,
  filters: PropTypes.array,
  loading: PropTypes.bool,
  onPage: PropTypes.func,
  onSearch: PropTypes.func,
  onSort: PropTypes.func,
  onVehicle: PropTypes.func,
  page: PropTypes.number,
  pages: PropTypes.number,
  search: PropTypes.string,
  sort: PropTypes.object,
  vehicles: PropTypes.array,
};

export default VehiclesList;
