import React, {Component} from 'react';
import PropTypes from 'prop-types';

// api
import listTicketsApi from '../../api/list.api.ticket';

// components
import ClosedTicketsModal from '../../components/ClosedTicketsModal/ClosedTicketsModal';

// date
import orderByDate from '@matthahn/sally-fw/lib/lib/orderByDate';

// events
import showClosedTicketsModalEvent from '../../events/showClosedTicketsModal.event.ticket';

// redux
import {connect} from 'react-redux';

// router
import {withRouter} from 'react-router-dom';

// subscription
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// ticket pages
import ticketRoute from '../../pages/TicketPage/route';

// uuid
import {v4} from 'uuid';

// Vehicle
import attachVehicleToObjects from '../../../vehicle/lib/attachVehicleToObjects.lib.vehicle';

class ClosedTicketsContainer extends Component {
  static propTypes = {
    vehicles: PropTypes.array,
    subscribe: PropTypes.func,
    history: PropTypes.object,
  };

  static INITIAL_STATE = {
    visible: false,
    loading: false,
    tickets: [],
  };

  state = {...this.constructor.INITIAL_STATE};

  componentDidMount() {
    this.props.subscribe(showClosedTicketsModalEvent.subscribe(this.show));
  }

  apiId = null;

  show = async () => {
    const apiId = v4();
    this.apiId = apiId;
    this.setState({
      ...this.constructor.INITIAL_STATE,
      visible: true,
      loading: true,
    });

    try {
      const {results} = await listTicketsApi({
        limit: 10,
        offset: 0,
        ordering: '-checkedin_at',
        approved: true,
      });
      if (!this.state.visible || apiId !== this.apiId) return;
      this.setState({loading: false, tickets: [...results]});
    } catch (error) {
      if (!this.state.visible || apiId !== this.apiId) return;
      this.setState({loading: false});
    }
  };

  hide = () => this.setState({visible: false});

  goToTicket = (ticket) => () => {
    this.hide();
    this.props.history.push(ticketRoute(ticket.id));
  };

  tickets = () => {
    const {vehicles} = this.props;
    const {tickets} = this.state;
    return orderByDate(
      attachVehicleToObjects({objects: tickets, vehicles}),
      'checkedin_at',
      'asc'
    ).filter(({vehicle}) => !!vehicle);
  };

  render() {
    const {visible, loading} = this.state;
    return (
      <ClosedTicketsModal
        visible={visible}
        loading={loading}
        tickets={this.tickets()}
        onClose={this.hide}
        onTicket={this.goToTicket}
      />
    );
  }
}

export default withRouter(
  subscriptionHoc(
    connect((state) => ({vehicles: state.spotlight.vehicles}))(
      ClosedTicketsContainer
    )
  )
);
