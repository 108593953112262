import styled from 'styled-components';

// colors
import {black, red} from '@matthahn/sally-ui/lib/libs/colors';

const Price = styled.div`
  text-align: right;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({discount}) => (discount ? red : black)};
`;

export default Price;
