// math lib
import subtract from '@matthahn/sally-fw/lib/lib/subtract';

const milesSinceLastSeen = ({vehicle}) =>
  subtract(
    vehicle?.mileage || 0,
    vehicle?.maintenance_state?.last_odometer_reading || 0
  );

export default milesSinceLastSeen;
