// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, Fragment} from 'react';

// tag api
import deleteTagApi from '../../api/delete.api.tag';
import updateTagApi from '../../api/update.api.tag';

// tag components
import EditTagModal from '../../components/EditTagModal/EditTagModal';
import TicketTags from '../../components/TicketTags/TicketTags';

// tag events
import showSelectTagModalEvent from '../../events/showSelectTagModal.event.tag';
import tagSelectedEvent from '../../events/selected.event.tag';
import tagUpdatedEvent from '../../events/updated.event.tag';

// tag lib
import getTagObjects from '../../lib/getTagObjects.lib.tag';

// ticket api
import updateTicketApi from '../../../ticket/api/update.api.ticket';

// ticket events
import ticketUpdatedEvent from '../../../ticket/events/updated.event.ticket';

class TicketTagEditorContainer extends Component {
  static propTypes = {
    small: PropTypes.bool,
    subscribe: PropTypes.func,
    ticket: PropTypes.object,
  };

  state = {
    adding: false,
    deletingTag: false,
    editableTag: null,
    editTagModalVisible: false,
    tags: [],
    updatingTag: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.props.subscribe(
      tagSelectedEvent.subscribe(this.tagSelected),
      tagUpdatedEvent.subscribe(this.setupTags)
    );
    this.setupTags();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setupTags = () => {
    const {ticket} = this.props;
    const tags = [...(ticket?.tags || [])];
    this.setState({tags: getTagObjects({tagIds: tags})});
  };

  updateTag = async () => {
    const {ticket} = this.props;
    const {editableTag, tags, updatingTag, deletingTag} = this.state;

    if (!editableTag || updatingTag || deletingTag) return;

    this.setState({updatingTag: true});

    try {
      const updatedTag = await updateTagApi({
        tagId: editableTag.id,
        tag: editableTag,
      });
      const updatedTags = [...tags].map((tag) =>
        tag.id === updatedTag.id ? updatedTag : tag
      );
      const updatedTicket = {...ticket, tags: [...updatedTags]};
      ticketUpdatedEvent.publish(updatedTicket);
      tagUpdatedEvent.publish(updatedTag);
      this.setState({
        editTagModalVisible: false,
        tags: updatedTags,
        updatingTag: false,
      });
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({updatingTag: false});
    }
  };

  removeTagFromTicket = async () => {
    const {ticket} = this.props;
    const {editableTag, tags, updatingTag, deletingTag} = this.state;

    if (!editableTag || updatingTag || deletingTag) return;

    this.setState({deletingTag: true});

    try {
      const updatedTags = [...tags].filter((tag) => tag.id !== editableTag.id);
      const tagIds = [...updatedTags].map((tag) => tag.id);
      const updatedTicket = await updateTicketApi(ticket.id, {tags: tagIds});
      ticketUpdatedEvent.publish(updatedTicket);
      this.setState({
        editTagModalVisible: false,
        tags: updatedTags,
        deletingTag: false,
      });
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({deletingTag: false});
    }
  };

  deleteTag = async () => {
    const {ticket} = this.props;
    const {editableTag, tags, updatingTag, deletingTag} = this.state;

    if (!editableTag || updatingTag || deletingTag) return;

    this.setState({deletingTag: true});

    try {
      await deleteTagApi(editableTag.id);
      const updatedTags = [...tags].filter((tag) => tag.id !== editableTag.id);
      const updatedTicket = {...ticket, tags: [...updatedTags]};
      ticketUpdatedEvent.publish(updatedTicket);
      this.setState({
        editTagModalVisible: false,
        tags: updatedTags,
        deletingTag: false,
      });
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({deletingTag: false});
    }
  };

  updateTicket = async (tagIds) => {
    const {ticket} = this.props;

    try {
      const updatedTicket = await updateTicketApi(ticket.id, {tags: tagIds});
      ticketUpdatedEvent.publish(updatedTicket);
      return updatedTicket;
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      return ticket;
    }
  };

  showTagEditor = (tag) => () => {
    this.setState({
      editableTag: {...tag},
      editTagModalVisible: true,
    });
  };

  hideTagEditor = () => {
    const {deletingTag, updatingTag} = this.state;
    if (deletingTag || updatingTag) return;
    this.setState({
      editTagModalVisible: false,
    });
  };

  addTag = () => {
    const {ticket} = this.props;
    const {adding, tags} = this.state;
    if (adding) return;
    const tagIds = [...tags].map((tag) => tag.id);
    showSelectTagModalEvent.publish({excludeTags: tagIds, ticket});
  };

  tagSelected = async ({tag, ticket}) => {
    const {adding} = this.state;
    if (adding || this.props.ticket.id !== ticket.id) return;

    this.setState({adding: true});
    const existingTagIds = [...this.state.tags].map((tag) => tag.id);
    const tagIds = [...existingTagIds, tag.id];
    await this.updateTicket(tagIds);
    if (!this.mounted) return;
    const updatedTags = [...this.state.tags, tag];
    this.setState({adding: false, tags: updatedTags});
  };

  editTagAttribute = (key) => (value) => {
    const {editableTag} = this.state;
    if (!editableTag) return;
    this.setState({editableTag: {...editableTag, [key]: value}});
  };

  render() {
    const {small} = this.props;
    const {
      adding,
      deletingTag,
      editableTag,
      editTagModalVisible,
      tags,
      updatingTag,
    } = this.state;
    return (
      <Fragment>
        <TicketTags
          adding={adding}
          onAdd={this.addTag}
          onEdit={this.showTagEditor}
          small={small}
          tags={tags}
        />
        <EditTagModal
          color={editableTag?.color}
          deleting={deletingTag}
          label={editableTag?.label}
          onChange={this.editTagAttribute}
          onClose={this.hideTagEditor}
          onDelete={this.removeTagFromTicket}
          onSave={this.updateTag}
          saving={updatingTag}
          visible={editTagModalVisible}
        />
      </Fragment>
    );
  }
}

export default subscriptionHOC(TicketTagEditorContainer);
