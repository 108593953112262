import f3tch from 'f3tch';

// Constants
import API_URL from '../../api/constants/url.const.api';

// Profiles
import profile from '@matthahn/sally-fw/lib/api/profiles/json.profile.api';

const signInApi = ({username, password}) =>
  f3tch('/users/login/')
    .profile(
      profile({authorize: false, url: API_URL(), ignoreAppVersionHeader: true})
    )
    .body({username, password})
    .post();

export default signInApi;
