import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

// alertify
import alertify from '../../../layout/lib/alertify';

// components
import {Modal, Row, Column, Info, Form} from '@matthahn/sally-ui';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// vehicle attributes
import spireonInstallDateAttr from '../../attributes/spireon_install_date.attribute.vehicle';
import spireonSerialAttr from '../../attributes/spireon_serial.attribute.vehicle';

// vehicle api
import getSpireonApi from '../../api/getSpireon.api.vehicle';
import updateVehicleApi from '../../api/updateGeorge.api.vehicle';

// vehicle lib
import checkSpireon from '../../lib/checkSpireon.lib.vehicle';

// vehicle preparations
import prepareForUpdate from '../../preparations/spireon.preparation.vehicle';

class Spireon extends Component {
  static propTypes = {
    children: PropTypes.func,
    onSync: PropTypes.func,
    vehicle: PropTypes.object,
  };

  static defaultProps = {
    children: () => null,
  };

  state = {
    loading: true,
    saving: false,
    spireon: null,
    modalVisible: false,
    spireon_serial: spireonSerialAttr(''),
    spireon_install_date: spireonInstallDateAttr(''),
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.vehicle.id !== this.props.vehicle.id) this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = async () => {
    this.setState({
      loading: true,
      spireon_serial: spireonSerialAttr(''),
      spireon_install_date: spireonInstallDateAttr(''),
    });
    const spireon = await this.getSpireon();
    if (!this.mounted) return;
    this.setState({spireon, loading: false});
  };

  change = (value, key) => {
    const {loading, saving} = this.state;
    if (saving || loading) return;
    this.setState({[key]: value});
  };

  getSpireon = async ({vehicle = this.props.vehicle} = {}) => {
    try {
      const spireon = await getSpireonApi(vehicle.id);
      this.props.onSync('spireon')(spireon);
      return spireon;
    } catch (error) {
      return null;
    }
  };

  showModal = () => {
    const {loading, spireon} = this.state;
    if (loading || !!spireon) return;
    this.setState({
      modalVisible: true,
      spireon_serial: spireonSerialAttr(''),
      spireon_install_date: spireonInstallDateAttr(''),
    });
  };

  hideModal = () => {
    if (this.state.saving) return;
    this.setState({modalVisible: false});
  };

  save = async () => {
    const {
      vehicle: {id: vehicleId},
      onSync,
    } = this.props;
    const {loading, saving, spireon_serial, spireon_install_date} = this.state;

    if (loading || saving) return;
    this.setState({saving: true});

    try {
      const preparedVehicle = await prepareForUpdate({
        spireon_serial,
        spireon_install_date,
      });
      const vehicle = await updateVehicleApi(vehicleId, preparedVehicle);
      const spireon = await this.getSpireon();
      if (!this.mounted) return;
      onSync('georgeVehicle')(vehicle);
      this.setState({saving: false, spireon, modalVisible: false});
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alertify({message});
      this.setState({saving: false});
    }
  };

  renderContent = () => {
    const {children, vehicle} = this.props;
    const {loading, spireon} = this.state;
    const spireonStatus = checkSpireon({vehicle, spireon});
    return children({
      loading,
      spireon,
      spireonStatus,
      onClick: this.showModal,
    });
  };

  render() {
    const {vehicle} = this.props;
    const {
      spireon,
      spireon_install_date,
      spireon_serial,
      saving,
      modalVisible,
    } = this.state;
    const spireonStatus = checkSpireon({vehicle, spireon});
    return (
      <Fragment>
        {this.renderContent()}
        <Modal
          visible={modalVisible}
          icon="car"
          title="Spireon"
          onClose={this.hideModal}
          buttonsRight={[{label: 'Save', loading: saving, onClick: this.save}]}
        >
          {(Content) => (
            <Fragment>
              {spireonStatus?.status !== 'done' && (
                <Content padding="none">
                  <Info type={spireonStatus.status} flat>
                    {spireonStatus.message}
                  </Info>
                </Content>
              )}
              <Content>
                <Form onSubmit={this.save} enter>
                  <Row margin>
                    <Column>
                      <AttributeInput
                        value={spireon_serial}
                        onChange={this.change}
                        disabled={saving}
                      >
                        {spireon_serial.label.default}
                      </AttributeInput>
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <AttributeInput
                        value={spireon_install_date}
                        onChange={this.change}
                        disabled={saving}
                      >
                        {spireon_install_date.label.default}
                      </AttributeInput>
                    </Column>
                  </Row>
                </Form>
              </Content>
            </Fragment>
          )}
        </Modal>
      </Fragment>
    );
  }
}

export default Spireon;
