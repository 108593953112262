import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import {Link} from 'react-router-dom';
import Logo from '../../Logo/Logo';

const LinkBlock = styled(Link)`
  display: block;
  margin: 0px;
  padding: 0px;
`;

const StyledLogo = styled(Logo)`
  height: 20px;
  cursor: pointer;
`;

const NavigationLogo = ({live, url}) => (
  <LinkBlock to={url}>
    <StyledLogo color={live ? 'black' : 'white'} />
  </LinkBlock>
);

NavigationLogo.propTypes = {
  live: PropTypes.bool,
  url: PropTypes.string,
};

export default NavigationLogo;
