import React from 'react';
import PropTypes from 'prop-types';

// local components
import Container from './components/Container';
import Image from './components/Image';

// local constants
import SETTINGS from './constants/settings.constants';

// slider
import Slider from 'react-slick';

const BgSlider = ({images, settings}) => (
  <Container>
    <Slider {...SETTINGS(settings)}>
      {images.map((image) => (
        <Container key={image}>
          <Image src={image} />
        </Container>
      ))}
    </Slider>
  </Container>
);

BgSlider.propTypes = {
  images: PropTypes.array,
  settings: PropTypes.object,
};

BgSlider.defaultProps = {
  settings: {},
};

export default BgSlider;
