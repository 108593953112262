import React from 'react';

// layout containers
import Page from '../../../layout/containers/Page/Page';

// local constants
import PAGE from './page';

// location containers
import LocationsContainer from '../../containers/LocationsContainer/LocationsContainer';

const LocationsPage = () => (
  <Page page={PAGE}>
    <LocationsContainer />
  </Page>
);

export default LocationsPage;
