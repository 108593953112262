// Constants
import CUSTOM_OWNER_VALUE from '../constants/customOwnerValue.lib.owner';

// Lib
import sortByString from '@matthahn/sally-fw/lib/lib/sortByString';

const prepareForSelect = ({owners, enableCustom = false}) => {
  const ownerOptions = [...owners]
    // .filter(({name}) => !['GEORGE', 'KUIPER'].includes(name))
    .map(({id, name}) => ({value: `${id}`, label: name}))
    .sort(sortByString({key: 'label'}));
  return enableCustom
    ? [...ownerOptions, {value: CUSTOM_OWNER_VALUE, label: 'Other'}]
    : ownerOptions;
};

export default prepareForSelect;
