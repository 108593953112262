import styled from 'styled-components';

// Colors
import {blueGrey} from '@matthahn/sally-ui/lib/libs/colors';

const Text = styled.div`
  width: 100%;
  color: ${blueGrey};
  font-size: 16px;
`;

export default Text;
