import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// actions
import {hide as hideAct} from './redux/actions';
import {signOut as signOutAct} from '../../../auth/redux/actions';

// branch actions
import {clearBranch as clearBranchAct} from '../../../branch/redux/actions';

// components
import SettingsModal from '../../components/SettingsModal/SettingsModal';

// mechanic events
import showCreateMechanicModalEvent from '../../../mechanic/events/showCreateModal.event.mechanic';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// package.json
import {version} from '../../../../package.json';

// permission events
import showPermissionsEvent from '../../../permission/events/show.event.permission';

// permission permissions
import canUpdatePermissionsPermission from '../../../permission/permissions/canUpdatePermissions.permission.permission';

// router
import {withRouter} from 'react-router-dom';

// task
import tasksRoute from '../../../task/pages/TasksPage/route';

class SettingsContainer extends Component {
  static propTypes = {
    window: PropTypes.object,
    history: PropTypes.object,
    visible: PropTypes.bool,
    dispatch: PropTypes.func,
  };

  static defaultProps = {
    window,
  };

  close = () => this.props.dispatch(hideAct());

  confirmSignOut = () =>
    notify({
      title: 'Are you sure?',
      content: '',
      secondary: {label: 'Cancel'},
      primary: {label: 'Log out', onClick: this.signOut},
    });

  signOut = () => {
    this.props.dispatch(signOutAct());
    this.close();
  };

  goToTask = () => {
    this.close();
    this.props.history.push(tasksRoute());
  };

  selectBranch = () => {
    this.close();
    this.props.dispatch(clearBranchAct());
  };

  showPermissions = () => {
    if (!canUpdatePermissionsPermission()) return;
    this.close();
    showPermissionsEvent.publish();
  };

  showMechanics = () => {
    this.close();
    showCreateMechanicModalEvent.publish();
  };

  actions = () => {
    const {branch} = this.props;
    return [
      {
        key: 'mechanics',
        icon: 'mdi-account-plus',
        title: 'Add Mechanic',
        onClick: this.showMechanics,
      },
      canUpdatePermissionsPermission() && {
        key: 'permission',
        icon: 'lock',
        title: 'Permissions',
        onClick: this.showPermissions,
      },
      {
        key: 'branch',
        icon: 'pushpino',
        title: branch?.tag,
        onClick: this.selectBranch,
      },
      {
        key: 'signOut',
        icon: 'logout',
        title: 'Log Out',
        onClick: this.confirmSignOut,
      },
      {
        key: 'version',
        icon: 'mobile1',
        title: 'Version',
        content: version,
      },
    ].filter((action) => !!action);
  };

  render() {
    const {visible} = this.props;
    return (
      <SettingsModal
        actions={this.actions()}
        onClose={this.close}
        visible={visible}
      />
    );
  }
}

export default withRouter(
  connect((state) => ({
    ...state.settings,
    branch: state.branch.branch,
  }))(SettingsContainer)
);
