// components
import {Modal, Row, Column} from '@matthahn/sally-ui';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const OwnerEditorModal = ({
  email,
  loading,
  name,
  onChange,
  onClose,
  onSave,
  phone_number,
  title,
  visible,
}) => (
  <Modal
    visible={visible}
    title={title}
    buttonsRight={[{label: 'Save', loading, onClick: onSave}]}
    onClose={onClose}
    size="small"
  >
    {(Content) => (
      <Content>
        <Row margin>
          <Column>
            <AttributeInput
              value={name}
              disabled={loading}
              onChange={onChange}
              placeholder="Owner name"
            >
              {name.label.default}
            </AttributeInput>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <AttributeInput
              value={phone_number}
              disabled={loading}
              onChange={onChange}
            >
              {phone_number.label.default}
            </AttributeInput>
          </Column>
        </Row>
        <Row>
          <Column>
            <AttributeInput
              value={email}
              disabled={loading}
              onChange={onChange}
            >
              {email.label.default}
            </AttributeInput>
          </Column>
        </Row>
      </Content>
    )}
  </Modal>
);

OwnerEditorModal.propTypes = {
  email: PropTypes.object,
  loading: PropTypes.bool,
  name: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  phone_number: PropTypes.object,
  title: PropTypes.string,
  visible: PropTypes.bool,
};

export default OwnerEditorModal;
