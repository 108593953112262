// api
import api from '@matthahn/sally-fw/lib/api/lib/getEverything.lib.api';

// documents api
import listDocumentsApi from '../../../../document/api/list.api.document';

// ticket documents
import repairImageDocument from '../../../documents/repairImage.document.ticket';

const getImagesService = async ({
  fileType = repairImageDocument,
  getDocuments = listDocumentsApi,
  ticket,
}) => {
  const {data} = await api(getDocuments, {ticket: ticket.id, type: fileType});
  return data;
};

export default getImagesService;
