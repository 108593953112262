import React from 'react';

// layout containers
import Page from '../../../layout/containers/Page/Page';

// local constants
import PAGE from './page';

// ticket containers
import DriversContainer from '../../containers/DriversContainer/DriversContainer';

const DriversPage = () => (
  <Page page={PAGE}>
    <DriversContainer />
  </Page>
);

export default DriversPage;
