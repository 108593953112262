// const updateTicketItems = ({ticket, items}) => ({
//   ...ticket,
//   items: [...items].reduce(
//     (combined, item) =>
//       !![...combined].find((existingItem) => existingItem.id === item.id)
//         ? [...combined].map((existingItem) =>
//             existingItem.id === item.id ? item : existingItem
//           )
//         : [...combined, item],
//     [...ticket.items]
//   ),
// });
const updateTicketItems = ({ticket, items}) =>
  [...items].reduce(
    (updatingTicket, item) => ({
      ...updatingTicket,
      items: !![...updatingTicket.items].find(
        (existingItem) => existingItem.id === item.id
      )
        ? [...updatingTicket.items].map((existingItem) =>
            existingItem.id === item.id ? item : existingItem
          )
        : [...updatingTicket.items, item],
      ticket_cost_summary:
        item?.ticket_cost_summary || updatingTicket?.ticket_cost_summary,
    }),
    {...ticket}
  );

export default updateTicketItems;
