// math lib
import subtract from '@matthahn/sally-fw/lib/lib/subtract';

// vehicle constants
import CHANGE_OIL_MILEAGE from '../constants/changeOilMileage.constant.vehicle';

// vehicle lib
import isEVVehicle from './isEV.lib.vehicle';

const shouldChangeOil = ({vehicle}) =>
  !!vehicle &&
  !isEVVehicle({vehicle}) &&
  subtract(
    vehicle?.mileage || 0,
    vehicle?.maintenance_state?.last_oil_change_mileage || 0
  ) >= CHANGE_OIL_MILEAGE;

export default shouldChangeOil;
