// alertify
import alertify from '../../../layout/lib/alertify';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// ticket api
import updateTicketApi from '../../api/update.api.ticket';

// ticket attributes
import notesAttr from '../../attributes/notes.attribute.ticket';

// ticket components
import NotesCard from '../../components/NotesCard/NotesCard';

// ticket permissions
import updateTicketPermission from '../../permissions/update.permission.ticket';

class NotesContainer extends Component {
  static propTypes = {
    syncTicket: PropTypes.func,
    ticket: PropTypes.object,
  };

  state = {
    notes: notesAttr(''),
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = () => {
    const {ticket} = this.props;
    this.setState({
      notes: notesAttr(ticket?.notes || ''),
    });
  };

  saveTicket = async () => {
    const {ticket, syncTicket} = this.props;
    const {notes} = this.state;
    if (!updateTicketPermission()) return;
    try {
      const savedTicket = await updateTicketApi(ticket.id, {
        notes: notes.api.format() || '',
      });
      const updatedTicket = {
        ...ticket,
        phone_number: savedTicket.phone_number,
        notes: savedTicket.notes,
      };
      syncTicket(updatedTicket);
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alertify({message});
    }
  };

  change = (notes) => this.setState({notes});

  render() {
    const {ticket} = this.props;
    const {notes} = this.state;
    return (
      <NotesCard
        editable={!ticket?.approved}
        notes={notes}
        onChange={this.change}
        onSave={this.saveTicket}
      />
    );
  }
}

export default NotesContainer;
