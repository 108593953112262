// alert
import alertify from '../../../layout/lib/alertify';

// api
import updateTicketStatusApi from '../../api/update.api.ticketStatus';

// attributes
import labelAttr from '../../attributes/label.attribute.ticketStatus';

// error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// events
import ticketStatusEditedEvent from '../../events/updated.event.ticketStatus';
import showUpdateTicketStatusModalEvent from '../../events/showUpdateModal.event.ticketStatus';

// ticketStatus components
import EditTicketStatusModal from '../../components/EditTicketStatusModal/EditTicketStatusModal';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// subscription
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

class EditTicketStatusContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  state = {
    visible: false,
    loading: false,
    label: labelAttr(''),
    ticketStatus: null,
  };

  componentDidMount() {
    this.props.subscribe(showUpdateTicketStatusModalEvent.subscribe(this.init));
  }

  init = ({ticketStatus}) => {
    this.setState({
      visible: true,
      ticketStatus,
      label: labelAttr(ticketStatus.label || ''),
    });
  };

  change = (value, key) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  close = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  save = async () => {
    const {loading, ticketStatus, label} = this.state;

    if (loading) return;

    const ticketStatusLabel = label.api.format();

    if (!ticketStatusLabel.trim().length)
      return alertify({message: 'Insert label'});

    this.setState({loading: true});

    try {
      const updatedTicketStatus = await updateTicketStatusApi(ticketStatus.id, {
        label: ticketStatusLabel,
      });
      ticketStatusEditedEvent.publish(updatedTicketStatus);
      this.setState({loading: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alertify({message});
      this.setState({loading: false});
    }
  };

  render() {
    const {visible, loading, label} = this.state;
    return (
      <EditTicketStatusModal
        loading={loading}
        label={label}
        onChange={this.change}
        onClose={this.close}
        onSave={this.save}
        visible={visible}
      />
    );
  }
}

export default subscriptionHoc(EditTicketStatusContainer);
