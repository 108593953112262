import styled from 'styled-components';

const HiddenFileInput = styled.input`
  width: 0px;
  height: 0px;
  display: none;
  visibility: hidden;
  opacity: 0;
`;

export default HiddenFileInput;
