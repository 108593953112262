// alerts
// import alert from '@matthahn/sally-ui/lib/libs/alert';

// error lib
// import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// service components
import ServiceEditorModal from '../../components/ServiceEditorModal/ServiceEditorModal';

// service events
import showServiceEditorModalEvent from '../../events/showServiceEditorModal.event.service';

class ServiceEditorContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  state = {
    loading: false,
    visible: false,
    service: null,
  };

  componentDidMount() {
    this.props.subscribe(showServiceEditorModalEvent.subscribe(this.show));
  }

  show = ({service}) => {
    this.setState({visible: true, service: {...service}});
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  change = (value, key) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  save = async () => {};

  render() {
    const {loading, service, visible} = this.state;
    return (
      <ServiceEditorModal
        loading={loading}
        onChange={this.change}
        onClose={this.hide}
        onSave={this.save}
        service={service}
        visible={visible}
      />
    );
  }
}

export default subscriptionHoc(ServiceEditorContainer);
