// task states
import createTaskState from '../state/create.state.task';

const noState = createTaskState({
  state: '',
  label: 'Select state',
  color: 'transparent',
});

export default noState;
