// components
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import SimpleMenu from '@matthahn/sally-ui/lib/components/SimpleMenu/SimpleMenu';

// layout components
import ListItem from '../../../layout/components/ListItem/ListItem';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const TicketMechanicsModal = ({
  mechanic,
  mechanics,
  onClose,
  onCreateMechanic,
  onSelect,
  visible,
}) => (
  <Modal
    onClose={onClose}
    size="large"
    title="Select a mechanic"
    visible={visible}
  >
    {(Content) => (
      <Content padding="none">
        <SimpleMenu
          options={mechanics}
          open="all"
          height={500}
          selected={!!mechanic ? [`${mechanic}`] : null}
          onSelect={onSelect}
        />
        <ListItem
          icon="mdi-account-plus"
          onClick={onCreateMechanic}
          title="Add Mechanic"
          noBorder
        />
      </Content>
    )}
  </Modal>
);

TicketMechanicsModal.propTypes = {
  mechanic: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mechanics: PropTypes.array,
  onClose: PropTypes.func,
  onCreateMechanic: PropTypes.func,
  onSelect: PropTypes.func,
  visible: PropTypes.bool,
};

export default TicketMechanicsModal;
