// lib
import sortByString from '@matthahn/sally-fw/lib/lib/sortByString';

// service lib
import getCategories from './getCategories.lib.service';
import getServicesByCategory from './getServicesByCategory.lib.service';

// redux
import store from '../../redux/store';

const getServicesAsOptions = (
  services = store.getState().spotlight.services
) => {
  const categories = getCategories(services)
    .map((category) => ({
      ...category,
      services: getServicesByCategory({services, category: category.name}).sort(
        sortByString({key: 'name', direction: 'asc'})
      ),
    }))
    .filter((category) => !!category.services.length)
    .sort(sortByString({key: 'name', direction: 'asc'}));
  const servicesWithoutCategories = [...services]
    .filter(({category}) => !category)
    .sort(sortByString({key: 'name', direction: 'asc'}));

  const parsedCategories = categories.map((category) => ({
    value: `${category.id}`,
    label: category.name,
    options: category.services.map((service) => ({
      value: `${service.id}`,
      label: service.name,
    })),
  }));
  const parsedServicesWithoutCategories = servicesWithoutCategories.map(
    (service) => ({
      value: `${service.id}`,
      label: service.name,
    })
  );
  return [...parsedCategories, ...parsedServicesWithoutCategories];
};

export default getServicesAsOptions;
