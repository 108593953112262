import styled from 'styled-components';

// Colors
import {mediumGrey} from '@matthahn/sally-ui/lib/libs/colors';

const Loader = styled.div`
  width: 100%;
  height: 60px;
  font-size: 40px;
  color: ${mediumGrey};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Loader;
