import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {signOut as signOutAct} from '../../../auth/redux/actions';
import {show as showSpotlightAct} from '../../../spotlight/redux/actions';

// Components
import Navigation from '../../components/Navigation/Navigation';

// drivers
// import driversIcon from '../../../driver/pages/DriversPage/icon';
// import driversLabel from '../../../driver/pages/DriversPage/label';
// import driversPage from '../../../driver/pages/DriversPage/page';
// import driversRoute from '../../../driver/pages/DriversPage/route';

// Libs
import isProduction from '../../../app/lib/isProduction.lib.app';

// locations page
import locationsIcon from '../../../location/pages/LocationsPage/icon';
import locationsLabel from '../../../location/pages/LocationsPage/label';
import locationsPage from '../../../location/pages/LocationsPage/page';
import locationsRoute from '../../../location/pages/LocationsPage/route';

// mechanics page
import mechanicsIcon from '../../../mechanic/pages/MechanicsPage/icon';
import mechanicsLabel from '../../../mechanic/pages/MechanicsPage/label';
import mechanicsPage from '../../../mechanic/pages/MechanicsPage/page';
import mechanicsRoute from '../../../mechanic/pages/MechanicsPage/route';

// owners page
import ownersIcon from '../../../owner/pages/OwnersPage/icon';
import ownersLabel from '../../../owner/pages/OwnersPage/label';
import ownersPage from '../../../owner/pages/OwnersPage/page';
import ownersRoute from '../../../owner/pages/OwnersPage/route';

// permission events
import showPermissionsEvent from '../../../permission/events/show.event.permission';

// permission permissions
import canUpdatePermissionsPermission from '../../../permission/permissions/canUpdatePermissions.permission.permission';

// services page
import customServiceItemsIcon from '../../../service/pages/CustomServiceItemsPage/icon';
import customServiceItemsLabel from '../../../service/pages/CustomServiceItemsPage/label';
import customServiceItemsPage from '../../../service/pages/CustomServiceItemsPage/page';
import customServiceItemsRoute from '../../../service/pages/CustomServiceItemsPage/route';

import serviceItemsIcon from '../../../service/pages/ServiceItemsPage/icon';
import serviceItemsLabel from '../../../service/pages/ServiceItemsPage/label';
import serviceItemsPage from '../../../service/pages/ServiceItemsPage/page';
import serviceItemsRoute from '../../../service/pages/ServiceItemsPage/route';

// tasks page
import tasksIcon from '../../../task/pages/TasksPage/icon';
import tasksLabel from '../../../task/pages/TasksPage/label';
import tasksPage from '../../../task/pages/TasksPage/page';
import tasksRoute from '../../../task/pages/TasksPage/route';

// task page
import taskPage from '../../../task/pages/TaskPage/page';

// ticket page
import ticketPage from '../../../ticket/pages/TicketPage/page';

// tickets page
import ticketsIcon from '../../../ticket/pages/TicketsPage/icon';
import ticketsLabel from '../../../ticket/pages/TicketsPage/label';
import ticketsPage from '../../../ticket/pages/TicketsPage/page';
import ticketsRoute from '../../../ticket/pages/TicketsPage/route';

// ticketStatuses page
import ticketStatusesIcon from '../../../ticketStatus/pages/TicketStatusesPage/icon';
import ticketStatusesLabel from '../../../ticketStatus/pages/TicketStatusesPage/label';
import ticketStatusesPage from '../../../ticketStatus/pages/TicketStatusesPage/page';
import ticketStatusesRoute from '../../../ticketStatus/pages/TicketStatusesPage/route';

// vehicles
import vehiclesIcon from '../../../vehicle/pages/VehiclesPage/icon';
import vehiclesLabel from '../../../vehicle/pages/VehiclesPage/label';
import vehiclesPage from '../../../vehicle/pages/VehiclesPage/page';
import vehiclesRoute from '../../../vehicle/pages/VehiclesPage/route';

// vehicle actions
import vehicleActionPage from '../../../vehicle/pages/VehicleActionPage/page';

// new vehicle
import newVehiclePage from '../../../vehicle/pages/NewVehiclePage/page';

// Libs
import {lib} from '@matthahn/sally-ui';

// ticket events
import showGoToTicketModalEvent from '../../../ticket/events/showGoToTicketModal.event.ticket';

// Helpers
const {notify} = lib;

class NavigationContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    dispatch: PropTypes.func,
    username: PropTypes.string,
  };

  onAvatar = () => {};

  showPermissions = () => {
    if (!this.canUpdatePermissions()) return;
    showPermissionsEvent.publish();
  };

  canUpdatePermissions = () => canUpdatePermissionsPermission();

  openGoToTicketModal = () => showGoToTicketModalEvent.publish();

  onPage = (page) => () => {};

  signOut = (prompt = true) => {
    if (prompt)
      return notify({
        id: 'signOut',
        title: 'Confirm Sign Out',
        icon: undefined,
        content: 'Are you sure you want to sign out?',
        primary: {
          label: 'No',
          onClick: () => null,
        },
        secondary: {
          label: 'Yes',
          onClick: () => this.signOut(false),
        },
        closable: false,
        closeOnOutsideClick: true,
      });

    this.props.dispatch(signOutAct());
  };

  showSpotlight = () => this.props.dispatch(showSpotlightAct());

  actions = () => [
    {
      icon: 'tag',
      onClick: this.openGoToTicketModal,
    },
    // {
    //   icon: 'search1',
    //   onClick: this.showSpotlight,
    // },
    this.canUpdatePermissions() && {
      icon: 'lock',
      onClick: this.showPermissions,
    },
    {
      icon: 'logout',
      onClick: this.signOut,
    },
  ];

  pages = () => {
    return [
      {
        id: ticketsPage,
        icon: ticketsIcon,
        url: ticketsRoute(),
        page: ticketsLabel,
        selected: [ticketPage, ticketsPage].includes(this.props.page),
      },
      {
        id: tasksPage,
        icon: tasksIcon,
        url: tasksRoute(),
        page: tasksLabel,
        selected: [taskPage, tasksPage].includes(this.props.page),
      },
      {
        id: serviceItemsPage,
        icon: serviceItemsIcon,
        url: serviceItemsRoute(),
        page: serviceItemsLabel,
        selected: [serviceItemsPage].includes(this.props.page),
      },
      {
        id: customServiceItemsPage,
        icon: customServiceItemsIcon,
        url: customServiceItemsRoute(),
        page: customServiceItemsLabel,
        selected: [customServiceItemsPage].includes(this.props.page),
      },
      {
        id: vehiclesPage,
        icon: vehiclesIcon,
        url: vehiclesRoute(),
        page: vehiclesLabel,
        selected: [newVehiclePage, vehicleActionPage, vehiclesPage].includes(
          this.props.page
        ),
      },
      // {
      //   id: driversPage,
      //   icon: driversIcon,
      //   url: driversRoute(),
      //   page: driversLabel,
      //   selected: [driversPage].includes(this.props.page),
      // },
      {
        id: mechanicsPage,
        icon: mechanicsIcon,
        url: mechanicsRoute(),
        page: mechanicsLabel,
        selected: [mechanicsPage].includes(this.props.page),
      },
      {
        id: ownersPage,
        icon: ownersIcon,
        url: ownersRoute(),
        page: ownersLabel,
        selected: [ownersPage].includes(this.props.page),
      },
      {
        id: ticketStatusesPage,
        icon: ticketStatusesIcon,
        url: ticketStatusesRoute(),
        page: ticketStatusesLabel,
        selected: [ticketStatusesPage].includes(this.props.page),
      },
      {
        id: locationsPage,
        icon: locationsIcon,
        url: locationsRoute(),
        page: locationsLabel,
        selected: [locationsPage].includes(this.props.page),
      },
    ].filter((page) => !!page);
  };

  render() {
    const {children, username} = this.props;
    return (
      <Navigation
        logoUrl=""
        actions={this.actions()}
        pages={this.pages()}
        avatar={username}
        live={isProduction()}
        onAvatarClick={this.onAvatar}
        onPage={this.onPage}
      >
        {children}
      </Navigation>
    );
  }
}

export default connect((state) => ({
  username: state.auth.user?.username,
  page: state.page.page,
}))(NavigationContainer);
