import styled from 'styled-components';

// Media
import {media} from '@matthahn/sally-ui/lib/libs/media';

const Card = styled.div`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  padding: 12px;

  ${media.xs`
    padding: 8px;
  `}
`;

export default Card;
