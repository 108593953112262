// api lib
import sortQuery from '../../../api/lib/sortQuery.lib.api';

// branch lib
import getBranchName from '../../../branch/lib/getBranchName.lib.branch';

// csv services
import downloadCsvService from '../../../csv/services/download.service.csv';

// events
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// local constants
import columns from './constants/columns.constant';

// owner lib
import getOwnerName from '../../../owner/lib/getName.lib.owner';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

// router
import {withRouter} from 'react-router-dom';

// vehicle api
import listVehiclesApi from '../../api/list.api.vehicle';

// vehicle components
import VehiclesList from '../../components/VehiclesList/VehiclesList';

// vehicle redux actions
import {setQuery as setVehiclesQuery} from '../../redux/actions';

// vehicle routes
import vehicleRoute from '../../pages/VehicleActionPage/route';
import newVehicleRoute from '../../pages/NewVehiclePage/route';

// vehicle sockets
import vehicleCreatedSocket from '../../sockets/created.socket.vehicle';
import vehicleDeletedSocket from '../../sockets/deleted.socket.vehicle';
import vehicleUpdatedSocket from '../../sockets/updated.socket.vehicle';

// uuid
import {v4} from 'uuid';

class VehiclesContainer extends Component {
  static propTypes = {
    branches: PropTypes.array,
    dispatch: PropTypes.func,
    drivers: PropTypes.array,
    history: PropTypes.object,
    owners: PropTypes.array,
    query: PropTypes.object,
    subscribe: PropTypes.func,
  };

  state = {
    downloadingCsv: false,
    loading: true,
    vehicles: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.getVehicles();
    this.props.subscribe(
      vehicleCreatedSocket.subscribe(this.addVehicle),
      vehicleDeletedSocket.subscribe(this.deleteVehicle),
      vehicleUpdatedSocket.subscribe(this.updateVehicle)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  apiId = null;

  getVehicles = async ({
    branches = this.props.query.branches,
    owner = this.props.query.owner,
    page = this.props.query.page,
    perPage = 50,
    search = this.props.query.search,
    sort = this.props.query.sort,
  } = {}) => {
    const {dispatch} = this.props;
    const apiId = v4();
    this.apiId = apiId;
    dispatch(setVehiclesQuery({branches, owner, page, search, sort}));
    this.setState({
      loading: true,
      vehicles: [],
    });
    try {
      const query = this.generateApiQuery({
        branches,
        owner,
        page,
        perPage,
        search,
        sort,
      });

      const {results: vehicles, count} = await listVehiclesApi(query);
      if (this.apiId !== apiId || !this.mounted) return;
      this.setState({
        loading: false,
        vehicles,
      });
      dispatch(setVehiclesQuery({pages: Math.ceil(count / perPage) || 1}));
    } catch (error) {
      if (this.apiId !== apiId || !this.mounted) return;
      this.setState({loading: false});
    }
  };

  generateApiQuery = ({branches, owner, page, perPage, search, sort}) => {
    const query = {
      ...sortQuery(sort),
      limit: perPage,
      offset: (page - 1) * perPage,
    };

    if (!!branches.length) query.branch__in = branches.join(',');
    if (!!owner) query.owner = owner;
    if (!!search) query.search = search;

    return query;
  };

  downloadCsv = async () => {
    const {query} = this.props;
    const {downloadingCsv} = this.state;
    if (downloadingCsv) return;
    this.setState({downloadingCsv: true});
    await downloadCsvService({
      apiCall: listVehiclesApi,
      fileName: 'vehicles.csv',
      query: this.generateApiQuery(query),
      parseData: (vehicles) =>
        [...vehicles].map((vehicle) => ({
          id: vehicle.id,
          branch: getBranchName(vehicle?.branch) || '',
          owner: getOwnerName({ownerId: vehicle?.owner}),
          vin: vehicle?.vin || '',
          svid: vehicle?.svid || '',
          license_plate: vehicle?.license_plate || '',
          medallion: vehicle?.medallion || '',
          make: vehicle?.make || '',
          model: vehicle?.model || '',
          year: vehicle?.year || '',
          george_id: vehicle?.george_id || '',
          kuiper_id: vehicle?.kuiper_id || '',
        })),
    });
    if (!this.mounted) return;
    this.setState({downloadingCsv: false});
  };

  addVehicle = (vehicle) => {
    const {loading} = this.props;
    if (loading) return;
    const vehicles = [...this.state.vehicles, vehicle];
    this.setState({vehicles});
  };

  updateVehicle = (vehicle) => {
    const {loading} = this.props;
    if (loading) return;
    const vehicles = [...this.state.vehicles].map((vehicleToUpdate) =>
      vehicleToUpdate.id === vehicle.id ? vehicle : vehicleToUpdate
    );
    this.setState({vehicles});
  };

  deleteVehicle = ({id}) => {
    const {loading} = this.props;
    if (loading) return;
    const vehicles = [...this.state.vehicles].filter(
      (vehicle) => vehicle.id !== id
    );
    this.setState({vehicles});
  };

  setPage = (page) => {
    if (this.state.loading) return;
    this.getVehicles({page});
  };

  sort = (sort) => this.getVehicles({sort, page: 1});

  search = (search) => this.getVehicles({search, page: 1});

  vehicles = () => this.state.vehicles;

  openVehicle = (vehicle) => () => {
    const {history} = this.props;
    history.push(vehicleRoute(vehicle.id));
  };

  setFilter = ({key, value}) =>
    this.getVehicles({
      [key]: ['all'].includes(value) ? null : value || null,
      page: 1,
    });

  goToNewVehicle = () => {
    this.props.history.push(newVehicleRoute());
  };

  actions = () => {
    const {downloadingCsv} = this.state;
    return [
      {
        key: 'downloadCSV',
        children: 'Download CSV',
        icon: 'download',
        loading: downloadingCsv,
        theme: 'grey',
        onClick: this.downloadCsv,
      },
      {
        key: 'create',
        children: 'New Vehicle',
        icon: 'plus',
        theme: 'black',
        onClick: this.goToNewVehicle,
      },
    ];
  };

  filters = () => {
    const {
      branches,
      owners,
      query: {branches: selectedBranches, owner},
    } = this.props;
    return [
      {
        key: 'branch',
        label: 'Branch',
        type: 'select',
        value: selectedBranches,
        options: [
          ...branches.map(({id, name}) => ({value: `${id}`, label: name})),
        ],
        onChange: (value) =>
          this.setFilter({
            key: 'branches',
            value: value,
          }),
      },
      {
        key: 'owner',
        label: 'Owner',
        type: 'select',
        value: owner,
        options: [
          ...owners.map(({id, name}) => ({value: `${id}`, label: name})),
        ],
        onChange: (value) =>
          this.setFilter({
            key: 'owner',
            value: value,
          }),
      },
    ];
  };

  render() {
    const {
      query: {page, pages, search, sort},
    } = this.props;
    const {loading} = this.state;
    const vehicles = this.vehicles();
    return (
      <VehiclesList
        actions={this.actions()}
        columns={columns}
        filters={this.filters()}
        loading={loading}
        onPage={this.setPage}
        onSearch={this.search}
        onSort={this.sort}
        onVehicle={this.openVehicle}
        page={page}
        pages={pages}
        search={search}
        sort={sort}
        vehicles={vehicles}
      />
    );
  }
}

export default subscriptionHoc(
  withRouter(
    connect((state) => ({
      branches: state.branch.branches,
      drivers: state.spotlight.drivers,
      owners: state.owner.owners,
      vehicles: state.spotlight.vehicles,
      query: state.vehicle.query,
    }))(VehiclesContainer)
  )
);
