import React from 'react';
import PropTypes from 'prop-types';

// date
import prettyDate from '../../../date/lib/pretty.lib.date';

// entry components
import TvEntryCard from '../TvEntryCard/TvEntryCard';

// layout
import BgSlider from '../../../layout/components/BgSlider/BgSlider';
import PageLoader from '../../../layout/components/PageLoader/PageLoader';

// local assets
import image1 from './assets/1.jpg';
import image2 from './assets/2.jpg';
import image3 from './assets/3.jpg';
import image4 from './assets/4.jpg';
import image5 from './assets/5.jpg';
import image6 from './assets/6.jpg';

// local components
import Background from './components/Background';
import Container from './components/Container';
import Date from './components/Date';
import Entries from './components/Entries';
import Header from './components/Header';
// import Logo from './components/Logo';
import Overlay from './components/Overlay';
import Page from './components/Page';
import Sub from './components/Sub';
import Welcome from './components/Welcome';

const PublicDailyEntries = ({loading, entries}) =>
  loading ? (
    <PageLoader />
  ) : (
    <Page>
      <Background>
        <BgSlider images={[image1, image2, image3, image4, image5, image6]} />
      </Background>
      <Overlay />
      <Welcome>
        Welcome
        <Sub>to Drive Sally</Sub>
      </Welcome>
      <Date>{prettyDate()}</Date>
      <Container>
        <Header>Schedule</Header>
        {entries.map((entry) => (
          <Entries key={entry.id}>
            <TvEntryCard
              date={entry?.date || null}
              vehicle={entry?.vehicle || null}
              driver={entry?.driver || null}
              highlight={entry?.in30Minutes}
            />
          </Entries>
        ))}
      </Container>
    </Page>
  );

PublicDailyEntries.propTypes = {
  loading: PropTypes.bool,
  entries: PropTypes.array,
};

export default PublicDailyEntries;
